import React, { FC } from "react";
import { classNames } from "helpers/classNames";
interface Props {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

const DangerButton: FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  size = "medium",
  fullWidth = false,
  isLoading = false,
  className,
  loadingText = "Sparar...",
  ...rest
}) => {
  const getClassForSize = (size: "small" | "medium" | "large") => {
    switch (size) {
      case "small":
        return classNames(
          "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300",
          fullWidth && "w-full"
        );
      case "large":
        return classNames(
          "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300",
          fullWidth && "w-full"
        );
      case "medium":
      default:
        return classNames(
          "inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm",
          fullWidth && "w-full"
        );
    }
  };
  return (
    <button
      type="button"
      className={classNames(getClassForSize(size), className ?? false)}
      {...rest}
    >
      {isLoading && (
        <svg
          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {isLoading ? loadingText : <>{children}</>}
    </button>
  );
};

export default DangerButton;
