import React, { FC } from "react";
import { classNames } from "helpers/classNames";
import { ClockIcon } from "@heroicons/react/20/solid";
interface Props {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

const TimeReportButton: FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  size = "medium",
  fullWidth = false,
  isLoading = false,
  loadingText = "Sparar...",
  className,
  ...rest
}) => {
  const getClassForSize = (size: "small" | "medium" | "large") => {
    switch (size) {
      case "small":
        return classNames(
          "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-semibold rounded shadow-sm text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none disabled:bg-gray-400",
          fullWidth && "w-full"
        );
      case "large":
        return classNames(
          "inline-flex items-center px-4 py-2 border border-transparent text-sm font-semibold rounded-md shadow-sm text-white bg-indigo-300 hover:bg-indigo-400 focus:outline-none disabled:bg-gray-400",
          fullWidth && "w-full"
        );
      case "medium":
      default:
        return classNames(
          "inline-flex items-center px-3 pl-2.5 py-2 border border-transparent text-sm leading-4 font-semibold rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none disabled:bg-gray-400",
          fullWidth && "w-full"
        );
    }
  };
  return (
    <button
      type="button"
      className={classNames(getClassForSize(size), !!className && className)}
      {...rest}
    >
      <ClockIcon className="mr-2 h-4 w-4 text-white" aria-hidden="true" />
      {isLoading ? loadingText : <>{children}</>}
    </button>
  );
};

export default TimeReportButton;
