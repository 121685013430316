import { FC } from "react";
import PrimaryButton from "components/buttons/PrimaryButton";

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export const FormFooter: FC<Props> = ({ onCancel, onSubmit, isLoading }) => (
  <div className="pt-5">
    <div className="flex justify-end">
      <button
        type="button"
        onClick={onCancel}
        className="mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
      >
        Avbryt
      </button>
      <PrimaryButton onClick={onSubmit} isLoading={isLoading}>
        Spara
      </PrimaryButton>
    </div>
  </div>
);
