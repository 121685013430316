import { FC, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import SecondaryButton from "./buttons/SecondaryButton";
import PrimaryButton from "./buttons/PrimaryButton";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import { CheckboxStupid } from "./CheckboxStupid";

interface Props {
  header: string;
  cancel: () => void;
  sendImageToParent: (imageURL: string, sendToWasteStation: boolean) => void;
  isLoading?: boolean;
  enableSendToWasteStation?: boolean;
}
export const SignatureModal: FC<Props> = ({
  // header,
  sendImageToParent,
  cancel,
  isLoading = false,
  enableSendToWasteStation,
}) => {
  const canvasRefSmall = useRef() as React.MutableRefObject<any>;
  const canvasRefLarge = useRef() as React.MutableRefObject<any>;

  const [sendToWasteStation, setSendToWasteStation] = useState(
    !!enableSendToWasteStation
  );

  const onSave = () => {
    if (!canvasRefSmall.current.isEmpty()) {
      sendImageToParent(
        canvasRefSmall.current.getTrimmedCanvas().toDataURL("image/png"),
        sendToWasteStation
      );
    } else if (!canvasRefLarge.current.isEmpty()) {
      sendImageToParent(
        canvasRefLarge.current.getTrimmedCanvas().toDataURL("image/png"),
        sendToWasteStation
      );
    } else {
      throw Error("Båda tomma");
    }
  };

  const clear = () => {
    canvasRefSmall.current?.clear();
    canvasRefLarge.current?.clear();
  };

  return (
    <div className=" bg-white py-4 sm:pb-0">
      {/* <div className="fixed top-0 left-0 h-screen w-screen bg-white p-4 z-50 sm:pl-24"> */}
      <div className="flex justify-end mb-1 sm:max-w-sm">
        <SecondaryButton size="small" onClick={clear}>
          <BackspaceIcon className="h-3 w-auto mr-1" />
          Rensa
        </SecondaryButton>
      </div>
      <div className="border border-gray-700 bg-white inline-block sm:hidden">
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 323, height: 129 }}
          ref={canvasRefSmall}
        />
      </div>
      <div className="border border-gray-700 bg-white hidden sm:inline-block">
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 405, height: 162 }}
          ref={canvasRefLarge}
        />
      </div>
      {!!enableSendToWasteStation && (
        <div className="my-6">
          <CheckboxStupid
            name="sendToWasteStation"
            label="Skicka transportdokument till mottagarstation"
            setChecked={setSendToWasteStation}
            checked={sendToWasteStation}
            small
          />
        </div>
      )}
      <div className="flex justify-between sm:max-w-sm">
        <SecondaryButton size="medium" onClick={cancel}>
          Avbryt
        </SecondaryButton>
        <PrimaryButton size="large" onClick={onSave} isLoading={isLoading}>
          {`Spara${sendToWasteStation ? " och skicka" : ""}`}
        </PrimaryButton>
      </div>
    </div>
  );
};
