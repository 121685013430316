import { useTasks } from "api/task.api";
import { PageLoader } from "components/PageLoader";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { capitalizeFirstLetter } from "helpers/string";
import { useSearchParams } from "react-router-dom";
import { EmailNotSent } from "./EmailNotSent";
// import { NotInvoiced } from "./NotInvoiced";
import { Tasks } from "./Tasks";
import {
  useAssignmentsNotEmailed,
  useAssignmentsWaitingForTimeReportConfirmation,
  useIgnoreEmailReminder,
} from "api/assignment.api";
import { useFailedToSendEmails, usePatchEmail } from "api/email.api";
import { AssignmentType } from "enums/AssignmentType.enum";
import { Assignment } from "types/assignment.type";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { FiveYearsInspection } from "types/fiveYearsInspection/fiveYearsInspection.type";
import { Tomning } from "types/tomning.type";
import { Tidsrapportering } from "./Tidsrapportering";
import userStore, { getUserCompanyId } from "store/user.store";
import { CompanyId } from "enums/CompanyId.enum";
import { useTransportDocumentNotReportedToNv } from "api/transportDocument.api";
import { AvfallsregistretTab } from "./AvfallsregistretTab";
import { RouteUrl } from "routes.type";
import { useCompany } from "api/company.api";

export enum DashboardTabs {
  Report = "rapporter",
  Tasks = "kommande",
  Tidsrapportering = "tidsrapportering",
  Avfallsregistret = "avfallsregistret",
}
// const INVOICE_TAB = "fakturering";

export default function DashboardPage() {
  // Email reminders
  const {
    data: assignmentsNotEmailed,
    isLoading: isLoadingAssignmentsNotEmailed,
  } = useAssignmentsNotEmailed();

  const companyId = userStore(getUserCompanyId) ?? -1;

  const { data: company } = useCompany(companyId);

  const { mutate: ignoreEmailReminder, isLoading: isLoadingEmailReminders } =
    useIgnoreEmailReminder();

  const handleIgnore = (assignmentId: number, type: AssignmentType) =>
    ignoreEmailReminder({ assignmentId, type });

  // Failed to send emails
  const { data: failedToSendEmails } = useFailedToSendEmails();

  const { mutate: ignoreFailedEmail } = usePatchEmail();

  const handleIgnoreFailedEmail = (emailId: number) =>
    ignoreFailedEmail({
      id: emailId,
      payload: {
        isErrorConfirmed: true,
      },
    });

  // Tasks
  const { data: tasks } = useTasks();

  // tidsrapportering
  const { data: assignmentsNotTimeReportConfirmed } =
    useAssignmentsWaitingForTimeReportConfirmation();

  // Rapportering till avfallsregistret
  const { data: notReportedToEnv } = useTransportDocumentNotReportedToNv();

  // Invoicing
  // const {
  //   data: inspectionsNotInvoiced,
  //   isLoading: isLoadingInspectionsNotInvoiced,
  // } = useInspectionsNotInvoiced();

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || DashboardTabs.Tasks;

  if (
    isLoadingAssignmentsNotEmailed ||
    isLoadingEmailReminders
    //  || isLoadingInspectionsNotInvoiced
  ) {
    return <PageLoader />;
  }

  const emailNotSentList = getEmailNotSentAssignments(
    assignmentsNotEmailed ?? []
  );
  const emailNotificationCount =
    emailNotSentList.length + (failedToSendEmails ?? []).length;

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            label: capitalizeFirstLetter(DashboardTabs.Tasks),
            labelName: "Schemaläggning",

            content: (
              <div className="flex-col space-y-4">
                <p className="mb-6 text-sm text-gray-700 max-w-lg">
                  Här listas de uppdrag ni behöver utföra de kommande veckorna
                  men som ej än är schemalagda. Listan genereras av påminnelser
                  ni aktiverat på era objekt.
                </p>
                <Tasks tasks={tasks} />
              </div>
            ),
            isSelected: currentTab === DashboardTabs.Tasks,
            badge:
              !!tasks && tasks.length > 0 ? tasks.length.toString() : undefined,
          },
          {
            label: capitalizeFirstLetter(DashboardTabs.Report),
            labelName: "Rapporter till kund",
            content: (
              <EmailNotSent
                assignments={emailNotSentList}
                handleIgnore={handleIgnore}
                failedToSendEmails={failedToSendEmails}
                ignoreFailedEmail={handleIgnoreFailedEmail}
              />
            ),
            isSelected: currentTab === DashboardTabs.Report,
            badge:
              emailNotificationCount > 0
                ? emailNotificationCount.toString()
                : undefined,
          },
          {
            label: capitalizeFirstLetter(DashboardTabs.Avfallsregistret),
            labelName: "Avfallsregistret",
            content: <AvfallsregistretTab items={notReportedToEnv ?? []} />,
            isSelected: currentTab === DashboardTabs.Avfallsregistret,
            badge:
              (notReportedToEnv ?? []).length > 0
                ? (notReportedToEnv ?? []).length.toString()
                : undefined,
            isHidden: !(company?.isNvActive === true),
          },
          {
            label: capitalizeFirstLetter(DashboardTabs.Tidsrapportering),
            labelName: "Tidsrapportering",
            content: (
              <Tidsrapportering
                assignments={assignmentsNotTimeReportConfirmed}
              />
            ),
            isSelected: currentTab === DashboardTabs.Tidsrapportering,
            badge:
              (assignmentsNotTimeReportConfirmed ?? []).length > 0
                ? (assignmentsNotTimeReportConfirmed ?? []).length.toString()
                : undefined,
            isHidden: companyId !== CompanyId.UTAB,
          },
        ]}
        setTab={setTab}
        isDashboard
      />
    </>
  );
}

export const getLinkToDashboardPage = (tab?: DashboardTabs) =>
  `${RouteUrl.App}/${RouteUrl.Dashboard}${tab ? `?tab=${tab}` : ""}`;

export type EmailNotSentAssignment = {
  type: AssignmentType;
  id: number;
  key: string;
  sixMonthsInspection?: SixMonthsInspection;
  fiveYearsInspection?: FiveYearsInspection;
  tomning?: Tomning;
  customerName: string;
  customerId: number;
  separatorId: number;
};

const getEmailNotSentAssignments = (
  assignments: Assignment[]
): EmailNotSentAssignment[] => {
  const result = [];
  for (const assignment of assignments) {
    if (assignment.sixMonthsInspection) {
      result.push({
        type: AssignmentType.SixMonthsInspection,
        id: assignment.id,
        key: `${assignment.id}-${AssignmentType.SixMonthsInspection}`,
        sixMonthsInspection: assignment.sixMonthsInspection,
        customerName: assignment.isProxyAssignment
          ? (assignment.parentCustomer?.name as string)
          : assignment.customer.name,
        customerId: assignment.customer.id,
        separatorId: assignment.separatorId,
      });
    }
    if (assignment.fiveYearsInspection) {
      result.push({
        type: AssignmentType.FiveYearInspection,
        id: assignment.id,
        key: `${assignment.id}-${AssignmentType.FiveYearInspection}`,
        fiveYearsInspection: assignment.fiveYearsInspection,
        customerName: assignment.isProxyAssignment
          ? (assignment.parentCustomer?.name as string)
          : assignment.customer.name,
        customerId: assignment.customer.id,
        separatorId: assignment.separatorId,
      });
    }
    if (assignment.tomning) {
      result.push({
        type: AssignmentType.Tomning,
        id: assignment.id,
        key: `${assignment.id}-${AssignmentType.Tomning}`,
        tomning: assignment.tomning,
        customerName: assignment.isProxyAssignment
          ? (assignment.parentCustomer?.name as string)
          : assignment.customer.name,
        customerId: assignment.customer.id,
        separatorId: assignment.separatorId,
      });
    }
  }
  return result;
};
