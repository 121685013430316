import { FC } from "react";
import { classNames } from "helpers/classNames";
import { InspectionResult } from "types/enums";

type RadioItem = {
  label: string;
  value: InspectionResult;
  isNegative?: boolean;
};
type RadioGroupProps = {
  label: string;
  items: RadioItem[];
  checkedValue?: InspectionResult;
  setCheckedValue: (value: InspectionResult) => void;
};
export const RadioGroupLR: FC<RadioGroupProps> = ({
  label,
  items,
  checkedValue,
  setCheckedValue,
}) => {
  return (
    <div className="sm:py-4 sm:border-t sm:border-gray-50">
      <div role="group" aria-labelledby={`label-${label}`}>
        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
          <div>
            <div
              className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-500"
              id={`label-${label}`}
            >
              {label}
            </div>
          </div>

          <div className="sm:col-span-1">
            <div className="max-w-md">
              <div className="mt-4 space-y-4">
                {items.map((item) => (
                  <div key={item.value} className="flex items-center">
                    <input
                      id={`${label}-${item.value}`}
                      value={item.value}
                      checked={checkedValue === item.value}
                      type="radio"
                      className={classNames(
                        " h-4 w-4 border-gray-300",
                        item.isNegative
                          ? "focus:ring-red-400 text-red-500"
                          : "focus:ring-cyan-400 text-cyan-500"
                      )}
                      onChange={() => setCheckedValue(item.value)}
                    />
                    <label
                      htmlFor={`${label}-${item.value}`}
                      className="ml-3 block text-sm font-medium text-gray-900"
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
