import { TomningQueryKey } from "api/tomning.api";
import {
  TransportDocumentQueryKey,
  useAddWaste,
} from "api/transportDocument.api";
import { useDeleteWaste, useUpdateWaste } from "api/waste.api";
import { useWasteCodeDropDown } from "api/wasteCode.api";
import { ComboBoxItem } from "components/ComboBox";
import { useNotification } from "hooks/useNotification";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { TransportDocument } from "types/transportDocument.type";
import { CreateWasteDTO, UpdateWasteDTO } from "types/waste.type";

export const useWasteLogic = (
  transportDocument?: TransportDocument,
  tomningId?: number
) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  // Update waste
  const [wasteIdToEdit, setWasteIdToEdit] = useState<number | undefined>();
  const wasteToEdit = transportDocument?.wasteList.find(
    ({ id }) => id === wasteIdToEdit
  );

  const { mutate: updateWaste, isLoading: isUpdatingWaste } = useUpdateWaste({
    onSuccess: () => {
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetOne,
        transportDocument?.id,
      ]);
      if (tomningId) {
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomningId]);
      }
      //   notification.info("Avfall sparat");
      handleCloseUpdateWasteModal();
    },
  });

  const {
    register: registerUpdateWaste,
    handleSubmit: handleWasteSubmit,
    reset: resetWaste,
    formState: { errors: updateWasteErrors },
  } = useForm<UpdateWasteDTO>({});

  const onUpdateWasteSubmit = handleWasteSubmit((data: UpdateWasteDTO) => {
    if (!wasteToEdit) {
      throw Error("no waste to edit");
    }
    updateWaste({ ...wasteToEdit, ...data });
  });

  const handleCloseUpdateWasteModal = () => {
    setWasteIdToEdit(undefined);
    // setTimeout(() => setWasteIdToEdit(undefined), 300);
  };

  useEffect(() => {
    if (wasteToEdit) {
      resetWaste({
        weightPreliminary: wasteToEdit.weightPreliminary,
        quantity: wasteToEdit.quantity,
        comment: wasteToEdit.comment,
      });
    }
    if (!wasteToEdit) {
      resetWaste();
    }
  }, [wasteToEdit, resetWaste]);

  // Add waste
  const [isWasteModalOpen, setIsWasteModalOpen] = useState<boolean>(false);
  const [wasteCodeQuery, setWasteCodeQuery] = useState<string>("");
  const { data: wasteCodes } = useWasteCodeDropDown(wasteCodeQuery);
  const [selectedWasteCode, setSelectedWasteCode] = useState<ComboBoxItem>({
    id: -1,
    name: "",
    description: "",
  });

  const handleSetWasteCodeQuery = (query: string) =>
    setWasteCodeQuery(query.toLowerCase());

  const wasteCodeComboBoxItems: ComboBoxItem[] = (wasteCodes ?? []).map(
    (c) => ({
      id: c.id,
      name: c.codePretty,
      description: c.description,
    })
  );

  const { mutate: addNewWaste, isLoading: isAddingNewWaste } = useAddWaste(
    transportDocument?.id || -1,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          TransportDocumentQueryKey.GetOne,
          transportDocument?.id,
        ]);
        if (tomningId) {
          queryClient.invalidateQueries([TomningQueryKey.GetOne, tomningId]);
        }
        notification.info("Avfall sparat");
        handleNewWasteModalClose();
      },
      onError: () => {
        notification.error("Något gick fel");
      },
    }
  );

  const [wasteCodeError, setWasteCodeError] = useState(false);
  useEffect(() => {
    if (wasteCodeError && selectedWasteCode.id > 0) {
      setWasteCodeError(false);
    }
  }, [wasteCodeError, selectedWasteCode]);
  const {
    register: registerNewWaste,
    handleSubmit: handleSubmitNewWaste,
    reset: resetNewWaste,
    formState: { errors: newWasteErrors },
  } = useForm<CreateWasteDTO>({
    defaultValues: {
      weightPreliminary: "",
      comment: "",
    },
  });

  const onNewWasteSubmit = handleSubmitNewWaste((data: CreateWasteDTO) => {
    if (!(selectedWasteCode.id > 0)) {
      setWasteCodeError(true);
      return;
    }

    addNewWaste({ ...data, wasteCodeId: selectedWasteCode.id });
  });

  const handleNewWasteModalClose = () => {
    setIsWasteModalOpen(false);
    resetNewWaste();
    setSelectedWasteCode({ id: -1, name: "", description: "" });
    setWasteCodeError(false);
  };

  // Delete waste

  // state for modal
  const [wasteIdToDelete, setWasteIdToDelete] = useState<number | undefined>();

  const wasteToDelete = transportDocument?.wasteList.find(
    ({ id }) => id === wasteIdToDelete
  );

  const { mutate: deleteWaste } = useDeleteWaste({
    onSuccess: () => {
      // handleNewWasteModalClose();
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetOne,
        transportDocument?.id,
      ]);
      if (tomningId) {
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomningId]);
      }
      setWasteIdToDelete(undefined);
      notification.info("Avfall borttaget");
    },
    onError: () => {
      notification.error("Något gick fel");
    },
  });

  const onDeleteConfirm = () => {
    if (!wasteIdToDelete) {
      throw Error("onDeleteConfirm wasteIdToDelete is undefined");
    }
    deleteWaste(wasteIdToDelete);
  };

  return {
    //update
    registerUpdateWaste,
    isUpdatingWaste,
    onUpdateWasteSubmit,
    wasteIdToEdit,
    setWasteIdToEdit,
    wasteToEdit,
    handleCloseUpdateWasteModal,
    updateWasteErrors,
    // add
    registerNewWaste,
    isAddingNewWaste,
    onNewWasteSubmit,
    handleNewWasteModalClose,
    handleSetWasteCodeQuery,
    wasteCodeComboBoxItems,
    isWasteModalOpen,
    setIsWasteModalOpen,
    wasteCodeError,
    selectedWasteCode,
    setSelectedWasteCode,
    newWasteErrors,
    // delete
    onDeleteConfirm,
    wasteToDelete,
    wasteIdToDelete,
    setWasteIdToDelete,
  };
};
