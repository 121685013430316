import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { FC, useEffect, useState } from "react";
import { config } from "react-spring";
import { Transition } from "./animations/Transition";

interface Props {
  isMissing?: boolean;
  text?: string;
}
export const RequiredForInspection: FC<Props> = ({
  isMissing = false,
  text = "Krävs för rapporter",
}) => {
  // Varje gång allt blir ok ska jag animera
  // Men inte första gången
  // testa en timeout
  const [hasRenderedFirstTime, setHasRenderedFirstTime] = useState(false);

  useEffect(() => {
    setTimeout(() => setHasRenderedFirstTime(true), 500);
  }, []);

  const [showCheckMark, setShowCheckMark] = useState(false);

  useEffect(() => {
    if (!isMissing && hasRenderedFirstTime) {
      // Detta betyder att vi ska visa det animerade checkmarket
      setShowCheckMark(true);
      setTimeout(() => setShowCheckMark(false), 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMissing]);

  return (
    <div className="flex ml-2 items-center justify-between">
      {isMissing && (
        <>
          <InformationCircleIcon
            className="h-4 w-4 text-indigo-300"
            // className="h-5 w-5 text-yellow-600"
            aria-hidden="true"
          />
          <span className="text-sm ml-1 text-indigo-400">{text}</span>
        </>
      )}
      <Transition
        settings={{
          from: { opacity: 0, transform: "scale(0) translateY(0px)" },
          enter: { opacity: 1, transform: "scale(1) translateY(0)" },
          leave: { opacity: 0 },
          config: config.wobbly,
          delay: 100,
        }}
        isVisible={showCheckMark}
      >
        <CheckCircleIcon className="h-5 w-5 text-cyan-400" />
      </Transition>
    </div>
  );
};
