import { useGuessKommun } from "api/postnummer.api";
import Dropdown from "components/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { Tips } from "components/Tips";
import { removeWhitespace } from "helpers/string";
import { FC, useEffect } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { KommunList } from "types/Kommun.type";
import {
  getSeparatorLabel,
  separatorTypeToString,
} from "types/separator/separator.helpers";
import {
  CreateSeparatorDTO,
  SeparatorType,
} from "types/separator/separator.type";

interface Props {
  register: UseFormRegister<CreateSeparatorDTO>;
  errors: any;
  copyAddressFromCustomer: () => void;
  selectedSeparatorType: SeparatorType;
  setValue: UseFormSetValue<CreateSeparatorDTO>;
  postnummer: string;
}

export const SeparatorMetadataForm: FC<Props> = ({
  register,
  errors,
  copyAddressFromCustomer,
  selectedSeparatorType,
  setValue,
  postnummer,
}) => {
  const mutation = useGuessKommun();
  useEffect(() => {
    if (mutation.data) {
      setValue("kommunKod", mutation.data);
    }
  }, [mutation.data, setValue]);

  const SEPARATOR_TYPES = [
    {
      value: SeparatorType.OilAndSlam,
      label: separatorTypeToString(SeparatorType.OilAndSlam),
    },
    {
      value: SeparatorType.Oil,
      label: separatorTypeToString(SeparatorType.Oil),
    },
    {
      value: SeparatorType.Slam,
      label: separatorTypeToString(SeparatorType.Slam),
    },
    {
      value: SeparatorType.Tvattranna,
      label: separatorTypeToString(SeparatorType.Tvattranna),
    },
    {
      value: SeparatorType.Fritext,
      label: separatorTypeToString(SeparatorType.Fritext),
    },
  ];
  return (
    <ModalContentWrapper>
      <Dropdown
        items={SEPARATOR_TYPES}
        name="type"
        label={getSeparatorLabel("type")}
        register={register}
        // disabled={isEdit}
      />
      {selectedSeparatorType === SeparatorType.Fritext && (
        <TextInput
          label={"Ange typ"}
          name="fritextType"
          register={register}
          autoFocus
          // placeholder="Tvättränna, etc"
          validation={{ required: "Obligatoriskt fält" }}
          errorMessage={errors?.fritextType?.message}
        />
      )}
      <TextInput
        label={getSeparatorLabel("identifier")}
        name="identifier"
        register={register}
        placeholder="ID eller egen märkning/beskrivning"
        validation={{ required: "Obligatoriskt fält" }}
        errorMessage={errors?.identifier?.message}
      />
      <TextInput
        label={getSeparatorLabel("propertyDesignation")}
        name="propertyDesignation"
        register={register}
      />
      <TextInput
        label={getSeparatorLabel("address")}
        name="address"
        register={register}
        action={copyAddressFromCustomer}
        actionText="Kopiera adress från kund"
      />
      <TextInput
        label={getSeparatorLabel("postalCode")}
        name="postalCode"
        register={register}
      />
      <TextInput
        label={getSeparatorLabel("city")}
        name="city"
        register={register}
      />
      <TextInput
        label={getSeparatorLabel("cfarNR")}
        name="cfarNR"
        register={register}
        link="https://cfarnrsok.scb.se/"
        linkText="Hitta"
      />

      <Dropdown
        items={KommunList.map((kommun) => ({
          label: kommun.label,
          value: kommun.code,
        }))}
        label="Kommun"
        name="kommunKod"
        register={register}
        action={
          !postnummer
            ? undefined
            : () =>
                mutation.mutate({ postnummer: removeWhitespace(postnummer) })
        }
        actionText="Beräkna från postnummer"
        isActionLoading={mutation.isLoading}
        descriptionItemText=" "
      />
      <Tips
        id="alksdjljdskfjldskfjsldkfjlsdkfjp90p3i209pofk+2ef"
        paragraphs={[
          `Ange ${getSeparatorLabel(
            "coordinates"
          )} för att enklare hitta objektet vid nästa tillfälle. För rapportering till Avfallsregistret krävs dessutom antingen adress eller koordinater. Öppna Google Maps, högerklicka på platsen och klicka på koordinaterna så kopieras de till urklipp. Klistra sedan in dem nedan. `,
        ]}
      />
      <TextInput
        name="coordinates"
        placeholder="xx.xxxxxxxxxxxxxx, yy.yyyyyyyyyyyyyy"
        label={getSeparatorLabel("coordinates")}
        // placeholder="49.4678135885645, 17.114995931269636"
        register={register}
        link="https://www.google.se/maps/"
        linkText="Öppna Google Maps"
      />
      <TextArea
        name="notes"
        label={getSeparatorLabel("notes")}
        register={register}
      />
    </ModalContentWrapper>
  );
};
