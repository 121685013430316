export type Kommun = {
  code: string;
  label: string;
};

export const GetKommunLabel = (code: string): string =>
  KommunList.find((kommun) => kommun.code === code)?.label || "";

export const KommunList: Kommun[] = [
  {
    code: "1440",
    label: "Ale (1440)",
  },
  {
    code: "1489",
    label: "Alingsås (1489)",
  },
  {
    code: "0764",
    label: "Alvesta (0764)",
  },
  {
    code: "0604",
    label: "Aneby (0604)",
  },
  {
    code: "1984",
    label: "Arboga (1984)",
  },
  {
    code: "2506",
    label: "Arjeplog (2506)",
  },
  {
    code: "2505",
    label: "Arvidsjaur (2505)",
  },
  {
    code: "1784",
    label: "Arvika (1784)",
  },
  {
    code: "1882",
    label: "Askersund (1882)",
  },
  {
    code: "2084",
    label: "Avesta (2084)",
  },
  {
    code: "1460",
    label: "Bengtsfors (1460)",
  },
  {
    code: "2326",
    label: "Berg (2326)",
  },
  {
    code: "2403",
    label: "Bjurholm (2403)",
  },
  {
    code: "1260",
    label: "Bjuv (1260)",
  },
  {
    code: "2582",
    label: "Boden (2582)",
  },
  {
    code: "1443",
    label: "Bollebygd (1443)",
  },
  {
    code: "2183",
    label: "Bollnäs (2183)",
  },
  {
    code: "0885",
    label: "Borgholm (0885)",
  },
  {
    code: "2081",
    label: "Borlänge (2081)",
  },
  {
    code: "1490",
    label: "Borås (1490)",
  },
  {
    code: "0127",
    label: "Botkyrka (0127)",
  },
  {
    code: "0560",
    label: "Boxholm (0560)",
  },
  {
    code: "1272",
    label: "Bromölla (1272)",
  },
  {
    code: "2305",
    label: "Bräcke (2305)",
  },
  {
    code: "1231",
    label: "Burlöv (1231)",
  },
  {
    code: "1278",
    label: "Båstad (1278)",
  },
  {
    code: "1438",
    label: "Dals-Ed (1438)",
  },
  {
    code: "0162",
    label: "Danderyd (0162)",
  },
  {
    code: "1862",
    label: "Degerfors (1862)",
  },
  {
    code: "2425",
    label: "Dorotea (2425)",
  },
  {
    code: "1730",
    label: "Eda (1730)",
  },
  {
    code: "0125",
    label: "Ekerö (0125)",
  },
  {
    code: "0686",
    label: "Eksjö (0686)",
  },
  {
    code: "0862",
    label: "Emmaboda (0862)",
  },
  {
    code: "0381",
    label: "Enköping (0381)",
  },
  {
    code: "0484",
    label: "Eskilstuna (0484)",
  },
  {
    code: "1285",
    label: "Eslöv (1285)",
  },
  {
    code: "1445",
    label: "Essunga (1445)",
  },
  {
    code: "1982",
    label: "Fagersta (1982)",
  },
  {
    code: "1382",
    label: "Falkenberg (1382)",
  },
  {
    code: "1499",
    label: "Falköping (1499)",
  },
  {
    code: "2080",
    label: "Falun (2080)",
  },
  {
    code: "1782",
    label: "Filipstad (1782)",
  },
  {
    code: "0562",
    label: "Finspång (0562)",
  },
  {
    code: "0482",
    label: "Flen (0482)",
  },
  {
    code: "1763",
    label: "Forshaga (1763)",
  },
  {
    code: "1439",
    label: "Färgelanda (1439)",
  },
  {
    code: "2026",
    label: "Gagnef (2026)",
  },
  {
    code: "0662",
    label: "Gislaved (0662)",
  },
  {
    code: "0461",
    label: "Gnesta (0461)",
  },
  {
    code: "0617",
    label: "Gnosjö (0617)",
  },
  {
    code: "0980",
    label: "Gotland (0980)",
  },
  {
    code: "1764",
    label: "Grums (1764)",
  },
  {
    code: "1444",
    label: "Grästorp (1444)",
  },
  {
    code: "1447",
    label: "Gullspång (1447)",
  },
  {
    code: "2523",
    label: "Gällivare (2523)",
  },
  {
    code: "2180",
    label: "Gävle (2180)",
  },
  {
    code: "1480",
    label: "Göteborg (1480)",
  },
  {
    code: "1471",
    label: "Götene (1471)",
  },
  {
    code: "0643",
    label: "Habo (0643)",
  },
  {
    code: "1783",
    label: "Hagfors (1783)",
  },
  {
    code: "1861",
    label: "Hallsberg (1861)",
  },
  {
    code: "1961",
    label: "Hallstahammar (1961)",
  },
  {
    code: "1380",
    label: "Halmstad (1380)",
  },
  {
    code: "1761",
    label: "Hammarö (1761)",
  },
  {
    code: "0136",
    label: "Haninge (0136)",
  },
  {
    code: "2583",
    label: "Haparanda (2583)",
  },
  {
    code: "0331",
    label: "Heby (0331)",
  },
  {
    code: "2083",
    label: "Hedemora (2083)",
  },
  {
    code: "1283",
    label: "Helsingborg (1283)",
  },
  {
    code: "1466",
    label: "Herrljunga (1466)",
  },
  {
    code: "1497",
    label: "Hjo (1497)",
  },
  {
    code: "2104",
    label: "Hofors (2104)",
  },
  {
    code: "0126",
    label: "Huddinge (0126)",
  },
  {
    code: "2184",
    label: "Hudiksvall (2184)",
  },
  {
    code: "0860",
    label: "Hultsfred (0860)",
  },
  {
    code: "1315",
    label: "Hylte (1315)",
  },
  {
    code: "0305",
    label: "Håbo (0305)",
  },
  {
    code: "1863",
    label: "Hällefors (1863)",
  },
  {
    code: "2361",
    label: "Härjedalen (2361)",
  },
  {
    code: "2280",
    label: "Härnösand (2280)",
  },
  {
    code: "1401",
    label: "Härryda (1401)",
  },
  {
    code: "1293",
    label: "Hässleholm (1293)",
  },
  {
    code: "1284",
    label: "Höganäs (1284)",
  },
  {
    code: "0821",
    label: "Högsby (0821)",
  },
  {
    code: "1266",
    label: "Hörby (1266)",
  },
  {
    code: "1267",
    label: "Höör (1267)",
  },
  {
    code: "2510",
    label: "Jokkmokk (2510)",
  },
  {
    code: "0123",
    label: "Järfälla (0123)",
  },
  {
    code: "0680",
    label: "Jönköping (0680)",
  },
  {
    code: "2514",
    label: "Kalix (2514)",
  },
  {
    code: "0880",
    label: "Kalmar (0880)",
  },
  {
    code: "1446",
    label: "Karlsborg (1446)",
  },
  {
    code: "1082",
    label: "Karlshamn (1082)",
  },
  {
    code: "1883",
    label: "Karlskoga (1883)",
  },
  {
    code: "1080",
    label: "Karlskrona (1080)",
  },
  {
    code: "1780",
    label: "Karlstad (1780)",
  },
  {
    code: "0483",
    label: "Katrineholm (0483)",
  },
  {
    code: "1715",
    label: "Kil (1715)",
  },
  {
    code: "0513",
    label: "Kinda (0513)",
  },
  {
    code: "2584",
    label: "Kiruna (2584)",
  },
  {
    code: "1276",
    label: "Klippan (1276)",
  },
  {
    code: "0330",
    label: "Knivsta (0330)",
  },
  {
    code: "2282",
    label: "Kramfors (2282)",
  },
  {
    code: "1290",
    label: "Kristianstad (1290)",
  },
  {
    code: "1781",
    label: "Kristinehamn (1781)",
  },
  {
    code: "2309",
    label: "Krokom (2309)",
  },
  {
    code: "1881",
    label: "Kumla (1881)",
  },
  {
    code: "1384",
    label: "Kungsbacka (1384)",
  },
  {
    code: "1960",
    label: "Kungsör (1960)",
  },
  {
    code: "1482",
    label: "Kungälv (1482)",
  },
  {
    code: "1261",
    label: "Kävlinge (1261)",
  },
  {
    code: "1983",
    label: "Köping (1983)",
  },
  {
    code: "1381",
    label: "Laholm (1381)",
  },
  {
    code: "1282",
    label: "Landskrona (1282)",
  },
  {
    code: "1860",
    label: "Laxå (1860)",
  },
  {
    code: "1814",
    label: "Lekeberg (1814)",
  },
  {
    code: "2029",
    label: "Leksand (2029)",
  },
  {
    code: "1441",
    label: "Lerum (1441)",
  },
  {
    code: "0761",
    label: "Lessebo (0761)",
  },
  {
    code: "0186",
    label: "Lidingö (0186)",
  },
  {
    code: "1494",
    label: "Lidköping (1494)",
  },
  {
    code: "1462",
    label: "Lilla Edet (1462)",
  },
  {
    code: "1885",
    label: "Lindesberg (1885)",
  },
  {
    code: "0580",
    label: "Linköping (0580)",
  },
  {
    code: "0781",
    label: "Ljungby (0781)",
  },
  {
    code: "2161",
    label: "Ljusdal (2161)",
  },
  {
    code: "1864",
    label: "Ljusnarsberg (1864)",
  },
  {
    code: "1262",
    label: "Lomma (1262)",
  },
  {
    code: "2085",
    label: "Ludvika (2085)",
  },
  {
    code: "2580",
    label: "Luleå (2580)",
  },
  {
    code: "1281",
    label: "Lund (1281)",
  },
  {
    code: "2481",
    label: "Lycksele (2481)",
  },
  {
    code: "1484",
    label: "Lysekil (1484)",
  },
  {
    code: "1280",
    label: "Malmö (1280)",
  },
  {
    code: "2023",
    label: "Malung-Sälen (2023)",
  },
  {
    code: "2418",
    label: "Malå (2418)",
  },
  {
    code: "1493",
    label: "Mariestad (1493)",
  },
  {
    code: "1463",
    label: "Mark (1463)",
  },
  {
    code: "0767",
    label: "Markaryd (0767)",
  },
  {
    code: "1461",
    label: "Mellerud (1461)",
  },
  {
    code: "0586",
    label: "Mjölby (0586)",
  },
  {
    code: "2062",
    label: "Mora (2062)",
  },
  {
    code: "0583",
    label: "Motala (0583)",
  },
  {
    code: "0642",
    label: "Mullsjö (0642)",
  },
  {
    code: "1430",
    label: "Munkedal (1430)",
  },
  {
    code: "1762",
    label: "Munkfors (1762)",
  },
  {
    code: "1481",
    label: "Mölndal (1481)",
  },
  {
    code: "0861",
    label: "Mönsterås (0861)",
  },
  {
    code: "0840",
    label: "Mörbylånga (0840)",
  },
  {
    code: "0182",
    label: "Nacka (0182)",
  },
  {
    code: "1884",
    label: "Nora (1884)",
  },
  {
    code: "1962",
    label: "Norberg (1962)",
  },
  {
    code: "2132",
    label: "Nordanstig (2132)",
  },
  {
    code: "2401",
    label: "Nordmaling (2401)",
  },
  {
    code: "0581",
    label: "Norrköping (0581)",
  },
  {
    code: "0188",
    label: "Norrtälje (0188)",
  },
  {
    code: "2417",
    label: "Norsjö (2417)",
  },
  {
    code: "0881",
    label: "Nybro (0881)",
  },
  {
    code: "0140",
    label: "Nykvarn (0140)",
  },
  {
    code: "0480",
    label: "Nyköping (0480)",
  },
  {
    code: "0192",
    label: "Nynäshamn (0192)",
  },
  {
    code: "0682",
    label: "Nässjö (0682)",
  },
  {
    code: "2101",
    label: "Ockelbo (2101)",
  },
  {
    code: "1060",
    label: "Olofström (1060)",
  },
  {
    code: "2034",
    label: "Orsa (2034)",
  },
  {
    code: "1421",
    label: "Orust (1421)",
  },
  {
    code: "1273",
    label: "Osby (1273)",
  },
  {
    code: "0882",
    label: "Oskarshamn (0882)",
  },
  {
    code: "2121",
    label: "Ovanåker (2121)",
  },
  {
    code: "0481",
    label: "Oxelösund (0481)",
  },
  {
    code: "2521",
    label: "Pajala (2521)",
  },
  {
    code: "1402",
    label: "Partille (1402)",
  },
  {
    code: "1275",
    label: "Perstorp (1275)",
  },
  {
    code: "2581",
    label: "Piteå (2581)",
  },
  {
    code: "2303",
    label: "Ragunda (2303)",
  },
  {
    code: "2409",
    label: "Robertsfors (2409)",
  },
  {
    code: "1081",
    label: "Ronneby (1081)",
  },
  {
    code: "2031",
    label: "Rättvik (2031)",
  },
  {
    code: "1981",
    label: "Sala (1981)",
  },
  {
    code: "0128",
    label: "Salem (0128)",
  },
  {
    code: "2181",
    label: "Sandviken (2181)",
  },
  {
    code: "0191",
    label: "Sigtuna (0191)",
  },
  {
    code: "1291",
    label: "Simrishamn (1291)",
  },
  {
    code: "1265",
    label: "Sjöbo (1265)",
  },
  {
    code: "1495",
    label: "Skara (1495)",
  },
  {
    code: "2482",
    label: "Skellefteå (2482)",
  },
  {
    code: "1904",
    label: "Skinnskatteberg (1904)",
  },
  {
    code: "1264",
    label: "Skurup (1264)",
  },
  {
    code: "1496",
    label: "Skövde (1496)",
  },
  {
    code: "2061",
    label: "Smedjebacken (2061)",
  },
  {
    code: "2283",
    label: "Sollefteå (2283)",
  },
  {
    code: "0163",
    label: "Sollentuna (0163)",
  },
  {
    code: "0184",
    label: "Solna (0184)",
  },
  {
    code: "2422",
    label: "Sorsele (2422)",
  },
  {
    code: "1427",
    label: "Sotenäs (1427)",
  },
  {
    code: "1230",
    label: "Staffanstorp (1230)",
  },
  {
    code: "1415",
    label: "Stenungsund (1415)",
  },
  {
    code: "0180",
    label: "Stockholm (0180)",
  },
  {
    code: "1760",
    label: "Storfors (1760)",
  },
  {
    code: "2421",
    label: "Storuman (2421)",
  },
  {
    code: "0486",
    label: "Strängnäs (0486)",
  },
  {
    code: "1486",
    label: "Strömstad (1486)",
  },
  {
    code: "2313",
    label: "Strömsund (2313)",
  },
  {
    code: "0183",
    label: "Sundbyberg (0183)",
  },
  {
    code: "2281",
    label: "Sundsvall (2281)",
  },
  {
    code: "1766",
    label: "Sunne (1766)",
  },
  {
    code: "1907",
    label: "Surahammar (1907)",
  },
  {
    code: "1214",
    label: "Svalöv (1214)",
  },
  {
    code: "1263",
    label: "Svedala (1263)",
  },
  {
    code: "1465",
    label: "Svenljunga (1465)",
  },
  {
    code: "1785",
    label: "Säffle (1785)",
  },
  {
    code: "2082",
    label: "Säter (2082)",
  },
  {
    code: "0684",
    label: "Sävsjö (0684)",
  },
  {
    code: "2182",
    label: "Söderhamn (2182)",
  },
  {
    code: "0582",
    label: "Söderköping (0582)",
  },
  {
    code: "0181",
    label: "Södertälje (0181)",
  },
  {
    code: "1083",
    label: "Sölvesborg (1083)",
  },
  {
    code: "1435",
    label: "Tanum (1435)",
  },
  {
    code: "1472",
    label: "Tibro (1472)",
  },
  {
    code: "1498",
    label: "Tidaholm (1498)",
  },
  {
    code: "0360",
    label: "Tierp (0360)",
  },
  {
    code: "2262",
    label: "Timrå (2262)",
  },
  {
    code: "0763",
    label: "Tingsryd (0763)",
  },
  {
    code: "1419",
    label: "Tjörn (1419)",
  },
  {
    code: "1270",
    label: "Tomelilla (1270)",
  },
  {
    code: "1737",
    label: "Torsby (1737)",
  },
  {
    code: "0834",
    label: "Torsås (0834)",
  },
  {
    code: "1452",
    label: "Tranemo (1452)",
  },
  {
    code: "0687",
    label: "Tranås (0687)",
  },
  {
    code: "1287",
    label: "Trelleborg (1287)",
  },
  {
    code: "1488",
    label: "Trollhättan (1488)",
  },
  {
    code: "0488",
    label: "Trosa (0488)",
  },
  {
    code: "0138",
    label: "Tyresö (0138)",
  },
  {
    code: "0160",
    label: "Täby (0160)",
  },
  {
    code: "1473",
    label: "Töreboda (1473)",
  },
  {
    code: "1485",
    label: "Uddevalla (1485)",
  },
  {
    code: "1491",
    label: "Ulricehamn (1491)",
  },
  {
    code: "2480",
    label: "Umeå (2480)",
  },
  {
    code: "0114",
    label: "Upplands Väsby (0114)",
  },
  {
    code: "0139",
    label: "Upplands-Bro (0139)",
  },
  {
    code: "0380",
    label: "Uppsala (0380)",
  },
  {
    code: "0760",
    label: "Uppvidinge (0760)",
  },
  {
    code: "0584",
    label: "Vadstena (0584)",
  },
  {
    code: "0665",
    label: "Vaggeryd (0665)",
  },
  {
    code: "0563",
    label: "Valdemarsvik (0563)",
  },
  {
    code: "0115",
    label: "Vallentuna (0115)",
  },
  {
    code: "2021",
    label: "Vansbro (2021)",
  },
  {
    code: "1470",
    label: "Vara (1470)",
  },
  {
    code: "1383",
    label: "Varberg (1383)",
  },
  {
    code: "0187",
    label: "Vaxholm (0187)",
  },
  {
    code: "1233",
    label: "Vellinge (1233)",
  },
  {
    code: "0685",
    label: "Vetlanda (0685)",
  },
  {
    code: "2462",
    label: "Vilhelmina (2462)",
  },
  {
    code: "0884",
    label: "Vimmerby (0884)",
  },
  {
    code: "2404",
    label: "Vindeln (2404)",
  },
  {
    code: "0428",
    label: "Vingåker (0428)",
  },
  {
    code: "1442",
    label: "Vårgårda (1442)",
  },
  {
    code: "1487",
    label: "Vänersborg (1487)",
  },
  {
    code: "2460",
    label: "Vännäs (2460)",
  },
  {
    code: "0120",
    label: "Värmdö (0120)",
  },
  {
    code: "0683",
    label: "Värnamo (0683)",
  },
  {
    code: "0883",
    label: "Västervik (0883)",
  },
  {
    code: "1980",
    label: "Västerås (1980)",
  },
  {
    code: "0780",
    label: "Växjö (0780)",
  },
  {
    code: "0512",
    label: "Ydre (0512)",
  },
  {
    code: "1286",
    label: "Ystad (1286)",
  },
  {
    code: "1492",
    label: "Åmål (1492)",
  },
  {
    code: "2260",
    label: "Ånge (2260)",
  },
  {
    code: "2321",
    label: "Åre (2321)",
  },
  {
    code: "1765",
    label: "Årjäng (1765)",
  },
  {
    code: "2463",
    label: "Åsele (2463)",
  },
  {
    code: "1277",
    label: "Åstorp (1277)",
  },
  {
    code: "0561",
    label: "Åtvidaberg (0561)",
  },
  {
    code: "0765",
    label: "Älmhult (0765)",
  },
  {
    code: "2039",
    label: "Älvdalen (2039)",
  },
  {
    code: "0319",
    label: "Älvkarleby (0319)",
  },
  {
    code: "2560",
    label: "Älvsbyn (2560)",
  },
  {
    code: "1292",
    label: "Ängelholm (1292)",
  },
  {
    code: "1407",
    label: "Öckerö (1407)",
  },
  {
    code: "0509",
    label: "Ödeshög (0509)",
  },
  {
    code: "1880",
    label: "Örebro (1880)",
  },
  {
    code: "1257",
    label: "Örkelljunga (1257)",
  },
  {
    code: "2284",
    label: "Örnsköldsvik (2284)",
  },
  {
    code: "2380",
    label: "Östersund (2380)",
  },
  {
    code: "0117",
    label: "Österåker (0117)",
  },
  {
    code: "0382",
    label: "Östhammar (0382)",
  },
  {
    code: "1256",
    label: "Östra Göinge (1256)",
  },
  {
    code: "2513",
    label: "Överkalix (2513)",
  },
  {
    code: "2518",
    label: "Övertorneå (2518)",
  },
];
