import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

interface Props {
  register: UseFormRegister<any>;
  label: string;
  name: string;
}

export const DateInput: FC<Props> = ({ register, label, name }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <dd className="mt-1 text-md text-gray-900">
      <div className="cursor-pointer">
        <input type="date" {...register(name)} />
      </div>
    </dd>
  </div>
);
