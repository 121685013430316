import Divider from "components/Divider";
import Dropdown from "components/Dropdown";
import { RadioGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
import { removeWhitespace } from "helpers/string";
import { FC, useEffect } from "react";
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { KommunList } from "types/Kommun.type";
import { useGuessKommun } from "api/postnummer.api";
import { TransportDTO } from "types/transport.type";

type Props = {
  register: UseFormRegister<TransportDTO>;
  formState: FormState<TransportDTO>;
  watch: UseFormWatch<TransportDTO>;
  errorList: string[];
  setValue: UseFormSetValue<TransportDTO>;
  disabled?: boolean;
};

export const Avsändare: FC<Props> = ({
  register,
  formState,
  watch,
  errorList,
  setValue,
  disabled = false,
}) => {
  const startPlatsLocationType = watch("startPlatsLocationType");
  const startPlatsPostnummer = watch("transportStartplats.adress.postnummer");

  // Gissa postnummer
  const gissaCustomerKommun = useGuessKommun();
  useEffect(() => {
    if (gissaCustomerKommun.data) {
      setValue("transportStartplats.kommunkod", gissaCustomerKommun.data);
    }
  }, [gissaCustomerKommun.data, setValue]);

  const kommunError =
    errorList.some((e) => e.includes("transportStartplats.kommunkod")) ||
    !!formState.errors.transportStartplats?.kommunkod?.message
      ? "Vänligen ange en kommun"
      : undefined;

  return (
    <div className="pr-6">
      <h2 className="text-gray-900 text-lg font-semibold mb-2">
        Avfallsproducent
      </h2>
      <div className="space-y-4">
        <TextInput
          register={register}
          label="Organisationsnummer"
          name="tidigareInnehavare"
          errorMessage={formState.errors.tidigareInnehavare?.message}
          disabled={disabled}
        />

        <Divider />

        <RadioGroup
          register={register}
          name="startPlatsLocationType"
          items={[
            { label: "Adress", value: "address", disabled },
            { label: "Koordinater", value: "coordinate", disabled },
          ]}
          row
        />
        {startPlatsLocationType === "address" ? (
          <>
            <TextInput
              key={"transportStartplats.adress.adressrad"}
              register={register}
              label="Objektets adress"
              name="transportStartplats.adress.adressrad"
              placeholder="Exempelvägen 123"
              errorMessage={
                formState?.errors?.transportStartplats?.adress?.adressrad
                  ?.message
              }
              disabled={disabled}
            />
            <TextInput
              key={"transportStartplats.adress.postnummer"}
              register={register}
              label="Objektets postnummer"
              name="transportStartplats.adress.postnummer"
              errorMessage={
                formState?.errors?.transportStartplats?.adress?.postnummer
                  ?.message
              }
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <TextInput
              key="transportStartplats.koordinat.nposition"
              register={register}
              label="N (SWEREF 99)"
              name="transportStartplats.koordinat.nposition"
              placeholder="1234567"
              errorMessage={
                formState?.errors?.transportStartplats?.koordinat?.nposition
                  ?.message
              }
              disabled={disabled}
            />
            <TextInput
              key="transportStartplats.koordinat.eposition"
              register={register}
              placeholder="13456"
              label="E (SWEREF 99)"
              name="transportStartplats.koordinat.eposition"
              errorMessage={
                formState?.errors?.transportStartplats?.koordinat?.eposition
                  ?.message
              }
              disabled={disabled}
            />
            {/* <TextInput
                key={"transportStartplats.koordinat.beskrivning"}
                register={register}
                label="Beskrivning"
                name="transportStartplats.koordinat.beskrivning"
              /> */}
          </>
        )}
        <Divider />

        <Dropdown
          items={KommunList.map((k) => ({
            label: k.label,
            value: k.code,
          }))}
          label="Kommun"
          name="transportStartplats.kommunkod"
          register={register}
          disabled={disabled}
          errorMessage={kommunError}
          action={
            !(removeWhitespace(startPlatsPostnummer ?? "").length === 5) ||
            disabled
              ? undefined
              : () =>
                  gissaCustomerKommun.mutate({
                    postnummer: removeWhitespace(startPlatsPostnummer),
                  })
          }
          actionText="Beräkna"
          isActionLoading={gissaCustomerKommun.isLoading}
        />
      </div>
    </div>
  );
};
