import { FC } from "react";

interface Props {
  children: React.ReactNode;
}

export const Value: FC<Props> = ({ children }) => (
  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
    {children}
  </dd>
);
