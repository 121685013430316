import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { FC } from "react";
import dismissedTipsStore from "store/dismissedTips.store";

interface Props {
  id: string;
  paragraphs: string[];
}
export const InfoNew: FC<Props> = ({ id, paragraphs }) => {
  const { dismissedTipsIds, addOne } = dismissedTipsStore();
  const isDismissed = dismissedTipsIds.some((_id) => _id === id);
  if (isDismissed) {
    return null;
  }
  return (
    <div className="rounded-md bg-indigo-50 p-4">
      <div className="flex mb-3 items-center">
        <InformationCircleIcon
          className="h-5 w-5 text-indigo-400 mr-2"
          aria-hidden="true"
        />
        <span className="text-indigo-800 text-sm font-semibold">
          Information
        </span>
      </div>
      {paragraphs.map((parra) => (
        <p key={parra} className="text-sm text-indigo-900 mb-4">
          {parra}
        </p>
      ))}

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => addOne(id)}
          className="ml-3 rounded-md bg-indigo-50 px-2 py-1.5 text-sm font-medium text-indigo-800 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-indigo-50"
        >
          Visa inte igen
        </button>
      </div>
    </div>
  );
};
