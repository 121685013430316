import { RapporteringsTyp } from "api/transportDocument.api";
import { TransportDTO } from "./transport.type";

export type NvRapportering = {
  id: number;
  data: TransportDTO;
  wasteUuid?: string;
  uuid: string;
  performedByUserUuid: string; // empty if automatic
  nvAvfallId: string;
  type: RapporteringsTyp;
  performedByUserName: string;
  customerName: string;
  createdAt: string;
};

export const rapporteringsTypLabel = (type: RapporteringsTyp) => {
  switch (type) {
    case RapporteringsTyp.Transport:
      return "Transport";
    case RapporteringsTyp.TransportPlanering:
      return "Rapporteringsombud";
    default: {
      console.error("rapporteringsTypLabel is missing type for", type);
      return "";
    }
  }
};
