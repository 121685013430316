import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { Protocol } from "./Protocol";
import { Form } from "./Form";
import Alert from "components/Alert";
import Modal from "components/Modal";
import { getSeparatorTypeLabel } from "types/separator/separator.helpers";
import menuStore from "store/menu.store";
import {
  FiveYearsInspectionQueryKey,
  useGetFiveYearsInspection,
  useSetFiveYearsInspectionFiles,
  useUpdateFiveYearsInspection,
} from "api/fiveYearsInspection.api";
import { FiveYearsInspection } from "types/fiveYearsInspection/fiveYearsInspection.type";
import { scrollToTop } from "helpers/scroll";
import { PageLoader } from "components/PageLoader";
import { InspectionWrapper } from "components/inspection/InspectionWrapper";
import { InspectionHeader } from "components/inspection/InspectionHeader";
import { useUpdateSeparatorLogic } from "hooks/useUpdateSeparatorLogic";
import { InspectionType } from "types/enums";
import { isEmail } from "helpers/string";
import { EditSeparatorTechnicalInformation } from "../separator/EditSeparatorTechnicalInformation";
import { RouteUrl } from "routes.type";
import { InspectionStatus } from "enums/InspectionStatus.enum";
import { inspectionStatusToAssignmentStatus } from "types/assignment.type";
import { EmailStatus } from "types/email.type";
import { useTomning } from "api/tomning.api";
import { ProxyAssignmentBanner } from "components/ProxyAssignmentBanner";

export default function FiveYearsInspectionPage() {
  const params = useParams();
  const inspectionId: string = params?.inspectionId || "";
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  // update inspection
  const {
    mutate: updateInspection,
    isSuccess,
    isLoading: isSavingInspection,
  } = useUpdateFiveYearsInspection();

  // klarmarkera
  const {
    mutate: updateInspectionKlarmarkera,
    isLoading: isUpdatingKlarmarkera,
  } = useUpdateFiveYearsInspection({
    onSuccess: (result: FiveYearsInspection) => {
      queryClient.invalidateQueries([
        FiveYearsInspectionQueryKey.GetOne,
        result.id,
      ]);
    },
  });

  const setKlarmarkera = (klarmarkerad: boolean) => {
    if (!inspection) {
      throw Error(
        "FiveYearsInspectionPage.setKlarmarkera, no inspection found"
      );
    }
    updateInspectionKlarmarkera({
      ...inspection,
      klarmarkerad,
    });
  };

  const mode = searchParams.get("mode") || "view";

  useEffect(() => {
    if (isSuccess) {
      setSearchParams({
        mode: "view",
      });
      scrollToTop();
    }
  }, [isSuccess, setSearchParams]);

  // Används för att uppdatera status på emails
  const [shouldRefetchInspection, setShouldRefetchInspection] = useState(false);

  const {
    data: inspection,
    isLoading,
    isError,
  } = useGetFiveYearsInspection(+inspectionId, shouldRefetchInspection);

  useEffect(() => {
    if (inspection) {
      setShouldRefetchInspection(
        (inspection.emails ?? []).some(
          (email) =>
            email.status === EmailStatus.Scheduled ||
            email.status === EmailStatus.Sent
        )
      );
    }
  }, [inspection]);

  const separator = inspection?.separator;

  const { setCurrentCustomerName, setSeparator, setInspection } = menuStore();
  useEffect(() => {
    return () => {
      // rensa bort för att inte få en hackig header
      setInspection("");
    };
  }, [setInspection]);

  useEffect(() => {
    if (separator) {
      setCurrentCustomerName(separator?.customer?.name || "");
      setSeparator(
        `${getSeparatorTypeLabel(separator)}${
          !separator.identifier ? "" : ` - ${separator.identifier}`
        }`
      );
      setInspection(inspectionId);
    }
  }, [
    inspectionId,
    separator,
    setCurrentCustomerName,
    setInspection,
    setSeparator,
  ]);

  // Update images
  // Detta är för att göra en optimistic update på delete, fult som fan
  const [tempDeletedFiles, setTempDeletedFiles] = useState<number[]>([]);

  const { mutate: setFiles, isLoading: isSettingFiles } =
    useSetFiveYearsInspectionFiles();

  const onFileAdd = (url: string) => {
    if (inspection) {
      setFiles({
        inspectionId: inspection.id,
        files: [...inspection.files, { id: undefined, name: url, url }],
      });
    }
  };

  const onFileDelete = (id: number) => {
    setTempDeletedFiles((prev) => [...prev, id]);
    if (inspection) {
      setFiles({
        inspectionId: inspection.id,
        files: (inspection.files || []).filter((file) => file.id !== id),
      });
    }
  };

  // Update separator
  const {
    isModalOpen,
    formErrors,
    isUpdating,
    onModalClose,
    openModal,
    onSeparatorSubmit,
    register,
    formData,
    isUpdateSeparatorSuccess,
  } = useUpdateSeparatorLogic(separator);

  useEffect(() => {
    if (isUpdateSeparatorSuccess) {
      queryClient.invalidateQueries([
        FiveYearsInspectionQueryKey.GetOne,
        inspectionId,
      ]);
    }
  }, [isUpdateSeparatorSuccess, inspectionId, queryClient]);

  // Tomning om man även har en sån
  const { data: tomning, isLoading: isLoadingTomning } = useTomning(
    inspection?.assignment?.tomning?.id ?? -1
  );

  if (isLoading || isLoadingTomning) {
    return <PageLoader />;
  }
  if (isError || !inspection || !separator) {
    return <div>Error</div>;
  }

  const isEditMode = mode === "edit";

  const documentationEmail = inspection.assignment.isProxyAssignment
    ? inspection.assignment.parentCustomer?.documentationEmail
    : inspection.separator.customer?.documentationEmail;

  return (
    <>
      {inspection.assignment.parentCustomer && (
        <ProxyAssignmentBanner
          parentCustomer={inspection.assignment.parentCustomer}
        />
      )}
      <InspectionWrapper
        afterUploadedFile={onFileAdd}
        existingFiles={inspection.files.filter(
          (file) => !tempDeletedFiles.includes(file.id)
        )}
        isLoadingFiles={isSettingFiles}
        deleteFile={onFileDelete}
        inspectionId={inspection.id}
        hasSentEmail={inspection.hasSentEmail}
        inspectionStatus={inspection.status}
        fileName={`5årsbesiktning-${inspection.inspectionDate
          .toString()
          .replaceAll("-", "")}.pdf`}
        type={InspectionType.FiveYears}
        hasValidDocumentationEmail={isEmail(documentationEmail)}
        inspection={inspection}
        openEditSeparatorModal={openModal}
        isEditMode={isEditMode}
        tomning={tomning}
        documentationEmail={documentationEmail}
      >
        {inspection.status === InspectionStatus.MissingSeparatorData && (
          <Alert
            text="Det saknas information om objektet för att kunna utföra en 5-årsbesiktning."
            buttonText="Lägg till information."
            onButtonClick={openModal}
          />
        )}
        <InspectionHeader
          status={inspection.status}
          isEditMode={isEditMode}
          setEditMode={() => setSearchParams({ mode: "edit" })}
          setKlarmarkera={setKlarmarkera}
          isSettingKlarmarkera={isUpdatingKlarmarkera}
          assignment={{
            ...inspection.assignment,
            separator,
            customer: inspection.separator.customer,
            user: inspection.user,
            fiveYearsInspection: inspection,
            assignmentStatus: inspectionStatusToAssignmentStatus(
              inspection.status
            ),
          }}
          openEditSeparatorModal={openModal}
        />
        {isEditMode ? (
          <Form
            inspection={inspection}
            onCancel={() => {
              scrollToTop();
              setSearchParams({ mode: "view" });
            }}
            updateInspection={updateInspection}
            isSavingInspection={isSavingInspection}
          />
        ) : (
          <Protocol inspection={inspection} />
        )}
        <Modal
          isOpen={isModalOpen}
          title={"Ändra objekt - " + separator.customer?.name}
          onClose={onModalClose}
          actionHandler={onSeparatorSubmit}
          actionText="Spara"
          isLoading={isUpdating}
          loadingText="Sparar..."
        >
          <EditSeparatorTechnicalInformation
            register={register}
            formErrors={formErrors}
            formData={formData}
          />
        </Modal>
      </InspectionWrapper>
    </>
  );
}

export const getLinkToFiveYearsInspectionPage = (
  customerId: number | string,
  separatorId: number | string,
  fiveYearsInspectionId: number | string
) =>
  `${RouteUrl.App}/${RouteUrl.Customers}/${customerId}/${separatorId}/${RouteUrl.FiveYearsInspection}/${fiveYearsInspectionId}`;
