import { DescriptionList } from "components/desriptionList/DescriptionList";
import { FC } from "react";
import { TransportDocument } from "types/transportDocument.type";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import { Item } from "components/desriptionList/Item";
import { Link } from "react-router-dom";
import { getLinkToTomningPage } from "../../tomning-page/TomningPage";

type Props = {
  td?: TransportDocument;
};

export const Overview: FC<Props> = ({ td }) => {
  if (!td) return null;
  return (
    <div className="pr-4">
      <DescriptionList>
        <h2 className="text-gray-900 text-base font-medium mb-4 px-2 flex justify-between items-end">
          Transportdokument
          <Link
            to={getLinkToTomningPage(
              td.customer.id,
              td.tomning.separator.id,
              td.tomning.id
            )}
            className="text-cyan-700 hover:text-cyan-800 font-medium text-sm underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Öppna
          </Link>
        </h2>
        <Item>
          <Label>Kund</Label>
          <Value>{td.customer.name}</Value>
        </Item>
        <Item>
          <Label>Mottagare</Label>
          <Value>{td.wasteStation?.name ?? "Okänt"}</Value>
        </Item>
        <Item>
          <Label>Operatör</Label>
          <Value>{td.user.name ?? "Okänt"}</Value>
        </Item>
      </DescriptionList>
    </div>
  );
};
