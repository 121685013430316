import { MandatoryIndicator } from "components/MandatoryIndicator";
import { FC } from "react";

type LabelValueWrapperProps = {
  children: React.ReactNode;
};
export const ProtocolLabelValueWrapper: FC<LabelValueWrapperProps> = ({
  children,
}) => <div className="sm:py-8 flex flex-col sm:flex-row">{children}</div>;

type LabelProps = {
  children: React.ReactNode;
  mandatory?: boolean;
};
export const ProtocolLabel: FC<LabelProps> = ({
  children,
  mandatory = false,
}) => (
  <dt className="text-sm font-normal text-gray-900 flex-1">
    {children}
    {mandatory && <MandatoryIndicator />}
  </dt>
);

type ValueProps = {
  children: React.ReactNode;
  color?: string;
};
export const ProtocolValue: FC<ValueProps> = ({
  children,
  color = "gray-800",
}) => (
  <dd
    className={`mt-1 text-sm text-${color} font-medium sm:mt-0 flex-1 sm:ml-4`}
  >
    {children}
  </dd>
);
