import { TomningQueryKey } from "api/tomning.api";
import { useUpdateTransportDocument } from "api/transportDocument.api";
import { useWasteStationList } from "api/wasteStation.api";
import { useNotification } from "hooks/useNotification";
import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { Tomning } from "types/tomning.type";

export const useUpdateWasteStation = (tomning?: Tomning) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  const { data: wasteStationList } = useWasteStationList();

  const wasteStationDropdownItems = useMemo(
    () =>
      (wasteStationList ?? [])
        .map(({ id, name }) => ({
          label: name,
          value: id,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [wasteStationList]
  );

  const { mutate: updateTransportDocument } = useUpdateTransportDocument(
    tomning?.transportDocument?.id || -1,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
      },
      onError: () => {
        notification.error("Något gick fel");
      },
    }
  );

  const updateWasteStation = (wasteStationId?: number) => {
    const td = tomning?.transportDocument;
    if (!td) {
      throw Error("No transport document");
    }
    const { wasteStation, ...rest } = td;
    updateTransportDocument({
      ...rest,
      wasteStationId,
    });
  };

  const selectedWasteStationId = tomning?.transportDocument?.wasteStation?.id;
  return {
    updateWasteStation,
    selectedWasteStationId,
    wasteStationDropdownItems,
  };
};
