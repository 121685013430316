import Dropdown from "components/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { Tips } from "components/Tips";
import { isEmail } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { KommunList } from "types/Kommun.type";
import { WasteStationDTO } from "types/wasteStation.type";

interface Props {
  register: UseFormRegister<WasteStationDTO>;
  errors: any;
}
export const WasteStationForm: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="Företagets namn"
        name="name"
        register={register}
        validation={{ required: "Obligatoriskt fält" }}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      <TextInput
        label="Organisationsnummer"
        name="organizationNumber"
        register={register}
        validation={{ required: "Obligatoriskt fält" }}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput
        label="Epostadress för rapporter"
        name="documentationEmail"
        register={register}
        validation={{
          validate: (value: string) =>
            !value || // Ok att lämna tomt
            isEmail(value) ||
            "Denna emailadress ser ut att vara felaktig",
        }}
        errorMessage={errors?.documentationEmail?.message}
      />
      <TextInput label="Gatuadress" name="address" register={register} />
      <TextInput label="Postnummer" name="postalCode" register={register} />
      <TextInput label="Stad" name="city" register={register} />
      <Dropdown
        items={KommunList.map((k) => ({
          label: k.label,
          value: k.code,
        }))}
        label="Kommun"
        name="knCode"
        register={register}
      />
      <Tips
        id="ahskgdaishd7iasdhkausjdhkasjhd"
        paragraphs={[
          "Om avfallsstationen saknar adress kan du istället ange en exakt position med koordinater. För rapportering till Avfallsregistret krävs antingen adress eller koordinater. Öppna Google Maps, högerklicka på platsen och klicka på koordinaterna så kopieras de till urklipp. Klistra sedan in dem nedan. ",
        ]}
      />
      <TextInput
        name="coordinates"
        label="Koordinater"
        // placeholder="49.4678135885645, 17.114995931269636"
        register={register}
        link="https://www.google.se/maps/"
        linkText="Öppna Google Maps"
      />

      <TextInput label="Telefon" name="phone" register={register} />
    </ModalContentWrapper>
  );
};
