import { CustomerQueryKey } from "api/customer.api";
import { useCreateSeparator } from "api/separator.api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Customer } from "types/customer.type";
import {
  CreateSeparatorDTO,
  SeparatorType,
} from "types/separator/separator.type";

export const useAddSeparatorLogic = (
  customer?: Customer,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();

  const [isNewSeparatorModalOpen, setIsNewSeparatorModalOpen] = useState(false);
  const {
    mutate: createSeparator,
    isSuccess: isCreateSeparatorSuccess,
    isLoading: isCreating,
    data: createdSeparator,
  } = useCreateSeparator();

  const {
    register: registerSeparator,
    handleSubmit: handleSeparatorSubmit,
    reset: resetSeparator,
    formState: { errors: addSeparatorErrors },
    setValue,
    watch: watchSeparator,
  } = useForm<CreateSeparatorDTO>({
    defaultValues: { type: SeparatorType.OilAndSlam, kommunKod: "" },
  });

  const onNewSeparatorModalClose = () => {
    setIsNewSeparatorModalOpen(false);
    resetSeparator({ type: SeparatorType.OilAndSlam });
  };

  useEffect(() => {
    if (isCreateSeparatorSuccess) {
      if (onSuccess) {
        onSuccess();
      } else {
        queryClient.invalidateQueries([
          CustomerQueryKey.GetOne,
          Number(customer?.id),
        ]);
      }
      onNewSeparatorModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSeparatorSuccess, queryClient]);

  const onSeparatorSubmit = async () => {
    if (!customer) {
      console.error("Hittade ingen kund att lägga till objekt till.");
      return;
    }
    handleSeparatorSubmit((data: CreateSeparatorDTO) => {
      if (customer) {
        createSeparator({ ...data, customerId: customer.id });
      }
    })();
  };

  const selectedSeparatorType = watchSeparator("type");

  const copyAddressFromCustomer = () => {
    if (!customer) {
      throw Error("missing customer");
    }
    setValue("address", customer.address);
    setValue("postalCode", customer.postalCode);
    setValue("city", customer.city);
    setValue("kommunKod", customer.kommunKod);
  };

  return {
    isNewSeparatorModalOpen,
    setIsNewSeparatorModalOpen,
    createSeparator,
    isCreating,
    registerSeparator,
    onSeparatorSubmit,
    addSeparatorErrors,
    selectedSeparatorType,
    onNewSeparatorModalClose,
    copyAddressFromCustomer,
    createdSeparator,
    setValue,
    watchSeparator,
  };
};
