import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

export const TextArea: FC<{
  label: string;
  register: UseFormRegister<any>;
  name: string;
  rows?: number;
}> = ({ label, register, name, rows = 3 }) => {
  return (
    <div className="sm:py-4 sm:border-t sm:border-gray-50">
      <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:pt-5">
        <label
          htmlFor="about"
          className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-1">
          <textarea
            {...register(name)}
            rows={rows}
            className="max-w-lg shadow-sm block w-full focus:ring-cyan-300 focus:border-cyan-400 sm:text-sm border border-gray-300 rounded-md"
          />
          {/* <p className="mt-2 text-sm text-gray-500">
            Write a few sentences about yourself.
          </p> */}
        </div>
      </div>
    </div>
  );
};
