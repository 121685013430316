import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddCompanyDTO, useAddCompany } from "api/company.api";

export const useAddCompanyLogic = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mutation = useAddCompany();
  const form = useForm<AddCompanyDTO>();

  const onSubmit = form.handleSubmit((data: AddCompanyDTO) =>
    mutation.mutate(data)
  );

  const closeModal = () => {
    form.reset();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  return {
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    form,
    closeModal,
    onSubmit,
    mutation,
  };
};
