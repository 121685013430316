import { useEffect } from "react";
import menuStore from "store/menu.store";
import { getSeparatorTypeLabel } from "types/separator/separator.helpers";
import { Tomning } from "types/tomning.type";

export const useTomningPageBreadCrumbs = (tomning?: Tomning) => {
  const { setCurrentCustomerName, setSeparator, setInspection } = menuStore();
  useEffect(() => {
    return () => {
      // rensa bort för att inte få en hackig header
      setInspection("");
    };
  }, [setInspection]);

  useEffect(() => {
    if (tomning) {
      setCurrentCustomerName(tomning.transportDocument.customer?.name || "");
      setSeparator(
        `${getSeparatorTypeLabel(tomning.separator)}${
          !tomning.separator.identifier
            ? ""
            : ` - ${tomning.separator.identifier}`
        }`
      );
      setInspection(tomning.id.toString());
    }
  }, [setCurrentCustomerName, setInspection, setSeparator, tomning]);
};
