import { classNames } from "helpers/classNames";
import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  link?: string;
}

export const TR: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableRowElement>,
      HTMLTableRowElement
    >
> = ({ children, link, ...props }) => (
  <tr
    {...props}
    className={classNames(
      !!link && "relative",
      !!props.onClick || !!link ? "cursor-pointer hover:bg-stone-50" : ""
    )}
  >
    {!!link && (
      <td className="absolute w-full h-full z-1">
        <Link to={link} className="block w-full h-full z-1 text-transparent">
          Link
        </Link>
      </td>
    )}
    {children}
  </tr>
);
