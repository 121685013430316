import { useLocation, useNavigate } from "react-router-dom";

export const useAddMultiParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const addMultiParam = (name: string, value: string | number) => {
    // Parse the current query string
    const searchParams = new URLSearchParams(location.search);

    // Check if the parameter with the exact value already exists
    const allValues = searchParams.getAll(name);
    if (!allValues.includes(value.toString())) {
      // Add the new query parameter if it does not exist
      searchParams.append(name, value.toString());

      // Navigate to the new URL with the updated query parameters
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  };

  return addMultiParam;
};
