import {
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
  ClipboardIcon,
} from "@heroicons/react/24/solid";
import { FC } from "react";
import {
  InspectionStatus,
  inspectionStatusLabel,
} from "enums/InspectionStatus.enum";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

interface Props {
  status: InspectionStatus;
}
export const InspectionStatusTag: FC<Props> = ({ status }) => (
  <span className={"flex items-center text-gray-800"}>
    <InspectionStatusIcon status={status} />
    <span className="text-sm text-gray-600 font-normal">
      {inspectionStatusLabel(status)}
    </span>
  </span>
);

export const InspectionStatusIcon: FC<Props> = ({ status }) => (
  <>
    {status === InspectionStatus.MissingSeparatorData ? (
      <ExclamationTriangleIcon
        className="flex-shrink-0 sm:mr-1 h-5 w-5 text-yellow-400"
        aria-hidden="true"
      />
    ) : status === InspectionStatus.Klarmarkerad ? (
      <CheckCircleIcon
        className="flex-shrink-0 sm:mr-1 h-5 w-5 text-cyan-400"
        aria-hidden="true"
      />
    ) : status === InspectionStatus.RequirementsFulfilled ? (
      <ClipboardDocumentCheckIcon
        className="flex-shrink-0 sm:mr-1 h-5 w-5 text-pink-400"
        aria-hidden="true"
      />
    ) : status === InspectionStatus.Created ? (
      <ClipboardIcon
        className="flex-shrink-0 sm:mr-1 h-5 w-5 text-indigo-400"
        aria-hidden="true"
      />
    ) : null}
  </>
);
