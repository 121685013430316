import { AssignmentStatusTag } from "components/AssignmentStatusTag";
import { FC } from "react";
import { Assignment } from "types/assignment.type";

interface ScheduledPropertyProps {
  label?: string;
  value?: string;
  action?: () => void;
  actionText?: string;
  assignment: Assignment;
}
export const ScheduledProperty: FC<ScheduledPropertyProps> = ({
  label,
  value,
  action,
  actionText = "Uppdrag",
  assignment,
}) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
      <span className="flex-grow flex">
        <span className="mr-4">{value}</span>
        <AssignmentStatusTag status={assignment.assignmentStatus} />
      </span>
      <span className="ml-4 flex-shrink-0">
        {!!action && (
          <button
            onClick={action}
            type="button"
            className="rounded-md bg-white font-medium text-cyan-600 hover:text-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            {actionText}
          </button>
        )}
      </span>
    </dd>
  </div>
);
