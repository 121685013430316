import { FC } from "react";
import TabContainer from "components/tab/TabContainer";
import { Separator } from "types/separator/separator.type";
import { TabDisplayList } from "components/tab/TabDisplayList";
import { TabDisplayItemWrapper } from "components/tab/TabDisplayItemWrapper";
import { TabDisplayItemLabel } from "../../../components/tab/TabDisplayItemLabel";
import { TabDisplayItemValue } from "../../../components/tab/TabDisplayItemValue";
import {
  getSeparatorLabel,
  getSeparatorTypeLabel,
  getShape,
  isOilSeparator,
} from "types/separator/separator.helpers";
import { JaNejStatus } from "types/enums";

interface Props {
  separator: Separator;
}
export const TechnicalInformation: FC<Props> = ({ separator }) => {
  return (
    <TabContainer>
      <TabDisplayList>
        {getTechnicalInformationFieldsForSeparator(separator).map(
          ({ label, value }) => (
            <TabDisplayItemWrapper key={label}>
              <TabDisplayItemLabel>{label}</TabDisplayItemLabel>
              <TabDisplayItemValue>{value}</TabDisplayItemValue>
            </TabDisplayItemWrapper>
          )
        )}
      </TabDisplayList>
    </TabContainer>
  );
};

export const getTechnicalInformationFieldsForSeparator = (
  separator: Separator
) =>
  [
    {
      label: getSeparatorLabel("type"),
      value: getSeparatorTypeLabel(separator),
    },
    {
      label: getSeparatorLabel("marking858"),
      value: separator.marking858 ?? "",
    },
    {
      label: getSeparatorLabel("verification858"),
      value:
        separator.verification858 === JaNejStatus.Ja
          ? `Ja - ${separator.verification858Text}`
          : "Nej",
    },
    {
      label: getSeparatorLabel("classification"),
      value:
        separator.classification === "Unavailable"
          ? getSeparatorLabel("separatorClassificationMissing")
          : separator.classification,
      oilOnly: true,
    },
    { label: getSeparatorLabel("manufacturer"), value: separator.manufacturer },
    {
      label: getSeparatorLabel("yearOfManufacture"),
      value: separator.yearOfManufacture,
    },
    {
      label: getSeparatorLabel("modelIdentifier"),
      value: separator.modelIdentifier,
    },
    { label: getSeparatorLabel("shape"), value: getShape(separator) },
    {
      label: getSeparatorLabel("totalVatskevolym"),
      value: !!separator.totalVatskevolym
        ? `${separator.totalVatskevolym} m³`
        : "",
    },
    {
      label: getSeparatorLabel("slamMaxLevel"),
      value: !!separator.slamMaxLevel ? `${separator.slamMaxLevel} mm` : "",
    },
    {
      label: getSeparatorLabel("maxSlamvolym"),
      value: !!separator.maxSlamvolym ? `${separator.maxSlamvolym} m³` : "",
    },
    {
      label: getSeparatorLabel("maxOljevolym"),
      value: !!separator.maxOljevolym ? `${separator.maxOljevolym} m³` : "",
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("flode"),
      value: !!separator.flode ? `${separator.flode} l/s` : "",
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("oilMaxLevel"),
      value: !!separator.oilMaxLevel ? `${separator.oilMaxLevel} mm` : "",
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("provtagningsbrunnFinns"),
      value: separator.provtagningsbrunnFinns,
    },
    {
      label: getSeparatorLabel("koalecensfilterFinns"),
      value: separator.koalecensfilterFinns,
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("nivalarmFinns"),
      value: separator.nivalarmFinns,
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("slamnivalarmFinns"),
      value: separator.slamnivalarmFinns,
    },
    {
      label: getSeparatorLabel("damningslarmHognivalarmFinns"),
      value: separator.damningslarmHognivalarmFinns,
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("flottarFinns"),
      value: separator.flottarFinns,
      oilOnly: true,
    },
    {
      label: getSeparatorLabel("flottarDensitetAngiven"),
      value: !!separator.flottarDensitetAngiven
        ? `${separator.flottarDensitetAngiven} kg/dm`
        : "",
      oilOnly: true,
    },
  ]
    .filter(
      (item) =>
        item.label !== getSeparatorLabel("flottarDensitetAngiven") ||
        separator.flottarFinns === JaNejStatus.Ja
    )
    .filter((item) => isOilSeparator(separator) || !item.oilOnly);

export const getDetailedSeparatorFields = (separator: Separator) => [
  ...getTechnicalInformationFieldsForSeparator(separator),
];
