import Divider from "components/Divider";
import Dropdown from "components/Dropdown";
import { RadioGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
import { removeWhitespace } from "helpers/string";
import { FC, useEffect } from "react";
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { KommunList } from "types/Kommun.type";
import { useGuessKommun } from "api/postnummer.api";
import { TransportDTO } from "types/transport.type";

type Props = {
  register: UseFormRegister<TransportDTO>;
  formState: FormState<TransportDTO>;
  watch: UseFormWatch<TransportDTO>;
  setValue: UseFormSetValue<TransportDTO>;
  disabled?: boolean;
  errorList: string[];
};

export const Mottagare: FC<Props> = ({
  register,
  formState,
  watch,
  setValue,
  disabled = false,
  errorList,
}) => {
  const slutPlatsLocationType = watch("slutPlatsLocationType");
  const slutPlatsPostnummer = watch("transportSlutplats.adress.postnummer");

  // Gissa postnummer
  const gissaMottagareKommun = useGuessKommun();

  useEffect(() => {
    if (gissaMottagareKommun.data) {
      setValue("transportSlutplats.kommunkod", gissaMottagareKommun.data);
    }
  }, [gissaMottagareKommun.data, setValue]);

  const kommunError =
    errorList.some((e) => e.includes("transportSlutplats.kommunkod")) ||
    !!formState.errors.transportSlutplats?.kommunkod?.message
      ? "Vänligen ange en kommun"
      : undefined;

  return (
    <div className="px-6">
      <h2 className="text-gray-900 text-lg font-semibold mb-2">Mottagare</h2>
      <div className="space-y-4">
        <TextInput
          register={register}
          label="Mottagarens organisationsnummer"
          name="nyInnehavare"
          errorMessage={formState?.errors?.nyInnehavare?.message}
          disabled={disabled}
        />

        <Divider />

        <RadioGroup
          register={register}
          name="slutPlatsLocationType"
          items={[
            { label: "Adress", value: "address", disabled },
            { label: "Koordinater", value: "coordinate", disabled },
          ]}
          row
        />
        {slutPlatsLocationType === "address" ? (
          <>
            <TextInput
              key={"transportSlutplats.adress.adressrad"}
              register={register}
              label="Adress"
              name="transportSlutplats.adress.adressrad"
              placeholder="Exempelvägen 123"
              errorMessage={
                formState?.errors?.transportSlutplats?.adress?.adressrad
                  ?.message
              }
              disabled={disabled}
            />
            <TextInput
              key={"transportSlutplats.adress.postnummer"}
              register={register}
              label="Postnummer"
              name="transportSlutplats.adress.postnummer"
              errorMessage={
                formState?.errors?.transportSlutplats?.adress?.postnummer
                  ?.message
              }
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <TextInput
              key="transportSlutplats.koordinat.nposition"
              placeholder="1234567"
              register={register}
              label="N (SWEREF 99)"
              name="transportSlutplats.koordinat.nposition"
              errorMessage={
                formState?.errors?.transportSlutplats?.koordinat?.nposition
                  ?.message
              }
              disabled={disabled}
              // link="https://rl.se/rt90"
              // linkText="Hitta"
            />
            <TextInput
              key="transportSlutplats.koordinat.eposition"
              register={register}
              label="E (SWEREF 99)"
              placeholder="123456"
              name="transportSlutplats.koordinat.eposition"
              errorMessage={
                formState?.errors?.transportSlutplats?.koordinat?.eposition
                  ?.message
              }
              disabled={disabled}
              // link="https://rl.se/rt90"
              // linkText="Hitta"
            />
            {/* <TextInput
                    key={"transportSlutplats.koordinat.beskrivning"}
                    register={register}
                    label="Beskrivning"
                    name="transportSlutplats.koordinat.beskrivning"
                  /> */}
          </>
        )}
        <Divider />
        <Dropdown
          items={KommunList.map((k) => ({
            label: k.label,
            value: k.code,
          }))}
          label="Kommun"
          name="transportSlutplats.kommunkod"
          register={register}
          action={
            !(removeWhitespace(slutPlatsPostnummer ?? "").length === 5) ||
            disabled
              ? undefined
              : () =>
                  gissaMottagareKommun.mutate({
                    postnummer: removeWhitespace(slutPlatsPostnummer),
                  })
          }
          actionText="Beräkna"
          errorMessage={kommunError}
          isActionLoading={gissaMottagareKommun.isLoading}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
