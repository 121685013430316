import { FC } from "react";
import { classNames } from "helpers/classNames";
import { Email, EmailStatus } from "types/email.type";
import { FilePreviewSmall } from "components/FilePreviewSmall";
import { formatDate, formatDateWithTime } from "helpers/date";
import { isToday } from "date-fns";
import { EmailStatusTag } from "components/EmailStatusTag";

type Props = {
  emails?: Email[];
  isSentWithOtherInspection: boolean;
};

export const SentTdEmails: FC<Props> = ({
  emails = [],
  isSentWithOtherInspection,
}) => {
  if (emails.length === 0) {
    return (
      <div
        className={classNames(
          "bg-white shadow-lg overflow-hidden sm:rounded-lg mt-7 lg:ml-7 mb-4 lg:mb-0 pb-6"
        )}
      >
        <div className="px-4 py-5 sm:px-6 flex pb-5 flex-col">
          <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mt-px flex items-center justify-between">
            Utskick
          </h3>
        </div>
        {/* Visa information om det behövs om att tidigare mail ej syns */}
        <div className="border-t border-gray-100 px-4 sm:px-6 pt-6 text-gray-900">
          <span>
            {isSentWithOtherInspection
              ? "Transportdokumentet har bifogats med kundens kontrollrapport."
              : "Inga utskick än så länge."}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "bg-white shadow-lg overflow-hidden sm:rounded-lg mt-7 lg:ml-7 mb-4 lg:mb-0 pb-6"
      )}
    >
      <div className="px-4 py-5 sm:px-6 flex pb-5 flex-col">
        <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mt-px flex items-center justify-between">
          Utskick
        </h3>
      </div>
      <div className="border-t border-gray-100 px-4 sm:px-6 divide-y divide-y-gray-200">
        {emails
          .sort((a, b) => (a.id < b.id ? 1 : -1))
          .map((email) => (
            <div key={email.id} className="flex flex-col py-7 text-sm">
              {/* <div>
             <AssignmentTypeTag type={emailTypeToAssignmentType(email.type)} />
           </div> */}

              {/* <span className="flex">
             <span className="text-gray-600 mr-1">Skickat:</span>
             {`Den ${formatDate(email.createdAt)}`}
           </span> */}
              <div className="flex justify-between items-center mb-1">
                <EmailStatusTag status={email.status} />
                <span className="text-gray-700 text-xs">
                  {email.status === EmailStatus.Scheduled
                    ? "Skickas inom kort"
                    : isToday(new Date(email.sentAt ?? email.createdAt))
                    ? formatDateWithTime(email.sentAt ?? email.createdAt)
                    : `${formatDate(email.sentAt ?? email.createdAt)}`}
                </span>
              </div>
              <span className="flex text-xs text-gray-900">
                <span className="text-gray-900 mr-1 ml-1">Till:</span>
                {email.to}
              </span>
              <div className="max-w-md mt-3">
                <div className="space-y-1">
                  {email.attachments.map((att) => (
                    <FilePreviewSmall
                      key={att.id}
                      url={att.path}
                      name={att.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
