import { useUpdateSeparator } from "api/separator.api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CreateSeparatorDTO,
  Separator,
  UpdateSeparatorDTO,
} from "types/separator/separator.type";
import { removeCircularDependencies } from "types/separator/separator.helpers";
// import Resizer from "react-image-file-resizer";

export const useUpdateSeparatorLogic = (separator: Separator | undefined) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const {
    mutate: updateSeparator,
    isSuccess: isUpdateSeparatorSuccess,
    isLoading: isUpdatingSeparator,
  } = useUpdateSeparator(separator?.id ?? -1);

  const {
    register,
    handleSubmit: handleSeparatorSubmit,
    watch: formData,
    reset,
    formState: { errors: formErrors },
    setValue: setSeparatorValue,
  } = useForm<CreateSeparatorDTO>();

  const resetForm = (separator: Separator) => {
    reset(removeCircularDependencies(separator));
  };

  useEffect(() => {
    if (separator) {
      resetForm(separator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [separator]);

  useEffect(() => {
    if (isUpdateSeparatorSuccess) {
      setIsModalOpen(false);
    }
  }, [isUpdateSeparatorSuccess]);

  const onSeparatorSubmit = async () => {
    handleSeparatorSubmit((data: UpdateSeparatorDTO) =>
      updateSeparator({ ...data })
    )();
  };

  const onModalClose = () => {
    // resetta fälten
    setIsModalOpen(false);
    document.getElementsByTagName("html")[0].style.overflow = "visible";

    setTimeout(() => {
      document.documentElement.style.overflow = "visible";
      !!separator && resetForm(separator);
    }, 300); // för att inte man ska hinna se att fälten ev ändras tillbaka till ursprungligt värde
  };

  const isUpdating = isUpdatingSeparator;

  return {
    isModalOpen,
    formErrors,
    isUpdating,
    onModalClose,
    openModal,
    onSeparatorSubmit,
    register,
    formData,
    isUpdateSeparatorSuccess,
    setSeparatorValue,
  };
};
