import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  CreateSixMonthsInspectionDTO,
  SixMonthsInspection,
} from "types/sixMonthsInspection/sixMonthsInspection.type";
import instance from "./axiosInstance";
import { InspectionFilesDTO } from "./fiveYearsInspection.api";

export const SIX_MONTHS_INSPECTION_ENDPOINT = "/six-months-inspection";

export enum SixMonthsInspectionQueryKey {
  GetOne = "get-six-months-inspection",
}

export const useGetSixMonthsInspection = (id: number, shouldPoll: boolean) =>
  useQuery(
    [SixMonthsInspectionQueryKey.GetOne, id],
    async (): Promise<SixMonthsInspection> =>
      (await instance.get(`${SIX_MONTHS_INSPECTION_ENDPOINT}/${id}`)).data,
    {
      refetchInterval: shouldPoll ? 3000 : false,
    }
  );

export const useCreateSixMonthsInspection = () => {
  const notification = useNotification();
  return useMutation(
    async (
      payload: CreateSixMonthsInspectionDTO
    ): Promise<SixMonthsInspection> =>
      (await instance.post(SIX_MONTHS_INSPECTION_ENDPOINT, payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateSixMonthsInspection = (options = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();
  return useMutation(
    async (payload: any): Promise<SixMonthsInspection> => {
      const { emails, separator, ...rest } = payload;
      return (await instance.put(SIX_MONTHS_INSPECTION_ENDPOINT, rest)).data;
    },
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (result: SixMonthsInspection) => {
        notification.info("Ändringarna sparade");
        queryClient.invalidateQueries([
          SixMonthsInspectionQueryKey.GetOne,
          result.id,
        ]);
      },
      ...options,
    }
  );
};

export const useSetSixMonthsInspectionFiles = (options = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: InspectionFilesDTO): Promise<SixMonthsInspection> =>
      (await instance.put(SIX_MONTHS_INSPECTION_ENDPOINT + "/files", payload))
        .data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (result: SixMonthsInspection) => {
        queryClient.invalidateQueries([
          SixMonthsInspectionQueryKey.GetOne,
          result.id,
        ]);
      },
      ...options,
    }
  );
};

export const useSendSixMonthsInspectionAttachments = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: {
      id: number;
      includeTransportDocument: boolean;
    }): Promise<number> =>
      (
        await instance.post(
          SIX_MONTHS_INSPECTION_ENDPOINT + "/send-attachments",
          payload
        )
      ).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (id: number) => {
        notification.info("Utskick schemalagt");
        queryClient.invalidateQueries([SixMonthsInspectionQueryKey.GetOne, id]);
      },
    }
  );
};
