import { RapporteringsTyp } from "api/transportDocument.api";
import { NvRapportering } from "./nvRapportering.type";
import { TransportDocument } from "./transportDocument.type";
import { WasteCode } from "./wasteCode.type";

export type Waste = {
  id: number;
  transportDocument: TransportDocument;
  transportDocumentId: number;
  wasteCode: WasteCode;
  wasteCodeId: number;
  weightPreliminary: string;
  weightFinal: string;
  quantity: string;
  comment: string;
  uuid: string;
  nvRapporteringList: NvRapportering[];
};

export type CreateWasteDTO = {
  wasteCodeId: number;
  weightPreliminary?: string;
  quantity?: string;
  comment: string;
};

export type UpdateWasteDTO = {
  id: number;
  wasteCodeId: number;
  weightPreliminary: string;
  weightFinal?: string;
  quantity: string;
  comment: string;
};
export const isTransportRapporterad = (w: Waste) => {
  console.log({ w });
  return w.nvRapporteringList.some(
    (r) => r.type === RapporteringsTyp.Transport
  );
};

export const isTransportPlaneringRapporterad = (w: Waste) =>
  w.nvRapporteringList.some(
    (r) => r.type === RapporteringsTyp.TransportPlanering
  );
