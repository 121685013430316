import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { classNames } from "helpers/classNames";
import { RequiredForInspection } from "./RequiredForInspection";
import { ModalLabel } from "./ModalLabel";

type RadioItem = {
  label: string;
  value: string;
  disabled?: boolean;
};
type RadioGroupProps = {
  label?: string;
  items: RadioItem[];
  register: UseFormRegister<any>;
  name: string;
  enableAnimation?: boolean;
  missingDataWarning?: boolean;
  isMissing?: boolean;
  validation?: any;
  errorMessage?: string;
  row?: boolean;
};
export const RadioGroup: FC<RadioGroupProps> = ({
  label,
  items,
  register,
  name,
  missingDataWarning,
  isMissing = false,
  validation,
  errorMessage,
  row = false,
}) => {
  // console.log(
  //   "Farstagången 2, 123 47 Farsta",
  //   isValidCoordinates("Farstagången 2, 123 47 Farsta")
  // );

  // console.log(
  //   "59°20 53.4 N 13°07 50.2 E",
  //   isValidCoordinates("59°20 53.4 N 13°07 50.2 E")
  // );

  return (
    <div>
      {!!label && (
        <ModalLabel htmlFor={name}>
          {label}
          {missingDataWarning && (
            <RequiredForInspection isMissing={isMissing} />
          )}
        </ModalLabel>
      )}

      <div
        className={classNames(
          "mt-2 flex",
          row ? "flex-row gap-x-8" : "flex-col"
        )}
      >
        {items.map((item, index) => (
          <div
            key={item.value}
            className={classNames("flex items-center", row ? "" : "mt-1 mb-2")}
          >
            <input
              id={`${name}-${item.value}`}
              value={item.value}
              {...register(name, validation && validation)}
              type="radio"
              className={classNames(
                "focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300",
                !!item.disabled && "opacity-50"
              )}
              disabled={!!item.disabled}
              autoFocus={index === 0}
            />
            <label
              htmlFor={`${name}-${item.value}`}
              className={classNames(
                "block text-sm font-medium text-gray-700",
                !!item.disabled && "opacity-50",
                row ? "ml-1.5" : "ml-3"
              )}
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>
      {!!errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};
