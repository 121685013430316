import { useMutation } from "react-query";
import {
  clearRefreshTokenExpirationDate,
  setRefreshTokenExpirationDate,
} from "store/refreshToken.store";
import userStore from "store/user.store";
import { User } from "types/user.type";
import instance from "./axiosInstance";

export const ENDPOINT_LOGIN = "/auth/login";
export const ENDPOINT_LOGOUT = "/auth/logout";
export const ENDPOINT_REFRESH_TOKEN = "/auth/refreshtoken";

export const useLogin = () => {
  const { setUser } = userStore();

  const {
    mutate: login,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    async (payload: LoginDTO): Promise<LoginResponse> =>
      (await instance.post(ENDPOINT_LOGIN, payload)).data,
    {
      onSuccess: (data) => {
        setUser(data.user);
        setRefreshTokenExpirationDate(
          new Date(data.refreshTokenExpirationDate).getTime().toString()
        );
      },
    }
  );

  return { login, isLoading, isSuccess, isError };
};

export const useServerLogout = () => {
  const { clearUser } = userStore();

  return useMutation(
    async (): Promise<void> => await instance.post(ENDPOINT_LOGOUT),
    {
      onSuccess: () => {
        clearUser();
        clearRefreshTokenExpirationDate();
      },
    }
  );
};

export type LoginDTO = {
  username: string;
  password: string;
};

export type LoginResponse = {
  user: User;
  refreshTokenExpirationDate: Date;
};
