import { classNames } from "helpers/classNames";
import { FC } from "react";

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  checked?: boolean;
  setChecked: (newValue: boolean) => void;
  small?: boolean;
}
export const CheckboxStupid: FC<Props> = ({
  label,
  name,
  disabled = false,
  checked = false,
  setChecked,
  small,
}) => {
  return (
    <fieldset>
      <div className="flex items-center">
        <div className="flex items-center h-5">
          <input
            type="checkbox"
            name={name}
            className={classNames(
              "focus:ring-cyan-400 text-cyan-600 border-gray-300 rounded disabled:text-gray-300",
              small ? "h-3.5 w-3.5" : "h-5 w-5"
            )}
            disabled={disabled}
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor={name}
            className={classNames(
              "font-normal disabled:text-gray-400",
              small ? "text-sm text-gray-700" : "text-base text-gray-900"
            )}
            onClick={() => setChecked(!checked)}
          >
            {label}
          </label>
        </div>
      </div>
    </fieldset>
  );
};
