export enum KontrollStatus {
  Ja = "Ja",
  Nej = "Nej",
  FinnsEj = "Finns ej",
  EjAngett = "Ej angett",
}

export enum JaNejStatus {
  Ja = "Ja",
  Nej = "Nej",
  Ej_Angett = "Ej angett",
}

export const isMissingJaNejAnswer = (val?: JaNejStatus): boolean =>
  !val || val === JaNejStatus.Ej_Angett;

export const isMissingKontrollAnswer = (val?: KontrollStatus): boolean =>
  !val || val === KontrollStatus.EjAngett;

export enum SeparatorClassification {
  Klass1 = "Klass1",
  Klass2 = "Klass2",
  AldreGravimetrisk = "AldreGravimetrisk",
  Unavailable = "Unavailable",
}

export enum InspectionResult {
  Approved = "Godkänd",
  NotApproved = "Ej godkänd",
  Unknown = "Ej angiven",
}

export const isMissingInspectionResult = (val?: InspectionResult): boolean =>
  !val || val === InspectionResult.Unknown;

export enum InspectionType {
  SixMonths = "6-månaderskontroll",
  FiveYears = "5-årskontroll",
  Slamtomning = "Tömning",
}

export enum Role {
  Guest = 0,
  User = 10,
  Admin = 20,
  SuperUser = 100,
}

export enum TomningType {
  Heltomning = "Heltomning",
  Toppsugning = "Toppsugning",
  NotAssigned = "NotAssigned",
  NotApplicable = "NotApplicable",
}

export enum TransportDocumentStatus {
  Created = "Created",
  Loaded = "Loaded",
  Weighed = "Weighed",
  Signed = "Signed",
}
