import { classNames } from "helpers/classNames";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
interface Props {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  validation?: any;
  errorMessage?: string;
  autoFocus?: boolean;
  placeholder?: string;
  rows?: number;
}
export const TextArea: FC<Props> = ({
  label,
  name,
  register,
  validation,
  errorMessage,
  autoFocus = false,
  placeholder,
  rows = 3,
}) => {
  const hasError = !!errorMessage;
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm",
            hasError
              ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300"
              : "border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
          )}
          autoComplete="off"
          {...register(name, validation && validation)}
          autoFocus={autoFocus}
          placeholder={placeholder}
          rows={rows}
        />
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
