import { UpdateCompanyDTO } from "api/company.api";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { isEmail } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

interface Props {
  register: UseFormRegister<UpdateCompanyDTO>;
  errors: any;
}
export const EditCompanyModal: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="Namn på ert företag"
        name="name"
        register={register}
        validation={{ required: "Detta fält är obligatoriskt" }}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      <TextInput
        label="Organisationsnummer"
        name="organizationNumber"
        register={register}
        validation={{ required: "Detta fält är obligatoriskt" }}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput label="Telefon" name="phone" register={register} />
      <TextInput label="Adress" name="address" register={register} />
      <TextInput label="Postnummer" name="postalCode" register={register} />
      <TextInput label="Stad" name="city" register={register} />
      <div className="flex-col">
        <h4 className="text-md leading-6 font-medium text-gray-900 mb-4 pt-4">
          Kontaktuppgifter för rapportering till Avfallsregistret
        </h4>
        <div className="space-y-8">
          <TextInput
            label="Kontaktperson - namn"
            name="contactFullName"
            register={register}
          />
          <TextInput
            label="Kontaktperson - epostadress"
            name="contactEmail"
            register={register}
            validation={{
              validate: (value: string) =>
                !value || // Ok att lämna tomt
                isEmail(value) ||
                "Denna emailadress ser ut att vara felaktig",
            }}
            errorMessage={errors?.contactEmail?.message}
          />
          <TextInput
            label="Kontaktperson - telefon"
            name="contactPhone"
            register={register}
          />
        </div>
      </div>
    </ModalContentWrapper>
  );
};
