import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from "types/user.type";
import instance from "./axiosInstance";
import { TransportDocumentQueryKey } from "./transportDocument.api";
import userStore from "store/user.store";
import { setRefreshTokenExpirationDate } from "store/refreshToken.store";
const ENDPOINT = "user";
export const GET_USERS_QK = "getUsers";
export const GET_USER_QK = "get-user";

export const useUsers = (options = {}) =>
  useQuery(
    [GET_USERS_QK],
    async (): Promise<User[]> => (await instance.get(ENDPOINT)).data,
    options
  );

export const useUser = (id: number) =>
  useQuery(
    [GET_USER_QK],
    async (): Promise<User> => (await instance.get(`${ENDPOINT}/${id}`)).data
  );

export const useSignup = () => {
  return useMutation(
    async (payload: SignupDTO) =>
      (await instance.post(`${ENDPOINT}/signup-by-code`, payload)).data
  );
};

export const useMagicLinkLogin = () => {
  const { setUser } = userStore();

  return useMutation(
    async (payload: { code: string }) =>
      (await instance.post(`${ENDPOINT}/magic-link-login`, payload)).data,
    {
      onSuccess: (data) => {
        setUser(data.user);
        setRefreshTokenExpirationDate(
          new Date(data.refreshTokenExpirationDate).getTime().toString()
        );
      },
    }
  );
};

export const useChangePassword = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: ChangePasswordDTO) =>
      await instance.put(`${ENDPOINT}/change-password`, payload),
    {
      onSuccess: () => {
        notification.info(`Lösenordet ändrat`);
      },
      onError: () => {
        notification.error("Fel lösenord, försök igen");
      },
    }
  );
};

// export const useUpdateUser = () => {
//   const notification = useNotification();
//   const queryClient = useQueryClient();
//   return useMutation(
//     async (payload: UpdateUserDTO) =>
//       await instance.put(`${ENDPOINT}`, payload),
//     {
//       onSuccess: () => {
//         notification.changesSaved();
//         queryClient.invalidateQueries([GET_USER_QK]);
//       },
//       onError: () => {
//         notification.error();
//       },
//     }
//   );
// };

export const useForgotPassword = () => {
  return useMutation(
    async (payload: ForgotPasswordDTO): Promise<void> =>
      await instance.post(`${ENDPOINT}/forgot-password`, payload)
  );
};

export const useResetPassword = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: ResetPasswordDTO) =>
      await instance.put(`${ENDPOINT}/reset-password`, payload),
    {
      onSuccess: () => {
        notification.info(`Lösenordet ändrat`);
      },
      onError: () => {
        notification.error("Något gick fel, försök igen");
      },
    }
  );
};

export const useSetActiveVehicles = (userId: number) => {
  const notification = useNotification();
  const quertClient = useQueryClient();
  return useMutation(
    async (payload: number[]) =>
      await instance.put(`${ENDPOINT}/${userId}/active-vehicles`, {
        vehicleIds: payload,
      }),
    {
      onSuccess: () => {
        quertClient.invalidateQueries([GET_USER_QK]);
        quertClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
        // notification.info(``);
      },
      onError: () => {
        notification.error("Något gick fel, försök igen");
      },
    }
  );
};

export type SignupDTO = {
  code: string;
  password: string;
  name: string;
};

export type ChangePasswordDTO = {
  oldPassword: string;
  newPassword: string;
};

export type ResetPasswordDTO = {
  password: string;
  code: string;
};

export type ForgotPasswordDTO = {
  email: string;
};

export type UpdateUserDTO = {
  name: string;
};
