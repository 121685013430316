/*========== Trigga åtgärder utifrån händelser i blanketten ==========*/

import { geodetic_to_grid } from "./coordinates.helpers";

const isValidCoordinates = (inputString?: string) => {
  if (!inputString) return false;
  const pattern = /\b\d{2}\.\d{5,}\b/g;
  const matches = inputString.match(pattern);
  return !!matches && matches.length === 2;
};

const getCoordinates = (inputString: string) => {
  const pattern = /\b\d{2}\.\d{5,}\b/g;
  const matches = inputString.match(pattern);
  return matches;
};

const getNPosition = (inputString?: string) => {
  if (!inputString) return "";
  if (!isValidCoordinates(inputString)) return "";
  return getCoordinates(inputString)?.[0] ?? "";
};

const getEPosition = (inputString?: string) => {
  if (!inputString) return "";
  if (!isValidCoordinates(inputString)) return "";
  const coordinates = getCoordinates(inputString);
  if (!coordinates || coordinates.length < 2) return "";
  return coordinates[1] ?? "";
};

// https://dag.issjo.se/Stuff/CoordinateConversion.php
// 59.31188861258965, 18.087684080533634
// keyUp WGS84-rutan latitud KeyUpLat
export const getSweref99 = (wgs84coordinates?: string) => {
  if (!isValidCoordinates(wgs84coordinates)) return;
  const lat = getNPosition(wgs84coordinates);
  const long = getEPosition(wgs84coordinates);
  //CalcLa
  const latitude = parseFloat(lat);
  const longitude = parseFloat(long);

  //   la = document.getElementById("lat_dd").value;
  //   la = parseFloat(latitude.replace(",", "."));
  //   lat_dm.value = convert_lat_to_dm(la);
  //   lat_dms.value = convert_lat_to_dms(la);
  //   update_rt90();
  const [n, e] = geodetic_to_grid(latitude, longitude);
  return [Math.trunc(n), Math.trunc(e)];
};
