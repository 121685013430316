import { LightBulbIcon } from "@heroicons/react/24/solid";
import { FC } from "react";
import dismissedTipsStore from "store/dismissedTips.store";

interface Props {
  id: string;
  paragraphs: string[];
}
export const Tips: FC<Props> = ({ id, paragraphs }) => {
  const { dismissedTipsIds, addOne } = dismissedTipsStore();
  const isDismissed = dismissedTipsIds.some((_id) => _id === id);
  if (isDismissed) {
    return null;
  }
  return (
    <div className="rounded-md bg-cyan-50 p-4">
      <div className="flex mb-3 items-center">
        <LightBulbIcon
          className="h-5 w-5 text-cyan-400 mr-2"
          aria-hidden="true"
        />
        <span className="text-cyan-800 text-sm font-semibold">Tips!</span>
      </div>
      {paragraphs.map((parra) => (
        <p key={parra} className="text-sm text-cyan-900 mb-4">
          {parra}
        </p>
      ))}

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => addOne(id)}
          className="ml-3 rounded-md bg-cyan-50 px-2 py-1.5 text-sm font-medium text-cyan-800 hover:bg-cyan-100 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 focus:ring-offset-cyan-50"
        >
          Visa inte igen
        </button>
      </div>
    </div>
  );
};
