import { FC } from "react";
import { Assignment } from "types/assignment.type";
import {
  ClipboardDocumentListIcon,
  DocumentPlusIcon,
  CircleStackIcon,
  UserIcon,
  DocumentMagnifyingGlassIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { AssignmentStatusTag } from "components/AssignmentStatusTag";
import { separatorTypeBySeparatorToStringShort } from "types/separator/separator.helpers";
import {
  AssignmentTypeTag,
  AssignmentTypeTagSmall,
} from "components/AssignmentTypeTag";
import { AssignmentType } from "enums/AssignmentType.enum";
import { ActionMenu } from "../dashboard/ActionMenu";
import { PriorityIcon } from "./PriorityIcon";
import { TD, TR } from "components/table";
import { getDayNameFromWeekDay } from "helpers/date";
import { ProxyAssignmentIcon } from "components/ProxyAssignmentIcon";
import { Tooltip } from "react-tooltip";
import { AssignmentStatus } from "enums/AssignmentStatus.enum";

interface AssignmentItemProps {
  assignment: Assignment;
  onEditClick: () => void;
  onDeleteClick: () => void;
  openInspection: (ass: Assignment) => void;
  loggedInUserId?: number;
  createInspection: (ass: Assignment) => void;
  isLate?: boolean;
  viewAssignment: () => void;
}
export const AssignmentItem: FC<AssignmentItemProps> = ({
  assignment,
  onEditClick,
  onDeleteClick,
  openInspection,
  loggedInUserId,
  createInspection,
  isLate = false,
  viewAssignment,
}) => {
  return (
    <TR>
      <TD hideOnMobile>
        <div className="flex items-center gap-x-1">
          {assignment.isPrioritized && <PriorityIcon />}
          {!!assignment.weekday ? (
            <span className=" flex gap-x-0 items-center">
              {/* <CalendarIcon
              className="mr-1 h-4 flex-shrink-0 text-gray-500"
              aria-hidden="true"
            /> */}
              {getDayNameFromWeekDay(assignment.weekday)}
            </span>
          ) : (
            <span className="text-gray-500">Under veckan</span>
          )}
        </div>
      </TD>
      <TD>
        {/* Kund */}
        <div className="flex flex-col justify-center gap-y-2 items-start max-w-[220px] lg:max-w-[200px] xl:max-w-[200px]">
          <span className="font-medium truncate max-w-[220px] lg:max-w-[200px] xl:max-w-[200px]">
            {assignment.customer.name ?? ""}
          </span>
          {!!assignment.parentCustomer && (
            <div className="flex gap-x-1 items-center text-sm max-w-[220px] lg:max-w-[200px] xl:max-w-[200px]">
              <ProxyAssignmentIcon />
              {assignment.parentCustomer?.name}
            </div>
          )}
        </div>
      </TD>
      {/* Uppdragstyp + Objekt*/}
      <TD>
        <div className="flex gap-x-2 items-center">
          {/* Uppdragstyp */}

          <AssignmentTypeTag
            className="hidden sm:inline-flex"
            type={assignment.type}
          />
          <AssignmentTypeTagSmall
            className="inline-flex sm:hidden"
            type={assignment.type}
          />
          {assignment.includeTomning && (
            <AssignmentTypeTag type={AssignmentType.Tomning} />
          )}
          {assignment.assignmentStatus !== AssignmentStatus.Scheduled && (
            <AssignmentStatusTag status={assignment.assignmentStatus} />
          )}
        </div>
      </TD>
      {/* Separator */}
      <TD>
        <span className="items-center text-sm text-gray-900 hidden xs:flex">
          <CircleStackIcon
            className="mr-1 h-4 flex-shrink-0 text-gray-500"
            aria-hidden="true"
          />
          <span className="truncate text-xs">
            {separatorTypeBySeparatorToStringShort(assignment.separator)}
          </span>
        </span>
      </TD>
      {/* operatör */}
      <TD hideOnTablet>
        <div className="flex gap-x-0 items-center">
          <UserIcon
            className="mr-1 h-4 flex-shrink-0 text-gray-500"
            aria-hidden="true"
          />
          {assignment.user.name}
        </div>
      </TD>
      {/* Intern kommentar */}
      <TD hideOnTablet>
        {assignment.notes ? (
          <span
            className="max-w-[200px] truncate flex items-center gap-x-1"
            data-tooltip-id={`assignment-notes-${assignment.id}`}
            data-tooltip-content={assignment.notes}
            data-tooltip-place="top"
          >
            <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
            <Tooltip id={`assignment-notes-${assignment.id}`} />
            <span className="max-w-[200px] truncate">{assignment.notes}</span>
          </span>
        ) : (
          <></>
        )}
      </TD>

      {/* Actionmeny */}
      <TD>
        <div className="flex flex-col justify-center items-center">
          <ActionMenu
            items={[
              {
                label: "Påbörja",
                onClick: () => createInspection(assignment),
                icon: (
                  <DocumentPlusIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                ),
              },
              {
                label: "Öppna",
                onClick: () => openInspection(assignment),
                icon: (
                  <ClipboardDocumentListIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                ),
              },
              {
                label: "Detaljer",
                onClick: viewAssignment,
                icon: (
                  <DocumentMagnifyingGlassIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                ),
              },
              {
                label: "Ändra",
                onClick: onEditClick,
                icon: (
                  <PencilSquareIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                ),
              },
              {
                label: "Ta bort",
                onClick: onDeleteClick,
                icon: (
                  <TrashIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                ),
              },
            ]
              .filter(
                (item) =>
                  item.label !== "Öppna" ||
                  !(
                    !assignment.fiveYearsInspection &&
                    !assignment.sixMonthsInspection &&
                    !assignment.tomning
                  )
              )
              .filter(
                (item) =>
                  item.label !== "Påbörja" ||
                  (loggedInUserId === assignment.userId &&
                    !assignment.fiveYearsInspection &&
                    !assignment.sixMonthsInspection &&
                    !assignment.tomning)
              )}
          />
        </div>
      </TD>
    </TR>
  );
};
