import { Transition } from "components/animations/Transition";
import { Switch } from "components/Switch";
import { formatDate, getWeekWithLabel } from "helpers/date";
import { FC } from "react";
import { Assignment } from "types/assignment.type";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { AvtalProperty } from "./AvtalProperty";
import { ScheduledProperty } from "./ScheduledProperty";

interface AvtalSexMånadersProps {
  isEnabled: boolean;
  setIsEnabled: (val: boolean) => void;
  label: string;
  openModal: () => void;
  taskDate?: Date;
  disableAnimation: boolean;
  latestSixMonthsInspection?: SixMonthsInspection;
  sixMonthsInspectionLink?: string;
  scheduledAssignment?: Assignment;
  setAssignmentIdToView: (id: number | null) => void;
}
export const AvtalSexmånaders: FC<AvtalSexMånadersProps> = ({
  isEnabled,
  setIsEnabled,
  label,
  openModal,
  taskDate,
  disableAnimation,
  latestSixMonthsInspection,
  sixMonthsInspectionLink,
  scheduledAssignment,
  setAssignmentIdToView,
}) => {
  return (
    <div className="col-span-1 mt-4">
      <Switch
        isEnabled={isEnabled}
        setIsEnabled={(val: boolean) => setIsEnabled(val)}
        label={label}
      />

      <div className="mt-4">
        <Transition
          settings={{
            from: { opacity: 0, maxHeight: 0 },
            enter: { opacity: 1, maxHeight: 121 },
            leave: { opacity: 0, maxHeight: 0 },
            config: { precision: 0.02 },
            immediate: disableAnimation,
          }}
          isVisible={isEnabled}
        >
          <dl className="border-b border-gray-100">
            {!!scheduledAssignment ? (
              <ScheduledProperty
                label="Nästa tillfälle"
                value={getWeekWithLabel(new Date(scheduledAssignment.date))}
                action={() => setAssignmentIdToView(scheduledAssignment.id)}
                assignment={scheduledAssignment}
              />
            ) : (
              <AvtalProperty
                label="Nästa tillfälle"
                value={
                  !taskDate ? "Ej angett" : `Senast ${formatDate(taskDate)}`
                }
                action={openModal}
              />
            )}
            <AvtalProperty
              label="Senaste tillfälle"
              value={formatDate(latestSixMonthsInspection?.inspectionDate)}
              link={sixMonthsInspectionLink}
              actionText="Se rapport"
            />
          </dl>
        </Transition>
      </div>
    </div>
  );
};
