import { DropdownItem } from "components/Dropdown";
import { TaskType } from "./TaskType.enum";

export enum AssignmentType {
  FiveYearInspection = 10,
  SixMonthsInspection = 20,
  Tomning = 30,
  Other = 100,
  Undefined = 0,
}

export const taskTypeToAssignmentType = (
  taskType: TaskType
): AssignmentType => {
  switch (taskType) {
    case TaskType.FiveYearInspection:
      return AssignmentType.FiveYearInspection;
    case TaskType.SixMonthsInspection:
      return AssignmentType.SixMonthsInspection;
    case TaskType.Tomning:
      return AssignmentType.Tomning;
    default:
      throw Error(
        `taskTypeToAssignmentType is missing implementation for ${taskType}`
      );
  }
};

export const getAssignmentTypeName = (assignmentType: AssignmentType) => {
  switch (assignmentType) {
    case AssignmentType.FiveYearInspection:
      return "5-årsbesiktning";
    case AssignmentType.SixMonthsInspection:
      return "6-månaderskontroll";
    case AssignmentType.Tomning:
      return "Tömning";
    case AssignmentType.Other:
      return "Övrigt";
    default:
      throw Error(
        `getAssignmentTypeName saknar implementation för ${assignmentType}`
      );
  }
};

export const assignmentTypeNameShort = (type: AssignmentType) => {
  switch (type) {
    case AssignmentType.FiveYearInspection:
      return "5-års";
    case AssignmentType.SixMonthsInspection:
      return "6-mån";
    case AssignmentType.Tomning:
      return "Tömning";
    default:
      throw Error("Invalid AssignmentType : " + type);
  }
};

export const getAssignmentTypeDropdownItems = (): DropdownItem[] => [
  {
    label: getAssignmentTypeName(AssignmentType.SixMonthsInspection),
    value: AssignmentType.SixMonthsInspection,
  },
  {
    label: getAssignmentTypeName(AssignmentType.FiveYearInspection),
    value: AssignmentType.FiveYearInspection,
  },
  {
    label: getAssignmentTypeName(AssignmentType.Tomning),
    value: AssignmentType.Tomning,
  },
];
