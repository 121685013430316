import { FC } from "react";

interface Props {
  children: React.ReactNode;
}

export const Item: FC<Props> = ({ children }) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
    {children}
  </div>
);
