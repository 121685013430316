import { AssignmentQueryKey, useUpdateAssignment } from "api/assignment.api";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { UseMutationResult, useQueryClient } from "react-query";
import { Assignment, AssignmentDTO } from "types/assignment.type";

export const useEditAssignmentLogic = (
  assignment: Assignment
): EditAssignmentLogic => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // api
  const queryClient = useQueryClient();
  const form = useForm<AssignmentDTO>();

  const closeModal = () => {
    form.reset(assignment);
    setIsModalOpen(false);
  };

  const mutation = useUpdateAssignment({
    onSuccess: () => {
      queryClient.invalidateQueries([AssignmentQueryKey.GetForUser]);
      closeModal();
    },
  });

  // form

  // Reset form when assignment changes
  useEffect(() => {
    if (assignment) {
      form.reset(assignment!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);

  const saveChanges = form.handleSubmit((data: AssignmentDTO) => {
    if (!data.userId || data.userId < 1) {
      throw Error("Couldn't find user");
    }

    mutation.mutate({
      id: assignment.id,
      payload: {
        ...data,
        userId: +data.userId,
        weekday: !data.weekday ? undefined : +data.weekday,
      },
    });
  });

  return {
    openModal: () => setIsModalOpen(true),
    isModalOpen,
    mutation,
    form,
    closeModal,
    saveChanges,
  };
};

export type EditAssignmentLogic = {
  openModal: () => void;
  closeModal: () => void;
  isModalOpen: boolean;
  mutation: UseMutationResult<
    Assignment,
    unknown,
    {
      payload: AssignmentDTO;
      id: string | number;
    },
    unknown
  >;
  form: UseFormReturn<AssignmentDTO, any>;
  saveChanges: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
};
