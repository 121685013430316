import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { isNumbers, removeWhitespace } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { Step1Data } from "./OpenTransportDocumentsPage";
import {
  TransportDocument,
  TransportDocumentGroup,
  sumPreliminaryTotalWeight,
} from "types/transportDocument.type";
import {
  VehicleType,
  vehicleNameAndType,
  vehicleTypeToName,
} from "types/vehicle.type";
import { ModalLabel } from "components/ModalLabel";
import { TransportDocumentStatus } from "types/enums";

interface Props {
  register: UseFormRegister<Step1Data>;
  errors: any;
  group?: TransportDocumentGroup;
  tdsToWeigh: TransportDocument[];
  actualTotalWeight?: string;
}

export const Step1Form: FC<Props> = ({
  register,
  errors,
  group,
  tdsToWeigh = [],
  actualTotalWeight,
}) => {
  if (!group) {
    return null;
  }

  // Ta ut alla transportdokument för gruppen.
  // Om det bara finns ett fordon ska man ta ut alla därifrån
  // Om det finns fler än 1 ska man ta ut alla som har true på det där

  const totalPreliminaryWeight = sumPreliminaryTotalWeight(tdsToWeigh);

  // Lista alla TDs beroende på vad man har checkat i.
  const truckRegNr = group.vehicles.find(
    (v) => v.vehicle.type === VehicleType.Truck
  )?.vehicle?.registrationNumber;

  const trailerRegNr = group.vehicles.find(
    (v) => v.vehicle.type === VehicleType.Trailer
  )?.vehicle?.registrationNumber;
  return (
    <ModalContentWrapper>
      <div>
        <ModalLabel>Mottagare</ModalLabel>
        <span className="mt-2">{group.wasteStation.name}</span>
      </div>
      {group.vehicles.filter((v) =>
        v.transportDocuments.some(
          (td) => td.status === TransportDocumentStatus.Loaded
        )
      ).length > 1 ? (
        <fieldset>
          <legend className="sr-only">Form</legend>
          <ModalLabel>Välj fordon</ModalLabel>
          <div className="mt-2 space-y-2">
            <div className="flex items-center">
              <div className="flex items-center h-5">
                <input
                  id="truckSelected"
                  type="checkbox"
                  {...register("truckSelected")}
                  className="focus:ring-cyan-400 h-5 w-5 text-cyan-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-base">
                <label
                  htmlFor="truckSelected"
                  className="font-medium text-gray-700"
                >
                  {`${truckRegNr} - ${vehicleTypeToName(VehicleType.Truck)}`}
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center h-5">
                <input
                  id="trailerSelected"
                  {...register("trailerSelected")}
                  type="checkbox"
                  className="focus:ring-cyan-400 h-5 w-5 text-cyan-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-base">
                <label
                  htmlFor="trailerSelected"
                  className="font-medium text-gray-700"
                >
                  {`${trailerRegNr} - ${vehicleTypeToName(
                    VehicleType.Trailer
                  )}`}
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      ) : (
        <div>
          <ModalLabel>Fordon</ModalLabel>
          <span className="mt-2">
            {vehicleNameAndType(group.vehicles[0].vehicle)}
          </span>
        </div>
      )}
      <div>
        <ModalLabel>Uppskattad vikt</ModalLabel>
        <span className="mt-2">{`${totalPreliminaryWeight} kg`}</span>
      </div>

      <div className="flex items-center justify-between relative">
        <div className="flex-1">
          <TextInput
            label="Vägd vikt"
            name="actualTotalWeight"
            register={register}
            autoFocus
            appendix="kg"
            size="third"
            validation={{
              validate: (value: string) => {
                const trimmed = removeWhitespace(value).replace("-", "");
                return isNumbers(trimmed) || "Ange vikten i hela kilo";
              },
            }}
            errorMessage={errors?.actualTotalWeight?.message}
          />
        </div>
        {isNumbers(actualTotalWeight ?? "") && (
          <span className="absolute right-0 pr-2 pt-6 text-base text-gray-600">{`${
            +(actualTotalWeight ?? "1") / 1000
          } ton`}</span>
        )}
      </div>
    </ModalContentWrapper>
  );
};
