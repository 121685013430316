import { useEffect, useState } from "react";

export const useHasPassedTimeAfterInitialRender = (
  timeInMilliseconds = 1000
) => {
  const [hasPassedTime, setHasPassedTime] = useState(false);

  useEffect(() => {
    setTimeout(() => setHasPassedTime(true), timeInMilliseconds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return hasPassedTime;
};
