import { useQueryClient } from "react-query";
import { clearRefreshTokenExpirationDate } from "store/refreshToken.store";
import userStore from "store/user.store";

export const useLogout = () => {
  const clearUser = userStore((state) => state.clearUser);
  const queryClient = useQueryClient();
  return () => {
    clearUser();
    clearRefreshTokenExpirationDate();
    queryClient.clear();
  };
};
