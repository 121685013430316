import { FC } from "react";
import TabContainer from "components/tab/TabContainer";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { Invitation } from "types/invitation.type";
import SecondaryButton from "components/buttons/SecondaryButton";
import { CheckIcon } from "@heroicons/react/24/outline";
import { User } from "types/user.type";
import { Role } from "types/enums";
import { ShieldCheckIcon } from "@heroicons/react/20/solid";

interface Props {
  users?: User[];
  invitations?: Invitation[];
  deleteInvitation: (id: number) => void;
}
export const UsersTab: FC<Props> = ({
  users = [],
  invitations = [],
  deleteInvitation,
}) => {
  return (
    <TabContainer>
      <Table>
        <THead>
          <TR>
            <TH isFirstColumn>E-postadress</TH>
            <TH>Namn</TH>
            <TH>Status</TH>
            <TH>Kontotyp</TH>
          </TR>
        </THead>
        <TBody>
          {users.map(({ email, name, role }: User) => (
            <TR key={email}>
              <TD isFirstColumn>{email}</TD>
              <TD>{name ?? ""}</TD>
              <TD>
                <div className="flex items-center text-cyan-700">
                  <span>Aktiv</span>
                  <CheckIcon className="w-4 h-4 ml-1" />
                </div>
              </TD>
              <TD>
                {role === Role.Admin ? (
                  <div className="flex items-center">
                    Administratör
                    <ShieldCheckIcon className="ml-1 h-5 w-5 text-cyan-500" />
                  </div>
                ) : (
                  "Operatör"
                )}
              </TD>
            </TR>
          ))}
          {invitations.map(({ email, id, role, name }: Invitation) => (
            <TR key={id}>
              <TD isFirstColumn>{email}</TD>
              <TD>{name}</TD>
              <TD>
                <SecondaryButton
                  size="small"
                  onClick={() => deleteInvitation(id)}
                >
                  Ta bort inbjudan
                </SecondaryButton>
              </TD>
              <TD>
                {role === Role.Admin ? (
                  <div className="flex items-center">
                    Administratör
                    <ShieldCheckIcon className="ml-1 h-5 w-5 text-cyan-500" />
                  </div>
                ) : (
                  "Operatör"
                )}
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
    </TabContainer>
  );
};
