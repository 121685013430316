import { FC } from "react";
import {
  formatInspectionResultStatus,
  formatJaNejStatus,
} from "components/inspection/inspection.helpers";
import {
  ProtocolLabel,
  ProtocolLabelValueWrapper,
  ProtocolValue,
} from "components/inspection/protocol/LabelValue";
import { ProtocolWrapper } from "components/inspection/protocol/ProtocolWrapper";
import { SectionHeader } from "components/inspection/protocol/SectionHeader";
import { FiveYearsInspection } from "types/fiveYearsInspection/fiveYearsInspection.type";
import { get5YInspectionLabel } from "types/fiveYearsInspection/fiveYearsInspection.helpers";
import { InspectionResult, JaNejStatus } from "types/enums";
import { Section } from "components/inspection/protocol/Section";
import { SectionContent } from "components/inspection/protocol/SectionContent";
import { formatDate } from "helpers/date";

export const Protocol: FC<{
  inspection: FiveYearsInspection;
}> = ({ inspection }) => (
  <ProtocolWrapper>
    <Section>
      <SectionHeader title="Obligatoriska punkter enligt 6 Kap i SS-EN 858-2" />
      <SectionContent>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("strengthInnerAndOuter")}
          </ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.strengthInnerAndOuter)}
          >
            {formatInspectionResultStatus(inspection.strengthInnerAndOuter)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>{get5YInspectionLabel("innerPlaque")}</ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.innerPlaque)}
          >
            {formatInspectionResultStatus(inspection.innerPlaque)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("conditionInnerParts")}
          </ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.conditionInnerParts)}
          >
            {formatInspectionResultStatus(inspection.conditionInnerParts)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("conditionElectricParts")}
          </ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.conditionElectricParts)}
          >
            {formatInspectionResultStatus(inspection.conditionElectricParts)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("flottarFunctionality")}
          </ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.flottarFunctionality)}
          >
            {inspection.separator.flottarFinns !== JaNejStatus.Ja
              ? "Finns ej"
              : formatInspectionResultStatus(inspection.flottarFunctionality)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>{get5YInspectionLabel("systemDensity")}</ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.systemDensity)}
          >
            {formatInspectionResultStatus(inspection.systemDensity)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
      </SectionContent>
    </Section>
    {!!inspection.localRequirements &&
      inspection.localRequirements.length > 0 && (
        <Section>
          <SectionHeader title="Lokala krav" />
          <SectionContent>
            {inspection.localRequirements.map(({ id, name, result }) => (
              <ProtocolLabelValueWrapper key={id}>
                <ProtocolLabel>{name}</ProtocolLabel>
                <ProtocolValue color={getInspectionResultColor(result)}>
                  {formatInspectionResultStatus(result, false)}
                </ProtocolValue>
              </ProtocolLabelValueWrapper>
            ))}
          </SectionContent>
        </Section>
      )}
    <Section>
      <SectionHeader title="Kontrollomdöme" />
      <SectionContent>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("requirementsFulfilled")}
          </ProtocolLabel>
          <ProtocolValue
            color={getInspectionResultColor(inspection.requirementsFulfilled)}
          >
            {formatInspectionResultStatus(inspection.requirementsFulfilled)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>{get5YInspectionLabel("remarks")}</ProtocolLabel>
          <ProtocolValue>{inspection.remarks}</ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>{get5YInspectionLabel("equipmentUsed")}</ProtocolLabel>
          <ProtocolValue>{inspection.equipmentUsed}</ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("referenceNumber")}
          </ProtocolLabel>
          <ProtocolValue>{inspection.referenceNumber ?? ""}</ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>{get5YInspectionLabel("user")}</ProtocolLabel>
          <ProtocolValue>{inspection.user.name}</ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("inspectionDate")}
          </ProtocolLabel>
          <ProtocolValue>
            {formatDate(new Date(inspection.inspectionDate))}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
      </SectionContent>
    </Section>
    <Section>
      <SectionHeader title="Tömning, återfyllning och återställning" />
      <SectionContent>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>Tömningar i samband med besiktningen</ProtocolLabel>
          <ProtocolValue>{getTomningar(inspection)}</ProtocolValue>
        </ProtocolLabelValueWrapper>

        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("befintligVattenfasAterford")}
          </ProtocolLabel>
          <ProtocolValue>
            {formatJaNejStatus(inspection.befintligVattenfasAterford)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>

        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("hasBefintligVattenfasByttsMotVatten")}
          </ProtocolLabel>
          <ProtocolValue>
            {formatJaNejStatus(inspection.hasBefintligVattenfasByttsMotVatten)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>

        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("waterIsRefilled")}
          </ProtocolLabel>
          <ProtocolValue>
            {formatJaNejStatus(inspection.waterIsRefilled)}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>{get5YInspectionLabel("flottorIsUp")}</ProtocolLabel>
          <ProtocolValue>
            {inspection.separator.flottarFinns === JaNejStatus.Ja
              ? formatJaNejStatus(inspection.flottorIsUp)
              : ""}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
        <ProtocolLabelValueWrapper>
          <ProtocolLabel>
            {get5YInspectionLabel("flottarDensitetUppmatt")}
          </ProtocolLabel>
          <ProtocolValue>
            {inspection.separator.flottarFinns === JaNejStatus.Ja
              ? inspection.flottarDensitetUppmatt
              : ""}
          </ProtocolValue>
        </ProtocolLabelValueWrapper>
      </SectionContent>
    </Section>
  </ProtocolWrapper>
);

const getTomningar = (inspection: FiveYearsInspection) => {
  const result = [];
  if (inspection.hasTomningOlja) {
    result.push(get5YInspectionLabel("hasTomningOlja"));
  }
  if (inspection.hasTomningSlam) {
    result.push(get5YInspectionLabel("hasTomningSlam"));
  }
  if (inspection.hasTomningVattenfas) {
    result.push(get5YInspectionLabel("hasTomningVattenfas"));
  }
  return result.join(", ");
};

const getInspectionResultColor = (inspectionResult?: InspectionResult) =>
  inspectionResult === InspectionResult.Approved
    ? "cyan-600"
    : inspectionResult === InspectionResult.NotApproved
    ? "red-500"
    : "gray-700";
