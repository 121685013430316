import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FC, useState } from "react";

interface Props {
  placeholder?: string;
  onSearch: (value: string) => void;
  initialValue?: string;
}
export const SearchField: FC<Props> = ({
  placeholder,
  onSearch,
  initialValue = "",
}) => {
  const [value, setValue] = useState(initialValue);

  return (
    <div className="mt-1 flex rounded-md shadow-sm max-w-md">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          className="block w-full rounded-none rounded-l-lg border-gray-100 pl-10 focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm box py-3"
          placeholder={placeholder ?? ""}
          autoComplete="off"
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSearch(value);
            }
          }}
        />
      </div>
      <button
        onClick={() => onSearch(value)}
        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md  bg-gray-100 px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 border-t border-t-gray-200 border-r border-r-gray-200 border-b border-b-gray-200"
      >
        <span>Sök</span>
      </button>
    </div>
  );
};
