import { TaskType } from "enums/TaskType.enum";
// import { Assignment } from "types/assignment.type";
import { Task } from "types/task.type";
import { Customer } from "../customer.type";
import { JaNejStatus, SeparatorClassification } from "../enums";
import { isBefore } from "date-fns";

export type Separator = {
  id: number;
  type: SeparatorType;
  identifier: string;
  propertyDesignation?: string;
  slamMaxLevel?: number;
  oilMaxLevel?: number;
  manufacturer?: string;
  yearOfManufacture?: string;
  marking858?: JaNejStatus;
  verification858?: JaNejStatus;
  verification858Text?: string;
  provtagningsbrunnFinns?: JaNejStatus;
  nivalarmFinns?: JaNejStatus;
  notes: string;
  slamnivalarmFinns?: JaNejStatus;
  damningslarmHognivalarmFinns?: JaNejStatus;
  koalecensfilterFinns?: JaNejStatus;
  classification?: SeparatorClassification;
  modelIdentifier?: string;
  shapeCylindric?: boolean;
  shapeRectangular?: boolean;
  shapeStandingUp?: boolean;
  shapeLyingDown?: boolean;
  shapeOther?: boolean;
  shapeOtherValue?: string;
  flottarFinns?: JaNejStatus;
  flottarDensitetAngiven?: string;
  totalVatskevolym?: string;
  maxSlamvolym?: string;
  fritextType: string;
  maxOljevolym?: string;
  avtalSlamtomning?: boolean;
  avtalFemarskontroll?: boolean;
  avtalHalvarsKontroll?: boolean;
  tomningReminder?: TomningReminderMonths;
  flode?: string;
  coordinates?: string;
  createdAt: Date;
  customer: Customer;
  customerId: number;
  files: SeparatorFile[];
  isDeleted: boolean;
  tasks?: Task[];
  address?: string;
  postalCode?: string;
  city?: string;
  kommunKod: string;
  cfarNR?: string;
};

export type SeparatorFile = {
  id: number;
  name: string;
  url: string;
};

export type CreateSeparatorDTO = {
  type: SeparatorType;
  identifier?: string;
  propertyDesignation?: string;
  slamMaxLevel?: number;
  oilMaxLevel?: number;
  manufacturer?: string;
  yearOfManufacture?: string;
  marking858?: JaNejStatus;
  verification858?: JaNejStatus;
  verification858Text?: string;
  provtagningsbrunnFinns?: JaNejStatus;
  nivalarmFinns?: JaNejStatus;
  notes: string;
  slamnivalarmFinns?: JaNejStatus;
  damningslarmHognivalarmFinns?: JaNejStatus;
  koalecensfilterFinns?: JaNejStatus;
  classification?: SeparatorClassification;
  modelIdentifier?: string;
  shapeCylindric?: boolean;
  fritextType: string;
  shapeRectangular?: boolean;
  shapeStandingUp?: boolean;
  shapeLyingDown?: boolean;
  avtalSlamtomning?: boolean;
  avtalFemarskontroll?: boolean;
  avtalHalvarsKontroll?: boolean;
  avtalSlamtomningAntalPerAr?: number;
  shapeOtherValue?: string;
  shapeOther?: boolean;
  flottarFinns?: JaNejStatus;
  flottarDensitetAngiven?: string;
  totalVatskevolym?: string;
  coordinates?: string;
  maxSlamvolym?: string;
  maxOljevolym?: string;
  flode?: string;
  customerId: number;
  nextSlamtomningReminder?: Date;
  address?: string;
  postalCode?: string;
  city?: string;
  cfarNR?: string;
  kommunKod: string;
};

export type UpdateSeparatorDTO = {
  type: SeparatorType;
  identifier?: string;
  propertyDesignation?: string;
  slamMaxLevel?: number;
  oilMaxLevel?: number;
  manufacturer?: string;
  yearOfManufacture?: string;
  marking858?: JaNejStatus;
  verification858?: JaNejStatus;
  verification858Text?: string;
  provtagningsbrunnFinns?: JaNejStatus;
  nivalarmFinns?: JaNejStatus;
  notes: string;
  slamnivalarmFinns?: JaNejStatus;
  damningslarmHognivalarmFinns?: JaNejStatus;
  koalecensfilterFinns?: JaNejStatus;
  classification?: SeparatorClassification;
  modelIdentifier?: string;
  shapeCylindric?: boolean;
  fritextType: string;
  shapeRectangular?: boolean;
  shapeStandingUp?: boolean;
  shapeLyingDown?: boolean;
  shapeOther?: boolean;
  shapeOtherValue?: string;
  flottarFinns?: JaNejStatus;
  coordinates?: string;
  flottarDensitetAngiven?: string;
  totalVatskevolym?: string;
  maxSlamvolym?: string;
  maxOljevolym?: string;
  flode?: string;
  avtalSlamtomning?: boolean;
  avtalFemarskontroll?: boolean;
  avtalHalvarsKontroll?: boolean;
  avtalSlamtomningAntalPerAr?: number;
  tomningReminder?: TomningReminderMonths;
  address?: string;
  postalCode?: string;
  city?: string;
  kommunKod: string;
};

export enum SeparatorType {
  Oil = "Oil",
  Slam = "Slam",
  OilAndSlam = "OilAndSlam",
  Tvattranna = "Tvattranna",
  Fritext = "Fritext",
}

export type TomningReminderMonths = {
  january: boolean;
  february: boolean;
  march: boolean;
  april: boolean;
  may: boolean;
  june: boolean;
  july: boolean;
  august: boolean;
  september: boolean;
  october: boolean;
  november: boolean;
  december: boolean;
};

export const getNextSeparatorTaskDate = (
  separator: Separator,
  taskType: TaskType
): Date | undefined => {
  if (!separator) {
    return undefined;
  }
  return (separator.tasks ?? [])
    .sort((a: Task, b: Task) =>
      isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1
    )
    .find((task: Task) => task.type === taskType)?.date;
};

export const hasAvtal = (separator: Separator) =>
  separator.avtalFemarskontroll ||
  separator.avtalHalvarsKontroll ||
  separator.avtalSlamtomning;

export const isAvskiljare = (separator: Separator) =>
  [SeparatorType.Oil, SeparatorType.OilAndSlam, SeparatorType.Slam].includes(
    separator.type
  );
