import { FC, useEffect, useMemo, useState } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import Dropdown from "components/Dropdown";
import { AssignmentDTO } from "types/assignment.type";
import { useUsers } from "api/user.api";
import { TextArea } from "components/TextArea";
import { useCustomer, useCustomerDropdown } from "api/customer.api";
import { getDaysAsDropdownItems, getWeeksAsDropdownItems } from "helpers/date";
import {
  AssignmentType,
  getAssignmentTypeDropdownItems,
} from "enums/AssignmentType.enum";
import { userToDropdownItem, customerToDropdownItem } from "types/user.type";
import { useWasteStationList } from "api/wasteStation.api";
import { useWasteCodeDropDown } from "api/wasteCode.api";
import { ComboBox, ComboBoxItem } from "components/ComboBox";
import { Transition } from "components/animations/Transition";
import { config } from "react-spring";
import { TextInput } from "components/TextInput";
import { WasteCodePreview } from "components/WasteCodePreview";
import { isAvskiljare } from "types/separator/separator.type";
import { Checkbox } from "components/Checkbox";
import { AnimateOpacity } from "components/animations/AnimateOpacity";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import { useAddSeparatorLogic } from "../tomning-page/hooks/useAddSeparatorLogic.hook";
import { SeparatorMetadataForm } from "../separator/SeparatorMetadataForm";
import { PriorityIcon } from "./PriorityIcon";
import { Tips } from "components/Tips";
import { Tooltip } from "react-tooltip";
import {
  getWasteCodePreliminaryWeightErrorMessage,
  WasteCodeFormValues,
  WasteCodeSchemaType,
} from "./Schedule.helpers";

interface Props {
  register: UseFormRegister<AssignmentDTO>;
  formData: UseFormWatch<AssignmentDTO>;
  formErrors?: any;
  selectedWasteCodes: ComboBoxItem[];
  setSelectedWasteCodes: React.Dispatch<React.SetStateAction<ComboBoxItem[]>>;
  setValue: UseFormSetValue<AssignmentDTO>;
  wasteCodeFields: FieldArrayWithId<WasteCodeFormValues, "wasteCodes", "id">[];
  appendWasteCode: UseFieldArrayAppend<WasteCodeFormValues, "wasteCodes">;
  removeWasteCode: UseFieldArrayRemove;
  selectedWasteCodeList: WasteCodeSchemaType[];

  wasteCodeForm: UseFormReturn<
    {
      wasteCodes: {
        id: number;
        name: string;
        description?: string | undefined;
        preliminaryWeight?: string | undefined;
      }[];
    },
    any
  >;
}

export const NewAssignment: FC<Props> = ({
  register,
  formData,
  formErrors,
  selectedWasteCodes,
  setSelectedWasteCodes,
  setValue,
  wasteCodeFields,
  appendWasteCode,
  removeWasteCode,
  wasteCodeForm,
  selectedWasteCodeList,
}) => {
  // Users
  const { data: users } = useUsers();
  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );
  // Endpoint för att hämta alla kunder
  // Dropdown
  const { data: customers } = useCustomerDropdown();
  const customerDropdownItems = useMemo(
    () => (customers ?? []).map(customerToDropdownItem),
    [customers]
  );
  const selectedCustomerId = Number(formData("customerId"));

  const { data: huvudkund } = useCustomer(selectedCustomerId, {
    enabled: selectedCustomerId > 0,
  });

  const { data: underkund } = useCustomer(Number(formData("proxyCustomerId")), {
    enabled: Number(formData("proxyCustomerId")) > 0,
  });

  const verksamhetsutovare = underkund ?? huvudkund;

  // useEffect som lyssnar på vald kund och hämtar varje gång man väljer en
  const separatorDropdownItems = useMemo(
    () =>
      (verksamhetsutovare?.separators ?? []).map(({ id, identifier }) => ({
        label: identifier ?? "Objekt saknar identifiering",
        value: id,
      })),
    [verksamhetsutovare]
  );

  // Skapa nytt objekt
  const addSeparatorLogic = useAddSeparatorLogic(verksamhetsutovare);

  // När man skapat ett objekt vill man sätta det som valt objekt
  useEffect(() => {
    if (
      addSeparatorLogic.createdSeparator &&
      separatorDropdownItems.find(
        (x) => x.value === addSeparatorLogic.createdSeparator?.id
      )
    ) {
      setValue("separatorId", addSeparatorLogic.createdSeparator.id);
    }
  }, [addSeparatorLogic.createdSeparator, separatorDropdownItems, setValue]);

  // Datum
  const selectedDate = formData("date");

  const weekDropdownItems = useMemo(() => getWeeksAsDropdownItems(), []);

  const daysDropdownItems = useMemo(
    () =>
      getDaysAsDropdownItems(
        !selectedDate ? undefined : new Date(selectedDate)
      ),
    [selectedDate]
  );

  // Defaultvalue till denna vecka
  // useEffect(() => {
  //   if (weekDropdownItems) {
  //     setValue("date", weekDropdownItems[0].value);
  //   }
  // }, [setValue, weekDropdownItems]);

  const assignmentTypeDropdownItems = useMemo(
    () => getAssignmentTypeDropdownItems(),
    []
  );

  const isTomning = +formData("type") === AssignmentType.Tomning;

  const isInspection = [
    AssignmentType.FiveYearInspection,
    AssignmentType.SixMonthsInspection,
  ].includes(+formData("type"));

  // Detta hanterar det fall när man först valt slamtömning och lagt till koder
  // och sen bytt till annan typ av uppdrag
  useEffect(() => {
    if (!isTomning && selectedWasteCodes.length > 0) {
      setSelectedWasteCodes([]);
    }
  }, [isTomning, selectedWasteCodes, setSelectedWasteCodes]);

  // waste station
  const { data: wasteStationList } = useWasteStationList({
    enabled: isTomning,
  });

  const wasteStationDropdownItems = useMemo(
    () =>
      (wasteStationList ?? []).map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [wasteStationList]
  );

  // Waste
  const [wasteCodeQuery, setWasteCodeQuery] = useState<string>("");
  const { data: wasteCodes } = useWasteCodeDropDown(wasteCodeQuery);
  const [selectedWasteCode, setSelectedWasteCode] = useState<ComboBoxItem>({
    id: -1,
    name: "",
    description: "",
  });

  useEffect(() => {
    if (selectedWasteCode.id > 0) {
      setSelectedWasteCodes((prev) => [
        ...prev.filter((c) => c.id !== selectedWasteCode.id),
        { ...selectedWasteCode },
      ]);
      setSelectedWasteCode({
        id: -1,
        name: "",
        description: "",
      });
      setWasteCodeQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWasteCode]);

  const handleSetWasteCodeQuery = (query: string) =>
    setWasteCodeQuery(query.toLowerCase());

  const wasteCodeComboBoxItems: ComboBoxItem[] = (wasteCodes ?? []).map(
    (c) => ({
      id: c.id,
      name: c.codePretty,
      description: c.description,
    })
  );

  const [wasteCodeError, setWasteCodeError] = useState(false);

  useEffect(() => {
    if (wasteCodeError && selectedWasteCode.id > 0) {
      setWasteCodeError(false);
    }
  }, [wasteCodeError, selectedWasteCode]);

  const selectedSeparatorId = formData("separatorId");
  const selectedSeparator = verksamhetsutovare?.separators?.find(
    // eslint-disable-next-line eqeqeq
    (sep) => sep.id == selectedSeparatorId
  );
  const hasSelectedAvskiljare = !selectedSeparator
    ? false
    : isAvskiljare(selectedSeparator);

  // const hasChildCustomers = (huvudkund?.childCustomers ?? []).length > 0;
  const isProxyAssignment = useMemo(
    () => !!huvudkund?.isParentCustomer,
    [huvudkund?.isParentCustomer]
  );

  useEffect(() => {
    if (!isProxyAssignment) {
      setValue("proxyCustomerId", 0);
      setValue("separatorId", 0);
      setValue("isProxyAssignment", false);
    } else {
      setValue("isProxyAssignment", true);
    }
  }, [isProxyAssignment, setValue]);

  const proxyCustomerDropdownItems = useMemo(
    () =>
      (huvudkund?.childCustomers ?? [])
        .map(customerToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [huvudkund]
  );

  const harHuvudkundMenInteUnderkund =
    !!huvudkund?.isParentCustomer && !underkund;

  return (
    <ModalContentWrapper>
      {/* Kund, typ av uppgift, identifiering på objekt, datum, anteckning */}

      {/* Kund, underkund */}
      <div className="flex gap-x-4">
        <div className="w-1/2">
          <Dropdown
            items={customerDropdownItems}
            name="customerId"
            label="Kund"
            descriptionItemText=" "
            register={register}
            validation={{
              validate: (value: number) => {
                return value > 0 || "Du måste välja en kund";
              },
            }}
            errorMessage={formErrors?.customerId?.message}
          />
        </div>
        <div className="w-1/2">
          <AnimateOpacity isVisible={!!huvudkund?.isParentCustomer}>
            <Dropdown
              items={proxyCustomerDropdownItems}
              name="proxyCustomerId"
              label="Verksamhetsutövare"
              descriptionItemText=""
              register={register}
              validation={{
                validate: (value: number) => {
                  return value > 0 || "Du måste välja en underkund";
                },
              }}
              errorMessage={formErrors?.proxyCustomerId?.message}
            />
          </AnimateOpacity>
        </div>
      </div>

      {/* Objekt */}
      <div className="flex gap-x-4 items-end">
        <div className="w-1/2">
          <Dropdown
            items={separatorDropdownItems}
            name="separatorId"
            label="Objekt"
            register={register}
            disabled={
              !verksamhetsutovare || separatorDropdownItems.length === 0
            }
            descriptionItemText={
              !verksamhetsutovare
                ? "Välj först kund"
                : harHuvudkundMenInteUnderkund
                ? "Välj först verksamhetsutövare"
                : !!verksamhetsutovare && separatorDropdownItems.length === 0
                ? "Kunden saknar objekt"
                : " "
            }
            validation={{
              validate: (value: number) => {
                return value > 0 || "Du måste välja ett objekt";
              },
            }}
            errorMessage={formErrors?.separatorId?.message}
          />
        </div>
        <div className="w-1/2 flex justify-end pb-0.5">
          {!!verksamhetsutovare && !harHuvudkundMenInteUnderkund && (
            <SecondaryButton
              size="medium"
              onClick={() => addSeparatorLogic.setIsNewSeparatorModalOpen(true)}
            >
              + Skapa nytt objekt
            </SecondaryButton>
          )}
        </div>
      </div>

      {/* Operatör, prioriterat uppdrag */}
      <Tips
        id="f327ec2a-6d8c-430c-b15b-22b30fe32399"
        paragraphs={[
          "Välj 'Prioriterat uppdrag' om uppdraget är viktigt och ska utföras snarast. Uppdraget kommer hamna överst i operatörens uppdragslista och markeras som prioriterat.",
        ]}
      />
      {/* Användare, prioriterat uppdrag */}
      <div className="flex gap-x-4 items-end">
        <div className="w-1/2">
          <Dropdown
            items={userDropdownItems}
            name="userId"
            descriptionItemText=" "
            label="Operatör"
            register={register}
            validation={{
              validate: (value: number) =>
                value > 0 || "Du måste välja en operatör",
            }}
            errorMessage={formErrors?.userId?.message}
          />
        </div>
        <div className="w-1/2 pb-2 px-0 flex justify-end items-center gap-x-2">
          <Checkbox
            label="Prioriterat uppdrag"
            name="isPrioritized"
            register={register}
          />
          <div
            data-tooltip-id={`new-assignment-priority-icon-tooltip`}
            data-tooltip-content={
              "Prioriterade uppdrag hamnar överst i operatörens lista och markeras med denna ikon."
            }
            data-tooltip-place="top"
          >
            <PriorityIcon />
          </div>
          <Tooltip id={`new-assignment-priority-icon-tooltip`} />
        </div>
      </div>

      {/* Vecka, exakt dag */}
      <div className="flex gap-x-4">
        <div className="w-1/2">
          <Dropdown
            items={weekDropdownItems}
            name="date"
            label="Vecka"
            register={register}
            validation={{
              validate: (value?: string) =>
                !!value || "Du måste välja ett datum",
            }}
            errorMessage={formErrors?.date?.message}
          />
        </div>
        <div className="w-1/2">
          <Dropdown
            items={daysDropdownItems}
            name="weekday"
            label="Exakt dag (valfritt)"
            register={register}
            disabledOptionValue={""}
            descriptionItemText=" "
          />
        </div>
      </div>

      {/* Uppdragstyp */}
      <div className="flex gap-x-4 items-end">
        <div className="w-1/2">
          <Dropdown
            items={assignmentTypeDropdownItems.filter(
              (assignmentType) =>
                (assignmentType.value !== AssignmentType.SixMonthsInspection &&
                  assignmentType.value !== AssignmentType.FiveYearInspection) ||
                hasSelectedAvskiljare
            )}
            name="type"
            label="Uppgift"
            descriptionItemText=" "
            register={register}
            validation={{
              validate: (value: number) => {
                return value > 0 || "Du måste välja en uppgift";
              },
            }}
            errorMessage={formErrors?.type?.message}
          />
        </div>
        <div className="w-1/2 pb-2 px-0">
          <AnimateOpacity isVisible={isInspection}>
            <Checkbox
              label="Lägg till tömning"
              name="includeTomning"
              register={register}
            />
          </AnimateOpacity>
        </div>
      </div>

      {/* specifika fält för tömning */}
      <Transition
        settings={{
          from: { opacity: 0 },
          enter: { opacity: 1 },
          // leave: {
          //   // transform: "scaleY(0)",
          //   opacity: 0,
          // },
          config: { ...config.gentle },
        }}
        isVisible={isTomning}
      >
        <div className="space-y-8">
          <Dropdown
            items={wasteStationDropdownItems}
            name="wasteStationId"
            label="Mottagarstation"
            register={register}
            disabled={wasteStationDropdownItems.length === 0}
            descriptionItemText={
              wasteStationDropdownItems.length === 0
                ? "Ni saknar mottagarstationer"
                : ""
            }
          />
          <div className="space-y-3">
            <ComboBox
              label="Avfallskoder"
              items={wasteCodeComboBoxItems}
              setQuery={handleSetWasteCodeQuery}
              selectedItem={selectedWasteCode}
              setSelectedItem={(item: ComboBoxItem) => {
                if (selectedWasteCodeList.find((x) => x.name === item.name)) {
                  return; // undviker dubbletter
                }
                appendWasteCode({
                  ...item,
                  description: item.description,
                  preliminaryWeight: "",
                });
              }}
            />

            {wasteCodeFields.map((field, index) => (
              <WasteCodePreview
                key={field.id}
                code={field}
                register={wasteCodeForm.register}
                index={index}
                deleteCode={() => removeWasteCode(index)}
                errorMessage={getWasteCodePreliminaryWeightErrorMessage(
                  selectedWasteCodeList.find((x) => x.name === field.name)
                )}
              />
            ))}
          </div>
        </div>
      </Transition>

      <TextInput
        label="Referensnummer (visas för kund)"
        name="referenceNumber"
        register={register}
      />
      <TextArea label="Intern kommentar" name="notes" register={register} />

      <Modal
        isOpen={addSeparatorLogic.isNewSeparatorModalOpen}
        title={"Nytt objekt - " + verksamhetsutovare?.name}
        actionText="Spara"
        onClose={addSeparatorLogic.onNewSeparatorModalClose}
        actionHandler={addSeparatorLogic.onSeparatorSubmit}
        isLoading={addSeparatorLogic.isCreating}
        loadingText="Sparar..."
      >
        <SeparatorMetadataForm
          register={addSeparatorLogic.registerSeparator}
          errors={addSeparatorLogic.addSeparatorErrors}
          copyAddressFromCustomer={addSeparatorLogic.copyAddressFromCustomer}
          selectedSeparatorType={addSeparatorLogic.selectedSeparatorType}
          setValue={addSeparatorLogic.setValue}
          postnummer={addSeparatorLogic.watchSeparator("postalCode") ?? ""}
        />
      </Modal>
    </ModalContentWrapper>
  );
};
