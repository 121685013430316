import { Assignment } from "./assignment.type";
import { Email } from "./email.type";
import { Separator } from "./separator/separator.type";

export type Customer = {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  companyId: number;
  organizationNumber: string;
  documentationEmail: string;
  notes?: string;
  separators?: Separator[];
  avtalskund?: boolean;
  customerIdentification?: string;
  childCustomers: Customer[];
  parentCustomers: Customer[];
  proxyAssignmentsAsParent: Assignment[];
  emails: Email[];
  isParentCustomer: boolean;
  kommunKod: string;
  nvFullmakt: boolean;
};

export type CreateCustomerDTO = {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  organizationNumber: string;
  documentationEmail: string;
  notes?: string;
  avtalskund?: boolean;
  emails?: Email[];
  customerIdentification?: string;
  separators?: Separator[];
  isParentCustomer?: boolean;
  kommunKod?: string;
  parentCustomerId?: number;
  nvFullmakt: boolean;
};

export const getEmptyCreateCustomerDTO = (): CreateCustomerDTO => ({
  name: "",
  address: "",
  postalCode: "",
  city: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
  organizationNumber: "",
  documentationEmail: "",
  notes: "",
  avtalskund: undefined,
  customerIdentification: "",
  kommunKod: "",
  nvFullmakt: false,
});
