import { AssignmentType } from "./AssignmentType.enum";

export enum EmailType {
  SixMonthsInspection,
  FiveYearsInspection,
  TransportDocument,
  Unknown,
}

export const emailTypeToAssignmentType = (emailType: EmailType) => {
  switch (emailType) {
    case EmailType.SixMonthsInspection:
      return AssignmentType.SixMonthsInspection;
    case EmailType.FiveYearsInspection:
      return AssignmentType.FiveYearInspection;
    case EmailType.TransportDocument:
      return AssignmentType.Tomning;
    default:
      throw Error("Unknown email type");
  }
};
