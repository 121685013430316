import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { ResetPasswordDTO, useResetPassword } from "api/user.api";
import { useNavigate } from "react-router-dom";
import { PasswordInput } from "components/PasswordInput";

interface ResetPasswordForm {
  password: string;
}

function ResetPasswordPage() {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";

  useEffect(() => {
    document.title = "Återställ lösenord - Driftjournalen";
  }, []);

  const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>();

  const onSubmit = handleSubmit((data: ResetPasswordForm) => {
    const dto: ResetPasswordDTO = {
      password: data.password,
      code,
    };
    resetPassword(dto);
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(`/signin`);
    }
  }, [isSuccess, navigate]);

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Återställ lösenord
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={onSubmit} className="space-y-6">
              <PasswordInput
                register={register}
                name="password"
                label="Välj lösenord (minst 8 tecken)"
                onSubmit={onSubmit}
                validation={{
                  validate: (value?: string) =>
                    (value ?? "").length >= 8 ||
                    "Lösenordet måste vara minst 8 tecken långt",
                }}
                errorMessage={errors.password?.message}
              />

              <div>
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300 sm:col-start-2 sm:text-sm"
                >
                  {isLoading && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {isLoading ? "Återställer lösenord..." : "Återställ lösenord"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
