import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

export const TBody: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
> = ({ children, ...rest }) => (
  <tbody {...rest} className="divide-y divide-gray-200 bg-white">
    {children}
  </tbody>
);
