import { CheckCircleIcon, TruckIcon } from "@heroicons/react/24/solid";
import {
  ArchiveBoxArrowDownIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { TransportDocumentStatus } from "types/enums";

interface Props {
  status: TransportDocumentStatus;
}

export const TransportDocumentStatusTag: FC<Props> = ({ status }) => {
  switch (status) {
    case TransportDocumentStatus.Created:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 pl-1.5 pr-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/10">
          <ClipboardIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-indigo-600/20" />
          {transportDocumentStatusLabel(status)}
        </span>
      );
    case TransportDocumentStatus.Loaded:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-pink-50 pl-1.5 pr-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-600/10">
          <TruckIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-pink-600/20" />
          {transportDocumentStatusLabel(status)}
        </span>
      );
    case TransportDocumentStatus.Weighed:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-cyan-50 pl-1.5 pr-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-600/10">
          <ArchiveBoxArrowDownIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-cyan-600/20" />
          {transportDocumentStatusLabel(status)}
        </span>
      );
    case TransportDocumentStatus.Signed:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-cyan-50 pl-1.5 pr-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-600/10">
          <CheckCircleIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-cyan-600/20" />
          {transportDocumentStatusLabel(status)}
        </span>
      );
    default:
      throw Error(
        `TransportDocumentStatusTag is missing implementation for ${status}`
      );
  }
};

export const transportDocumentStatusLabel = (
  status: TransportDocumentStatus
) => {
  switch (status) {
    case TransportDocumentStatus.Created:
      return "Påbörjad";
    case TransportDocumentStatus.Loaded:
      return "Lastad";
    case TransportDocumentStatus.Weighed:
      return "Lossad";
    case TransportDocumentStatus.Signed:
      return "Signerad";
    default:
      throw Error(
        "transportDocumentStatusLabel missing implementation for" + status
      );
  }
};
