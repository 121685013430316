import { FC, ReactNode, useEffect, useState } from "react";
import { PageTransition } from "components/animations/SnapUp";
import { useUploadFiles } from "api/image.api";
import { v4 as guid } from "uuid";
import {
  FiveYearsInspection,
  InspectionFile,
} from "types/fiveYearsInspection/fiveYearsInspection.type";
import instance from "api/axiosInstance";
import { useSendFiveYearsInspectionAttachments } from "api/fiveYearsInspection.api";
import { InspectionStatus } from "enums/InspectionStatus.enum";
import { InspectionType } from "types/enums";
import { useSendSixMonthsInspectionAttachments } from "api/sixMonthsInspection.api";
import { Attachments } from "./attachments/Attachments";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { useQueryClient } from "react-query";
import { useIsAdmin } from "store/user.store";
import { ScreenWidth, useScreenWidth } from "hooks/useScreenWidth";
import { AssignmentQueryKey } from "api/assignment.api";
import { isMobile } from "helpers/isMobile";
import { SentEmails } from "./SentEmails";
import { Tomning } from "types/tomning.type";

interface Props {
  children: ReactNode;
  afterUploadedFile?: (url: string) => void; // Kör denna när man laddat upp en ny fil
  existingFiles?: InspectionFile[];
  isLoadingFiles?: boolean;
  deleteFile: (id: number) => void;
  inspectionId?: number;
  hasSentEmail?: boolean;
  inspectionStatus?: InspectionStatus;
  fileName?: string;
  type: InspectionType;
  hasValidDocumentationEmail: boolean;
  inspection: SixMonthsInspection | FiveYearsInspection;
  openEditSeparatorModal: () => void;
  isEditMode?: boolean;
  tomning?: Tomning;
  documentationEmail?: string;
}

export const InspectionWrapper: FC<Props> = ({
  children,
  afterUploadedFile = () => null,
  existingFiles = [],
  isLoadingFiles = false,
  deleteFile,
  inspectionId = -1,
  hasSentEmail = false,
  inspectionStatus,
  fileName = "",
  type,
  inspection,
  tomning,
  documentationEmail,
}) => {
  const queryClient = useQueryClient();

  const isAdmin = useIsAdmin();

  const [disableAnimation] = useState(hasSentEmail);
  const {
    mutate: uploadImages,
    isSuccess: isUploadImagesSuccess,
    data: uploadedImages,
    isLoading: isUploadingImages,
    reset,
  } = useUploadFiles();

  const [isSending, setIsSending] = useState<boolean>(false);

  const onFileAdd = async (blob: Blob | null, filename: string) => {
    if (!blob) return;
    const file = new File([blob], filename);
    if (!file) {
      return;
    }

    const renamedFile = new File(
      [file],
      `${filename.split(".")[0]}-${guid().split("-")[0]}${
        guid().split("-")[1]
      }.${file.name.split(".")[1]}`
    );

    uploadImages([renamedFile]);
  };

  useEffect(() => {
    if (isUploadImagesSuccess && uploadedImages) {
      // Här ska jag uppdatera inspection med de nya bilderna
      afterUploadedFile(uploadedImages[0]);

      // onFilesChange(uploadedImages);
      reset();
    }
  }, [isUploadImagesSuccess, uploadedImages, reset, afterUploadedFile]);

  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const previewPDF = () => {
    setIsLoadingPreview(true);
    const url =
      type === InspectionType.FiveYears
        ? `/five-years-inspection/${inspectionId}/preview-pdf`
        : `/six-months-inspection/${inspectionId}/preview-pdf`;
    if (isMobile()) {
      instance
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          setIsLoadingPreview(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
        });
    } else {
      instance
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          setIsLoadingPreview(false);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
    }
  };

  const {
    mutate: sendFiveYearsInspectionAttachments,
    isLoading: isSendingFiveYearsAttachments,
    isSuccess: isSendingFiveYearsAttachmentsSuccess,
    isError: isSendingFiveYearsError,
  } = useSendFiveYearsInspectionAttachments();

  const {
    mutate: sendSixMonthsInspectionAttachments,
    isLoading: isSendingSixMonthsAttachments,
    isSuccess: isSendingSixMonthsSuccess,
    isError: isSendingSixMonthsError,
  } = useSendSixMonthsInspectionAttachments();

  const isLoading =
    isSendingFiveYearsAttachments || isSendingSixMonthsAttachments;

  const isSuccess =
    isSendingFiveYearsAttachmentsSuccess || isSendingSixMonthsSuccess;

  const isError = isSendingFiveYearsError || isSendingSixMonthsError;

  const sendPDF = (includeTransportDocument: boolean) => {
    setIsSending(true);
    if (type === InspectionType.FiveYears) {
      sendFiveYearsInspectionAttachments({
        id: inspectionId,
        includeTransportDocument,
      });
    } else {
      sendSixMonthsInspectionAttachments({
        id: inspectionId,
        includeTransportDocument,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSending(false), 100);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSendingFiveYearsAttachmentsSuccess) {
      queryClient.invalidateQueries([AssignmentQueryKey.GetNotEmailed]);
    }
  }, [isSendingFiveYearsAttachmentsSuccess, queryClient]);

  useEffect(() => {
    if (isSendingSixMonthsSuccess) {
      queryClient.invalidateQueries([AssignmentQueryKey.GetNotEmailed]);
    }
  }, [isSendingSixMonthsSuccess, queryClient]);

  useEffect(() => {
    if (isError) {
      setIsSending(false);
    }
  }, [isError]);

  const screenWidth = useScreenWidth();

  return (
    <PageTransition>
      {/* Mobile/Tablet version */}
      {screenWidth === ScreenWidth.Desktop ? (
        <div className="hidden lg:block">
          <div className="flex flex-col-reverse lg:items-start lg:flex-row">
            <div className="bg-white shadow-lg overflow-hidden lg:rounded-lg max-w-4xl mt-7 sm:mt-0 flex-1">
              {children}
            </div>
            <div className="flex flex-col w-full lg:w-1/3">
              <Attachments
                disableAnimation={disableAnimation}
                previewPDF={previewPDF}
                existingFiles={existingFiles}
                isLoadingFiles={isLoadingFiles}
                deleteFile={deleteFile}
                hasSentEmail={hasSentEmail}
                inspectionStatus={inspectionStatus}
                fileName={fileName}
                onFileAdd={onFileAdd}
                isUploadingImages={isUploadingImages}
                sendPDF={sendPDF}
                isLoading={isLoading}
                isSending={isSending}
                isLoadingPreview={isLoadingPreview}
                inspection={inspection}
                tomning={tomning}
                documentationEmail={documentationEmail}
              />
              {isAdmin && (
                <SentEmails
                  emails={inspection.emails}
                  createdAt={inspection.createdAt}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="lg:hidden">
          <div className="flex flex-col lg:items-start lg:flex-row">
            <div className="bg-white shadow-lg overflow-hidden max-w-4xl flex-1">
              {children}
            </div>
            <Attachments
              disableAnimation={disableAnimation}
              previewPDF={previewPDF}
              existingFiles={existingFiles}
              isLoadingFiles={isLoadingFiles}
              deleteFile={deleteFile}
              hasSentEmail={hasSentEmail}
              inspectionStatus={inspectionStatus}
              fileName={fileName}
              onFileAdd={onFileAdd}
              isUploadingImages={isUploadingImages}
              sendPDF={sendPDF}
              isLoading={isLoading}
              isSending={isSending}
              isLoadingPreview={isLoadingPreview}
              inspection={inspection}
              tomning={tomning}
              documentationEmail={documentationEmail}
            />
            {isAdmin && (
              <SentEmails
                emails={inspection.emails}
                createdAt={inspection.createdAt}
              />
            )}
          </div>
        </div>
      )}
    </PageTransition>
  );
};
