import {
  AssignmentQueryKey,
  DeleteAssignmentPayload,
  SchedulePageResult,
  consolidateAssignments,
  useAssignments,
  useCreateAssignment,
  useDeleteAssignment,
  useUpdateAssignment,
} from "api/assignment.api";
import { PageLoader } from "components/PageLoader";
import { Panel } from "components/Panel";
import {
  Assignment,
  AssignmentDTO,
  getEmptyAssignmentDTO,
} from "types/assignment.type";
// import { InitialsAvatar } from "components/InitialsAvatar";
import { useNavigate } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";
import { AssignmentType } from "enums/AssignmentType.enum";
import PrimaryButton from "components/buttons/PrimaryButton";
import Modal from "components/Modal";
import { NewAssignment } from "./NewAssignment";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { PageTransition } from "components/animations/SnapUp";
import userStore, { getUserCompanyId } from "store/user.store";
import { UpdateAssignment } from "./UpdateAssignment";
import { DeleteAssignment } from "./DeleteAssignment";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
import { useCreateSixMonthsInspection } from "api/sixMonthsInspection.api";
import { useCreateFiveYearsInspection } from "api/fiveYearsInspection.api";
import { format } from "date-fns";
import { DateDropdownItem } from "components/DateDropdownStupid";
import { dateToDropdownItem, isBeforeThisWeek } from "helpers/date";
import { AssignmentInfoModal } from "./AssignmentInfoModal";
import { ComboBoxItem } from "components/ComboBox";
import { useCreateTomning } from "api/tomning.api";
import { getLinkToTomningPage } from "../tomning-page/TomningPage";
import { AssignmentItem } from "./AssignmentItem";
import { Filters } from "./Filters";
import { useAddMultiParam } from "hooks/useAddMultiParam";
import { useReadMultiParam } from "hooks/useReadMultiParam";
import { useRemoveMultiParam } from "hooks/useRemoveMultiParam";
import { TBody, Table } from "components/table";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  getWasteCodePreliminaryWeightErrorMessage,
  pontasTempSchema,
  WasteCodeFormValues,
} from "./Schedule.helpers";
import { useNotification } from "hooks/useNotification";

export default function SchedulePage() {
  const { user } = userStore();

  // For filters
  const companyId = userStore(getUserCompanyId) ?? -1; // kommer aldrig hända
  const [hasLoadedFirstTime, setHasLoadedFirstTime] = useState(false);

  const addMultiParam = useAddMultiParam();
  const readMuliParam = useReadMultiParam();
  const removeMultiParam = useRemoveMultiParam();

  // Customer filter

  const customerIds = readMuliParam("customerId").map((id) => +id);
  const addCustomerId = (id: number) => addMultiParam("customerId", id);
  const deleteCustomerId = (id: number) => removeMultiParam("customerId", id);

  // User filter
  const userIds = readMuliParam("userId").map((id) => +id);
  const addUserId = (id: number) => addMultiParam("userId", id);
  const deleteUserId = (id: number) => removeMultiParam("userId", id);

  const isFiltersActive = userIds.length > 0 || customerIds.length > 0;

  // Assignments
  const { data: _searchResult, isLoading: isLoadingAssignments } =
    useAssignments(companyId, customerIds, userIds);

  const [searchResult, setSearchResult] = useState<SchedulePageResult | null>(
    null
  );

  const [assignments, setAssignments] = useState<Assignment[]>([]);

  useEffect(() => {
    if (_searchResult) {
      setSearchResult(_searchResult);
    }
  }, [_searchResult]);

  useEffect(() => {
    if (searchResult) {
      setAssignments(consolidateAssignments(searchResult));
    } else {
      setAssignments([]);
    }
  }, [searchResult]);

  useEffect(() => {
    if (!hasLoadedFirstTime && !isLoadingAssignments) {
      setHasLoadedFirstTime(true);
    }
  }, [hasLoadedFirstTime, isLoadingAssignments]);

  // Add assignment
  const [isNewAssignmentModalOpen, setIsNewAssignmentModalOpen] =
    useState(false);

  // waste codes
  const [selectedWasteCodes, setSelectedWasteCodes] = useState<ComboBoxItem[]>(
    []
  );

  const wasteCodeForm = useForm<WasteCodeFormValues>({
    resolver: zodResolver(pontasTempSchema),
    defaultValues: {
      wasteCodes: [],
    },
    reValidateMode: "onChange",
  });

  const selectedWasteCodesList = wasteCodeForm.watch("wasteCodes");

  const { fields, append, remove } = useFieldArray({
    control: wasteCodeForm.control,
    name: "wasteCodes",
  });

  const {
    mutate: scheduleTask,
    isLoading: isCreatingAssignment,
    isSuccess: isCreateAssignmentSuccess,
  } = useCreateAssignment();

  const {
    register: registerAssignment,
    handleSubmit,
    reset: resetAssignmentForm,
    watch: formData,
    setValue,
    formState: { errors: formErrors },
  } = useForm<AssignmentDTO>({
    defaultValues: getEmptyAssignmentDTO(),
  });

  const customerId = formData("customerId");
  useEffect(() => {
    setValue("separatorId", 0);
  }, [customerId, setValue]);

  const onAssignmentSubmit = handleSubmit((data: AssignmentDTO) => {
    if (!data.userId || data.userId < 1) {
      throw Error("Couldn't find user");
    }
    if (!data.customerId || data.customerId < 1) {
      throw Error("Couldn't find customer");
    }
    if (!data.separatorId || data.separatorId < 1) {
      throw Error("Couldn't find separator");
    }
    if (!data.type || data.type < 1) {
      throw Error("Couldn't find separator");
    }
    if (
      selectedWasteCodesList.some(
        (x) => !!getWasteCodePreliminaryWeightErrorMessage(x)
      )
    ) {
      // Man får inte submitta någon preliminär vikt som är fel
      return;
    }
    scheduleTask({
      ...data,
      userId: +data.userId,
      separatorId: +data.separatorId,
      customerId: +data.customerId,
      type: +data.type,
      wasteCodeList: selectedWasteCodesList.map(
        ({ id, preliminaryWeight }) => ({ id, preliminaryWeight })
      ),
      proxyCustomerId: !data.proxyCustomerId
        ? undefined
        : +data.proxyCustomerId,
      wasteStationId:
        !data.wasteStationId || +data.wasteStationId === 0
          ? null
          : +data.wasteStationId,
      weekday: !data.weekday ? undefined : +data.weekday,
    });
  });

  const onAddAssignmentModalClose = useCallback(() => {
    setIsNewAssignmentModalOpen(false);
    setTimeout(() => {
      resetAssignmentForm(getEmptyAssignmentDTO());
      setSelectedWasteCodes([]);
      wasteCodeForm.reset();
    }, 300);
  }, [setIsNewAssignmentModalOpen, resetAssignmentForm, wasteCodeForm]);

  const queryClient = useQueryClient();
  const notification = useNotification();
  useEffect(() => {
    if (isCreateAssignmentSuccess) {
      onAddAssignmentModalClose();
      queryClient.invalidateQueries([AssignmentQueryKey.GetAllForSchedulePage]);
      notification.info("Uppdraget har skapats");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAssignmentSuccess, onAddAssignmentModalClose, queryClient]);

  /**
   * Edit assignment
   */

  // modal
  const [assignmentIdToUpdate, setAssignmentIdToUpdate] = useState<
    number | null
  >(null);

  // api
  const {
    mutate: updateAssignment,
    isLoading: isUpdatingAssignment,
    isSuccess: isUpdateAssignmentSuccess,
  } = useUpdateAssignment({
    onSuccess: () =>
      queryClient.invalidateQueries([AssignmentQueryKey.GetAllForSchedulePage]),
  });

  // form
  const {
    register: registerUpdateAssignment,
    handleSubmit: handleUpdateAssignmentSubmit,
    reset: resetUpdateAssignment, // resetta varje gång man sätter prqg
    formState: { errors: updateAssignmentErrors },
    watch: updateAssignmentFormdata,
    setValue: setUpdateAssignmentFormValue,
  } = useForm<AssignmentDTO>();

  // Populate or clear the form every time prequalificationGroupIdToUpdate is set
  useEffect(() => {
    if (assignmentIdToUpdate) {
      const assignment = assignments.find(
        (ass) => ass.id === assignmentIdToUpdate
      );

      if (!assignment) {
        throw Error(
          `assignment is empty though the user clicked a row, id: ${assignmentIdToUpdate}`
        );
      }
      if (assignment.isProxyAssignment && !assignment.parentCustomer) {
        throw Error("Missing parent customer");
      }
      // Nedanstående är den värsta koden i systemet
      resetUpdateAssignment({
        ...assignment,
        customerId: assignment.isProxyAssignment
          ? (assignment.parentCustomer?.id as number)
          : assignment.customer.id,
        proxyCustomerId: assignment.isProxyAssignment
          ? assignment.customer.id
          : null,
        separatorId: assignment.separator.id,
      });
    } else {
      resetUpdateAssignment(getEmptyAssignmentDTO());
    }
  }, [assignmentIdToUpdate, assignments, resetUpdateAssignment]);

  // handler
  const onUpdateAssignment = handleUpdateAssignmentSubmit(
    (data: AssignmentDTO) => {
      if (!assignmentIdToUpdate) {
        throw Error("Missing assignmentIdToUpdate");
      }

      if (!data.userId || data.userId < 1) {
        throw Error("Couldn't find user");
      }
      if (!data.customerId || data.customerId < 1) {
        throw Error("Couldn't find customer");
      }
      if (!data.separatorId || data.separatorId < 1) {
        throw Error("Couldn't find separator");
      }
      if (!data.type || data.type < 1) {
        throw Error("Couldn't find type");
      }
      updateAssignment({
        id: assignmentIdToUpdate,
        payload: {
          ...data,
          userId: +data.userId,
          separatorId: +data.separatorId,
          customerId: +data.customerId,
          type: +data.type,
          weekday: !data.weekday ? undefined : +data.weekday,
        },
      });
    }
  );
  // onclose
  const onUpdateAssignmentModalClose = useCallback(() => {
    setAssignmentIdToUpdate(null);
    setTimeout(() => resetUpdateAssignment(), 300);
  }, [setAssignmentIdToUpdate, resetUpdateAssignment]);

  // close modal on successful PUT
  useEffect(() => {
    if (isUpdateAssignmentSuccess) {
      onUpdateAssignmentModalClose();
    }
  }, [isUpdateAssignmentSuccess, onUpdateAssignmentModalClose]);

  /**
   *  Delete assignment
   */

  // modal
  const [assignmentIdToDelete, setAssignmentIdToDelete] = useState<
    number | null
  >(null);

  //api
  const {
    mutate: deleteAssignment,
    isLoading: isDeletingAssingment,
    isSuccess: isDeleteAssignmentSuccess,
  } = useDeleteAssignment();

  // handler
  const onDeleteAssignment = () => {
    const assignment = assignments.find(
      (ass) => ass.id === assignmentIdToDelete
    );
    if (!assignment) {
      throw Error(
        `onDeleteAssignment is missing assignment with id ${assignmentIdToDelete}`
      );
    }

    const payload: DeleteAssignmentPayload = { id: assignment.id };

    deleteAssignment(payload);
  };

  const onDeleteAssignmentModalClose = useCallback(() => {
    setAssignmentIdToDelete(null);
  }, []);

  useEffect(() => {
    if (isDeleteAssignmentSuccess) {
      onDeleteAssignmentModalClose();
      queryClient.invalidateQueries([AssignmentQueryKey.GetAllForSchedulePage]);
    }
  }, [isDeleteAssignmentSuccess, onDeleteAssignmentModalClose, queryClient]);

  /**
   * View assignment
   */
  // modal
  const [assignmentIdToView, setAssignmentIdToView] = useState<number | null>(
    null
  );

  // Open inspection
  const navigate = useNavigate();

  const openInspection = (assignment: Assignment) => {
    if (!!assignment.fiveYearsInspection) {
      navigate(
        getLinkToFiveYearsInspectionPage(
          assignment?.customer?.id ?? "-1",
          assignment?.separator?.id ?? "-1",
          assignment.fiveYearsInspection.id
        )
      );
    } else if (!!assignment.sixMonthsInspection) {
      navigate(
        getLinkToSixMonthsInspectionPage(
          assignment?.customer?.id ?? "-1",
          assignment?.separator?.id ?? "-1",
          assignment.sixMonthsInspection.id
        )
      );
    } else if (!!assignment.tomning) {
      navigate(
        getLinkToTomningPage(
          assignment.customer.id ?? "-1",
          assignment.separator.id ?? "-1",
          assignment.tomning.id
        )
      );
    } else {
      throw Error("openInspection Ska aldrig komma hit");
    }
  };

  // Create inspection and navigate
  const {
    data: createdSixMonthsInspection,
    mutate: createSixMonthsInspection,
    isLoading: isCreatingSixMonthsInspection,
  } = useCreateSixMonthsInspection();

  const {
    data: createdFiveYearsInspection,
    mutate: createFiveYearsInspection,
    isLoading: isCreatingFiveYearsInspection,
  } = useCreateFiveYearsInspection();

  const {
    mutate: createTomning,
    data: createdTomning,
    isLoading: isCreatingTomning,
  } = useCreateTomning();

  // Update assignment with inspectionid after inspection create

  // Navigate to inspection after assignment is updated with it's id
  useEffect(() => {
    if (createdFiveYearsInspection) {
      navigate(
        getLinkToFiveYearsInspectionPage(
          createdFiveYearsInspection.separator.customer.id,
          createdFiveYearsInspection.separator.id,
          createdFiveYearsInspection.id
        )
      );
    } else if (createdSixMonthsInspection) {
      navigate(
        getLinkToSixMonthsInspectionPage(
          createdSixMonthsInspection.separator.customer.id,
          createdSixMonthsInspection.separator.id,
          createdSixMonthsInspection.id
        )
      );
    } else if (createdTomning) {
      navigate(
        getLinkToTomningPage(
          createdTomning.separator.customer.id,
          createdTomning.separator.id,
          createdTomning.id
        )
      );
    }
  }, [
    createdFiveYearsInspection,
    createdSixMonthsInspection,
    createdTomning,
    navigate,
  ]);

  const handleCreateInspection = (assignment: Assignment) => {
    if (assignment.type === AssignmentType.FiveYearInspection) {
      createFiveYearsInspection({
        separatorId: assignment.separatorId,
        inspectionDate: new Date(format(new Date(), "yyyy-MM-dd")),
        assignmentId: assignment.id,
      });
    } else if (assignment.type === AssignmentType.SixMonthsInspection) {
      createSixMonthsInspection({
        separatorId: assignment.separatorId,
        inspectionDate: new Date(format(new Date(), "yyyy-MM-dd")),
        assignmentId: assignment.id,
      });
    } else if (assignment.type === AssignmentType.Tomning) {
      createTomning({
        separatorId: assignment.separatorId,
        inspectionDate: new Date(format(new Date(), "yyyy-MM-dd")),
        assignmentId: assignment.id,
      });
    } else {
      throw Error("handleCreateInspection missing type");
    }
  };

  if (
    (isLoadingAssignments && !hasLoadedFirstTime) || // Vi vill inte visa loader om det redan finns data att lista
    isCreatingFiveYearsInspection ||
    isCreatingSixMonthsInspection ||
    isCreatingTomning
  ) {
    return <PageLoader />;
  }

  const assignmentToDelete = assignments.find(
    (ass) => ass.id === assignmentIdToDelete
  );

  // Används när man ändrar en assignment med datum bakåt i tiden
  const getPassedDownDropdownItem = (
    assignmentId?: number | null
  ): DateDropdownItem | undefined => {
    const assignment = assignments.find((ass) => ass.id === assignmentId);
    if (!assignment) return undefined;
    if (!isBeforeThisWeek(new Date(assignment.date))) return undefined;

    return dateToDropdownItem(new Date(assignment.date));
  };

  return (
    <>
      <PageTransition>
        <Panel>
          <div className="flex flex-col sm:flex-row justify-between items-start gap-x-12 mb-8">
            <Filters
              customerIds={customerIds}
              addCustomerId={addCustomerId}
              deleteCustomerId={deleteCustomerId}
              userIds={userIds}
              addUserId={addUserId}
              deleteUserId={deleteUserId}
            />
            <div>
              <PrimaryButton
                className="mt-6"
                onClick={() => setIsNewAssignmentModalOpen(true)}
              >
                + Nytt uppdrag
              </PrimaryButton>
            </div>
          </div>
          <div className="space-y-8">
            {assignments.length === 0 ? (
              <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Inga uppdrag
                </h3>
                {isFiltersActive && (
                  <p className="mt-2 max-w-2xl text-sm text-gray-500">
                    Vi kunde tyvärr inte hitta några uppdrag som matchar dina
                    valda filter. Du kan prova att justera dina
                    filterinställningar eller återställa dem för att se alla
                    tillgängliga uppdrag.
                  </p>
                )}
              </div>
            ) : (
              <>
                <Table>
                  {/* <THead>
                    <TR>
                      <TH>Veckodag</TH>
                      <TH>Kund</TH>
                      <TH>Operatör</TH>
                      <TH>Uppdrag</TH>
                      <TH>Kommentar</TH>
                      <TH isLastColumn>
                        <></>
                      </TH>
                    </TR>
                  </THead> */}
                  <TBody>
                    {(searchResult?.prioritizedAssignments ?? []).length >
                      0 && (
                      <>
                        <tr className="border-t border-gray-200">
                          <th
                            colSpan={7}
                            scope="colgroup"
                            className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            <span className="flex gap-x-2">
                              <span>Prioriterade uppdrag</span>
                            </span>
                          </th>
                        </tr>
                        {searchResult?.prioritizedAssignments.map(
                          (assignment) => (
                            <AssignmentItem
                              key={assignment.id}
                              assignment={assignment}
                              onEditClick={() =>
                                setAssignmentIdToUpdate(assignment.id)
                              }
                              onDeleteClick={() =>
                                setAssignmentIdToDelete(assignment.id)
                              }
                              openInspection={openInspection}
                              loggedInUserId={user?.id}
                              createInspection={handleCreateInspection}
                              isLate={false}
                              viewAssignment={() =>
                                setAssignmentIdToView(assignment.id)
                              }
                            />
                          )
                        )}
                      </>
                    )}

                    {searchResult?.weeks.map((week) => (
                      <Fragment key={week.weekNumber}>
                        <tr className="border-t border-gray-200">
                          <th
                            colSpan={7}
                            scope="colgroup"
                            className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            <div className="flex gap-x-2">
                              {`Vecka ${week.weekNumber}`}
                              <span className="ml-0 text-gray-600 font-normal text-sm">
                                {week.weekSpan}
                              </span>
                            </div>
                          </th>
                        </tr>
                        {week.assignments.map((assignment) => (
                          <AssignmentItem
                            key={assignment.id}
                            assignment={assignment}
                            onEditClick={() =>
                              setAssignmentIdToUpdate(assignment.id)
                            }
                            onDeleteClick={() =>
                              setAssignmentIdToDelete(assignment.id)
                            }
                            openInspection={openInspection}
                            loggedInUserId={user?.id}
                            createInspection={handleCreateInspection}
                            isLate={false}
                            viewAssignment={() =>
                              setAssignmentIdToView(assignment.id)
                            }
                          />
                        ))}
                      </Fragment>
                    ))}
                  </TBody>
                </Table>
              </>
            )}
          </div>
        </Panel>
      </PageTransition>
      <Modal
        isOpen={isNewAssignmentModalOpen}
        onClose={onAddAssignmentModalClose}
        title="Nytt uppdrag"
        actionHandler={onAssignmentSubmit}
        isLoading={isCreatingAssignment}
        actionText="Schemalägg"
        isPrettyWide
      >
        <FormProvider {...wasteCodeForm}>
          <NewAssignment
            register={registerAssignment}
            formData={formData}
            formErrors={formErrors}
            selectedWasteCodes={selectedWasteCodes}
            setSelectedWasteCodes={setSelectedWasteCodes}
            setValue={setValue}
            wasteCodeFields={fields}
            appendWasteCode={append}
            removeWasteCode={remove}
            wasteCodeForm={wasteCodeForm}
            selectedWasteCodeList={selectedWasteCodesList}
          />
        </FormProvider>
      </Modal>
      <Modal
        isOpen={!!assignmentIdToUpdate}
        onClose={onUpdateAssignmentModalClose}
        title="Ändra uppdrag"
        actionHandler={onUpdateAssignment}
        isLoading={isUpdatingAssignment}
        isPrettyWide
      >
        <UpdateAssignment
          register={registerUpdateAssignment}
          formData={updateAssignmentFormdata}
          formErrors={updateAssignmentErrors}
          setValue={setUpdateAssignmentFormValue}
          passedDateDropdownItem={getPassedDownDropdownItem(
            assignmentIdToUpdate
          )}
          assignment={assignments.find((a) => a.id === assignmentIdToUpdate)}
        />
      </Modal>
      <Modal
        isOpen={!!assignmentIdToDelete && !!assignmentToDelete}
        onClose={onDeleteAssignmentModalClose}
        title="Ta bort uppdrag"
        actionHandler={onDeleteAssignment}
        isLoading={isDeletingAssingment}
      >
        <DeleteAssignment assignment={assignmentToDelete} />
      </Modal>

      <AssignmentInfoModal
        assignmentId={assignmentIdToView}
        closeModal={() => setAssignmentIdToView(null)}
        isOpen={!!assignmentIdToView}
      />
    </>
  );
}
