import { appRoutes, RouteUrl } from "routes.type";
import { Assignment } from "types/assignment.type";

export const getAssignmentInspectionLink = (assignment: Assignment): string => {
  if (assignment.fiveYearsInspection) {
    return `${appRoutes.customers.fullPath}/${assignment.customerId}/${assignment.separatorId}/${RouteUrl.FiveYearsInspection}/${assignment.fiveYearsInspection.id}`;
  } else if (assignment.sixMonthsInspection) {
    return `${appRoutes.customers.fullPath}/${assignment.customerId}/${assignment.separatorId}/${RouteUrl.SixMonthsInspection}/${assignment.sixMonthsInspection.id}`;
  } else if (assignment.tomning) {
    return `${appRoutes.customers.fullPath}/${assignment.customerId}/${assignment.separatorId}/${RouteUrl.Tomning}/${assignment.tomning.id}`;
  } else {
    // Ska aldrg komma hit, borde rapportera fel om det händer
    throw Error("Fel");
  }
};

export const getAssignmentInspectionLinkForEmails = (
  assignment: any
): string => {
  if (assignment.fiveYearsInspection) {
    return `${appRoutes.customers.fullPath}/${assignment.customerId}/${assignment.separatorId}/${RouteUrl.FiveYearsInspection}/${assignment.fiveYearsInspection.id}`;
  } else if (assignment.sixMonthsInspection) {
    return `${appRoutes.customers.fullPath}/${assignment.customerId}/${assignment.separatorId}/${RouteUrl.SixMonthsInspection}/${assignment.sixMonthsInspection.id}`;
  } else if (assignment.tomning) {
    return `${appRoutes.customers.fullPath}/${assignment.customerId}/${assignment.separatorId}/${RouteUrl.Tomning}/${assignment.tomning.id}`;
  } else {
    throw Error("Fel");
  }
};
