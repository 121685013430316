import { ComboBox, ComboBoxItem } from "components/ComboBox";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { isNumbers, removeWhitespace } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { CreateWasteDTO } from "types/waste.type";

interface Props {
  register: UseFormRegister<CreateWasteDTO>;
  wasteCodes: ComboBoxItem[];
  errors: any;
  handleSetWasteCodeQuery: (val: string) => void;
  selectedWasteCode: ComboBoxItem;
  setSelectedWasteCode: (value: ComboBoxItem) => void;
  wasteCodeError: boolean;
}
export const WasteForm: FC<Props> = ({
  register,
  errors,
  wasteCodes,
  handleSetWasteCodeQuery,
  selectedWasteCode,
  setSelectedWasteCode,
  wasteCodeError,
}) => {
  return (
    <ModalContentWrapper>
      <div>
        <ComboBox
          label="Avfallskod"
          items={wasteCodes}
          setQuery={handleSetWasteCodeQuery}
          selectedItem={selectedWasteCode}
          setSelectedItem={setSelectedWasteCode}
        />
        {wasteCodeError && (
          <p className="mt-2 text-sm text-red-600">
            Detta fält är obligatoriskt
          </p>
        )}
        {selectedWasteCode.id !== -1 && (
          <div className="text-base mt-4">
            <span className="font-semibold">{`${selectedWasteCode.name} `}</span>
            <span>{selectedWasteCode.description}</span>
          </div>
        )}
      </div>
      <TextInput
        label="Uppskattad vikt"
        name="weightPreliminary"
        register={register}
        autoFocus
        appendix="kg"
        size="third"
        validation={{
          validate: (value: string) => {
            const trimmed = removeWhitespace(value).replace("-", "");
            return (
              !trimmed || // Ok att lämna tomt
              isNumbers(trimmed) ||
              "Ange vikten i hela kilo"
            );
          },
        }}
        errorMessage={errors?.weightPreliminary?.message}
      />
      {/* <TextInput
        label="Antal"
        name="quantity"
        register={register}
        autoFocus
        appendix="st"
        size="half"
      /> */}
      <TextArea
        label="Kommentar"
        name="comment"
        register={register}
        autoFocus
      />
    </ModalContentWrapper>
  );
};
