import { JA_NEJ_RADIO_ITEMS } from "components/inspection/inspection.constants";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { RadioGroup } from "components/RadioGroup";
import { RequiredForInspection } from "components/RequiredForInspection";
import { TextInput } from "components/TextInput";
import { FC, useEffect, useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import {
  InspectionType,
  isMissingJaNejAnswer,
  JaNejStatus,
  SeparatorClassification,
} from "types/enums";
import { getSeparatorLabel } from "types/separator/separator.helpers";
import {
  CreateSeparatorDTO,
  SeparatorType,
} from "types/separator/separator.type";

interface Props {
  register: UseFormRegister<CreateSeparatorDTO>;
  formErrors?: any;
  formData: UseFormWatch<CreateSeparatorDTO>;
}

export const EditSeparatorTechnicalInformation: FC<Props> = ({
  register,
  formErrors,
  formData,
}) => {
  const selectedType = formData("type");
  const flottarFinns = formData("flottarFinns");
  const shapeCylindric = formData("shapeCylindric");
  const shapeLyingDown = formData("shapeLyingDown");
  const shapeRectangular = formData("shapeRectangular");
  const shapeStandingUp = formData("shapeStandingUp");
  const shapeOther = formData("shapeOther");
  const shapeOtherValue = formData("shapeOtherValue");
  const marking858 = formData("marking858");

  const isMissingShape =
    !shapeCylindric &&
    !shapeLyingDown &&
    !shapeRectangular &&
    !shapeStandingUp &&
    (!shapeOther || !shapeOtherValue);

  const isVerification858SetToJa = !!(
    formData("verification858") === JaNejStatus.Ja
  );
  const [shouldAutofocusShapeOtherValue, setShouldAutofocusShapeOtherValue] =
    useState(false);
  const [
    shouldAutofocusVerification858Text,
    setShouldAutofocusVerification858Text,
  ] = useState(false);

  useEffect(() => {
    if (!shapeOther) {
      setShouldAutofocusShapeOtherValue(true);
    }
    if (marking858 === JaNejStatus.Nej && isVerification858SetToJa) {
      setShouldAutofocusVerification858Text(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOilType =
    selectedType === SeparatorType.Oil ||
    selectedType === SeparatorType.OilAndSlam;

  const is858 = marking858 === JaNejStatus.Ja || isVerification858SetToJa;

  return (
    <ModalContentWrapper>
      <RadioGroup
        name="marking858"
        label={getSeparatorLabel("marking858")}
        register={register}
        items={JA_NEJ_RADIO_ITEMS}
        missingDataWarning
        isMissing={isMissingJaNejAnswer(marking858)}
      />
      <div>
        {marking858 === JaNejStatus.Nej && (
          <RadioGroup
            name="verification858"
            label={getSeparatorLabel("verification858")}
            register={register}
            items={JA_NEJ_RADIO_ITEMS}
            missingDataWarning
            isMissing={isMissingJaNejAnswer(formData("verification858"))}
          />
        )}
        {marking858 === JaNejStatus.Nej && isVerification858SetToJa && (
          <TextInput
            name="verification858Text"
            register={register}
            label={getSeparatorLabel("verification858Text")}
            autoFocus={shouldAutofocusVerification858Text}
            validation={{
              validate: (value?: string) =>
                !!value || // Ok att lämna tomt
                !isVerification858SetToJa ||
                "Detta fält får inte lämnas tomt",
            }}
            errorMessage={formErrors?.verification858Text?.message}
            fiveYearWarning
            isMissing={!formData("verification858Text")}
          />
        )}
      </div>

      {isOilType && (
        <RadioGroup
          name="classification"
          enableAnimation={false}
          label={getSeparatorLabel("classification")}
          register={register}
          items={[
            {
              label: getSeparatorLabel("separatorClassificationKlass1"),
              value: SeparatorClassification.Klass1,
              disabled: !is858,
            },
            {
              label: getSeparatorLabel("separatorClassificationKlass2"),
              value: SeparatorClassification.Klass2,
              disabled: !is858,
            },
            {
              label: getSeparatorLabel(
                "separatorClassificationAldreGravimetrisk"
              ),
              value: SeparatorClassification.AldreGravimetrisk,
              disabled: is858,
            },
            {
              label: getSeparatorLabel("separatorClassificationMissing"),
              value: SeparatorClassification.Unavailable,
            },
          ]}
          missingDataWarning
          isMissing={!formData("classification")}
        />
      )}

      <TextInput
        label={getSeparatorLabel("manufacturer")}
        name="manufacturer"
        register={register}
      />
      <TextInput
        label={getSeparatorLabel("yearOfManufacture")}
        name="yearOfManufacture"
        register={register}
        size="third"
      />
      <TextInput
        label={getSeparatorLabel("modelIdentifier")}
        name="modelIdentifier"
        register={register}
      />

      <Shape
        register={register}
        shapeOther={shapeOther}
        shouldAutofocusShapeOtherValue={shouldAutofocusShapeOtherValue}
        missingDataWarning
        isMissing={isMissingShape}
      />

      <TextInput
        label={getSeparatorLabel("totalVatskevolym")}
        name="totalVatskevolym"
        register={register}
        appendix="m³"
        size="third"
      />
      <TextInput
        label={getSeparatorLabel("slamMaxLevel")}
        name="slamMaxLevel"
        register={register}
        appendix="mm"
        size="third"
      />
      <TextInput
        label={getSeparatorLabel("maxSlamvolym")}
        name="maxSlamvolym"
        register={register}
        appendix="m³"
        size="third"
      />
      {isOilType && (
        <TextInput
          label={getSeparatorLabel("maxOljevolym")}
          name="maxOljevolym"
          register={register}
          appendix="m³"
          size="third"
        />
      )}

      {(marking858 === JaNejStatus.Ja || isVerification858SetToJa) &&
        isOilType && (
          <TextInput
            label={getSeparatorLabel("flode")}
            name="flode"
            register={register}
            appendix="l/s"
            size="third"
          />
        )}

      {isOilType && (
        <TextInput
          label={getSeparatorLabel("oilMaxLevel")}
          name="oilMaxLevel"
          register={register}
          appendix="mm"
          size="third"
        />
      )}

      <RadioGroup
        name="provtagningsbrunnFinns"
        enableAnimation={false}
        label={getSeparatorLabel("provtagningsbrunnFinns")}
        register={register}
        items={JA_NEJ_RADIO_ITEMS}
        missingDataWarning
        isMissing={isMissingJaNejAnswer(formData("provtagningsbrunnFinns"))}
      />

      {isOilType && (
        <RadioGroup
          name="koalecensfilterFinns"
          enableAnimation={false}
          label={getSeparatorLabel("koalecensfilterFinns")}
          register={register}
          items={JA_NEJ_RADIO_ITEMS}
          missingDataWarning
          isMissing={isMissingJaNejAnswer(formData("koalecensfilterFinns"))}
        />
      )}
      {isOilType && (
        <RadioGroup
          name="nivalarmFinns"
          enableAnimation={false}
          label={getSeparatorLabel("nivalarmFinns")}
          register={register}
          items={JA_NEJ_RADIO_ITEMS}
          missingDataWarning
          isMissing={isMissingJaNejAnswer(formData("nivalarmFinns"))}
        />
      )}

      <RadioGroup
        name="slamnivalarmFinns"
        enableAnimation={false}
        label={getSeparatorLabel("slamnivalarmFinns")}
        register={register}
        items={JA_NEJ_RADIO_ITEMS}
        missingDataWarning
        isMissing={isMissingJaNejAnswer(formData("slamnivalarmFinns"))}
      />
      {isOilType && (
        <RadioGroup
          name="damningslarmHognivalarmFinns"
          enableAnimation={false}
          label={getSeparatorLabel("damningslarmHognivalarmFinns")}
          register={register}
          items={JA_NEJ_RADIO_ITEMS}
          missingDataWarning
          isMissing={isMissingJaNejAnswer(
            formData("damningslarmHognivalarmFinns")
          )}
        />
      )}
      {isOilType && (
        <RadioGroup
          name="flottarFinns"
          enableAnimation={false}
          label={getSeparatorLabel("flottarFinns")}
          register={register}
          items={JA_NEJ_RADIO_ITEMS}
          missingDataWarning
          isMissing={isMissingJaNejAnswer(formData("flottarFinns"))}
        />
      )}
      {flottarFinns === JaNejStatus.Ja && (
        <>
          <TextInput
            label={getSeparatorLabel("flottarDensitetAngiven")}
            name="flottarDensitetAngiven"
            appendix="kg/dm³"
            register={register}
            size="third"
          />
        </>
      )}
    </ModalContentWrapper>
  );
};

interface ShapeProps {
  register: UseFormRegister<CreateSeparatorDTO>;
  shapeOther?: boolean;
  shouldAutofocusShapeOtherValue?: boolean;
  missingDataWarning?: boolean;
  inspectionType?: InspectionType;
  isMissing?: boolean;
}
const Shape: FC<ShapeProps> = ({
  register,
  shapeOther,
  shouldAutofocusShapeOtherValue,
  missingDataWarning = false,
  isMissing = false,
}) => (
  <fieldset>
    <legend className="sr-only">Form</legend>
    <div className="flex text-sm font-medium text-gray-700" aria-hidden="true">
      Form
      {missingDataWarning && <RequiredForInspection isMissing={isMissing} />}
    </div>

    <div className="mt-2 space-y-2">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="shapeCylindric"
            type="checkbox"
            {...register("shapeCylindric")}
            className="focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="shapeCylindric" className="font-medium text-gray-700">
            Cylindrisk
          </label>
        </div>
      </div>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="shapeRectangular"
            {...register("shapeRectangular")}
            type="checkbox"
            className="focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor="shapeRectangular"
            className="font-medium text-gray-700"
          >
            Rektangulär
          </label>
        </div>
      </div>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="shapeStandingUp"
            {...register("shapeStandingUp")}
            type="checkbox"
            className="focus:ring-cyan-600 h-4 w-4 text-cyan-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor="shapeStandingUp"
            className="font-medium text-gray-700"
          >
            Stående
          </label>
        </div>
      </div>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="shapeLyingDown"
            {...register("shapeLyingDown")}
            type="checkbox"
            className="focus:ring-cyan-600 h-4 w-4 text-cyan-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="shapeLyingDown" className="font-medium text-gray-700">
            Liggande
          </label>
        </div>
      </div>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="shapeOther"
            {...register("shapeOther")}
            type="checkbox"
            className="focus:ring-cyan-600 h-4 w-4 text-cyan-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="shapeOther" className="font-medium text-gray-700">
            Annan
          </label>
        </div>
      </div>
      {shapeOther && (
        <input
          type="text"
          id={"shapeOtherValue"}
          {...register("shapeOtherValue")}
          className="shadow-sm focus:ring-cyan-600 focus:border-cyan-600 block sm:text-sm border-gray-300 rounded-md w-full mt-2"
          autoComplete="off"
          placeholder="Fyll i annan form här"
          autoFocus={shouldAutofocusShapeOtherValue}
        />
      )}
    </div>
  </fieldset>
);

export const getMissingSeparatorDataWarning = (
  inspectionType?: InspectionType
) => {
  switch (inspectionType) {
    case InspectionType.FiveYears:
      return "Krävs för 5-årsbesiktning";
    case InspectionType.SixMonths:
      return "Krävs för 6-månaderskontroll";
    default:
      throw Error("getWarning not implemented for " + inspectionType);
  }
};
