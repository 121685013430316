import { useLocation } from "react-router-dom";

export const useReadMultiParam = () => {
  const location = useLocation();

  const readMultiParam = (name: string) => {
    // Parse the current query string
    const searchParams = new URLSearchParams(location.search);

    // Get all values for the specified parameter
    return searchParams.getAll(name);
  };

  return readMultiParam;
};
