import { Transition } from "components/animations/Transition";
import { MandatoryIndicator } from "components/MandatoryIndicator";
import { RequiredForInspection } from "components/RequiredForInspection";
import { classNames } from "helpers/classNames";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { config } from "react-spring";

export const InputField: FC<{
  label: string;
  register: UseFormRegister<any>;
  name: string;
  enableAnimation?: boolean;
  size?: "third" | "half" | "full";
  appendix?: string;
  disabled?: boolean;
  mandatory?: boolean;
  disabledText?: string;
  isMissingValue?: boolean;
  missingValueText?: string;
}> = ({
  label,
  register,
  name,
  enableAnimation = false,
  size = "full",
  appendix,
  disabled = false,
  mandatory = false,
  disabledText,
  isMissingValue = false,
  missingValueText = "Obligatorisk punkt",
}) => {
  const sizeClass = size === "third" ? "w-1/3" : size === "half" ? "w-1/2" : "";

  return (
    <Transition
      settings={{
        from: { height: 0, opacity: 0 },
        enter: { height: 59, opacity: 1 },
        leave: { height: 0, opacity: 0 },
        config: { ...config.default, duration: 200 },
        immediate: !enableAnimation,
      }}
      isVisible={true}
    >
      <div className="sm:py-4 sm:border-t sm:border-gray-50">
        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"
          >
            {label}
            {mandatory && <MandatoryIndicator />}
          </label>
          {!!disabledText && disabled ? (
            <span className="text-sm font-base text-gray-400 mt-4">
              {disabledText}
            </span>
          ) : (
            <div className="flex">
              <div
                className={classNames(
                  "mt-1 relative rounded-md shadow-sm flex",
                  sizeClass
                )}
              >
                <input
                  type="text"
                  className={classNames(
                    "block w-full rounded-md shadow-sm sm:text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
                  )}
                  autoComplete="off"
                  {...register(name)}
                  disabled={disabled}
                />

                {appendix && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span className="text-gray-500 sm:text-sm">{appendix}</span>
                  </div>
                )}
              </div>
              <RequiredForInspection
                isMissing={isMissingValue}
                text={missingValueText}
              />
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
};
