import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { classNames } from "helpers/classNames";
import { MandatoryIndicator } from "components/MandatoryIndicator";
import { RequiredForInspection } from "components/RequiredForInspection";

type RadioItem = {
  label: string;
  value: string;
  isNegative?: boolean;
};
type RadioGroupProps = {
  label: string;
  items: RadioItem[];
  register: UseFormRegister<any>;
  name: string;
  isDisabled?: boolean;
  mandatory?: boolean;
  isValid?: boolean;
  disabledText?: string;
  isMissingValue?: boolean;
  missingValueText?: string;
  removeTopBorder?: boolean;
};
export const RadioGroup: FC<RadioGroupProps> = ({
  label,
  items,
  register,
  name,
  isDisabled = false,
  mandatory = false,
  disabledText,
  isMissingValue = false,
  missingValueText = "Obligatorisk punkt",
  removeTopBorder = false,
}) => {
  return (
    <div
      className={classNames(
        "sm:py-4",
        removeTopBorder ? "" : "sm:border-t sm:border-gray-50"
      )}
    >
      <div role="group" aria-labelledby={`label-${label}`}>
        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
          {/* <div> */}
          <div
            className={classNames(
              "text-base font-medium sm:text-sm",
              "text-gray-900 sm:text-gray-500"
            )}
            id={`label-${label}`}
          >
            {label}
            {mandatory && !isDisabled && <MandatoryIndicator />}
          </div>
          {/* be UTAB visa hur de gör för att rapportera till NV */}
          {/* Ny kontroll: Nyinstallation */}
          <div className="sm:col-span-1">
            {!!disabledText && isDisabled ? (
              <div className="max-w-md pt-4">
                <span className="text-sm font-base text-gray-400">
                  {disabledText}
                </span>
              </div>
            ) : (
              <div className="max-w-md flex">
                <div className="mt-4 space-y-4">
                  {items.map((item) => (
                    <div key={item.label} className="flex items-center">
                      <input
                        id={`${name}-${item.value}`}
                        value={item.value}
                        {...register(name)}
                        type="radio"
                        className={classNames(
                          " h-4 w-4 border-gray-300",
                          isDisabled
                            ? "text-gray-200"
                            : item.isNegative
                            ? "focus:ring-red-400 text-red-500"
                            : "focus:ring-cyan-500 text-cyan-600"
                        )}
                        disabled={isDisabled}
                      />
                      <label
                        htmlFor={`${name}-${item.value}`}
                        className={classNames(
                          "ml-3 block text-sm font-base",
                          isDisabled ? "text-gray-400" : "text-gray-900"
                        )}
                      >
                        {item.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="mt-4">
                  <RequiredForInspection
                    isMissing={isMissingValue}
                    text={missingValueText}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
