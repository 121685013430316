import { PageLoader } from "components/PageLoader";
import { formatDate } from "helpers/date";
import { useChangelog } from "api/changelog.api";
import { Changelog } from "types/changelog.type";
import { PageTransition } from "components/animations/SnapUp";
import { Panel } from "components/Panel";

export default function ChangelogPage() {
  const { data: changelog, isLoading } = useChangelog();
  if (isLoading) return <PageLoader />;

  return (
    <PageTransition>
      <Panel>
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 "
              >
                Beskrivning
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
              >
                Datum
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {(changelog ?? []).map((item: Changelog) => (
              <tr key={item.id}>
                <td className=" py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 max-w-sm">
                  {item.description.split("-----").length > 1 ? (
                    <ul className="list-disc px-4 space-y-3 leading-5">
                      {item.description.split("-----").map((dot) => (
                        <li key={dot}>{dot}</li>
                      ))}
                    </ul>
                  ) : (
                    item.description
                  )}
                </td>
                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                  {formatDate(new Date(item.createdAt))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel>
    </PageTransition>
  );
}
