import { FC } from "react";

export const Label: FC<{
  children: React.ReactNode;
  mandatory?: boolean;
  isLocked?: boolean;
}> = ({ children, mandatory = false, isLocked = false }) => (
  <label className="text-base font-medium sm:text-sm text-gray-900 sm:text-gray-500 flex items-center">
    {children}
    {mandatory && (
      <span className="text-base font-semibold text-indigo-400 ml-0.5">*</span>
    )}
  </label>
);
