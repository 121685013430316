import { useWindowSize } from "./useWindowSize";

export enum ScreenWidth {
  Mobile,
  Tablet,
  Desktop,
}

// Don't change these, they map to Tailwind's values.
export enum Breakpoint {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  TWO_XL = 1536,
}

export const useScreenWidth = (): ScreenWidth => {
  const { width } = useWindowSize();
  const widthAsNumber = width ?? 0;

  if (widthAsNumber > Breakpoint.LG) {
    return ScreenWidth.Desktop;
  } else if (widthAsNumber > Breakpoint.SM) {
    return ScreenWidth.Tablet;
  } else {
    return ScreenWidth.Mobile;
  }
};
