import { useCustomerDropdown } from "api/customer.api";
import { useUsers } from "api/user.api";
import DropdownStupid from "components/DropdownStupid";
import { Pill } from "components/Pill";
import { FC, useMemo } from "react";
import { customerToDropdownItem, userToDropdownItem } from "types/user.type";

type Props = {
  customerIds: number[];
  addCustomerId: (id: number) => void;
  deleteCustomerId: (id: number) => void;
  userIds: number[];
  addUserId: (id: number) => void;
  deleteUserId: (id: number) => void;
};
export const Filters: FC<Props> = ({
  customerIds,
  addCustomerId,
  deleteCustomerId,
  userIds,
  addUserId,
  deleteUserId,
}) => {
  const { data: customers } = useCustomerDropdown();
  const customerDropdownItems = useMemo(
    () => (customers ?? []).map(customerToDropdownItem),
    [customers]
  );
  const { data: users } = useUsers();
  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );
  return (
    <div className="flex flex-col md:flex-row gap-y-6 gap-x-0 md:gap-y-0 md:gap-x-12">
      <div className="flex flex-col gap-y-4 flex-1">
        <div className="w-64">
          <DropdownStupid
            items={customerDropdownItems}
            label="Kund"
            handleChange={(id) => addCustomerId(+id as number)}
            descriptionItemText={
              customerIds.length === 0
                ? "Visar alla"
                : `${customerIds.length} ${
                    customerIds.length > 1 ? "kunder valda" : "kund vald"
                  }`
            }
            // className=" max-w-[200px]"
          />
        </div>
        {customerIds.length > 0 && (
          <div className="flex flex-wrap gap-x-3 gap-y-2">
            {customerIds.map((id) => (
              <Pill
                label={
                  customerDropdownItems.find((c) => c.value === id)?.label ?? ""
                }
                onDeleteClick={() => deleteCustomerId(id)}
                key={id}
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-4 flex-1">
        <div className="w-64">
          <DropdownStupid
            items={userDropdownItems}
            label="Operatör"
            handleChange={(id) => addUserId(+id as number)}
            descriptionItemText={
              userIds.length === 0
                ? "Visar alla"
                : `${userIds.length} ${
                    userIds.length > 1 ? "operatörer valda" : "operatör vald"
                  }`
            }
          />
        </div>
        {userIds.length > 0 && (
          <div className="flex flex-wrap gap-x-3 gap-y-2 ">
            {userIds.map((id) => (
              <Pill
                label={
                  userDropdownItems.find((c) => c.value === id)?.label ?? ""
                }
                onDeleteClick={() => deleteUserId(id)}
                key={id}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
