export enum InspectionStatus {
  Created = 10,
  Loaded = 15,
  RequirementsFulfilled = 20,
  Weighed = 25,
  Signed = 28,
  Klarmarkerad = 30,
  Cancelled = 40,
  MissingSeparatorData = 50,
  Unknown = -1,
}

export const inspectionStatusLabel = (status: InspectionStatus) => {
  switch (status) {
    case InspectionStatus.Created:
      return "Påbörjad";
    case InspectionStatus.RequirementsFulfilled:
      return "Fullständig";
    case InspectionStatus.Klarmarkerad:
      return "Klar";
    case InspectionStatus.Cancelled:
      return "Avbruten";
    case InspectionStatus.MissingSeparatorData:
      return "Saknar objektdata";
    default:
      throw Error(
        "inspectionStatusLabel missing implementation for " + status.toString()
      );
  }
};

// Ta bort org nr obligatoriskt

// när man lägger till ny rapport, välj
