import { useMutation, useQuery, useQueryClient } from "react-query";
import { Task, TaskDTO } from "types/task.type";
import instance from "./axiosInstance";

export const ENDPOINT = "/task";
export const GET_TASKS_QK = "get-tasks";

export const useTasks = (settings = {}) =>
  useQuery(
    GET_TASKS_QK,
    async (): Promise<Task[]> => (await instance.get(`${ENDPOINT}`)).data,
    { ...settings }
  );

export const useCreateTask = (settings = {}) => {
  return useMutation(
    async (payload: TaskDTO): Promise<Task> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      ...settings,
    }
  );
};

export const useUpdateTask = (settings = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (task: Task): Promise<Task> => {
      const { id, ...payload } = task;
      return (await instance.put(`${ENDPOINT}/${id}`, payload)).data;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_TASKS_QK]);
      },
      ...settings,
    }
  );
};

// Poppar fram den till nästa tillfälle
export const useIgnoreTask = (settings = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number): Promise<Task> => {
      return (await instance.put(`${ENDPOINT}/${id}/next`)).data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_TASKS_QK]);
      },
      ...settings,
    }
  );
};
