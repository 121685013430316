import { FC } from "react";
import {
  Waste,
  isTransportPlaneringRapporterad,
  isTransportRapporterad,
} from "types/waste.type";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import PrimaryButton from "components/buttons/PrimaryButton";
import { DotMenu } from "components/DotMenu";

type Props = {
  waste: Waste;
  openEditModal: () => void;
  onDeleteClick: () => void;
  isLocked: boolean;
};
export const WasteCodePreview: FC<Props> = ({
  waste,
  openEditModal,
  onDeleteClick,
  isLocked,
}) => (
  <div className="flex p-4 pr-2 border border-gray-100 rounded-lg shadow-sm bg-gray-50">
    <div className="flex items-center">
      <span className="flex flex-col space-y-5 cursor-pointer">
        <div className="space-y-0.5 flex flex-col">
          <span className="text-normal text-gray-900 font-semibold">
            {waste.wasteCode.codePretty}
          </span>
          <span className="text-sm text-gray-500" style={{ maxWidth: "32ch" }}>
            {waste.wasteCode.description}
          </span>
        </div>
        {!!waste.weightFinal && (
          <span className="text-normal text-gray-900 font-medium">{`Vikt ${waste.weightPreliminary} kg`}</span>
        )}
        {!!waste.weightPreliminary && !waste.weightFinal && (
          <span className="text-normal text-gray-900 font-medium">{`Prel. vikt ${waste.weightPreliminary} kg`}</span>
        )}
        {!!waste.quantity && (
          <span className="text-normal text-gray-900 font-medium">{`${waste.quantity} styck`}</span>
        )}
        {!!waste.comment && (
          <div className="flex items-center">
            <PencilSquareIcon className="h-5 w-auto mr-2 text-gray-500" />
            <span className="text-normal font-medium text-gray-600 italic">
              {waste.comment}
            </span>
          </div>
        )}
        {!waste.weightPreliminary && !isLocked && (
          <div>
            <PrimaryButton size="small" onClick={openEditModal}>
              Ange uppskattad vikt
            </PrimaryButton>
          </div>
        )}
      </span>
    </div>

    <div className="ml-auto flex flex-col items-end justify-between">
      <DotMenu
        items={[
          {
            label: "Ändra",
            onClick: openEditModal,
            icon: (
              <PencilSquareIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
          },
          {
            label: "Ta bort",
            onClick: onDeleteClick,
            icon: (
              <TrashIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
          },
        ]}
      />
      <div className="space-y-4 flex flex-col">
        {isTransportRapporterad(waste) && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-cyan-100 px-1.5 py-0.5 text-xs font-medium text-cyan-700">
            <svg
              className="h-1.5 w-1.5 fill-cyan-500"
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            Transport rapporterad
          </span>
        )}
        {isTransportPlaneringRapporterad(waste) && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-cyan-100 px-1.5 py-0.5 text-xs font-medium text-cyan-700">
            <svg
              className="h-1.5 w-1.5 fill-cyan-500"
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            Ombudsrapporterad
          </span>
        )}
      </div>
    </div>
  </div>
);
