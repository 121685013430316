import { FC } from "react";

type Props = {
  label: string;
  onDeleteClick?: () => void;
};
export const Pill: FC<Props> = ({ label, onDeleteClick }) => (
  <span className="inline-flex items-center gap-x-0.5 rounded-md  border border-gray-200 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-900">
    {label}
    {!!onDeleteClick && (
      <button
        type="button"
        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-600/20"
        onClick={onDeleteClick}
      >
        <span className="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          className="h-3.5 w-3.5 stroke-gray-800/50 group-hover:stroke-gray-800/75"
        >
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span className="absolute -inset-1" />
      </button>
    )}
  </span>
);
