import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

interface Props {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  disabled?: boolean;
}
export const Checkbox: FC<Props> = ({
  label,
  name,
  register,
  disabled = false,
}) => {
  return (
    <fieldset>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id={name}
            type="checkbox"
            {...register(name)}
            className="focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300 rounded disabled:text-gray-300"
            disabled={disabled}
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor={name}
            className="font-normal text-gray-900 disabled:text-gray-400 select-none"
          >
            {label}
          </label>
        </div>
      </div>
    </fieldset>
  );
};
