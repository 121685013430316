import { FC, useEffect } from "react";
import { UseMutateFunction } from "react-query";
import { SeparatorType } from "types/separator/separator.type";
import {
  SixMonthsInspection,
  UpdateSixMonthsInspectionDTO,
} from "types/sixMonthsInspection/sixMonthsInspection.type";
import {
  getSixMonthsInspectionLabel,
  removeCircularDependencies,
} from "types/sixMonthsInspection/sixMonthsInspection.helpers";
import { useForm } from "react-hook-form";
import { FormWrapper } from "components/inspection/form/FormWrapper";
import { InputField } from "components/inspection/form/InputField";
import { RadioGroup } from "components/inspection/form/RadioGroup";
import {
  isMissingJaNejAnswer,
  isMissingKontrollAnswer,
  JaNejStatus,
} from "types/enums";
import { TextArea } from "components/inspection/form/TextArea";
import { FormFooter } from "components/inspection/form/FormFooter";
import { JA_NEJ_RADIO_ITEMS } from "components/inspection/inspection.constants";
import { Section } from "components/inspection/protocol/Section";
import { SectionContent } from "components/inspection/protocol/SectionContent";
import { useIsJrab } from "hooks/useJrab";
import { DateField } from "components/inspection/form/DateField";

interface FormProps {
  inspection: SixMonthsInspection;
  onCancel: () => void;
  enableFieldAnimation: boolean;
  updateInspection: UseMutateFunction<
    UpdateSixMonthsInspectionDTO,
    unknown,
    UpdateSixMonthsInspectionDTO,
    unknown
  >;
  separatorType: SeparatorType;
  isSavingInspection: boolean;
}
export const Form: FC<FormProps> = ({
  inspection,
  onCancel,
  updateInspection,
  isSavingInspection,
}) => {
  const isJrab = useIsJrab();

  const { register, handleSubmit, reset, watch } =
    useForm<UpdateSixMonthsInspectionDTO>();

  useEffect(() => {
    if (inspection) {
      reset(removeCircularDependencies(inspection));
    }
  }, [inspection, reset]);

  const onSubmit = handleSubmit((data: UpdateSixMonthsInspectionDTO) =>
    updateInspection(data)
  );

  const isOilSeparator = inspection.separator.type !== SeparatorType.Slam;

  const hasProvtagningsbrunn =
    inspection.separator.provtagningsbrunnFinns === JaNejStatus.Ja;
  const hasKoalecensFilter =
    inspection.separator.koalecensfilterFinns === JaNejStatus.Ja;
  const hasSlamnivalarm =
    inspection.separator.slamnivalarmFinns === JaNejStatus.Ja;
  const hasDamningslarm =
    inspection.separator.damningslarmHognivalarmFinns === JaNejStatus.Ja;
  const hasFlottar = inspection.separator.flottarFinns === JaNejStatus.Ja;
  const hasOljeskiktsLarm =
    inspection.separator.nivalarmFinns === JaNejStatus.Ja;

  const isMissingOljeNiva = !watch("oljeNiva") && isOilSeparator;
  const isMissingSlamNiva = !watch("slamNiva");

  const isMissingOkularBesiktningGenomford = isMissingJaNejAnswer(
    watch("okularBesiktningGenomford")
  );
  const isMissingProvtagningsbrunnKontrollerad =
    isMissingJaNejAnswer(watch("provtagningsbrunnKontrollerad")) &&
    hasProvtagningsbrunn;
  const isMissingProvtagningsbrunnRengjord =
    isMissingJaNejAnswer(watch("provtagningsbrunnRengjord")) &&
    hasProvtagningsbrunn;

  const isMissingFilterKontrollerat =
    isOilSeparator &&
    hasKoalecensFilter &&
    isMissingKontrollAnswer(watch("filterKontrollerat"));

  const isMissingFilterRengjort =
    isOilSeparator &&
    hasKoalecensFilter &&
    isMissingKontrollAnswer(watch("filterRengjort"));

  const isMissingOljenivalarmKontrollerat =
    isOilSeparator &&
    hasOljeskiktsLarm &&
    isMissingKontrollAnswer(watch("oljenivalarmKontrollerat"));

  const isMissingSlamnivalarmKontrollerat =
    hasSlamnivalarm &&
    isOilSeparator &&
    isMissingKontrollAnswer(watch("slamnivalarmKontrollerat"));

  const isMissingDamningslarmKontrollerat =
    hasDamningslarm &&
    isOilSeparator &&
    isMissingKontrollAnswer(watch("damningslarmKontrollerat"));

  const isMissingFlottarKontrollerat =
    hasFlottar &&
    isOilSeparator &&
    isMissingKontrollAnswer(watch("flottarKontrollerat"));

  const isMissingReferenceNumber = !watch().referenceNumber && isJrab;

  return (
    <FormWrapper>
      <Section>
        <div className="mt-8 sm:mt-0"></div>
        <SectionContent>
          {isOilSeparator && (
            <InputField
              label={`Oljenivå${
                !inspection.separator.oilMaxLevel
                  ? ""
                  : ` (max ${inspection.separator.oilMaxLevel} mm)`
              }`}
              register={register}
              name="oljeNiva"
              appendix="mm"
              size="third"
              mandatory
              isMissingValue={isMissingOljeNiva}
            />
          )}

          <InputField
            label={getSixMonthsInspectionLabel("slamNiva")}
            register={register}
            name="slamNiva"
            appendix="mm"
            size="third"
            mandatory
            isMissingValue={isMissingSlamNiva}
          />
          <RadioGroup
            label={getSixMonthsInspectionLabel("okularBesiktningGenomford")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="okularBesiktningGenomford"
            mandatory
            isMissingValue={isMissingOkularBesiktningGenomford}
          />
          <RadioGroup
            label={getSixMonthsInspectionLabel("provtagningsbrunnKontrollerad")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="provtagningsbrunnKontrollerad"
            isDisabled={!hasProvtagningsbrunn}
            mandatory
            isMissingValue={isMissingProvtagningsbrunnKontrollerad}
          />
          <RadioGroup
            label={getSixMonthsInspectionLabel("provtagningsbrunnRengjord")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="provtagningsbrunnRengjord"
            isDisabled={!hasProvtagningsbrunn}
            mandatory
            isMissingValue={isMissingProvtagningsbrunnRengjord}
          />
          {isOilSeparator && (
            <RadioGroup
              label={getSixMonthsInspectionLabel("filterKontrollerat")}
              items={JA_NEJ_RADIO_ITEMS}
              register={register}
              name="filterKontrollerat"
              isDisabled={!hasKoalecensFilter}
              mandatory
              isMissingValue={isMissingFilterKontrollerat}
            />
          )}
          {isOilSeparator && (
            <RadioGroup
              label={getSixMonthsInspectionLabel("filterRengjort")}
              items={JA_NEJ_RADIO_ITEMS}
              register={register}
              name="filterRengjort"
              isDisabled={!hasKoalecensFilter}
              mandatory
              isMissingValue={isMissingFilterRengjort}
            />
          )}
          {isOilSeparator && (
            <RadioGroup
              label={getSixMonthsInspectionLabel("oljenivalarmKontrollerat")}
              items={JA_NEJ_RADIO_ITEMS}
              register={register}
              name="oljenivalarmKontrollerat"
              isDisabled={!hasOljeskiktsLarm}
              mandatory
              isMissingValue={isMissingOljenivalarmKontrollerat}
            />
          )}
          {isOilSeparator && (
            <RadioGroup
              label={getSixMonthsInspectionLabel("slamnivalarmKontrollerat")}
              items={JA_NEJ_RADIO_ITEMS}
              register={register}
              name="slamnivalarmKontrollerat"
              isDisabled={!hasSlamnivalarm}
              mandatory
              isMissingValue={isMissingSlamnivalarmKontrollerat}
            />
          )}
          {isOilSeparator && (
            <RadioGroup
              label={getSixMonthsInspectionLabel("damningslarmKontrollerat")}
              items={JA_NEJ_RADIO_ITEMS}
              register={register}
              name="damningslarmKontrollerat"
              isDisabled={!hasDamningslarm}
              mandatory
              isMissingValue={isMissingDamningslarmKontrollerat}
            />
          )}
          {isOilSeparator && (
            <RadioGroup
              label={getSixMonthsInspectionLabel("flottarKontrollerat")}
              items={JA_NEJ_RADIO_ITEMS}
              register={register}
              name="flottarKontrollerat"
              isDisabled={!hasFlottar}
              mandatory
              isMissingValue={isMissingFlottarKontrollerat}
            />
          )}
          <TextArea
            label={getSixMonthsInspectionLabel("anmarkningar")}
            register={register}
            name="anmarkningar"
          />
          <InputField
            label={getSixMonthsInspectionLabel("referenceNumber")}
            register={register}
            name="referenceNumber"
            isMissingValue={isMissingReferenceNumber}
          />
          <DateField
            label={getSixMonthsInspectionLabel("inspectionDate")}
            name="inspectionDate"
            register={register}
          />
        </SectionContent>
      </Section>
      <FormFooter
        onCancel={onCancel}
        onSubmit={onSubmit}
        isLoading={isSavingInspection}
      />
    </FormWrapper>
  );
};
