import { TomningQueryKey } from "api/tomning.api";
import {
  TransportDocumentQueryKey,
  useUpdateTransportDocument,
} from "api/transportDocument.api";
import { useVehicles } from "api/vehicle.api";
import { useNotification } from "hooks/useNotification";
import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { Tomning } from "types/tomning.type";
import { vehicleTypeToName } from "types/vehicle.type";

export const useVehicleLogic = (tomning?: Tomning) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  const { data: vehicles } = useVehicles();

  const vehicleDropdownItems = useMemo(
    () =>
      (vehicles ?? [])
        .map(({ id, registrationNumber, type }) => ({
          label: `${registrationNumber} - ${vehicleTypeToName(type)}`,
          value: id,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [vehicles]
  );

  const { mutate: updateTransportDocument } = useUpdateTransportDocument(
    tomning?.transportDocument?.id || -1,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
      },
      onError: () => {
        notification.error("Något gick fel");
      },
    }
  );

  const updateVehicle = (vehicleId?: number) => {
    const td = tomning?.transportDocument;
    if (!td) {
      throw Error("No transport document");
    }
    const { vehicle, ...rest } = td;
    updateTransportDocument({
      ...rest,
      vehicleId,
    });
  };

  return {
    updateVehicle,
    selectedVehicleId: tomning?.transportDocument?.vehicle?.id,
    vehicleDropdownItems,
  };
};
