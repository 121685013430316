import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Assignment,
  AssignmentDTO,
  ConfirmTimeReportDTO,
  TimeReportDTO,
} from "types/assignment.type";
import instance, { getErrorMessage } from "./axiosInstance";
import { useNotification } from "hooks/useNotification";
import { Tomning } from "types/tomning.type";
import { AssignmentType } from "enums/AssignmentType.enum";
import { TransportDocumentQueryKey } from "./transportDocument.api";

const ENDPOINT = "/assignment";

export enum AssignmentQueryKey {
  GetOne = "assignment-get-assignment",
  GetAllForSchedulePage = "assignment-get-assignments",
  GetForUser = "assignment-get-assignments-for-user",
  GetNotEmailed = "assignment-get-assignments-not-emailed",
  WaitingForTimeReport = "assignment-waiting-for-time-report",
  WaitingForTimeReportConfirmation = "assignment-waiting-for-time-report-confirmation",
}

export const useAssignment = (id?: number | null, settings = {}) =>
  useQuery(
    [AssignmentQueryKey.GetOne, id],
    async (): Promise<Assignment> =>
      (await instance.get(`${ENDPOINT}/${id}`)).data,
    { enabled: (id ?? -1) > 0, ...settings }
  );

export const useAssignments = (
  companyId: number,
  customerIds?: number[],
  userIds?: number[]
) => {
  const customerIdParams =
    !customerIds || customerIds.length === 0
      ? ""
      : `&${customerIds.map((id) => `customerId=${id}`).join("&")}`;
  const userIdParams =
    !userIds || userIds.length === 0
      ? ""
      : `&${userIds.map((id) => `userId=${id}`).join("&")}`;
  return useQuery(
    [
      AssignmentQueryKey.GetAllForSchedulePage,
      companyId,
      customerIdParams,
      userIdParams,
    ],
    async (): Promise<SchedulePageResult> =>
      (
        await instance.get(
          `${ENDPOINT}?companyId=${companyId}${customerIdParams}${userIdParams}`
        )
      ).data
  );
};

export const useAssignmentsForUser = (id: number | string, settings = {}) =>
  useQuery(
    [AssignmentQueryKey.GetForUser, id],
    async (): Promise<SchedulePageResult> =>
      (await instance.get(`${ENDPOINT}/user/${id}`)).data,
    { ...settings }
  );

export const useCreateAssignment = (settings = {}) => {
  return useMutation(
    async (payload: AssignmentDTO): Promise<Assignment> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      ...settings,
    }
  );
};

export const useCreateAssignmentWithInspection = (settings = {}) => {
  return useMutation(
    async (payload: AssignmentDTO): Promise<Assignment> =>
      (await instance.post(`${ENDPOINT}/with-inspection`, payload)).data,
    {
      ...settings,
    }
  );
};

export const useUpdateAssignment = (settings = {}) => {
  return useMutation(
    async (data: {
      payload: AssignmentDTO;
      id: number | string;
    }): Promise<Assignment> =>
      (await instance.put(`${ENDPOINT}/${data.id}`, data.payload)).data,
    {
      ...settings,
    }
  );
};

export const useAddTomningToAssignment = (
  assignmentId: number,
  settings = {}
) => {
  return useMutation(
    async (): Promise<Tomning> =>
      (await instance.put(`${ENDPOINT}/${assignmentId}/tomning`)).data,
    {
      ...settings,
    }
  );
};

export const useDeleteAssignment = (settings = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();
  return useMutation(
    async (data: DeleteAssignmentPayload): Promise<number> =>
      (await instance.delete(`${ENDPOINT}/${data.id}`)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          AssignmentQueryKey.GetAllForSchedulePage,
        ]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
      },
      onError: (err) => notification.error(getErrorMessage(err)),
      ...settings,
    }
  );
};

export const useSearchAssignments = () => {
  return useMutation(
    async (payload: SearchPayload): Promise<AssignmentsQueryResult> =>
      (await instance.post(ENDPOINT + "/search", payload)).data
  );
};

export const useAssignmentsNotEmailed = (options = {}) =>
  useQuery(
    [AssignmentQueryKey.GetNotEmailed],
    async (): Promise<Assignment[]> =>
      (await instance.get(`${ENDPOINT}/email-not-sent`)).data,
    { ...options }
  );

export const useIgnoreEmailReminder = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async ({
      assignmentId,
      type,
    }: {
      assignmentId: number;
      type: AssignmentType;
    }) =>
      await instance.put(ENDPOINT + "/ignore-email-reminder", {
        assignmentId,
        type,
      }),
    {
      onSuccess: () => {
        notification.info(`Påminnelsen dold`);
        queryClient.invalidateQueries([AssignmentQueryKey.GetNotEmailed]);
      },
    }
  );
};

export const useUpdateTimeReport = (settings = {}) => {
  return useMutation(
    async (data: TimeReportDTO[]) =>
      await instance.put(ENDPOINT + "/set-time-report", { items: data }),
    {
      ...settings,
    }
  );
};

export const useConfirmTimeReport = (settings = {}) => {
  return useMutation(
    async (data: ConfirmTimeReportDTO) =>
      await instance.put(ENDPOINT + "/confirm-time-report", data),
    {
      ...settings,
    }
  );
};

export const useAssignmentsWaitingForTimeReport = (
  userId: number,
  options = {}
) =>
  useQuery(
    [AssignmentQueryKey.WaitingForTimeReport],
    async (): Promise<Assignment[]> =>
      (await instance.get(`${ENDPOINT}/user/${userId}/waiting-for-time-report`))
        .data,
    { ...options }
  );

export const useAssignmentsWaitingForTimeReportConfirmation = (options = {}) =>
  useQuery(
    [AssignmentQueryKey.WaitingForTimeReportConfirmation],
    async (): Promise<Assignment[]> =>
      (await instance.get(`${ENDPOINT}/waiting-for-time-report-confirmation`))
        .data,
    { ...options }
  );

export type AssignmentsQueryResult = {
  assignments: Assignment[];
  totalCount: number;
};

export type DeleteAssignmentPayload = {
  id: number | string;
};

export type SearchPayload = {
  query: string;
  offset: number;
  limit: number;
  includeDeleted?: boolean;
};

export type SchedulePageResult = {
  prioritizedAssignments: Assignment[];
  weeks: {
    weekNumber: number;
    weekStartDate: string;
    assignments: Assignment[];
    weekSpan: string;
  }[];
};

// returnerar alla assignments i en lista
export function consolidateAssignments(result: SchedulePageResult) {
  // Initialize an empty array to hold all assignments
  let allAssignments: Assignment[] = [];

  // Add all prioritized assignments
  allAssignments = allAssignments.concat(result.prioritizedAssignments);

  // Iterate over each week and add its assignments
  result.weeks.forEach((week) => {
    allAssignments = allAssignments.concat(week.assignments);
  });

  return allAssignments;
}
