export enum VehicleType {
  Truck = "truck",
  Trailer = "trailer",
}

export type Vehicle = {
  id: number;
  registrationNumber: string;
  type: VehicleType;
  contentLink?: string;
};

export type VehicleDTO = {
  registrationNumber: string;
  type: VehicleType;
};

export const vehicleTypeToName = (type: VehicleType) => {
  switch (type) {
    case VehicleType.Truck:
      return "Bil";
    case VehicleType.Trailer:
      return "Släp";
    default:
      throw Error("Unknown vehicle type");
  }
};

export const vehicleNameAndType = (vehicle: Vehicle) =>
  `${vehicle.registrationNumber} - ${vehicleTypeToName(vehicle.type)}`;
