import { FC, useMemo } from "react";
import { UseFormRegister } from "react-hook-form";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import Dropdown from "components/Dropdown";
import { AssignmentDTO } from "types/assignment.type";
import {
  AssignmentType,
  getAssignmentTypeDropdownItems,
} from "enums/AssignmentType.enum";
import { CircleStackIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import { Separator, isAvskiljare } from "types/separator/separator.type";
import { getIdentifierWithType } from "types/separator/separator.helpers";

interface Props {
  register: UseFormRegister<AssignmentDTO>;
  formErrors?: any;
  customerName: string;
  separator: Separator;
}

export const NewAssignment: FC<Props> = ({
  register,
  formErrors,
  customerName,
  separator,
}) => {
  const assignmentTypeDropdownItems = useMemo(
    () => getAssignmentTypeDropdownItems(),
    []
  );

  return (
    <ModalContentWrapper>
      <div className="min-w-0 flex-auto space-y-2">
        <p className="text-sm font-semibold leading-6 text-gray-700 flex items-center">
          <FaceSmileIcon className="h-4 w-auto text-gray-500 mr-1" />
          {customerName}
        </p>
        <p className="truncate text-xs leading-5 text-gray-500 flex items-center">
          <CircleStackIcon className="h-4 w-auto text-gray-500 mr-1" />
          {getIdentifierWithType(separator)}
        </p>

        {/* <TransportDocumentStatusTag status={td.status} /> */}
      </div>
      {/* Kund, typ av uppgift, identifiering på oljeavskiljare, datum, anteckning */}
      <Dropdown
        items={assignmentTypeDropdownItems.filter(
          (assignmentType) =>
            (assignmentType.value !== AssignmentType.SixMonthsInspection &&
              assignmentType.value !== AssignmentType.FiveYearInspection) ||
            isAvskiljare(separator)
        )}
        name="type"
        label="Uppgift"
        register={register}
        validation={{
          validate: (value: number) => {
            return value > 0 || "Du måste välja en uppgift";
          },
        }}
        errorMessage={formErrors?.type?.message}
      />
    </ModalContentWrapper>
  );
};
