import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

export const THead: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
> = ({ children, className = "bg-gray-100", ...rest }) => (
  <thead {...rest} className={className}>
    {children}
  </thead>
);
