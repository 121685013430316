import { FC } from "react";
import {
  formatJaNejStatus,
  formatKontrollStatus,
} from "components/inspection/inspection.helpers";
import {
  ProtocolLabel,
  ProtocolLabelValueWrapper,
  ProtocolValue,
} from "components/inspection/protocol/LabelValue";
import { ProtocolWrapper } from "components/inspection/protocol/ProtocolWrapper";
import { KontrollStatus } from "types/enums";
import { SeparatorType } from "types/separator/separator.type";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { getSixMonthsInspectionLabel } from "types/sixMonthsInspection/sixMonthsInspection.helpers";
import { Section } from "components/inspection/protocol/Section";
import { SectionContent } from "components/inspection/protocol/SectionContent";
import { formatDate } from "helpers/date";

export const Protocol: FC<{ inspection: SixMonthsInspection }> = ({
  inspection,
}) => {
  const isOilSeparator = inspection.separator.type !== SeparatorType.Slam;
  return (
    <ProtocolWrapper>
      <div className="mt-8 sm:mt-0"></div>
      <Section>
        <SectionContent>
          {isOilSeparator && (
            <ProtocolLabelValueWrapper>
              <ProtocolLabel>
                {getSixMonthsInspectionLabel("oljeNiva")}
                {/* <MandatoryIndicator /> */}
              </ProtocolLabel>
              <ProtocolValue>{`${inspection.oljeNiva ?? ""}${
                !inspection.oljeNiva ? "" : " mm"
              }`}</ProtocolValue>
            </ProtocolLabelValueWrapper>
          )}
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("slamNiva")}
              {/* <MandatoryIndicator /> */}
            </ProtocolLabel>
            <ProtocolValue>{`${inspection.slamNiva ?? ""}${
              !inspection.slamNiva ? "" : " mm"
            }`}</ProtocolValue>
          </ProtocolLabelValueWrapper>
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("okularBesiktningGenomford")}
              {/* <MandatoryIndicator /> */}
            </ProtocolLabel>
            <ProtocolValue>
              {formatJaNejStatus(inspection.okularBesiktningGenomford)}
            </ProtocolValue>
          </ProtocolLabelValueWrapper>
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("provtagningsbrunnKontrollerad")}
              {/* <MandatoryIndicator /> */}
            </ProtocolLabel>
            <ProtocolValue>
              {formatJaNejStatus(inspection.okularBesiktningGenomford)}
            </ProtocolValue>
          </ProtocolLabelValueWrapper>
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("provtagningsbrunnRengjord")}
              {/* <MandatoryIndicator /> */}
            </ProtocolLabel>
            <ProtocolValue>
              {formatJaNejStatus(inspection.okularBesiktningGenomford)}
            </ProtocolValue>
          </ProtocolLabelValueWrapper>
          {isOilSeparator && (
            <ProtocolLabelValueWrapper>
              <ProtocolLabel>
                {getSixMonthsInspectionLabel("filterKontrollerat")}
                {/* <MandatoryIndicator /> */}
              </ProtocolLabel>
              <ProtocolValue>
                {formatKontrollStatus(inspection.filterKontrollerat)}
              </ProtocolValue>
            </ProtocolLabelValueWrapper>
          )}
          {inspection.filterKontrollerat !== KontrollStatus.FinnsEj &&
            isOilSeparator && (
              <ProtocolLabelValueWrapper>
                <ProtocolLabel>
                  {getSixMonthsInspectionLabel("filterRengjort")}
                  {/* <MandatoryIndicator /> */}
                </ProtocolLabel>
                <ProtocolValue>
                  {formatKontrollStatus(inspection.filterRengjort)}
                </ProtocolValue>
              </ProtocolLabelValueWrapper>
            )}
          {isOilSeparator && (
            <ProtocolLabelValueWrapper>
              <ProtocolLabel>
                {getSixMonthsInspectionLabel("oljenivalarmKontrollerat")}
                {/* <MandatoryIndicator /> */}
              </ProtocolLabel>
              <ProtocolValue>
                {formatKontrollStatus(inspection.oljenivalarmKontrollerat)}
              </ProtocolValue>
            </ProtocolLabelValueWrapper>
          )}
          {isOilSeparator && (
            <ProtocolLabelValueWrapper>
              <ProtocolLabel>
                {getSixMonthsInspectionLabel("slamnivalarmKontrollerat")}
                {/* <MandatoryIndicator /> */}
              </ProtocolLabel>
              <ProtocolValue>
                {formatKontrollStatus(inspection.slamnivalarmKontrollerat)}
              </ProtocolValue>
            </ProtocolLabelValueWrapper>
          )}
          {isOilSeparator && (
            <ProtocolLabelValueWrapper>
              <ProtocolLabel>
                {getSixMonthsInspectionLabel("damningslarmKontrollerat")}
                {/* <MandatoryIndicator /> */}
              </ProtocolLabel>
              <ProtocolValue>
                {formatKontrollStatus(inspection.damningslarmKontrollerat)}
              </ProtocolValue>
            </ProtocolLabelValueWrapper>
          )}
          {isOilSeparator && (
            <ProtocolLabelValueWrapper>
              <ProtocolLabel>
                {getSixMonthsInspectionLabel("flottarKontrollerat")}
                {/* <MandatoryIndicator /> */}
              </ProtocolLabel>
              <ProtocolValue>
                {formatKontrollStatus(inspection.flottarKontrollerat)}
              </ProtocolValue>
            </ProtocolLabelValueWrapper>
          )}

          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("anmarkningar")}
            </ProtocolLabel>
            <ProtocolValue>{inspection.anmarkningar}</ProtocolValue>
          </ProtocolLabelValueWrapper>
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("referenceNumber")}
            </ProtocolLabel>
            <ProtocolValue>{inspection.referenceNumber}</ProtocolValue>
          </ProtocolLabelValueWrapper>
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>{getSixMonthsInspectionLabel("user")}</ProtocolLabel>
            <ProtocolValue>{inspection.user.name}</ProtocolValue>
          </ProtocolLabelValueWrapper>
          <ProtocolLabelValueWrapper>
            <ProtocolLabel>
              {getSixMonthsInspectionLabel("inspectionDate")}
            </ProtocolLabel>
            <ProtocolValue>
              {formatDate(new Date(inspection.inspectionDate))}
            </ProtocolValue>
          </ProtocolLabelValueWrapper>
        </SectionContent>
      </Section>
    </ProtocolWrapper>
  );
};
