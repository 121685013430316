import { PageTransition } from "components/animations/SnapUp";
import { Panel } from "components/Panel";
import { FeedbackDTO, useSendFeedback } from "api/feedback.api";
import { useForm } from "react-hook-form";
import { TextArea } from "components/TextArea";
import PrimaryButton from "components/buttons/PrimaryButton";
import { EmptyStatePop } from "components/EmptyStatePop";

export default function FeedbackPage() {
  const { mutate, isLoading, isSuccess } = useSendFeedback();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FeedbackDTO>();

  const onSubmit = handleSubmit((data: FeedbackDTO) => mutate(data));

  return (
    <PageTransition>
      <Panel>
        {isSuccess ? (
          <EmptyStatePop text="Tack för din återkoppling!" />
        ) : (
          <div className="p-4 text-gray-800 max-w-lg text-sm">
            <p className="mb-4">
              Din återkoppling är avgörande för att vi ska kunna utveckla och
              förbättra Driftjournalen på bästa möjliga sätt. Vi uppskattar alla
              sorters feedback, till exempel:
            </p>
            <ul className="list-disc pl-4 space-y-1">
              <li className="">Rapportering av buggar.</li>
              <li className="">
                Sidor/funktioner där det är otydligt vad man ska göra.
              </li>
              <li className="">
                Störande moment, till exempel att det krävs för många knapptryck
                för att utföra en viss uppgift.
              </li>
              <li className="">
                Dina egna förslag till förbättringar eller nya funktioner.
              </li>
            </ul>
            <p className="mb-8 mt-4">
              Tack för att du bidrar till att göra Driftjournalen bättre!
            </p>
            <TextArea
              register={register}
              name="text"
              rows={5}
              label=""
              placeholder="Skriv här"
              validation={{ required: "Detta fält behöver innehålla något" }}
              errorMessage={errors?.text?.message}
            />
            <div className="flex justify-end mt-2">
              <PrimaryButton onClick={onSubmit} isLoading={isLoading}>
                Skicka
              </PrimaryButton>
            </div>
          </div>
        )}
      </Panel>
    </PageTransition>
  );
}
