import { SixMonthsInspection } from "./sixMonthsInspection.type";

export const getSixMonthsInspectionLabel = (fieldName: string) => {
  switch (fieldName) {
    case "inspectionDate":
      return "Kontrolldatum";
    case "oljeNiva":
      return "Oljenivå";
    case "slamNiva":
      return "Slamnivå";
    case "user":
      return "Namn behörig kontrollant";
    case "okularBesiktningGenomford":
      return "Okulär besiktning genomförd";
    case "filterKontrollerat":
      return "Koalesator kontrollerad";
    case "filterRengjort":
      return "Koalesator rengjord";
    case "oljenivalarmKontrollerat":
      return "Oljeskiktslarm kontrollerat";
    case "damningslarmKontrollerat":
      return "Dämningslarm kontrollerat";
    case "slamnivalarmKontrollerat":
      return "Slamnivålarm kontrollerat";
    case "flottarKontrollerat":
      return "Flottör kontrollerad";
    case "anmarkningar":
      return "Anmärkningar";
    case "allmanBedomning":
      return "Allmän bedömning";
    case "provtagningsbrunnKontrollerad":
      return "Provtagningsbrunn kontrollerad";
    case "provtagningsbrunnRengjord":
      return "Provtagningsbrunn rengjord";
    case "referenceNumber":
      return "Referensnummer";
    default:
      return "";
  }
};

export const removeCircularDependencies = (inspection: SixMonthsInspection) => {
  const { separator, ...rest } = inspection;
  return rest;
};
