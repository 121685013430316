import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  CreateCustomerDTO,
  getEmptyCreateCustomerDTO,
} from "types/customer.type";
import { useCreateCustomer } from "api/customer.api";

export const useAddCustomerLogic = (
  predefinedData?: Partial<CreateCustomerDTO>,
  mutationSettings = {}
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mutation = useCreateCustomer(mutationSettings);

  const form = useForm<CreateCustomerDTO>({
    defaultValues: {
      isParentCustomer: false,
    },
  });

  const onSubmit = form.handleSubmit((data: CreateCustomerDTO) =>
    mutation.mutate({ ...data, ...predefinedData })
  );

  const closeModal = () => {
    form.reset(getEmptyCreateCustomerDTO());
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    form,
    closeModal,
    onSubmit,
    mutation,
  };
};
