import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "./axiosInstance";
import {
  CreateFiveYearsInspectionDTO,
  FiveYearsInspection,
} from "types/fiveYearsInspection/fiveYearsInspection.type";
import { useNotification } from "hooks/useNotification";

const ENDPOINT = "/five-years-inspection";
export enum FiveYearsInspectionQueryKey {
  GetOne = "GET_FIVE_YEARS_INSPECTION-",
}

export const useGetFiveYearsInspection = (id: number, shouldPoll: boolean) =>
  useQuery(
    [FiveYearsInspectionQueryKey.GetOne, id],
    async (): Promise<FiveYearsInspection> =>
      (await instance.get(`${ENDPOINT}/${id}`)).data,
    {
      refetchInterval: shouldPoll ? 3000 : false,
    }
  );

export const useCreateFiveYearsInspection = () => {
  const notification = useNotification();

  return useMutation(
    async (
      payload: CreateFiveYearsInspectionDTO
    ): Promise<FiveYearsInspection> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateFiveYearsInspection = (options = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: any): Promise<FiveYearsInspection> => {
      const { emails, separator, ...rest } = payload;
      return (await instance.put(ENDPOINT, rest)).data;
    },
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (result: FiveYearsInspection) => {
        notification.info("Ändringarna sparade");
        queryClient.invalidateQueries([
          FiveYearsInspectionQueryKey.GetOne,
          result.id,
        ]);
      },
      ...options,
    }
  );
};

export const useSetFiveYearsInspectionFiles = (options = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: InspectionFilesDTO): Promise<FiveYearsInspection> =>
      (await instance.put(ENDPOINT + "/files", payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (result: FiveYearsInspection) => {
        queryClient.invalidateQueries([
          FiveYearsInspectionQueryKey.GetOne,
          result.id,
        ]);
      },
      ...options,
    }
  );
};

export const useSendFiveYearsInspectionAttachments = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: {
      id: number;
      includeTransportDocument: boolean;
    }): Promise<number> =>
      (await instance.post(ENDPOINT + "/send-attachments", payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (inspectionId: number) => {
        notification.info("Utskick schemalagt");
        queryClient.invalidateQueries([
          FiveYearsInspectionQueryKey.GetOne,
          inspectionId,
        ]);
      },
    }
  );
};

export type InspectionFilesDTO = {
  inspectionId: number;
  files: FileDTO[];
};

export type FileDTO = {
  id?: number;
  name: string;
  url: string;
};
