import { FC, useEffect, useState } from "react";
import { UseMutateFunction, useQueryClient } from "react-query";
import {
  FiveYearsInspection,
  LocalRequirement,
  LocalRequirementDTO,
} from "types/fiveYearsInspection/fiveYearsInspection.type";
import { useForm } from "react-hook-form";
import {
  InspectionResult,
  isMissingInspectionResult,
  isMissingJaNejAnswer,
  JaNejStatus,
} from "types/enums";
import {
  FiveYearsInspectionQueryKey,
  useUpdateFiveYearsInspection,
} from "api/fiveYearsInspection.api";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import AddLocalRequirement from "./AddLocalRequirement";
import { RadioGroupLR } from "components/inspection/form/RadioGroupLR";
import { FormWrapper } from "components/inspection/form/FormWrapper";
import { FormFooter } from "components/inspection/form/FormFooter";
import {
  INSPECTION_RESULT_RADIO_ITEMS,
  JA_NEJ_RADIO_ITEMS,
} from "components/inspection/inspection.constants";
import { SectionHeader } from "components/inspection/protocol/SectionHeader";
import { RadioGroup } from "components/inspection/form/RadioGroup";
import { TextArea } from "components/inspection/form/TextArea";
// import { useNotification } from "hooks/useNotification";
import { InputField } from "components/inspection/form/InputField";
import { CheckboxGroup } from "components/inspection/form/CheckboxGroup";
import { get5YInspectionLabel } from "types/fiveYearsInspection/fiveYearsInspection.helpers";
import { SectionContent } from "components/inspection/protocol/SectionContent";
import { Section } from "components/inspection/protocol/Section";
import { useIsJrab } from "hooks/useJrab";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DateField } from "components/inspection/form/DateField";

interface FormProps {
  inspection: FiveYearsInspection;
  onCancel: () => void;
  updateInspection: UseMutateFunction<
    FiveYearsInspection,
    unknown,
    FiveYearsInspection,
    unknown
  >;
  isSavingInspection: boolean;
}
export const Form: FC<FormProps> = ({
  inspection,
  onCancel,
  updateInspection,
  isSavingInspection,
}) => {
  const isJrab = useIsJrab();

  const { register, handleSubmit, watch } = useForm<FiveYearsInspection>({
    defaultValues: {
      ...inspection,
      strengthInnerAndOuter:
        inspection.strengthInnerAndOuter ?? InspectionResult.Unknown,
      innerPlaque: inspection.innerPlaque ?? InspectionResult.Unknown,
      conditionInnerParts:
        inspection.conditionInnerParts ?? InspectionResult.Unknown,
      conditionElectricParts:
        inspection.conditionElectricParts ?? InspectionResult.Unknown,
      flottarFunctionality:
        inspection.flottarFunctionality ?? InspectionResult.Unknown,
      systemDensity: inspection.systemDensity ?? InspectionResult.Unknown,
      requirementsFulfilled:
        inspection.requirementsFulfilled ?? InspectionResult.Unknown,

      dimensioning: inspection.dimensioning ?? JaNejStatus.Ej_Angett,
      oasWastewater: inspection.oasWastewater ?? JaNejStatus.Ej_Angett,
      oasDaywater: inspection.oasDaywater ?? JaNejStatus.Ej_Angett,
      waterIsRefilled: inspection.waterIsRefilled ?? JaNejStatus.Ej_Angett,
      flottorIsUp: inspection.flottorIsUp ?? JaNejStatus.Ej_Angett,
    },
  });

  const onSubmit = handleSubmit((data: FiveYearsInspection) =>
    updateInspection({
      ...data,
      localRequirements,
    })
  );

  const [localRequirements, setLocalRequirements] = useState<
    LocalRequirement[]
  >(inspection.localRequirements ?? []);

  useEffect(() => {
    setLocalRequirements(inspection.localRequirements ?? []);
  }, [inspection.localRequirements]);

  const [isLocalRequirementsModalOpen, setIsLocalRequirementsModalOpen] =
    useState(false);

  const {
    register: registerLocalRequirement,
    handleSubmit: handleLocalrequirementSubmit,
    reset: resetLocalRequirement,
  } = useForm<LocalRequirementDTO>();

  const onLocalrequirementsModalClose = () => {
    resetLocalRequirement();
    setIsLocalRequirementsModalOpen(false);
  };

  const queryClient = useQueryClient();
  const {
    mutate: updateInspectionForLocalRequirements,
    isSuccess,
    isLoading,
  } = useUpdateFiveYearsInspection();

  // const notification = useNotification();
  useEffect(() => {
    if (isSuccess) {
      // notification.info("Lokalt krav tillagt");
      onLocalrequirementsModalClose();
      queryClient.invalidateQueries([
        FiveYearsInspectionQueryKey.GetOne,
        inspection.id,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onLocalRequirementSubmit = () => {
    handleLocalrequirementSubmit(({ name }: LocalRequirementDTO) => {
      updateInspectionForLocalRequirements({
        ...inspection,
        localRequirements: [
          ...(inspection.localRequirements ?? []),
          {
            name,
          },
        ],
      });
    })();
  };

  const onLocalRequirementChange = (id: number, value: InspectionResult) =>
    setLocalRequirements((prev) =>
      prev.map((item) => (item.id === id ? { ...item, result: value } : item))
    );

  const deleteLocalRequirement = (name: string) =>
    setLocalRequirements((prev) => prev.filter((item) => item.name !== name));

  const flottarFinns = inspection.separator.flottarFinns === JaNejStatus.Ja;

  // För att displaya "Obligatoriskt fält"
  const isMissingStrengthInnerAndOuter = isMissingInspectionResult(
    watch().strengthInnerAndOuter
  );
  const isMissingInnerPlaque = isMissingInspectionResult(watch().innerPlaque);
  const isMissingConditionInnerParts = isMissingInspectionResult(
    watch().conditionInnerParts
  );
  const isMissingConditionElectricParts = isMissingInspectionResult(
    watch().conditionElectricParts
  );
  const isMissingFlottarFunctionality =
    flottarFinns && isMissingInspectionResult(watch().flottarFunctionality);
  const isMissingSystemDensity = isMissingInspectionResult(
    watch().systemDensity
  );
  const isMissingRequirementsFulfilled = isMissingInspectionResult(
    watch().requirementsFulfilled
  );
  const befintligVattenfasAterford = watch().befintligVattenfasAterford;
  const isMissingBefintligVattenfasAterford = isMissingJaNejAnswer(
    befintligVattenfasAterford
  );
  const isMissingHasBefintligVattenfasByttsMotVatten =
    befintligVattenfasAterford === JaNejStatus.Nej &&
    isMissingJaNejAnswer(watch().hasBefintligVattenfasByttsMotVatten);
  const isMissingWaterIsRefilled = isMissingJaNejAnswer(
    watch().waterIsRefilled
  );
  const isMissingFlottorIsUp =
    flottarFinns && isMissingJaNejAnswer(watch().flottorIsUp);

  const isMissingFlottarDensitetUppmatt =
    flottarFinns && !watch().flottarDensitetUppmatt;

  const isMissingReferenceNumber = !watch().referenceNumber && isJrab;

  return (
    <FormWrapper>
      <Section>
        <SectionHeader title="Obligatoriska punkter enligt 6 Kap i SS-EN 858-2" />
        <SectionContent>
          <RadioGroup
            label={get5YInspectionLabel("strengthInnerAndOuter")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="strengthInnerAndOuter"
            isMissingValue={isMissingStrengthInnerAndOuter}
          />
          <RadioGroup
            label={get5YInspectionLabel("innerPlaque")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="innerPlaque"
            isMissingValue={isMissingInnerPlaque}
          />
          <RadioGroup
            label={get5YInspectionLabel("conditionInnerParts")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="conditionInnerParts"
            isMissingValue={isMissingConditionInnerParts}
          />
          <RadioGroup
            label={get5YInspectionLabel("conditionElectricParts")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="conditionElectricParts"
            isMissingValue={isMissingConditionElectricParts}
          />
          <RadioGroup
            label={get5YInspectionLabel("flottarFunctionality")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="flottarFunctionality"
            isDisabled={!(inspection.separator.flottarFinns === JaNejStatus.Ja)}
            disabledText="Finns ej"
            isMissingValue={isMissingFlottarFunctionality}
          />
          <RadioGroup
            label={get5YInspectionLabel("systemDensity")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="systemDensity"
            isMissingValue={isMissingSystemDensity}
          />
        </SectionContent>
      </Section>
      <Section>
        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline sm:pt-16 pb-4 align-middle mt-10 sm:mt-0 mb-4 sm:mb-0">
          <div className="sm:col-span-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Lokala krav
            </h3>
            <p className="mt-1 mb-4 text-sm text-gray-500">
              Om det finns lokala krav från kommunen eller kunden kan du lägga
              till dem här
            </p>
            <SecondaryButton
              onClick={() => setIsLocalRequirementsModalOpen(true)}
            >
              Lägg till
            </SecondaryButton>
          </div>
        </div>
        <SectionContent>
          {localRequirements.map(({ id, name, result }) => (
            <div key={id} className="flex justify-between">
              <div className="flex-1">
                <RadioGroupLR
                  label={name}
                  items={[
                    ...INSPECTION_RESULT_RADIO_ITEMS,
                    { label: "Ej utförd", value: InspectionResult.Unknown },
                  ]}
                  checkedValue={result}
                  setCheckedValue={(value) =>
                    onLocalRequirementChange(id ?? -1, value)
                  }
                />
              </div>
              <div className="flex justify-center items-center">
                <TrashIcon
                  onClick={() => deleteLocalRequirement(name)}
                  className="text-sm text-gray-500 h-5 cursor-pointer hover:text-red-400 hover:transform hover:scale-110"
                />
              </div>
            </div>
          ))}
        </SectionContent>
      </Section>
      <Section>
        <SectionHeader title="Kontrollomdöme" />
        <SectionContent>
          <RadioGroup
            label={get5YInspectionLabel("requirementsFulfilled")}
            items={INSPECTION_RESULT_RADIO_ITEMS}
            register={register}
            name="requirementsFulfilled"
            isMissingValue={isMissingRequirementsFulfilled}
          />
          <TextArea
            label={get5YInspectionLabel("remarks")}
            register={register}
            name="remarks"
            rows={5}
          />
          <InputField
            label={get5YInspectionLabel("equipmentUsed")}
            register={register}
            name="equipmentUsed"
          />
          <InputField
            label={get5YInspectionLabel("referenceNumber")}
            register={register}
            name="referenceNumber"
            isMissingValue={isMissingReferenceNumber}
          />
          <DateField
            label={get5YInspectionLabel("inspectionDate")}
            name="inspectionDate"
            register={register}
          />
        </SectionContent>
      </Section>
      <Section>
        <SectionHeader title="Tömning, återfyllning och återställning" />
        <SectionContent>
          <CheckboxGroup
            label="Tömningar i samband med besiktning"
            items={[
              {
                label: "Olja",
                name: "hasTomningOlja",
              },
              {
                label: "Slam",
                name: "hasTomningSlam",
              },
              {
                label: "Vattenfas",
                name: "hasTomningVattenfas",
              },
            ]}
            register={register}
          />
          <RadioGroup
            label={get5YInspectionLabel("befintligVattenfasAterford")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="befintligVattenfasAterford"
            isMissingValue={isMissingBefintligVattenfasAterford}
          />
          <RadioGroup
            label={get5YInspectionLabel("hasBefintligVattenfasByttsMotVatten")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="hasBefintligVattenfasByttsMotVatten"
            isDisabled={befintligVattenfasAterford === JaNejStatus.Ja}
            disabledText="Vattenfas återförd, ej bytt"
            isMissingValue={isMissingHasBefintligVattenfasByttsMotVatten}
          />
          <RadioGroup
            label={get5YInspectionLabel("waterIsRefilled")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="waterIsRefilled"
            isMissingValue={isMissingWaterIsRefilled}
          />

          <RadioGroup
            label={get5YInspectionLabel("flottorIsUp")}
            items={JA_NEJ_RADIO_ITEMS}
            register={register}
            name="flottorIsUp"
            isDisabled={!flottarFinns}
            disabledText="Finns ej"
            isMissingValue={isMissingFlottorIsUp}
          />
          <InputField
            label={get5YInspectionLabel("flottarDensitetUppmatt")}
            register={register}
            name="flottarDensitetUppmatt"
            enableAnimation={false}
            size="half"
            appendix="kg/m³"
            disabled={!flottarFinns}
            disabledText="Finns ej"
            isMissingValue={isMissingFlottarDensitetUppmatt}
          />
        </SectionContent>
      </Section>
      <FormFooter
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isSavingInspection}
      />
      <Modal
        isOpen={isLocalRequirementsModalOpen}
        onClose={onLocalrequirementsModalClose}
        title="Lägg till lokalt krav"
        actionText="Spara"
        actionHandler={onLocalRequirementSubmit}
        isLoading={isLoading}
      >
        <AddLocalRequirement
          register={registerLocalRequirement}
          actionHandler={onLocalRequirementSubmit}
        />
      </Modal>
    </FormWrapper>
  );
};
