// import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import userStore, { getUserRole } from "store/user.store";
import { useIsLoggedIn } from "./hooks/useIsLoggedIn";
import routes from "./routes";
function App() {
  const isLoggedIn = useIsLoggedIn();
  const role = userStore(getUserRole);
  const routing = useRoutes(routes(isLoggedIn, role));

  // const appRef = React.useRef<HTMLDivElement>(null);

  // const stopDrop = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  // useEffect(() => {
  //   appRef?.current?.addEventListener("dragover", stopDrop);
  //   appRef?.current?.addEventListener("drop", stopDrop);
  //   return () => {
  //     appRef?.current?.removeEventListener("dragover", stopDrop);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     appRef?.current?.removeEventListener("drop", stopDrop);
  //   };
  // }, []);

  return <div>{routing}</div>;
}

export default App;
