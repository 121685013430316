import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  text: string;
  onDismissClick?: () => void;
  className?: string;
}
export const Warning: FC<Props> = ({ text, onDismissClick, className }) => {
  return (
    <div className={twMerge("rounded-md bg-yellow-50 p-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-yellow-800">{text}</p>
        </div>
      </div>
      {!!onDismissClick && (
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onDismissClick}
            className="ml-3 rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
          >
            Visa inte igen
          </button>
        </div>
      )}
    </div>
  );
};
