import { classNames } from "helpers/classNames";
import React, { FC } from "react";
interface Props {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  disableFocus?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  fullWidth?: boolean;
}

const SecondaryButton: FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  size = "medium",
  disableFocus = false,
  isLoading = false,
  loadingText,
  fullWidth = false,
  ...rest
}) => {
  const getClassForSize = (size: "small" | "medium" | "large") => {
    switch (size) {
      case "small":
        return "inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300";
      case "large":
        return "inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2";
      case "medium":
      default:
        return classNames(
          "inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2",
          fullWidth && "w-full"
        );
    }
  };
  return (
    <button
      type="button"
      className={getClassForSize(size)}
      tabIndex={disableFocus ? -1 : 0}
      {...rest}
    >
      <div className="flex items-center mx-auto">
        {isLoading && (
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-gray-300 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="3"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {isLoading && !!loadingText ? loadingText : <>{children}</>}
      </div>
    </button>
  );
};

export default SecondaryButton;
