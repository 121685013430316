import { useEffect, useState } from "react";
import { Tomning } from "types/tomning.type";
import { TomningQueryKey, useResetTomning } from "api/tomning.api";
import { useNotification } from "hooks/useNotification";
import { useQueryClient } from "react-query";
import { TransportDocumentQueryKey } from "api/transportDocument.api";

export const useResetTomningLogic = (tomning?: Tomning) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  // Reset to assignment fields
  const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);

  const {
    mutate: resetTomning,
    isLoading: isResettingTomning,
    isSuccess: isResetSuccess,
  } = useResetTomning({
    onSuccess: (data: Tomning) => {
      notification.info("Uppdraget återställt");
      queryClient.invalidateQueries([TomningQueryKey.GetOne, data.id]);
      queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
    },
  });

  const handleResetTomning = () => {
    if (!tomning) {
      throw Error("No tomnning");
    }
    resetTomning(tomning.id);
  };

  useEffect(() => {
    if (isResetSuccess) {
      setIsResetModalOpen(false);
    }
  }, [isResetSuccess]);

  return {
    handleResetTomning,
    isResettingTomning,
    isResetModalOpen,
    setIsResetModalOpen,
  };
};
