export enum TaskType {
  FiveYearInspection = 10,
  SixMonthsInspection = 20,
  Tomning = 30,
  Other = 100,
}

export const getTaskTypeName = (taskType: TaskType): string => {
  switch (taskType) {
    case TaskType.FiveYearInspection:
      return "5-årsbesiktning";
    case TaskType.SixMonthsInspection:
      return "6-månaderskontroll";
    case TaskType.Tomning:
      return "Tömning";
    case TaskType.Other:
      return "Övrigt";
    default:
      throw Error(`getTaskTypeName saknar implementation för ${taskType}`);
  }
};
