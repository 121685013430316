/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  CustomerQueryKey,
  ProxyCustomerDto,
  useAddProxyCustomer,
  useCustomer,
  useDeleteCustomer,
  useProxyAssignmentsAsParent,
  useUpdateCustomer,
} from "api/customer.api";
import { PageLoader } from "components/PageLoader";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { capitalizeFirstLetter } from "helpers/string";
import menuStore from "store/menu.store";
import { Separator } from "types/separator/separator.type";
import { Customer } from "types/customer.type";
import CustomerDetails from "./CustomerDetailsTab";
import Separators from "./SeparatorsTab";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useForm } from "react-hook-form";
import { CreateCustomerDTO } from "types/customer.type";
import Modal from "components/Modal";
import AddCustomer from "../customer-list/AddCustomer";
import { useQueryClient } from "react-query";
import PrimaryButton from "components/buttons/PrimaryButton";
import { SeparatorMetadataForm } from "../separator/SeparatorMetadataForm";
import { useIsAdmin } from "store/user.store";
import { EmailTab } from "./EmailTab";
import { Role } from "types/enums";
import { ProxyCustomerTab } from "./ProxyCustomerTab";
import { NewProxyCustomerForm } from "./NewProxyCustomerForm";
import { ProxyAssignmentsTab } from "./ProxyAssignmentsTab";
import { useAddCustomerLogic } from "hooks/useAddCustomerLogic";
import { useNotification } from "hooks/useNotification";
import { ActionMenu } from "../dashboard/ActionMenu";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import DangerModal from "components/DangerModal";
import { RouteUrl } from "routes.type";
import { useAddSeparatorLogic } from "../tomning-page/hooks/useAddSeparatorLogic.hook";

enum Tabs {
  Allmänt = "uppgifter",
  Objekt = "objekt",
  Underkunder = "underkunder",
  Epost = "epost",
  Tredjepartsuppdrag = "tredjepartsuppdrag",
}

export default function CustomerPage() {
  const params = useParams();
  const customerId: string = params?.customerId || "-1";

  const isAdmin = useIsAdmin();

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });
  const currentTab = searchParams.get("tab") || Tabs.Allmänt;

  // GET Customer
  const {
    data: customer,
    isLoading,
    isError,
  } = useCustomer(Number(customerId));

  // Edit customer
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setCurrentCustomerName } = menuStore();

  useEffect(() => {
    if (customer && !isLoading) {
      setCurrentCustomerName(customer.name);
    }
  }, [customer, setCurrentCustomerName, isLoading]);

  const {
    mutate,
    isSuccess,
    isLoading: isUpdatingCustomer,
  } = useUpdateCustomer(+customerId);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateCustomerDTO>();
  const onSubmit = handleSubmit((data: CreateCustomerDTO) => mutate(data));

  const resetForm = useCallback(
    (customer: Customer) => {
      if (customer) {
        reset(customer);
      } else {
        reset(); // Tror aldrig man kommer hit men men
      }
    },
    [reset]
  );

  useEffect(() => {
    if (customer) {
      resetForm(customer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      if (customer) {
        resetForm(customer);
      }
    }, 300); // Wait for modal to close
  };

  // Delete customer

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const deleteCustomer = useDeleteCustomer(+customerId, {
    onSuccess: () => {
      queryClient.invalidateQueries([CustomerQueryKey.GetAll]);
      notification.info(`Kund borttagen`);
      navigate(`/app/${RouteUrl.Customers}`);
    },
    onError: () => {
      notification.error("Något gick fel, kontakta support");
    },
  });

  // New separator
  const queryClient = useQueryClient();

  const addSeparatorLogic = useAddSeparatorLogic(customer);

  // Proxy assignments
  const { data: proxyAssignmentsAsParent } = useProxyAssignmentsAsParent(
    +customerId
  );

  /**
   * Add proxy customer
   */
  const [isAddProxyCustomerModalOpen, setIsAddProxyCustomerModalOpen] =
    useState(false);

  const {
    register: registerProxyCustomer,
    handleSubmit: handleProxyCustomerSubmit,
    reset: resetProxyCustomerForm,
    formState: { errors: proxyCustomerErrors },
  } = useForm<ProxyCustomerDto>({
    defaultValues: {
      proxyCustomerId: 0,
    },
  });

  const closeProxyCustomerModal = useCallback(() => {
    setIsAddProxyCustomerModalOpen(false);
    setTimeout(() => {
      resetProxyCustomerForm({ proxyCustomerId: 0 });
    }, 300); // Wait for modal to close
  }, [resetProxyCustomerForm]);

  const addProxyCustomer = useAddProxyCustomer();

  const onAddProxyCustomerSubmit = async () =>
    handleProxyCustomerSubmit((data: ProxyCustomerDto) => {
      if (customer) {
        addProxyCustomer.mutate({ ...data, customerId: customer.id });
      }
    })();

  useEffect(() => {
    if (addProxyCustomer.isSuccess) {
      closeProxyCustomerModal();
    }
  }, [addProxyCustomer.isSuccess, closeProxyCustomerModal]);

  /**
   * Skapa ny kund och sätt till underkund
   */
  const notification = useNotification();
  const createCustomer = useAddCustomerLogic(
    { parentCustomerId: +customerId },
    {
      onSuccess: (c: Customer) => {
        queryClient.invalidateQueries([CustomerQueryKey.GetOne, +customerId]);
        notification.info(`${c.name} skapad`);
      },
    }
  );

  useEffect(() => {
    if (createCustomer.mutation.isSuccess) {
      createCustomer.closeModal();
    }
  }, [createCustomer]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || !customer) {
    return <h1>Error</h1>;
  }

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            label: capitalizeFirstLetter(Tabs.Allmänt),
            labelName: "Översikt",
            content: <CustomerDetails customer={customer} />,
            isSelected: currentTab === Tabs.Allmänt,
            button: isAdmin && (
              <div className="relative mt-2">
                <ActionMenu
                  items={[
                    {
                      label: "Ändra",
                      onClick: () => setIsModalOpen(true),
                      icon: (
                        <PencilSquareIcon
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      ),
                    },
                    {
                      label: "Ta bort kund",
                      onClick: () => setIsDeleteModalOpen(true),
                      icon: (
                        <TrashIcon className="mr-3 h-5 w-5 text-rose-700 group-hover:text-rose-800" />
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            label: capitalizeFirstLetter(Tabs.Objekt),
            content: (
              <Separators
                separators={customer.separators?.sort(
                  (a: Separator, b: Separator) =>
                    a.createdAt < b.createdAt ? -1 : 1
                )}
                customer={customer}
                openNewSeparatorModal={() =>
                  addSeparatorLogic.setIsNewSeparatorModalOpen(true)
                }
              />
            ),
            isSelected: currentTab === Tabs.Objekt,
            button:
              (customer.separators ?? []).length > 0 ? (
                <PrimaryButton
                  size="small"
                  onClick={() =>
                    addSeparatorLogic.setIsNewSeparatorModalOpen(true)
                  }
                >
                  + Nytt objekt
                </PrimaryButton>
              ) : null,
            isVisible:
              !customer.isParentCustomer ||
              (customer.separators ?? []).length > 0,
          },
          {
            label: capitalizeFirstLetter(Tabs.Underkunder),
            content: (
              <ProxyCustomerTab
                customer={customer}
                openModal={() => setIsAddProxyCustomerModalOpen(true)}
              />
            ),
            isSelected: currentTab === Tabs.Underkunder,
            button:
              (customer.childCustomers ?? []).length > 0 && isAdmin ? (
                <div className="flex items-center space-x-4">
                  <SecondaryButton
                    size="small"
                    onClick={() => setIsAddProxyCustomerModalOpen(true)}
                  >
                    Koppla befintlig
                  </SecondaryButton>
                  <PrimaryButton
                    size="small"
                    onClick={createCustomer.openModal}
                  >
                    + Ny underkund
                  </PrimaryButton>
                </div>
              ) : null,
            isVisible: customer.isParentCustomer,
          },
          {
            label: Tabs.Tredjepartsuppdrag,
            labelName: "Förmedlade uppdrag",
            content: (
              <ProxyAssignmentsTab
                assignments={proxyAssignmentsAsParent}
                customer={customer}
              />
            ),
            isSelected: currentTab === Tabs.Tredjepartsuppdrag,
            isVisible: customer.isParentCustomer,
          },
          {
            label: capitalizeFirstLetter(Tabs.Epost),
            content: <EmailTab customer={customer} />,
            isSelected: currentTab === Tabs.Epost,
            minimumRole: Role.Admin,
          },
        ].filter((tab) => tab.isVisible !== false)}
        setTab={setTab}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Ändra kund"
        actionText="Spara"
        actionHandler={onSubmit}
        isLoading={isUpdatingCustomer}
        loadingText="Sparar..."
      >
        <AddCustomer register={register} errors={errors} />
      </Modal>
      <Modal
        isOpen={addSeparatorLogic.isNewSeparatorModalOpen}
        title={"Nytt objekt - " + customer.name}
        actionText="Spara"
        onClose={addSeparatorLogic.onNewSeparatorModalClose}
        actionHandler={addSeparatorLogic.onSeparatorSubmit}
        isLoading={addSeparatorLogic.isCreating}
        loadingText="Sparar..."
      >
        <SeparatorMetadataForm
          register={addSeparatorLogic.registerSeparator}
          errors={addSeparatorLogic.addSeparatorErrors}
          copyAddressFromCustomer={addSeparatorLogic.copyAddressFromCustomer}
          selectedSeparatorType={addSeparatorLogic.selectedSeparatorType}
          setValue={addSeparatorLogic.setValue}
          postnummer={addSeparatorLogic.watchSeparator("postalCode") ?? ""}
        />
      </Modal>
      <Modal
        isOpen={isAddProxyCustomerModalOpen}
        title="Lägg till underkund"
        actionText="Lägg till"
        onClose={closeProxyCustomerModal}
        actionHandler={onAddProxyCustomerSubmit}
      >
        <NewProxyCustomerForm
          register={registerProxyCustomer}
          formErrors={proxyCustomerErrors}
          idsToExclude={[
            customer.id,
            ...customer.childCustomers.map((c) => c.id),
          ]}
        />
      </Modal>

      <Modal
        isOpen={createCustomer.isModalOpen}
        onClose={createCustomer.closeModal}
        title="Skapa ny underkund"
        actionHandler={createCustomer.onSubmit}
        isLoading={createCustomer.mutation.isLoading}
        loadingText="Spara..."
        actionText="Spara"
      >
        <AddCustomer
          register={createCustomer.form.register}
          errors={createCustomer.form.formState.errors}
          preventParentCustomer
        />
      </Modal>
      <DangerModal
        isOpen={isDeleteModalOpen}
        title={`Ta bort kund`}
        actionText="Ta bort kund"
        actionHandler={() => deleteCustomer.mutate()}
        onClose={() => setIsDeleteModalOpen(false)}
        paragraphs={[`Är du säker på att du vill ta bort ${customer.name}?`]}
      />
    </>
  );
}
