import { FC } from "react";
import { EmailStatus } from "types/email.type";

interface Props {
  status: EmailStatus;
}

export const EmailStatusTag: FC<Props> = ({ status }) => {
  switch (status) {
    case EmailStatus.Scheduled:
      return (
        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-900">
          <svg
            className="h-1.5 w-1.5 fill-gray-400"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          Schemalagt
        </span>
      );
    case EmailStatus.FailedToSend:
      return (
        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700">
          <svg
            className="h-1.5 w-1.5 fill-red-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          Misslyckades
        </span>
      );
    case EmailStatus.Sent:
      return (
        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-900">
          <svg
            className="h-1.5 w-1.5 fill-yellow-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          Skickat
        </span>
      );

    case EmailStatus.Delivered:
      return (
        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-900">
          <svg
            className="h-1.5 w-1.5 fill-green-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          Levererat
        </span>
      );

    default:
      throw Error(`EmailStatusTag is missing implementation for ${status}`);
  }
};
