import { useNavigate } from "react-router-dom";
import EmptyState from "components/EmptyState";
import { THead, TR, TBody, Table, TH, TD } from "components/table";
import { Customer } from "types/customer.type";
import TabContainer from "components/tab/TabContainer";
import { appRoutes } from "routes.type";
import { Info } from "components/Info";

interface Props {
  customer: Customer;
  openModal: () => void;
}

export const ProxyCustomerTab = ({ openModal, customer }: Props) => {
  const navigate = useNavigate();

  const onCustomerClick = (id: number | string) =>
    navigate(`${appRoutes.customers.fullPath}/${id}`);

  if (customer.childCustomers.length === 0) {
    return (
      <>
        <EmptyState
          header="Inga underkunder"
          subHeader={`Med ”Underkunder” avses sådana kunder där uppdragen förmedlats via ${customer.name}. ”Underkunden” är alltså slutkunden och innehavare av 
          objektet (exempelvis oljeavskiljare eller annat objekt).`}
          onClick={openModal}
          buttonText="Lägg till underkund"
        />
      </>
    );
  }

  return (
    <TabContainer>
      <Info
        text={`Med ”Underkunder” avses sådana kunder där uppdragen förmedlats via ${customer.name}. ”Underkunden” är alltså slutkunden och innehavare av 
objektet (exempelvis oljeavskiljare eller annat objekt).`}
      />
      <div className="px-4 sm:px-0 mt-6">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>Företag</TH>
              <TH>Kontaktperson</TH>
              <TH hideOnMobile>Telefonnummer</TH>
              <TH hideOnTablet>Stad</TH>
            </TR>
          </THead>
          <TBody>
            {customer.childCustomers
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map(({ id, name, contactName, contactPhone, city }) => (
                <TR key={id} onClick={() => onCustomerClick(id)}>
                  <TD isFirstColumn>{name}</TD>
                  <TD>{contactName}</TD>
                  <TD hideOnMobile>{contactPhone}</TD>
                  <TD hideOnTablet>{city}</TD>
                </TR>
              ))}
          </TBody>
        </Table>
      </div>
    </TabContainer>
  );
};
