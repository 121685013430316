import { FC } from "react";

type Props = {
  text?: string;
};

export const ProxyAssignmentTag: FC<Props> = ({
  text = "Förmedlat uppdrag",
}) => {
  return (
    <span className="inline-flex items-center gap-x-0.5 rounded-md  pl-1.5 pr-2 py-1 text-xs font-semibold text-white ring-1 ring-inset ring-sky-600/10 bg-gradient-to-r from-indigo-300 via-rose-300 to-pink-300">
      {text}
    </span>
  );
};
