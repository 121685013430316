import {
  NotReportedToEnvType,
  RapporteringsTyp,
} from "api/transportDocument.api";
import { EmptyStatePop } from "components/EmptyStatePop";
import TabContainer from "components/tab/TabContainer";
import { TBody, TD, TH, THead, TR, Table } from "components/table";
import { UTC_DIFF_IN_HOURS, formatDate } from "helpers/date";
import { FC } from "react";
import { getLinkToRapporteraTransportPage } from "../naturvardsverket/transport/NewTransportPage";
import { getLinkToRapporteraTransportPlaneringPage } from "../naturvardsverket/transportplanering/NewTransportPlaneringPage";
import {
  isPast,
  isToday,
  isTomorrow,
  format,
  parseISO,
  addDays,
  addHours,
} from "date-fns";
import { sv } from "date-fns/locale";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

interface Props {
  items: NotReportedToEnvType[];
}

export const AvfallsregistretTab: FC<Props> = ({ items }) => {
  return (
    <TabContainer>
      {items.length === 0 ? (
        <EmptyStatePop text="Allt avfall rapporterat" />
      ) : (
        <Table>
          <THead>
            <TR>
              <TH className="w-0 px-0">
                <></>
              </TH>
              <TH>Kund</TH>
              <TH>Mottagare</TH>
              <TH>Typ</TH>
              <TH>Transportdatum</TH>
              <TH>Deadline</TH>
            </TR>
          </THead>
          <TBody>
            {items.map((item) => (
              <TR
                key={item.itemId}
                link={
                  item.type === RapporteringsTyp.TransportPlanering
                    ? getLinkToRapporteraTransportPlaneringPage(item.tdId)
                    : getLinkToRapporteraTransportPage(item.tdId)
                }
              >
                <TD>{item.customerName}</TD>
                <TD>{item.wasteStationName}</TD>
                <TD>
                  {item.type === RapporteringsTyp.Transport
                    ? "Transport"
                    : "Rapporteringsombud"}
                </TD>
                <TD>{formatDate(item.transportDate)}</TD>
                <TD>
                  <div className="flex">
                    {deadline(
                      addDays(new Date(item.transportDate), 2).toISOString()
                    ) === "Försenad" && (
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-500 mr-1"
                        aria-hidden="true"
                      />
                    )}
                    {deadline(
                      addDays(new Date(item.transportDate), 2).toISOString()
                    )}
                  </div>
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      )}
    </TabContainer>
  );
};

function deadline(inputDate: string): string {
  // Parse the input date string to a Date object
  const date = addHours(parseISO(inputDate), -UTC_DIFF_IN_HOURS);
  const formattedTime = format(date, "HH:mm"); // Formats time as "HH:MM"

  // Check if the date has passed
  if (isPast(date)) {
    return "Försenad";
  }

  // Check if the date is today
  if (isToday(date)) {
    return `Idag ${formattedTime}`;
  }

  // Check if the date is tomorrow
  if (isTomorrow(date)) {
    return `Imorgon ${formattedTime}`;
  }

  // For future dates, return the weekday name in Swedish and the time
  const weekday = format(date, "EEEE", { locale: sv }); // Use Swedish locale
  return `${
    weekday.charAt(0).toUpperCase() + weekday.slice(1)
  } ${formattedTime}`;
}
