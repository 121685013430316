import { FC } from "react";
import TabContainer from "components/tab/TabContainer";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { Vehicle, vehicleTypeToName } from "types/vehicle.type";
import { RouteUrl } from "routes.type";
import { PencilSquareIcon, QrCodeIcon } from "@heroicons/react/20/solid";
import { ActionMenu } from "../dashboard/ActionMenu";

interface Props {
  vehicles?: Vehicle[];
  updateVehicle: (id: number) => void;
}
export const VehicleTab: FC<Props> = ({ vehicles = [], updateVehicle }) => {
  const navigateToQrPage = (id: number) =>
    window.open(
      `${window.location.origin}/${RouteUrl.VehicleContentQr}/${id}`,
      "_blank"
    );

  return (
    <TabContainer>
      <Table>
        <THead>
          <TR>
            <TH isFirstColumn>Registreringsnummer</TH>
            <TH>Typ</TH>
            <TH>
              <></>
            </TH>
          </TR>
        </THead>
        <TBody>
          {vehicles.map(({ registrationNumber, type, id }: Vehicle) => (
            <TR key={id}>
              <TD isFirstColumn>{registrationNumber}</TD>
              <TD>{vehicleTypeToName(type)}</TD>
              <TD isLastColumn>
                <ActionMenu
                  items={[
                    {
                      label: "Ändra",
                      icon: (
                        <PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                      ),
                      onClick: () => updateVehicle(id),
                    },
                    {
                      label: "QR-kod",
                      icon: (
                        <QrCodeIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                      ),
                      onClick: () => navigateToQrPage(id),
                    },
                  ]}
                />
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
    </TabContainer>
  );
};
