import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { classNames } from "helpers/classNames";
import { RequiredForInspection } from "./RequiredForInspection";
import { ModalLabel } from "./ModalLabel";
import SecondaryButton from "./buttons/SecondaryButton";
interface Props {
  label?: string;
  name: string;
  register: UseFormRegister<any>;
  appendix?: string;
  validation?: any;
  size?: "third" | "half" | "full";
  errorMessage?: string;
  autoFocus?: boolean;
  placeholder?: string;
  fiveYearWarning?: boolean;
  link?: string;
  linkText?: string;
  type?: "text" | "password";
  onKeyUp?: () => void;
  isMissing?: boolean;
  action?: () => void;
  actionText?: string;
  defaultValue?: string;
  lightLabel?: boolean;
  disabled?: boolean;
}
export const TextInput: FC<Props> = ({
  label,
  name,
  register,
  validation,
  appendix,
  size = "full",
  errorMessage,
  autoFocus = false,
  placeholder,
  fiveYearWarning,
  link,
  linkText,
  type = "text",
  onKeyUp = () => null,
  isMissing = false,
  action,
  actionText,
  defaultValue,
  lightLabel = false,
  disabled = false,
}) => {
  const hasError = !!errorMessage;
  const sizeClass =
    size === "third" ? "w-1/2 sm:w-1/3" : size === "half" ? "sm:w-1/2" : "";
  return (
    <div>
      {label && (
        <ModalLabel light={lightLabel}>
          {label}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="underline text-cyan-600 ml-auto"
            >
              {linkText}
            </a>
          )}
          {fiveYearWarning && <RequiredForInspection isMissing={isMissing} />}
          {action && <div className="flex-1"></div>}
          {action && (
            <SecondaryButton size="small" onClick={action}>
              {actionText}
            </SecondaryButton>
          )}
        </ModalLabel>
      )}
      <div
        className={classNames("mt-1 relative rounded-md shadow-sm", sizeClass)}
      >
        <input
          type={type}
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200",
            hasError
              ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 "
              : "border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
          )}
          autoComplete="off"
          {...register(name, validation && validation)}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onKeyUp();
            }
          }}
          defaultValue={defaultValue}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {appendix && !hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm">{appendix}</span>
          </div>
        )}
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
