import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { ModalLabel } from "./ModalLabel";
import SecondaryButton from "./buttons/SecondaryButton";

export interface DropdownItem {
  value: string | number;
  label: string;
}
interface Props {
  items: DropdownItem[];
  name: string;
  label: string;
  register: UseFormRegister<any>;
  disabled?: boolean;
  descriptionItemText?: string;
  validation?: any;
  errorMessage?: string;
  hideLabel?: boolean;
  disabledOptionValue?: string | number;
  action?: () => void;
  actionText?: string;
  isActionLoading?: boolean;
}

const Dropdown: FC<Props> = ({
  items,
  name,
  label,
  register,
  disabled = false,
  descriptionItemText,
  validation,
  errorMessage,
  hideLabel = false,
  disabledOptionValue = 0,
  action,
  actionText,
  isActionLoading,
}) => {
  const hasError = !!errorMessage;

  return (
    <div>
      {!hideLabel && (
        <ModalLabel>
          {label}
          <div className="flex-1"></div>
          {action && (
            <SecondaryButton
              size="small"
              onClick={action}
              isLoading={isActionLoading}
            >
              {actionText}
            </SecondaryButton>
          )}
        </ModalLabel>
      )}
      <select
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm rounded-md text-gray-900"
        {...register(name, validation && validation)}
        disabled={disabled}
      >
        <option disabled value={disabledOptionValue} hidden>
          {!descriptionItemText
            ? `Välj ${label.charAt(0).toUpperCase() + label.slice(1)}`
            : descriptionItemText}
        </option>
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};

export default Dropdown;
