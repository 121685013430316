import TabContainer from "components/tab/TabContainer";
import { Table, TR, TH, TBody, TD, THead } from "components/table";
import { FC, useState } from "react";
import { Assignment } from "types/assignment.type";
import { EmptyStatePop } from "components/EmptyStatePop";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import Modal from "components/Modal";
import { TidsrapporteringModal } from "./TidsrapporteringModal";
import { AssignmentQueryKey, useConfirmTimeReport } from "api/assignment.api";
import { useQueryClient } from "react-query";
import { Tips } from "components/Tips";

interface Props {
  assignments?: Assignment[];
}
export const Tidsrapportering: FC<Props> = ({ assignments = [] }) => {
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<
    number | undefined
  >();

  const selectedAssignment = assignments.find(
    (ass) => ass.id === selectedAssignmentId
  );

  const queryClient = useQueryClient();
  const { mutate: confirmTimeReport, isLoading: isConfirmingTimeReport } =
    useConfirmTimeReport({
      onSuccess: () => {
        queryClient.invalidateQueries([
          AssignmentQueryKey.WaitingForTimeReportConfirmation,
        ]);
        setSelectedAssignmentId(undefined);
      },
    });

  const handleConfirmTimeReport = () => {
    const assignment = assignments.find((a) => a.id === selectedAssignmentId);
    if (!assignment) {
      throw Error("Can't find assignment");
    }
    confirmTimeReport({ assignmentId: assignment.id });
  };
  return (
    <TabContainer>
      {assignments.length === 0 ? (
        <EmptyStatePop text="Alla rapporter tidsrapporterade" />
      ) : (
        <>
          <Tips
            id="349259d9-24be-44a4-85a3-ede31203b4a8"
            paragraphs={[
              "När en operatör har tidsrapporterat ett uppdrag, visas det i denna lista för att underlätta fakturering till era kunder. Genom att klicka på ett specifikt uppdrag kan du se antalet timmar som operatören har arbetat. Bekräfta ett uppdrag för att ta bort det från listan.",
              "I nuläget stödjer vi endast tidsrapportering för 'Tömning'. Om ni önskar tidsrapportering för andra uppdrag, vänligen kontakta oss så kan vi lägga till det åt er.",
            ]}
          />
          <Table>
            <THead className="bg-gray-50">
              <TR>
                <TH isFirstColumn>Kund</TH>
                <TH>Typ</TH>
                <TH>Operatör</TH>
              </TR>
            </THead>
            <TBody>
              {assignments.map((assignment) => (
                <TR
                  key={assignment.id}
                  onClick={() => setSelectedAssignmentId(assignment.id)}
                >
                  <TD isFirstColumn>
                    {assignment.parentCustomer?.name ??
                      assignment.customer.name}
                  </TD>
                  <TD>
                    <AssignmentTypeTag type={assignment.type} />
                  </TD>
                  <TD>{assignment.user.name}</TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </>
      )}
      <Modal
        isOpen={!!selectedAssignmentId}
        onClose={() => setSelectedAssignmentId(undefined)}
        title="Tidsrapportering"
        isLoading={isConfirmingTimeReport}
        actionHandler={handleConfirmTimeReport}
        actionText="Bekräfta"
      >
        {!!selectedAssignment && (
          <TidsrapporteringModal assignment={selectedAssignment} />
        )}
      </Modal>
    </TabContainer>
  );
};
