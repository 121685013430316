import { DangerAction } from "components/DangerAction";
import TabContainer from "components/tab/TabContainer";
import { FC } from "react";

type Props = {
  openDeleteModal: () => void;
  isDeletingSeparator: boolean;
};

export const AdminTab: FC<Props> = ({
  isDeletingSeparator,
  openDeleteModal,
}) => {
  return (
    <TabContainer>
      <DangerAction
        handleButtonClick={openDeleteModal}
        isLoading={isDeletingSeparator}
        header="Ta bort objekt"
        text="Objektet försvinner från listan över kundens objekt, och filer som du laddat upp för objektet kommer raderas. Rapporter som skapats på detta objekt kommer finnas kvar och du kommer via dem även kunna se information om objektet."
        buttonText="Ta bort objekt"
      />
    </TabContainer>
  );
};
