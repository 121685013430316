import {
  TomningQueryKey,
  UpdateTomningDTO,
  useUpdateTomning,
} from "api/tomning.api";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Tomning } from "types/tomning.type";

export const useUpdateTomningType = (tomning?: Tomning) => {
  // Update tomning
  // const notification = useNotification();
  const queryClient = useQueryClient();
  const {
    mutate: updateTomning,
    // isLoading: isUpdatingTomning,
  } = useUpdateTomning({
    onSuccess: (data: Tomning) => {
      // notification.info("Typ av tömning sparad");
      queryClient.invalidateQueries([TomningQueryKey.GetOne, data.id]);
    },
  });

  const {
    register: registerTomningType,
    handleSubmit,
    reset: resetTomningForm,
    watch: watchTomning,
  } = useForm<UpdateTomningDTO>({});

  const onTomningSubmit = handleSubmit((data: UpdateTomningDTO) => {
    if (!tomning) {
      throw Error("No tomning");
    }
    updateTomning({ ...tomning, ...data });
  });

  const watchType = watchTomning("type");

  // listen to changes in type and update tomning when needed
  useEffect(() => {
    if (!tomning) {
      return;
    }
    if (!!watchType && tomning.type !== watchType) {
      onTomningSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchType]);

  // Reset form when tomning is loaded/changed
  useEffect(() => {
    if (tomning) {
      resetTomningForm({
        type: tomning.type,
      });
    }
  }, [tomning, resetTomningForm]);

  return {
    registerTomningType,
  };
};
