import { useParams } from "react-router-dom";
// import SecondaryButton from "components/buttons/SecondaryButton";

const ImagePage = () => {
  const params = useParams();
  const img: string = params?.img || "";

  return (
    <>
      {/* <SecondaryButton
        size="small"
        style={{ position: "fixed", top: 6, left: 6 }}
        onClick={() => window.close()}
      >
        Stäng
      </SecondaryButton> */}
      <img
        src={`https://oakontroll.fra1.digitaloceanspaces.com/${img}`}
        alt={img}
      />
    </>
  );
};

export default ImagePage;
