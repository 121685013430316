import { FC, useEffect } from "react";
import TabContainer from "../../../components/tab/TabContainer";
import { Separator } from "types/separator/separator.type";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { FilePreview } from "components/FilePreview";
import { FileUploadButton } from "components/buttons/FileUploadButton";
import { useUploadFiles } from "api/image.api";
import { v4 as guid } from "uuid";
import {
  getSeparatorLabel,
  getSeparatorTypeLabel,
} from "types/separator/separator.helpers";
import { DescriptionList } from "components/desriptionList/DescriptionList";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import { Item } from "components/desriptionList/Item";
import { hasAvtal } from "types/separator/separator.type";
import userStore, { getUserRole } from "store/user.store";
import { Role } from "types/enums";
import { getMonthsString } from "helpers/date";
import { GetKommunLabel } from "types/Kommun.type";

interface Props {
  separator: Separator;
  afterUploadedFile: (url: string) => void; // Kör denna när man laddat upp en ny fil
  isLoadingFiles?: boolean;
  deleteFile: (id: number) => void;
  openAdminTab: () => void;
}
export const GeneralTab: FC<Props> = ({
  separator,
  afterUploadedFile,
  isLoadingFiles,
  deleteFile,
  openAdminTab,
}) => {
  const {
    mutate: uploadImages,
    isSuccess: isUploadImagesSuccess,
    data: uploadedImages,
    isLoading: isUploadingImages,
    reset,
  } = useUploadFiles();

  const role = userStore(getUserRole);

  const onFileAdd = async (blob: Blob | null, filename: string) => {
    if (!blob) return;
    const file = new File([blob], filename);
    if (!file) {
      return;
    }

    const renamedFile = new File(
      [file],
      `${filename.split(".")[0]}-${guid().split("-")[0]}${
        guid().split("-")[1]
      }.${file.name.split(".")[1]}`
    );

    uploadImages([renamedFile]);
  };

  useEffect(() => {
    if (isUploadImagesSuccess && uploadedImages) {
      // Här ska jag uppdatera inspection med de nya bilderna
      afterUploadedFile(uploadedImages[0]);

      // onFilesChange(uploadedImages);
      reset();
    }
  }, [isUploadImagesSuccess, uploadedImages, reset, afterUploadedFile]);

  return (
    <TabContainer>
      <DescriptionList>
        <Item>
          <Label>{getSeparatorLabel("type")}</Label>
          <Value>{getSeparatorTypeLabel(separator)}</Value>
        </Item>
        <Item>
          <Label>{getSeparatorLabel("identifier")}</Label>
          <Value>{separator.identifier}</Value>
        </Item>
        <Item>
          <Label>{getSeparatorLabel("propertyDesignation")}</Label>
          <Value>{separator.propertyDesignation}</Value>
        </Item>

        <Item>
          <Label>Påminnelser om schemaläggning</Label>
          <Value>
            {hasAvtal(separator) ? (
              <ul className="space-y-1">
                {separator.avtalFemarskontroll && (
                  <CheckedAvtal
                    label={getSeparatorLabel("avtalFemarskontroll")}
                  />
                )}
                {separator.avtalHalvarsKontroll && (
                  <CheckedAvtal
                    label={getSeparatorLabel("avtalHalvarsKontroll")}
                  />
                )}
                {separator.avtalSlamtomning && (
                  <CheckedAvtal
                    label={`Tömning (${getMonthsString(
                      separator.tomningReminder
                    )})`.replace("()", "")}
                  />
                )}
              </ul>
            ) : role === Role.Admin ? (
              <button
                type="button"
                className="underline text-cyan-600"
                onClick={openAdminTab}
              >
                Lägg till påminnelser
              </button>
            ) : (
              ""
            )}
          </Value>
        </Item>
        <Item>
          <Label>Adress</Label>
          <Value>
            <div className="space-y-1">
              {!!separator.address && <p>{separator.address}</p>}
              {!!separator.postalCode && <p>{separator.postalCode}</p>}
              {!!separator.city && <p>{separator.city}</p>}
              {!!separator.kommunKod && (
                <p>{GetKommunLabel(separator.kommunKod)}</p>
              )}
            </div>
          </Value>
        </Item>
        {!!separator.cfarNR && (
          <Item>
            <Label>{getSeparatorLabel("cfarNR")}</Label>
            <Value>{separator.cfarNR}</Value>
          </Item>
        )}

        <Item>
          <Label>{getSeparatorLabel("coordinates")}</Label>
          <Value>
            {!!separator.coordinates && (
              <a
                href={`http://www.google.com/maps/place/${separator.coordinates.replace(
                  " ",
                  ""
                )}`}
                target="_blank"
                rel="noreferrer"
                className="underline text-cyan-600"
              >
                Öppna Google Maps
              </a>
            )}
          </Value>
        </Item>
        <Item>
          <Label>Filer</Label>
          <Value>
            <div className="space-y-4">
              {separator.files?.map(({ id, url }) => (
                <FilePreview
                  key={id}
                  url={url}
                  onDelete={() => deleteFile(id)}
                />
              ))}
              <FileUploadButton
                onFileAdd={onFileAdd}
                isLoading={isUploadingImages || isLoadingFiles}
              />
            </div>
          </Value>
        </Item>
        <Item>
          <Label>{getSeparatorLabel("notes")}</Label>
          <Value>{separator.notes}</Value>
        </Item>
      </DescriptionList>
    </TabContainer>
  );
};

const CheckedAvtal: FC<{ label: string }> = ({ label }) => (
  <li>
    <span className={"text-sm flex items-center text-900-700 mr-6"}>
      <CheckCircleIcon
        className="flex-shrink-0 mr-1 h-4 w-4 text-cyan-500"
        aria-hidden="true"
      />
      {label}
    </span>
  </li>
);
