export const appendCharacterIfNotAlreadyThere = (
  string: string,
  character: string
): string => {
  if (!string) {
    return character;
  }
  if (string[string.length - 1] === character) {
    return string;
  }
  return `${string}${character}`;
};

export const capitalizeFirstLetter = (str: string) =>
  !str ? str : str.charAt(0).toUpperCase() + str.slice(1);

export const removeWhitespace = (str: string = "") =>
  !str ? "" : str.replace(/\s/g, "");

export const isNumbers = (str: string) => /^\d+$/.test(str);

export const isEmail = (str?: string) => !!str && /^\S+@\S+\.\S+$/.test(str);
