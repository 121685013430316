import { PlusIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import {
  Company,
  UpdateCompanyDTO,
  useCompany,
  useUpdateCompany,
} from "api/company.api";
import {
  GET_INVITATIONS_KEY,
  useDeleteInvitation,
  useGetInvitations,
  useInviteUser,
} from "api/invitation.api";
import { useUsers } from "api/user.api";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { capitalizeFirstLetter } from "helpers/string";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import userStore, { getUserCompanyId } from "store/user.store";
import { Role } from "types/enums";
import { InvitationDTO } from "types/invitation.type";
import { AddUserModal } from "./AddUserModal";
import { EditCompanyModal } from "./EditCompanyModal";
import { OverviewTab } from "./OverviewTab";
import { UsersTab } from "./UsersTab";
import { VehicleTab } from "./VehicleTab";
import {
  useCreateVehicle,
  useUpdateVehicle,
  useVehicles,
} from "api/vehicle.api";
import { VehicleDTO, VehicleType } from "types/vehicle.type";
import { VehicleModal } from "./VehicleModal";

enum Tabs {
  Allmänt = "uppgifter",
  Users = "användare",
  Vehicles = "fordon",
}
export const MyCompanyPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });
  const currentTab = searchParams.get("tab") || Tabs.Allmänt;
  const companyId = userStore(getUserCompanyId) ?? -1; // kommer aldrig hända

  // Get company
  const { data: company, isLoading: isLoadingCompany } = useCompany(companyId);

  // Update company
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] =
    useState<boolean>(false);

  const {
    mutate: updateCompany,
    isSuccess: isUpdateCompanySuccess,
    isLoading: isUpdatingCompany,
  } = useUpdateCompany(companyId);

  const {
    register: registerCompany,
    handleSubmit: handleUpdateCompanySubmit,
    reset: resetCompany,
    formState: { errors: updateCompanyErrors },
  } = useForm<UpdateCompanyDTO>();
  const onUpdateCompanySubmit = handleUpdateCompanySubmit(
    (data: UpdateCompanyDTO) => updateCompany(data)
  );

  const resetForm = useCallback(
    (company: Company) => {
      if (company) {
        resetCompany(company);
      } else {
        resetCompany(); // Tror aldrig man kommer hit men men
      }
    },
    [resetCompany]
  );

  useEffect(() => {
    if (company) {
      resetForm(company);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (isUpdateCompanySuccess) {
      setIsEditCompanyModalOpen(false);
    }
  }, [isUpdateCompanySuccess]);

  const onEditCompanyModalClose = () => {
    setIsEditCompanyModalOpen(false);
    setTimeout(() => {
      if (company) {
        resetForm(company);
      }
    }, 300); // Wait for modal to close
  };

  // Const set logoURL
  const setLogoUrl = (logoUrl: string) => {
    if (!company) return;
    const { id, ...rest } = company;
    updateCompany({ ...rest, logoUrl });
  };

  // Users
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: invitations, isLoading: isLoadingInvitations } =
    useGetInvitations();
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const {
    mutate: inviteUser,
    isLoading: isInvitingUser,
    isSuccess: isInviteUserSuccess,
  } = useInviteUser();

  const {
    register: registerInvitation,
    handleSubmit: handleInvitationSubmit,
    reset: resetInvitation,
    formState: { errors: invitationErrors },
  } = useForm<InvitationDTO>({ defaultValues: { role: Role.User } });

  const onInvitationSubmit = handleInvitationSubmit((data: InvitationDTO) =>
    inviteUser(data)
  );

  const onInvitationModalClose = useCallback(() => {
    setIsInviteUserModalOpen(false);
    setTimeout(() => resetInvitation(), 300); // Wait for modal to close
  }, [resetInvitation]);

  useEffect(() => {
    if (isInviteUserSuccess) {
      onInvitationModalClose();
    }
  }, [isInviteUserSuccess, onInvitationModalClose]);

  // Delete invitation
  const { mutate: deleteInvitation, isSuccess: isDeleteInvitationSuccess } =
    useDeleteInvitation(); // TODO: Lägg till optimistisk uppdatering här
  const onDeleteInvitation = (invitationId: number) => {
    deleteInvitation(invitationId);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    if (isDeleteInvitationSuccess) {
      queryClient.invalidateQueries([GET_INVITATIONS_KEY]);
    }
  }, [isDeleteInvitationSuccess, queryClient]);

  // Vehicles
  const { data: vehicles } = useVehicles();

  // New vehicle
  const {
    mutate: addVehicle,
    isSuccess: isAddVehicleSuccess,
    isLoading: isAddVehicleLoading,
  } = useCreateVehicle();
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);

  const {
    register: registerNewVehicle,
    handleSubmit: handleNewVehicleSubmit,
    reset: resetNewVehicle,
    formState: { errors: newVehicleErrors },
  } = useForm<VehicleDTO>({
    defaultValues: { registrationNumber: "", type: VehicleType.Truck },
  });

  const onNewVehicleSubmit = handleNewVehicleSubmit((data: VehicleDTO) =>
    addVehicle(data)
  );

  const onNewVehicleModalClose = useCallback(() => {
    setIsAddVehicleModalOpen(false);
    setTimeout(() => resetNewVehicle(), 300); // Wait for modal to close
  }, [resetNewVehicle]);

  useEffect(() => {
    if (isAddVehicleSuccess) {
      onNewVehicleModalClose();
    }
  }, [isAddVehicleSuccess, onNewVehicleModalClose]);

  // update vehicle
  const [vehicleIdToUpdate, setVehicleIdToUpdate] = useState<
    number | undefined
  >();

  const { mutate: updateVehicle, isSuccess: isUpdateVehicleSuccess } =
    useUpdateVehicle(vehicleIdToUpdate);

  const {
    register: registerUpdateVehicle,
    handleSubmit: handleUpdateVehicleSubmit,
    reset: resetUpdateVehicle,
    formState: { errors: updateVehicleErrors },
  } = useForm<VehicleDTO>();

  const onUpdateVehicleSubmit = handleUpdateVehicleSubmit(
    (data: VehicleDTO) => {
      if (vehicleIdToUpdate) {
        updateVehicle({ ...data });
      } else {
        throw Error("vehicleIdToUpdate is undefined");
      }
    }
  );

  const onUpdateVehicleModalClose = useCallback(() => {
    setVehicleIdToUpdate(undefined);
    setTimeout(() => resetUpdateVehicle(), 300); // Wait for modal to close
  }, [resetUpdateVehicle]);

  useEffect(() => {
    if (vehicleIdToUpdate) {
      const vehicle = vehicles?.find((v) => v.id === vehicleIdToUpdate);
      if (!vehicle) {
        throw Error("vehicle is undefined");
      }
      resetUpdateVehicle({ ...vehicle });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleIdToUpdate]);

  useEffect(() => {
    if (isUpdateVehicleSuccess) {
      onUpdateVehicleModalClose();
    }
  }, [isUpdateVehicleSuccess, onUpdateVehicleModalClose]);

  if (isLoadingUsers || isLoadingInvitations || isLoadingCompany) {
    return <PageLoader />;
  }

  if (!company) {
    return <p>Error</p>;
  }

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            label: capitalizeFirstLetter(Tabs.Allmänt),
            content: <OverviewTab company={company} setLogoUrl={setLogoUrl} />,
            isSelected: currentTab === Tabs.Allmänt,
            button: (
              <SecondaryButton
                size="small"
                onClick={() => setIsEditCompanyModalOpen(true)}
              >
                Ändra
              </SecondaryButton>
            ),
          },
          {
            label: capitalizeFirstLetter(Tabs.Users),
            content: (
              <UsersTab
                users={users}
                invitations={invitations}
                deleteInvitation={onDeleteInvitation}
              />
            ),
            isSelected: currentTab === Tabs.Users,
            button: (
              <PrimaryButton
                onClick={() => setIsInviteUserModalOpen(true)}
                size="small"
              >
                <UserPlusIcon className="h-5 w-5 mr-1" /> Ny användare
              </PrimaryButton>
            ),
          },
          {
            label: capitalizeFirstLetter(Tabs.Vehicles),
            content: (
              <VehicleTab
                vehicles={vehicles}
                updateVehicle={(id) => setVehicleIdToUpdate(id)}
              />
            ),
            isSelected: currentTab === Tabs.Vehicles,
            button: (
              <PrimaryButton
                onClick={() => setIsAddVehicleModalOpen(true)}
                size="small"
              >
                <PlusIcon className="h-5 w-5 mr-1" /> Nytt fordon
              </PrimaryButton>
            ),
          },
        ]}
        setTab={setTab}
      />
      <Modal
        isOpen={isInviteUserModalOpen}
        onClose={onInvitationModalClose}
        title="Lägg till användare"
        actionText="Skicka inbjudan"
        actionHandler={onInvitationSubmit}
        isLoading={isInvitingUser}
        loadingText="Skickar inbjudan..."
      >
        <AddUserModal register={registerInvitation} errors={invitationErrors} />
      </Modal>
      <Modal
        isOpen={isEditCompanyModalOpen}
        onClose={onEditCompanyModalClose}
        title="Ändra företagets inställningar"
        actionText="Spara ändringar"
        actionHandler={onUpdateCompanySubmit}
        isLoading={isUpdatingCompany}
        loadingText="Sparar ändringar..."
      >
        <EditCompanyModal
          register={registerCompany}
          errors={updateCompanyErrors}
        />
      </Modal>
      <Modal
        isOpen={isAddVehicleModalOpen}
        onClose={onNewVehicleModalClose}
        title="Nytt fordon"
        actionText="Spara"
        actionHandler={onNewVehicleSubmit}
        isLoading={isAddVehicleLoading}
      >
        <VehicleModal register={registerNewVehicle} errors={newVehicleErrors} />
      </Modal>
      <Modal
        isOpen={!!vehicleIdToUpdate}
        onClose={onUpdateVehicleModalClose}
        title="Ändra fordon"
        actionText="Spara"
        actionHandler={onUpdateVehicleSubmit}
      >
        <VehicleModal
          register={registerUpdateVehicle}
          errors={updateVehicleErrors}
        />
      </Modal>
    </>
  );
};
