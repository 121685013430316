import { Transition } from "components/animations/Transition";
import { Switch } from "components/Switch";
import { FC, useEffect, useState } from "react";
import { AvtalProperty } from "./AvtalProperty";
import {
  Separator,
  TomningReminderMonths,
} from "types/separator/separator.type";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { TomningMonthsForm } from "./TomningMonthsForm";
import { useUpdateSeparator } from "api/separator.api";
import {
  formatDate,
  getMonthNameWithYear,
  getMonthsString,
  getWeekWithLabel,
} from "helpers/date";
import { Tomning } from "types/tomning.type";
import { Assignment } from "types/assignment.type";
import { ScheduledProperty } from "./ScheduledProperty";

interface AvtalSlamtömningProps {
  isEnabled: boolean;
  setIsEnabled: (val: boolean) => void;
  disableAnimation: boolean;
  separator: Separator;
  latestTomning?: Tomning;
  linkToLatestTomning?: string;
  scheduledAssignment?: Assignment;
  setAssignmentIdToView: (id: number | null) => void;
  taskDate?: Date;
}
export const AvtalSlamtömning: FC<AvtalSlamtömningProps> = ({
  isEnabled,
  setIsEnabled,
  disableAnimation,
  separator,
  latestTomning,
  linkToLatestTomning,
  setAssignmentIdToView,
  scheduledAssignment,
  taskDate,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // tömning-månader

  const {
    mutate: updateSeparator,
    isLoading: isUpdatingSeparator,
    isSuccess: isSeparatorUpdated,
  } = useUpdateSeparator(separator.id);

  useEffect(() => {
    if (isSeparatorUpdated) {
      setIsModalVisible(false);
    }
  }, [isSeparatorUpdated, setIsModalVisible]);

  const {
    register: registerTomningMonths,
    handleSubmit: handleTomningMonthsSubmit,
    reset: resetTomningMonths,
  } = useForm<TomningReminderMonths>();
  const onSubmit = handleTomningMonthsSubmit((data: TomningReminderMonths) => {
    //
    if (!separator) {
      throw Error("separator is undefined");
    }

    updateSeparator({
      ...separator,
      tomningReminder: data,
    });
  });

  useEffect(() => {
    if (separator) {
      resetTomningMonths({
        january: separator.tomningReminder?.january,
        february: separator.tomningReminder?.february,
        march: separator.tomningReminder?.march,
        april: separator.tomningReminder?.april,
        may: separator.tomningReminder?.may,
        june: separator.tomningReminder?.june,
        july: separator.tomningReminder?.july,
        august: separator.tomningReminder?.august,
        september: separator.tomningReminder?.september,
        october: separator.tomningReminder?.october,
        november: separator.tomningReminder?.november,
        december: separator.tomningReminder?.december,
      });
    }
  }, [separator, resetTomningMonths]);

  const [openModalOnEnable, setOpenModalOnEnable] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpenModalOnEnable(true), 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEnabled && openModalOnEnable) {
      setIsModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  return (
    <div className="col-span-1 mt-4">
      <Switch
        isEnabled={isEnabled}
        setIsEnabled={(val: boolean) => setIsEnabled(val)}
        label={"Tömning"}
      />

      <div className="mt-4">
        <Transition
          settings={{
            from: { opacity: 0, maxHeight: 0 },
            enter: { opacity: 1, maxHeight: 181 },
            leave: { opacity: 0, maxHeight: 0 },
            config: { precision: 0.02 },
            immediate: disableAnimation,
          }}
          isVisible={isEnabled}
        >
          <dl className="border-b border-gray-100">
            <AvtalProperty
              label="Månader"
              value={getMonthsString(separator.tomningReminder)}
              action={() => setIsModalVisible(true)}
              actionText="Ändra"
            />
            {!!scheduledAssignment ? (
              <ScheduledProperty
                label="Nästa tillfälle"
                value={getWeekWithLabel(new Date(scheduledAssignment.date))}
                action={() => setAssignmentIdToView(scheduledAssignment.id)}
                assignment={scheduledAssignment}
              />
            ) : (
              <AvtalProperty
                label="Nästa tillfälle"
                value={!taskDate ? "-" : getMonthNameWithYear(taskDate)}
              />
            )}
            <AvtalProperty
              label="Senaste tillfälle"
              value={formatDate(latestTomning?.inspectionDate)}
              link={linkToLatestTomning}
              actionText="Se rapport"
            />
          </dl>
        </Transition>
      </div>
      <Modal
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        title="Tillfällen"
        actionHandler={onSubmit}
        actionText="Spara"
        isLoading={isUpdatingSeparator}
      >
        <TomningMonthsForm register={registerTomningMonths} />
      </Modal>
    </div>
  );
};
