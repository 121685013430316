import create from "zustand";
import { persist } from "zustand/middleware";

const dismissedMessagesStore = create<DismissedMessagesState>()(
  persist(
    (set) => ({
      customerCustomerIdentification: false,
      setCustomerCustomerIdentification: (
        customerCustomerIdentification: boolean
      ) =>
        set((state) => ({
          ...state,
          customerCustomerIdentification,
        })),
      changeTdUser: false,
      setChangeTdUser: (changeTdUser: boolean) =>
        set((state) => ({
          ...state,
          changeTdUser,
        })),
      weightDiff: false,
      setWeightDiff: (weightDiff: boolean) =>
        set((state) => ({
          ...state,
          weightDiff,
        })),
      activeVehicleInfo: false,
      setActiveVehicleInfo: (activeVehicleInfo: boolean) =>
        set((state) => ({
          ...state,
          activeVehicleInfo,
        })),
      tpDone: false,
      setTpDone: (tpDone: boolean) =>
        set((state) => ({
          ...state,
          tpDone,
        })),
    }),
    {
      name: "dismissed-messages-storage",
      getStorage: () => localStorage,
    }
  )
);

interface DismissedMessagesState {
  customerCustomerIdentification: boolean;
  setCustomerCustomerIdentification: (val: boolean) => void;
  changeTdUser: boolean;
  setChangeTdUser: (val: boolean) => void;
  weightDiff: boolean;
  setWeightDiff: (val: boolean) => void;
  activeVehicleInfo: boolean;
  setActiveVehicleInfo: (val: boolean) => void;
  tpDone: boolean;
  setTpDone: (val: boolean) => void;
}

export default dismissedMessagesStore;

export const getCustomerCustomerIdentification = (
  state: DismissedMessagesState
) => state.customerCustomerIdentification;
