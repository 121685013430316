import userStore, { getUserCompanyId } from "store/user.store";
import { FiveYearsInspection } from "types/fiveYearsInspection/fiveYearsInspection.type";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";

//const HÅJT = 7;
const JRAB = 8;
export const useIsJrab = () => {
  const companyId = userStore(getUserCompanyId);
  return companyId === JRAB;
};

export const isJrab = (inspection: FiveYearsInspection | SixMonthsInspection) =>
  inspection.separator.customer?.companyId === JRAB;
