import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { classNames } from "helpers/classNames";
import { FC } from "react";

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  pageSize?: number;
  setPage: (page: number) => void;
  pageButtonCount?: number;
}
export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalCount,
  pageSize = 20,
  setPage,
  pageButtonCount = 6,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const buttonCount =
    totalPages < pageButtonCount ? totalPages : pageButtonCount;
  const minButtonValue =
    currentPage < pageButtonCount
      ? 1
      : currentPage === totalPages
      ? totalPages - pageButtonCount + 1
      : currentPage - pageButtonCount + 2;

  const buttonArray = Array.from(
    new Array(buttonCount),
    (x, i) => i + minButtonValue
  );

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-5 sm:px-6">
      {totalPages > 1 && (
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => setPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Föregående
          </button>
          <button
            onClick={() => setPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Nästa
          </button>
        </div>
      )}

      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {totalCount === 0 ? (
              <span className="font-medium">
                Din sökning gav inget resultat
              </span>
            ) : (
              <>
                Visar{" "}
                <span className="font-medium">
                  {(currentPage - 1) * pageSize + 1}
                </span>{" "}
                till{" "}
                <span className="font-medium">
                  {pageSize >= totalCount
                    ? totalCount
                    : currentPage * pageSize > totalCount
                    ? totalCount
                    : currentPage * pageSize}
                </span>{" "}
                av <span className="font-medium">{totalCount}</span> resultat
              </>
            )}
          </p>
        </div>
        {totalPages > 1 && (
          <div>
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button
                onClick={() => setPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={classNames(
                  "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
                  currentPage === 1 && "bg-gray-50 hover:cursor-default"
                )}
              >
                <span className="sr-only">Föregående</span>
                <ChevronLeftIcon
                  className={classNames(
                    "h-5 w-5",
                    currentPage === 1 && "text-gray-200"
                  )}
                  aria-hidden="true"
                />
              </button>
              {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
              {buttonArray.map((page: number) => (
                <PageButton
                  key={page}
                  pageNumber={page}
                  isSelected={page === currentPage}
                  onClick={setPage}
                />
              ))}
              <button
                onClick={() => setPage(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={classNames(
                  "relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
                  currentPage === totalPages &&
                    "bg-gray-50 hover:cursor-default"
                )}
              >
                <span className="sr-only">Nästa</span>
                <ChevronRightIcon
                  className={classNames(
                    "h-5 w-5",
                    currentPage === totalPages && "text-gray-200"
                  )}
                  aria-hidden="true"
                />
              </button>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

interface PageButtonProps {
  pageNumber: number;
  isSelected: boolean;
  onClick: (page: number) => void;
}
const PageButton: FC<PageButtonProps> = ({
  pageNumber,
  isSelected,
  onClick,
}) => {
  return (
    <button
      onClick={() => onClick(pageNumber)}
      className={
        isSelected
          ? "relative z-10 inline-flex items-center border border-cyan-500 bg-cyan-50 px-4 py-2 text-sm font-medium text-cyan-600 focus:z-20"
          : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
      }
    >
      {pageNumber}
    </button>
  );
};
