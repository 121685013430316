import { TransportSätt } from "types/tomning.type";
import { z } from "zod";
import {
  EmailSchema,
  KontaktPersonNamnSchema,
  KontaktTelefon,
  OrganizationNumberSchema,
  PlatsDTOSchema,
  VerksamhetsnamnSchema,
  avfallSchema,
} from "./transport.type";

export const TransportPlaneringDtoSchema = z
  .object({
    avfall: z
      .array(avfallSchema)
      .min(1, { message: "avfall must contain at least one item" }),
    // objekt
    cfarNR: z.string().min(1, "Ange uppkomstplatsens cfarNR"),
    uppkomstplats: PlatsDTOSchema,
    uppkomstplatsLocationType: z.enum(["address", "coordinate"]),
    // mottagarstation
    mottagningsplats: PlatsDTOSchema,
    mottagningsplatsLocationType: z.enum(["address", "coordinate"]),
    nyInnehavare: OrganizationNumberSchema,
    // verksamhet
    verksamhetsutovare: OrganizationNumberSchema,
    verksamhetensNamn: VerksamhetsnamnSchema,
    verksamhetensKontaktpersonNamn: KontaktPersonNamnSchema,
    verksamhetensKontaktpersonEpost: EmailSchema,
    verksamhetensKontaktpersonTelefonnummer: KontaktTelefon,

    // ombud
    // transportor: OrganizationNumberSchema,
    ombud: OrganizationNumberSchema,
    ombudetsNamn: VerksamhetsnamnSchema,
    ombudetsKontaktpersonNamn: KontaktPersonNamnSchema,
    ombudetsKontaktpersonEpost: EmailSchema,
    ombudetsKontaktpersonTelefonnummer: KontaktTelefon,

    borttransportDatum: z.string(),
    transportsatt: z.nativeEnum(TransportSätt),
    referens: z.string().optional(),
    transportDocumentId: z.number().optional(),
  })
  .refine(
    (data) => {
      if (
        data.uppkomstplatsLocationType === "address" &&
        !data.uppkomstplats.adress
      ) {
        return false; // Invalid if startPlatsLocationType is 'address' but adress is not provided
      }
      if (
        data.uppkomstplatsLocationType === "coordinate" &&
        !data.uppkomstplats.koordinat
      ) {
        return false; // Invalid if startPlatsLocationType is 'coordinate' but koordinat is not provided
      }
      return true; // Valid otherwise
    },
    {
      message: "Transport startplats must match the selected location type",
    }
  )
  .refine(
    (data) => {
      if (
        data.mottagningsplatsLocationType === "address" &&
        !data.mottagningsplats.adress
      ) {
        return false; // Invalid if startPlatsLocationType is 'address' but adress is not provided
      }
      if (
        data.mottagningsplatsLocationType === "coordinate" &&
        !data.mottagningsplats.koordinat
      ) {
        return false; // Invalid if startPlatsLocationType is 'coordinate' but koordinat is not provided
      }
      return true; // Valid otherwise
    },
    {
      message: "Transport startplats must match the selected location type",
    }
  );

export type TransportPlaneringDTO = z.infer<typeof TransportPlaneringDtoSchema>;
