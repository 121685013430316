import { ChangePasswordDTO, useChangePassword, useUser } from "api/user.api";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import userStore from "store/user.store";
import ChangePasswordModal from "./ChangePasswordModal";

export type ChangePasswordForm = {
  oldPassword: string;
  newPassword: string;
};
export const AccountPage = () => {
  // Change password
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const {
    mutate: changePassword,
    isLoading: isChangingPassword,
    isSuccess: isChangePasswordSuccess,
  } = useChangePassword();
  const {
    register: registerChangePassword,
    handleSubmit: handleChangepasswordSubmit,
    reset: resetChangePasswordForm,
    formState: { errors: changePasswordErrors },
  } = useForm<ChangePasswordForm>();
  const onChangePasswordSubmit = handleChangepasswordSubmit(
    (data: ChangePasswordForm) => {
      const signupData: ChangePasswordDTO = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      changePassword(signupData);
    }
  );

  const closeChangePasswordModal = useCallback(() => {
    setIsChangePasswordModalOpen(false);
    setTimeout(() => {
      resetChangePasswordForm();
    }, 300); // Wait for modal to close
  }, [resetChangePasswordForm]);

  useEffect(() => {
    if (isChangePasswordSuccess) {
      closeChangePasswordModal();
    }
  }, [closeChangePasswordModal, isChangePasswordSuccess]);

  // get user
  const { user, setUser } = userStore();
  const { data: serverUser, isLoading } = useUser(user?.id ?? -1);

  useEffect(() => {
    if (serverUser) {
      setUser(serverUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverUser]);
  // change name
  // const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  // const {
  //   mutate: changeName,
  //   isLoading: isChangingName,
  //   isSuccess: isChangeNameSuccess,
  // } = useUpdateUser();
  // const {
  //   register: registerName,
  //   handleSubmit: handleChangeNameSubmit,
  //   reset: resetChangeNameForm,
  //   formState: { errors: changeNameErrors },
  // } = useForm<UpdateUserDTO>({ defaultValues: { name: user?.name } });
  // const onChangeNameSubmit = handleChangeNameSubmit((data: UpdateUserDTO) => {
  //   changeName(data);
  // });

  // const closeChangeNamedModal = useCallback(() => {
  //   setIsChangeNameModalOpen(false);
  //   setTimeout(() => {
  //     resetChangeNameForm();
  //   }, 300); // Wait for modal to close
  // }, [resetChangeNameForm]);

  // useEffect(() => {
  //   if (isChangeNameSuccess) {
  //     closeChangeNamedModal();
  //   }
  // }, [closeChangeNamedModal, isChangeNameSuccess]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!user) {
    return <h1>error</h1>;
  }

  return (
    <div className="mt-10 divide-y divide-gray-200 bg-white shadow sm:rounded-lg px-8 py-6 max-w-4xl">
      <div>
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">E-postadress</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">{serverUser?.email}</span>
              <span className="ml-4 flex-shrink-0"></span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Namn</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">{serverUser?.name}</span>
              <span className="ml-4 flex-shrink-0">
                {/* <button
                  onClick={() => setIsChangeNameModalOpen(true)}
                  type="button"
                  className="rounded-md bg-white font-medium text-cyan-600 hover:text-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Ändra namn
                </button> */}
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">Lösenord</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">********</span>
              <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                <button
                  type="button"
                  onClick={() => setIsChangePasswordModalOpen(true)}
                  className="rounded-md bg-white font-medium text-cyan-600 hover:text-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Byt lösenord
                </button>
              </span>
            </dd>
          </div>
        </dl>
      </div>
      <Modal
        isOpen={isChangePasswordModalOpen}
        title={"Ändra lösenord"}
        actionText="Ändra lösenord"
        onClose={closeChangePasswordModal}
        actionHandler={onChangePasswordSubmit}
        isLoading={isChangingPassword}
        loadingText="Ändrar lösenord..."
      >
        <ChangePasswordModal
          register={registerChangePassword}
          errors={changePasswordErrors}
        />
      </Modal>
      {/* <Modal
        isOpen={isChangeNameModalOpen}
        title={"Ändra namn"}
        actionText="Spara"
        onClose={closeChangeNamedModal}
        actionHandler={onChangeNameSubmit}
        isLoading={isChangingName}
        loadingText="Sparar..."
      >
        <ChangeNameModal register={registerName} errors={changeNameErrors} />
      </Modal> */}
    </div>
  );
};
