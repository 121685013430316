import { removeWhitespace } from "helpers/string";
import { TransportSätt } from "types/tomning.type";
import { z } from "zod";

const kod = z
  .string()
  .length(6, { message: "6 siffror utan mellanslag" })
  .regex(/^\d{6}$/, { message: "Felaktig avfallskod" });

const mangd = z.preprocess(
  (val) => {
    if (typeof val === "string" && /^\d*\.?\d+$/.test(val)) {
      return parseFloat(val);
    }
    return val;
  },
  z.union([
    z.number().min(0.1, { message: "minst 0.1 kg" }),
    z.string().refine((val) => false, {
      message: "Ange en korrekt vikt. Decimaltal skrivs med punkt, inte komma",
    }),
  ])
);

const wasteUuid = z.string().optional();

export const avfallSchema = z.object({ kod, mangd, wasteUuid });

// const NPositionSchema = z.preprocess((val) => {
//   // Attempt to convert string to number if it's a string
//   if (typeof val === "string") {
//     const parsed = parseFloat(val);
//     return !isNaN(parsed) ? parsed : val;
//   }
//   return val;
// }, z.number().min(1000000, { message: "1000000 - 9999999" }).max(9999999, { message: "1000000 - 9999999" }));

// const EPositionSchema = z.preprocess((val) => {
//   // Attempt to convert string to number if it's a string
//   if (typeof val === "string") {
//     const parsed = parseFloat(val);
//     return !isNaN(parsed) ? parsed : val;
//   }
//   return val;
// }, z.number().min(100000, { message: "eposition must be a number between 100000 and 9999999" }).max(9999999, { message: "eposition must be a number between 100000 and 9999999" }));

export const isValidSwerefEValue = (
  input?: string | number | null | undefined
) => {
  if (typeof input === "number") {
    return input >= 100000 && input <= 9999999;
  }

  if (typeof input === "string") {
    const parsed = parseFloat(input);
    return !isNaN(parsed) && parsed >= 1000000 && parsed <= 9999999;
  }
  return false;
};

export const isValidSwerefNValue = (
  input?: string | number | null | undefined
) => {
  if (typeof input === "number") {
    return input >= 1000000 && input <= 9999999;
  }

  if (typeof input === "string") {
    const parsed = parseFloat(input);
    return !isNaN(parsed) && parsed >= 1000000 && parsed <= 9999999;
  }
  return false;
};

const KoordinatDTOSchema = z.object({
  nposition: z.union([z.number(), z.string()]).optional(),
  eposition: z.union([z.number(), z.string()]).optional(),
  // eposition: EPositionSchema,
});

// Define the AdressDTO schema
const AdressDTOSchema = z.object({
  adressrad: z.string().optional(),
  postnummer: z.string().optional(),
  // .length(5, "5 siffror utan mellanslag")
  // .regex(/^\d+$/, "5 siffror utan mellanslag"),
});

export const OrganizationNumberSchema = z
  .string()
  .regex(/^(?:\d-*){10,}$/, {
    message: "Ange ett korrekt organisationsnummer",
  })
  .refine((value) => (value.match(/\d/g) || []).length === 10, {
    message: "Ange ett korrekt organisationsnummer",
  });

// Define the PlatsDTO schema
export const PlatsDTOSchema = z
  .object({
    kommunkod: z
      .string()
      .length(4, "Ogiltig kommunkod")
      .regex(/^\d+$/, { message: "Ogiltig kommunkod" }),
    adress: AdressDTOSchema.optional(),
    koordinat: KoordinatDTOSchema.optional(),
  })
  .refine((data) => data.adress || data.koordinat, {
    message: "Ange antingen adress eller koordinater",
  });

export const KontaktPersonNamnSchema = z
  .string()
  .refine((val) => val.replace(/\s+/g, "").length >= 2, {
    message: "Minst två tecken lång",
  })
  .refine((val) => !/\d/.test(val), {
    message: "Endast bokstäver tillåtna",
  });

export const EmailSchema = z
  .string()
  .refine((val) => z.string().email().safeParse(val).success, {
    message: "Ogiltig e-postadress.",
  });

export const VerksamhetsnamnSchema = z
  .string()
  .refine((val) => val.replace(/\s+/g, "").length >= 1, {
    message: "Får ej vara tomt",
  });

export const KontaktTelefon = z
  .string()
  .min(2, { message: "Ange kontaktpersonens telefonnummer" });

export const TransportDtoSchema = z
  .object({
    transportDocumentId: z.number().optional(),
    avfall: z
      .array(avfallSchema)
      .min(1, { message: "avfall must contain at least one item" }),
    nyInnehavare: OrganizationNumberSchema, // mottagarstation orgnr
    tidigareInnehavare: OrganizationNumberSchema, // kundens orgnr
    transportsatt: z.nativeEnum(TransportSätt),
    transportStartDatum: z.string(),
    transportStartplats: PlatsDTOSchema,
    transportSlutplats: PlatsDTOSchema,
    // verksamhet
    verksamhetensKontaktpersonNamn: KontaktPersonNamnSchema,
    verksamhetensKontaktpersonEpost: EmailSchema,
    verksamhetensKontaktpersonTelefonnummer: KontaktTelefon,
    verksamhetensNamn: VerksamhetsnamnSchema,
    verksamhetsutovare: OrganizationNumberSchema,

    startPlatsLocationType: z.enum(["address", "coordinate"]),
    slutPlatsLocationType: z.enum(["address", "coordinate"]),
    referens: z.string().optional(),
  })
  .refine(
    (data) => {
      if (
        data.startPlatsLocationType === "address" &&
        !data.transportStartplats.adress
      ) {
        return false; // Invalid if startPlatsLocationType is 'address' but adress is not provided
      }
      if (
        data.startPlatsLocationType === "coordinate" &&
        !data.transportStartplats.koordinat
      ) {
        return false; // Invalid if startPlatsLocationType is 'coordinate' but koordinat is not provided
      }
      return true; // Valid otherwise
    },
    {
      message: "Transport startplats must match the selected location type",
    }
  )
  .refine(
    (data) => {
      if (
        data.slutPlatsLocationType === "address" &&
        !data.transportSlutplats.adress
      ) {
        return false; // Invalid if startPlatsLocationType is 'address' but adress is not provided
      }
      if (
        data.slutPlatsLocationType === "coordinate" &&
        !data.transportSlutplats.koordinat
      ) {
        return false; // Invalid if startPlatsLocationType is 'coordinate' but koordinat is not provided
      }
      return true; // Valid otherwise
    },
    {
      message: "Transport startplats must match the selected location type",
    }
  );

export type TransportDTO = z.infer<typeof TransportDtoSchema>;

export const isValidPostnummer = (input = "") =>
  /^\d{5}$/.test(removeWhitespace(input));

export const isValidAdressrad = (input?: string) => {
  const trimmedInput = input?.trim() ?? "";
  return trimmedInput.length > 0;
};
