import { Transition } from "components/animations/Transition";
import { Checkbox } from "components/Checkbox";
import { Info } from "components/Info";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { Tips } from "components/Tips";
import { isEmail, isNumbers, removeWhitespace } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { config } from "react-spring";
import dismissedMessagesStore from "store/dismissedMessages.store";
import { CreateCustomerDTO } from "types/customer.type";

interface Props {
  register: UseFormRegister<CreateCustomerDTO>;
  errors: any;
  preventParentCustomer?: boolean;
}
const AddCustomer: FC<Props> = ({
  register,
  errors,
  preventParentCustomer,
}) => {
  const { customerCustomerIdentification, setCustomerCustomerIdentification } =
    dismissedMessagesStore();
  return (
    <ModalContentWrapper>
      <TextInput
        label="Företagets namn"
        name="name"
        register={register}
        validation={{ required: "Obligatoriskt fält" }}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      {!preventParentCustomer && (
        <Checkbox
          label="Uppdragsförmedlare"
          register={register}
          name="isParentCustomer"
        />
      )}
      <div>
        <Transition
          settings={{
            enter: { opacity: 1, height: 140 },
            leave: { opacity: 0, height: 0 },
            config: { ...config.slow, precision: 0.2, friction: 30 },
          }}
          isVisible={!customerCustomerIdentification}
        >
          <div className="flex-col">
            <Info
              text="Eget kundnummer används till att själv välja ett kundnummer för er kund. Exempelvis kanske ni vill använda samma nummer som kunden har i ert faktureringssystem."
              onDismissClick={() => setCustomerCustomerIdentification(true)}
            />
            <div className="h-4"></div>
          </div>
        </Transition>
        <TextInput
          label="Eget kundnummer"
          name="customerIdentification"
          register={register}
          placeholder="Valfritt"
        />
      </div>
      <TextInput
        label="Organisationsnummer"
        name="organizationNumber"
        register={register}
        validation={{ required: "Obligatoriskt fält" }}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput label="Gatuadress" name="address" register={register} />
      <TextInput
        label="Postnummer"
        name="postalCode"
        register={register}
        size="third"
        validation={{
          validate: (value: string) => {
            const trimmed = removeWhitespace(value).replace("-", "");
            return (
              !trimmed || // Ok att lämna tomt
              (isNumbers(trimmed) && trimmed.length === 5) ||
              "Ange ett korrekt postnummer"
            );
          },
        }}
        errorMessage={errors?.postalCode?.message}
      />
      <TextInput label="Stad" name="city" register={register} />
      <TextInput
        label="Epostadress för rapporter"
        name="documentationEmail"
        register={register}
        validation={{
          validate: (value: string) =>
            !value || // Ok att lämna tomt
            isEmail(value) ||
            "Denna emailadress ser ut att vara felaktig",
        }}
        errorMessage={errors?.documentationEmail?.message}
      />
      <TextInput label={"Kommunkod"} name="kommunKod" register={register} />
      <div className="space-y-4">
        <Checkbox
          label="Kunden har givit fullmakt för rapportering till Avfallsregistret"
          register={register}
          name="nvFullmakt"
        />
        <Tips
          id="a9sd98adjfosdjf0sd8fjs0doifj0ipjelksjdfldzfdsfsdoifjpsdfl"
          paragraphs={[
            "Om ni aktiverat rapportering till Naturvårdsverkets Avfallsregister i Driftjournalen kommer vi efter avslutad transport påminna er om att ombudsrapportera för denna kund.",
          ]}
        />
      </div>

      <TextArea label="Anteckningar" name="notes" register={register} />
      <div className="flex-col">
        <h4 className="text-md leading-6 font-medium text-gray-900 mb-4 pt-4">
          Kontaktperson
        </h4>
        <div className="space-y-8">
          <TextInput label="Namn" name="contactName" register={register} />
          <TextInput label="Telefon" name="contactPhone" register={register} />
          <TextInput
            label="Epostadress"
            name="contactEmail"
            register={register}
            validation={{
              validate: (value: string) =>
                !value || // Ok att lämna tomt
                isEmail(value) ||
                "Denna emailadress ser ut att vara felaktig",
            }}
            errorMessage={errors?.contactEmail?.message}
          />
        </div>
      </div>
    </ModalContentWrapper>
  );
};

export default AddCustomer;
