import create from "zustand";
import { persist } from "zustand/middleware";

const dismissedTipsStore = create<State>()(
  persist(
    (set) => ({
      dismissedTipsIds: [],
      addOne: (id: string) =>
        set((state) => ({
          ...state,
          dismissedTipsIds: [...state.dismissedTipsIds, id],
        })),
    }),
    {
      name: "dismissed-tips-storage",
      getStorage: () => localStorage,
    }
  )
);

interface State {
  dismissedTipsIds: string[];
  addOne: (id: string) => void;
}

export default dismissedTipsStore;
