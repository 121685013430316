import { classNames } from "helpers/classNames";
import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  hideOnSmallTablet?: boolean;
  isLink?: boolean;
}

export const TD: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableCellElement>,
      HTMLTableCellElement
    >
> = ({
  children,
  className,
  isFirstColumn = false,
  isLastColumn = false,
  hideOnMobile = false,
  hideOnTablet = false,
  hideOnSmallTablet = false,
  isLink = false,
  ...rest
}) =>
  isLink ? (
    <td className="z-[1] relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      {children}
    </td>
  ) : (
    <td
      {...rest}
      className={twMerge(
        classNames(
          "z-[1]",
          hideOnTablet
            ? "hidden lg:table-cell"
            : hideOnSmallTablet
            ? "hidden md:table-cell"
            : hideOnMobile && "hidden sm:table-cell",
          isFirstColumn
            ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6"
            : isLastColumn
            ? "whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium sm:pr-6 flex justify-end"
            : "whitespace-nowrap px-3 py-4 text-sm text-gray-900",
          className ?? false
        )
      )}
    >
      {children}
    </td>
  );
