import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { ChangePasswordForm } from "./AccountPage";

interface Props {
  register: UseFormRegister<ChangePasswordForm>;
  errors: any;
}
const ChangePasswordModal: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        type="password"
        register={register}
        name="oldPassword"
        label="Gammalt lösenord"
        errorMessage={errors.oldPassword?.message}
      />
      <TextInput
        type="password"
        register={register}
        name="newPassword"
        label="Nytt lösenord (minst 8 tecken)"
        validation={{
          validate: (value?: string) =>
            (value ?? "").length >= 8 ||
            "Lösenordet måste vara minst 8 tecken långt",
        }}
        errorMessage={errors.newPassword?.message}
      />
    </ModalContentWrapper>
  );
};

export default ChangePasswordModal;
