import { FinalEmailDTO, useSendFinalEmail } from "api/transportDocument.api";
import { useState } from "react";

export const useSendFinalLogic = () => {
  const { mutate, isLoading: isSendingFinalEmail } = useSendFinalEmail();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return {
    isSendFinalEmailModalOpen: isModalOpen,
    openFinalEmailModal: () => setIsModalOpen(true),
    isSendingFinalEmail,
    sendFinalEmail: (data: FinalEmailDTO) => mutate(data),
  };
};
