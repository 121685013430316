import { FC } from "react";

interface Props {
  children: React.ReactNode;
}

export const ProtocolWrapper: FC<Props> = ({ children }) => (
  <div className="sm:p-0 divide-y px-4 pb-8 sm:px-6 ">
    <dl className="space-y-6">{children}</dl>
  </div>
);
