import { FC } from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import { classNames } from "helpers/classNames";

type Props = {
  isEnabled: boolean;
  setIsEnabled: (val: boolean) => void;
  label: string;
  description?: string;
};
export const Switch: FC<Props> = ({
  label,
  description,
  isEnabled,
  setIsEnabled,
}) => {
  return (
    <HeadlessSwitch.Group as="div" className="flex items-center">
      <HeadlessSwitch
        checked={isEnabled}
        onChange={setIsEnabled}
        className={classNames(
          isEnabled ? "bg-cyan-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isEnabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </HeadlessSwitch>
      <HeadlessSwitch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {/* <span className="text-sm text-gray-500">(Save 10%)</span> */}
      </HeadlessSwitch.Label>
    </HeadlessSwitch.Group>
  );
};
