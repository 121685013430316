export const get5YInspectionLabel = (label: string) => {
  switch (label) {
    case "id":
      return "ID";
    case "inspectionDate":
      return "Kontrolldatum";
    case "separator":
      return "Objekt";
    case "separatorId":
      return "Objekt ID";
    case "user":
      return "Namn behörig kontrollant";
    case "referenceNumber":
      return "Referensnummer";
    case "strengthInnerAndOuter":
      return "Hållfasthetsmässigt inre- och yttre skick";
    case "innerPlaque":
      return "Inre beläggningar (om sådana finns)";
    case "conditionInnerParts":
      return "Skick inbyggda delar";
    case "conditionElectricParts":
      return "Skick / funktion elektriska enheter / installationer";
    case "flottarFunctionality":
      return "Kontroll av inställning / funktion för automatisk avstängningsenhet";
    case "systemDensity":
      return "Systemets täthet";
    case "localRequirements":
      return "Lokala krav";
    case "requirementsFulfilled":
      return "Avskiljaren uppfyller ställda krav";
    case "remarks":
      return "Anmärkningar";
    case "equipmentUsed":
      return "ID-nr på kontrollutrustning";
    case "dimensioning":
      return "Dimensionering enligt EN 858:2 eller SNV 1975:10";
    case "oasWastewater":
      return "OAS kopplad till spillvatten";
    case "oasDaywater":
      return "OAS kopplad till dagvatten";
    case "otherControl":
      return "Övrig kontroll som behöver utföras";
    case "remarksNonAccredation":
      return "Noteringar";
    case "waterIsRefilled":
      return "Avskiljaren har fyllts till driftnivå";
    case "flottorIsUp":
      return "Automatiska avstängningsventilen återställd i upplyft läge efter återfyllning";
    case "flottarDensitetUppmatt":
      return "Flottör densitet (uppmätt)";
    case "hasTomningOlja":
      return "Olja";
    case "hasTomningSlam":
      return "Slam";
    case "hasTomningVattenfas":
      return "Vattenfas";
    case "befintligVattenfasAterford":
      return "Befintlig vattenfas återförd efter genomförande";
    case "hasBefintligVattenfasByttsMotVatten":
      return "Befintlig vattenfas har bytts ut mot färskvatten";
    default:
      return "";
  }
};
