import { ModalContentWrapper } from "components/ModalContentWrapper";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { TomningReminderMonths } from "types/separator/separator.type";

interface Props {
  register: UseFormRegister<TomningReminderMonths>;
}

export const TomningMonthsForm: FC<Props> = ({ register }) => {
  return (
    <ModalContentWrapper>
      <CheckboxGroup
        label="Ange månader som objektet ska tömmas"
        register={register}
        items={[
          {
            label: "Januari",
            name: "january",
          },
          {
            label: "Februari",
            name: "february",
          },
          {
            label: "Mars",
            name: "march",
          },
          {
            label: "April",
            name: "april",
          },
          {
            label: "Maj",
            name: "may",
          },
          {
            label: "Juni",
            name: "june",
          },
          {
            label: "Juli",
            name: "july",
          },
          {
            label: "Augusti",
            name: "august",
          },
          {
            label: "September",
            name: "september",
          },
          {
            label: "Oktober",
            name: "october",
          },
          {
            label: "November",
            name: "november",
          },
          {
            label: "December",
            name: "december",
          },
        ]}
      />
    </ModalContentWrapper>
  );
};

interface CheckboxGroupProps {
  register: UseFormRegister<any>;
  label: string;
  items: { label: string; name: string }[];
}
const CheckboxGroup: FC<CheckboxGroupProps> = ({ register, label, items }) => (
  <fieldset>
    <legend className="sr-only">{label}</legend>
    <div className="flex text-sm font-medium text-gray-700" aria-hidden="true">
      {label}
    </div>
    <div className="mt-3 space-y-2.5">
      {items.map((item) => (
        <div key={item.name} className="flex items-start">
          <div className="flex items-center h-5">
            <input
              id={item.name}
              type="checkbox"
              {...register(item.name)}
              className="focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={item.name} className="font-medium text-gray-700">
              {item.label}
            </label>
          </div>
        </div>
      ))}
    </div>
  </fieldset>
);
