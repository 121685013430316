import { ModalContentWrapper } from "components/ModalContentWrapper";
import { FC, useEffect, useState } from "react";
import {
  TransportDocument,
  TransportDocumentGroup,
  getWasteFromTransportDocuments,
} from "types/transportDocument.type";
import { ModalLabel } from "components/ModalLabel";
import PrimaryButton from "components/buttons/PrimaryButton";
import { isNumber } from "helpers/number";
import { TextInputStupid } from "components/TextInputStupid";
import { FormWaste, distributeWeight } from "./helpers";
import { classNames } from "helpers/classNames";
import { CheckCircleIcon, LightBulbIcon } from "@heroicons/react/24/solid";
import dismissedMessagesStore from "store/dismissedMessages.store";
import Divider from "components/Divider";

interface Props {
  group?: TransportDocumentGroup;
  tdsToWeigh: TransportDocument[];
  actualTotalWeight?: string;
  onSaveClick: (items: FormWaste[]) => void;
  onClose: () => void;
}

export const Step2Form: FC<Props> = ({
  group,
  tdsToWeigh = [],
  actualTotalWeight,
  onSaveClick,
  onClose,
}) => {
  const { weightDiff, setWeightDiff } = dismissedMessagesStore();

  const [formWasteList, setFormWasteList] = useState<FormWaste[]>([]);

  useEffect(() => {
    if (formWasteList.length === 0 && tdsToWeigh.length > 0) {
      setFormWasteList(
        getWasteFromTransportDocuments(tdsToWeigh).map((waste) => ({
          id: waste.id,
          transportDocumentId: waste.transportDocument.id,
          weight: waste.weightPreliminary.toString(),
          weightOriginal: waste.weightPreliminary.toString(),
          label: `Avfall ${waste.wasteCode.code}`,
          // label: `${waste.wasteCode.codePretty} - ${truncateString(
          //   waste.wasteCode.description
          // )}`,
        }))
      );
    }
  }, [formWasteList, tdsToWeigh]);

  const [diff, setDiff] = useState<number>(0);
  useEffect(() => {
    const currentInputFieldSum = formWasteList.reduce(
      (sum, item) => (sum += isNumber(item.weight) ? +item.weight : 0),
      0
    );
    setDiff(currentInputFieldSum - +(actualTotalWeight ?? 0));
  }, [actualTotalWeight, formWasteList]);

  if (!group) {
    return null;
  }

  return (
    <ModalContentWrapper>
      {/* <div>
        <ModalLabel>Preliminär vikt</ModalLabel>
        <span className="mt-2">{totalPreliminaryWeight}</span>
      </div> */}

      {!weightDiff && (
        <div className="rounded-md bg-cyan-50 p-4">
          <div className="flex mb-3 items-center">
            <LightBulbIcon
              className="h-5 w-5 text-cyan-400 mr-2"
              aria-hidden="true"
            />
            <span className="text-cyan-800 text-sm font-semibold">Tips!</span>
          </div>
          <p className="text-sm text-cyan-700 mb-4">
            Tryck ”Fördela” för att justera vikten för respektive avfall
            automatiskt. Vägd vikt för respektive avfall kommer då att justeras
            proportionellt utifrån angivna uppskattade vikter.
          </p>
          <p className="text-sm text-cyan-700 mb-4">
            Exempel: Avfall ”A” har en uppskattad vikt på 7000 kg och avfall ”B”
            har en uppskattad vikt på 3000 kg. Fördelningen på lasten är alltså
            70 % (avfall ”A”) och 30% (avfall ”B”). Vid invägning noteras
            däremot en total vikt 11 000 kg, alltså behöver överskjutande 1000
            kg fördelas mellan de olika avfallen. Vid automatisk fördelning
            kommer då 700 kg (70% av 1000 kg) läggas till på avfall A, och 300
            kg (30% av 1000 kg) läggas till på avfall B. Om invägd vikt skulle
            vara lägre än uppskattad vikt kommer vikterna istället reduceras
            utifrån samma beräkningsmodell.
          </p>
          {/* <p className="text-sm text-cyan-700 mb-4">
            Till höger om textfälten står den vikt du uppskattade vid
            hämtningen. Klicka på knappen för att återställa fältet till det
            värdet.
          </p> */}

          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setWeightDiff(true)}
              className="ml-3 rounded-md bg-cyan-50 px-2 py-1.5 text-sm font-medium text-cyan-800 hover:bg-cyan-100 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 focus:ring-offset-cyan-50"
            >
              Visa inte igen
            </button>
          </div>
        </div>
      )}

      <div>
        <ModalLabel>Vägd vikt</ModalLabel>
        <span className="mt-2">{`${actualTotalWeight} kg`}</span>
      </div>

      <div className="flex justify-between items-end">
        <div>
          <ModalLabel>
            {diff > 0 ? "Att dra från " : "Att lägga till"}
          </ModalLabel>
          <span
            className={classNames(
              "mt-2 text-base flex items-center",
              diff === 0 ? "text-gray-800" : "text-red-600"
            )}
          >
            {`${Math.abs(diff)} kg`}
            {diff === 0 && (
              <CheckCircleIcon className="text-cyan-500 h-6 w-6 ml-2" />
            )}
          </span>
        </div>
        {diff !== 0 && (
          <div>
            <PrimaryButton
              size="medium"
              onClick={() =>
                setFormWasteList((prev) =>
                  distributeWeight(
                    prev,
                    !!actualTotalWeight ? +actualTotalWeight : 0
                  )
                )
              }
            >
              Fördela
            </PrimaryButton>
          </div>
        )}
      </div>

      {formWasteList.map((item) => (
        <div key={item.id}>
          <Divider />
          <div className="flex items-center justify-between relative mt-6">
            <div className="flex-1">
              <TextInputStupid
                defaultValue={item.weight}
                label={item.label}
                lightLabel
                appendix="kg"
                size="third"
                value={item.weight}
                onChange={(val: string) => {
                  if (!isNumber(val) && !!val) return;
                  setFormWasteList((prev) => [
                    ...prev.map((x) =>
                      x.id === item.id
                        ? {
                            ...x,
                            weight: val,
                          }
                        : x
                    ),
                  ]);
                }}
              />
            </div>
            <span className="absolute right-1 top-8">
              <span
                className="text-base text-gray-500"
                onClick={() =>
                  setFormWasteList((prev) => [
                    ...prev.map((x) =>
                      x.id === item.id
                        ? {
                            ...x,
                            weight: item.weightOriginal,
                          }
                        : x
                    ),
                  ])
                }
              >{`(Prel. ${item.weightOriginal} kg)`}</span>
            </span>
          </div>
        </div>
      ))}

      <div>
        <div className="w-full border-t border-gray-200 mt-4 sm:mt-0" />
        <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense sm:p-4 bg-white mt-8 sm:mt-0">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300 sm:col-start-2 sm:text-sm"
            onClick={() => onSaveClick(formWasteList)}
            disabled={diff !== 0}
          >
            {false && (
              <svg
                className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}

            {"Spara"}
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={onClose}
          >
            {"Avbryt"}
          </button>
        </div>
      </div>
    </ModalContentWrapper>
  );
};
