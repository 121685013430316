/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "components/Link";
import { appRoutes, RouteLabels, RouteUrl } from "routes.type";
import menuStore from "store/menu.store";
import TransportButton from "./buttons/TransportButton";
import {
  ArchiveBoxArrowDownIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";
import TimeReportButton from "./buttons/TimeReportButton";

interface BreadCrumb {
  label: string;
  to: string;
}
const Header: FC<{
  transportDocumentCount: number;
  waitingForTimeReportCount?: number;
  openTimeReportModal: () => void;
}> = ({
  transportDocumentCount,
  waitingForTimeReportCount,
  openTimeReportModal,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [breadCrumbs, setBreadCrumbs] = React.useState<BreadCrumb[]>([]);
  const {
    currentCustomerName,
    setCurrentCustomerName,
    separator,
    inspection,
    currentWasteStationName,
    setCurrentWasteStationName,
  } = menuStore();

  const isOpenTransportDocumentPage = pathname.includes(
    `${RouteUrl.OpenTransportDocuments}`
  );

  const [iconType, setIconType] = useState<
    "object" | "customer" | "wastestation" | undefined
  >();

  useEffect(() => {
    const url = pathname.replace("/app/", "");
    const pages = url.split("/");
    const newBreadCrumbs: BreadCrumb[] = [];
    if (pages?.length > 0) {
      let first = pages[0];
      switch (first) {
        case appRoutes.customers.path:
          newBreadCrumbs.push({
            label: appRoutes.customers.label,
            to: appRoutes.customers.fullPath,
          });

          if (pages.length > 1 && currentCustomerName) {
            newBreadCrumbs.push({
              label: currentCustomerName,
              to: `${appRoutes.customers.fullPath}/${pages[1]}`,
            });
            if (pages.length === 2) {
              setIconType("customer");
            }
            if (pages.length > 2) {
              newBreadCrumbs.push({
                label: separator,
                to: `${appRoutes.customers.fullPath}/${pages[1]}/${pages[2]}`,
              });
              if (pages.length === 3) {
                setIconType("object");
              }
              if (pages.length > 3) {
                if (pages[3] === RouteUrl.FiveYearsInspection) {
                  newBreadCrumbs.push({
                    label: "5-årsbesiktning",
                    to: `${appRoutes.customers.fullPath}/${pages[1]}/${pages[2]}/${pages[3]}/${inspection}`,
                  });
                } else if (pages[3] === RouteUrl.SixMonthsInspection) {
                  newBreadCrumbs.push({
                    label: "6-månaderskontroll",
                    to: `${appRoutes.customers.fullPath}/${pages[1]}/${pages[2]}/${pages[3]}/${inspection}`,
                  });
                } else if (pages[3] === RouteUrl.Tomning) {
                  newBreadCrumbs.push({
                    label: "Tömning",
                    to: `${appRoutes.customers.fullPath}/${pages[1]}/${pages[2]}/${pages[3]}/${inspection}`,
                  });
                }
              }
            }
          }
          if (pages.length < 2 || pages.length > 3) {
            setIconType(undefined);
          }
          break;
        case appRoutes.organization.path:
          newBreadCrumbs.push({
            label: appRoutes.organization.label,
            to: appRoutes.organization.fullPath,
          });
          setIconType(undefined);
          break;
        case appRoutes.schedule.path:
          newBreadCrumbs.push({
            label: appRoutes.schedule.label,
            to: appRoutes.schedule.fullPath,
          });
          setIconType(undefined);
          break;
        case appRoutes.userSchedule.path:
          newBreadCrumbs.push({
            label: appRoutes.userSchedule.label,
            to: appRoutes.userSchedule.fullPath,
          });
          setIconType(undefined);
          break;
        case appRoutes.account.path:
          newBreadCrumbs.push({
            label: appRoutes.account.label,
            to: appRoutes.account.fullPath,
          });
          setIconType(undefined);
          break;
        case appRoutes.inspections.path:
          newBreadCrumbs.push({
            label: appRoutes.inspections.label,
            to: appRoutes.inspections.fullPath,
          });
          setIconType(undefined);
          break;
        case appRoutes.dashboard.path:
          newBreadCrumbs.push({
            label: appRoutes.dashboard.label,
            to: appRoutes.dashboard.fullPath,
          });
          setIconType(undefined);
          break;
        // case appRoutes.changelog.path:
        //   newBreadCrumbs.push({
        //     label: appRoutes.changelog.label,
        //     to: appRoutes.changelog.fullPath,
        //   });
        //   setIconType(undefined);
        //   break;
        case appRoutes.feedback.path:
          newBreadCrumbs.push({
            label: appRoutes.feedback.label,
            to: appRoutes.feedback.fullPath,
          });
          setIconType(undefined);
          break;
        case appRoutes.wasteStations.path: {
          newBreadCrumbs.push({
            label: appRoutes.wasteStations.label,
            to: appRoutes.wasteStations.fullPath,
          });
          if (pages.length > 1) {
            newBreadCrumbs.push({
              label: currentWasteStationName,
              to: `${appRoutes.wasteStations.fullPath}/${pages[1]}`,
            });
            setIconType("wastestation");
          } else {
            setIconType(undefined);
          }

          break;
        }
        case appRoutes.avfallsregistret.path: {
          setIconType(undefined);
          newBreadCrumbs.push({
            label: appRoutes.avfallsregistret.label,
            to: appRoutes.avfallsregistret.fullPath,
          });
          if (pages.length > 1) {
            if (pages[1].includes(RouteUrl.RapporteraTransportPlanering)) {
              newBreadCrumbs.push({
                label: RouteLabels.RapporteraTransportPlanering,
                to: `${appRoutes.avfallsregistret.fullPath}/${RouteUrl.RapporteraTransportPlanering}`,
              });
            } else if (pages[1].includes(RouteUrl.RapporteraTransport)) {
              newBreadCrumbs.push({
                label: RouteLabels.RapporteraTransport,
                to: `${appRoutes.avfallsregistret.fullPath}/${RouteUrl.RapporteraTransport}`,
              });
            }
          }

          break;
        }
        case appRoutes.openTransportDocuments.path:
          newBreadCrumbs.push({
            label: appRoutes.openTransportDocuments.label,
            to: appRoutes.openTransportDocuments.fullPath,
          });
          setIconType(undefined);
          break;
        default:
          break;
      }
    }
    if (
      pages?.length < 2 ||
      (pages[0] !== appRoutes.customers.path && currentCustomerName)
    ) {
      setCurrentCustomerName("");
    }
    if (
      pages?.length < 2 ||
      (pages[0] !== appRoutes.wasteStations.path && currentWasteStationName)
    ) {
      setCurrentWasteStationName("");
    }
    setBreadCrumbs(newBreadCrumbs);
    document.title =
      (newBreadCrumbs.length > 0
        ? newBreadCrumbs[newBreadCrumbs.length - 1].label
        : "") + " - Driftjournalen";
  }, [
    pathname,
    currentCustomerName,
    setCurrentCustomerName,
    currentWasteStationName,
    setCurrentWasteStationName,
    separator,
    inspection,
  ]);

  const header =
    breadCrumbs.length > 0 ? breadCrumbs[breadCrumbs.length - 1].label : "";

  return (
    <header className="mt-6 sm:m-0">
      <div className="mr-auto max-w-[1740px] px-4 sm:px-6 lg:px-8">
        <div>
          <div>
            {/* <nav className="sm:hidden" aria-label="Back">
              <a
                href="#"
                className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200"
              >
                <ChevronLeftIcon
                  className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-500"
                  aria-hidden="true"
                />
                Back
              </a>
            </nav> */}
            {/* Breadcrumbs */}
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-2">
                {breadCrumbs.map((breadCrumb, index) => (
                  <li key={breadCrumb.to}>
                    <div className={index > 0 ? "flex items-center" : "flex"}>
                      {index > 0 && (
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      <Link
                        to={breadCrumb.to}
                        className={
                          index > 0
                            ? "ml-2 text-sm font-medium text-gray-500 hover:text-gray-700 flex items-center"
                            : "text-sm font-medium text-gray-500 hover:text-gray-700 flex items-center"
                        }
                      >
                        {breadCrumb.label}
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
          <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl leading-7 sm:truncate sm:text-xl font-medium sm:tracking-tight flex justify-between items-center">
                <div className="flex items-center">
                  {/* {iconType === "customer" && (
                    <FaceSmileIcon className="h-5 w-auto text-gray-600 mr-1" />
                  )} */}
                  {iconType === "object" && (
                    <CircleStackIcon className="h-5 w-auto text-gray-500 mr-1" />
                  )}
                  {iconType === "wastestation" && (
                    <ArchiveBoxArrowDownIcon className="h-5 w-auto text-gray-600 mr-1" />
                  )}
                  {header}
                </div>
                <div className="block space-x-4">
                  {(waitingForTimeReportCount ?? 0) > 0 && (
                    <TimeReportButton
                      onClick={openTimeReportModal}
                    >{`Tidsrapport (${waitingForTimeReportCount})`}</TimeReportButton>
                  )}
                  {transportDocumentCount > 0 &&
                    !isOpenTransportDocumentPage && (
                      <TransportButton
                        onClick={() =>
                          navigate(`/app/${RouteUrl.OpenTransportDocuments}`)
                        }
                      >{`Last (${transportDocumentCount})`}</TransportButton>
                    )}
                </div>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
