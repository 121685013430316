import { SeparatorQueryKey, useUpdateSeparator } from "api/separator.api";
import { Separator } from "types/separator/separator.type";
import { useQueryClient } from "react-query";
import { useNotification } from "hooks/useNotification";

export const useToggleAvtal = (separator: Separator) => {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const { mutate: updateSeparator } = useUpdateSeparator(separator?.id ?? -1, {
    onMutate: async (newSeparator: Separator) => {
      // Optimistically update to the new value
      await queryClient.cancelQueries([
        SeparatorQueryKey.GetOne,
        newSeparator.id,
      ]);
      const previousSeparator = queryClient.getQueryData([
        SeparatorQueryKey.GetOne,
        newSeparator.id,
      ]);
      queryClient.setQueryData(
        [SeparatorQueryKey.GetOne, newSeparator.id],
        newSeparator
      );
      return { previousSeparator };
    },
    onSuccess: (data: Separator) => {
      queryClient.invalidateQueries([SeparatorQueryKey.GetOne, data.id]);
    },
    onError: (
      error: any,
      __: any,
      context: { previousSeparator: Separator }
    ) => {
      queryClient.setQueryData(
        [SeparatorQueryKey.GetOne, context.previousSeparator.id],
        context.previousSeparator
      );
      notification.error("Något gick fel på servern, testa igen.");
    },
  });

  const setAvtalFemarskontroll = (newValue: boolean) =>
    updateSeparator({
      ...separator,
      avtalFemarskontroll: newValue,
    });

  const setAvtalHalvarsKontroll = (newValue: boolean) =>
    updateSeparator({
      ...separator,
      avtalHalvarsKontroll: newValue,
    });

  const setAvtalSlamtomning = (newValue: boolean) =>
    updateSeparator({
      ...separator,
      avtalSlamtomning: newValue,
    });

  return {
    setAvtalFemarskontroll,
    setAvtalHalvarsKontroll,
    setAvtalSlamtomning,
  };
};
