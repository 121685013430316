import { ActionMenu } from "../dashboard/ActionMenu";
import {
  ArrowUturnLeftIcon,
  DocumentMagnifyingGlassIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { Tomning, isReadyForDelivery } from "types/tomning.type";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { Link } from "components/Link";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
// import { useDeleteTomning } from "api/tomning.api";
// import { Assignment } from "types/assignment.type";
// import { useNavigate } from "react-router-dom";
// import { useIsAdmin } from "store/user.store";
// import { RouteUrl } from "routes.type";
import { TransportDocumentStatusTag } from "components/TransportDocumentStatusTag";
import { AssignmentTypeButton } from "components/AssignmentTypeTag";
import { AssignmentType } from "enums/AssignmentType.enum";
import SecondaryButton from "components/buttons/SecondaryButton";

interface Props {
  openResetModal: () => void;
  sendPreliminary: () => void;
  openAssignmentInfoModal: () => void;
  tomning: Tomning;
  isLocked: boolean;
  openChangeUserModal: () => void;
  isSigned: boolean;
  isWeighed: boolean;
  toggleEditMode: () => void;
  isEditMode: boolean;
  // assignment: Assignment;
}
// Behöver chaufför signera även hos mottagarstationen?

export const Header: FC<Props> = ({
  openResetModal,
  sendPreliminary,
  openAssignmentInfoModal,
  tomning,
  isLocked,
  openChangeUserModal,
  isSigned,
  isWeighed,
  toggleEditMode,
  isEditMode,
  // assignment,
}) => {
  // const isAdmin = useIsAdmin();
  // const navigate = useNavigate();
  // const { mutate: deleteTomning } = useDeleteTomning(tomning.id, {
  //   onSuccess: () => {
  //     if (!!assignment.fiveYearsInspection) {
  //       navigate(
  //         getLinkToFiveYearsInspectionPage(
  //           assignment.customerId,
  //           assignment.separatorId,
  //           assignment.fiveYearsInspection.id
  //         )
  //       );
  //     } else if (!!assignment.sixMonthsInspection) {
  //       navigate(
  //         getLinkToSixMonthsInspectionPage(
  //           assignment.customerId,
  //           assignment.separatorId,
  //           assignment.sixMonthsInspection.id
  //         )
  //       );
  //     } else {
  //       navigate(
  //         `${RouteUrl.App}/${
  //           isAdmin ? RouteUrl.Schedule : RouteUrl.UserSchedule
  //         }`
  //       );
  //     }
  //   },
  // });
  return (
    <div className="px-4 py-5 sm:px-6 border-b border-b-gray-100">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-8">
        <div className="sm:col-span-1">
          <h3 className="text-lg leading-6 py-1 font-medium text-gray-900 flex justify-between items-center relative">
            <span className="flex items-center space-x-2">
              {/* <span>Tömning</span> */}
              <TransportDocumentStatusTag
                status={tomning.transportDocument.status}
              />
              {/* {isLocked && <LockedBadge text="Låst" />} */}
              {/* <InspectionStatusTag status={InspectionStatus.Created} /> */}
            </span>
            <SecondaryButton size="small" onClick={toggleEditMode}>
              {isEditMode ? "Avsluta inmatningsläge" : "Inmatningsläge"}
            </SecondaryButton>
            <div className="flex items-center space-x-6">
              {!!tomning.assignment.fiveYearsInspection && (
                <Link
                  to={getLinkToFiveYearsInspectionPage(
                    tomning.assignment.customerId,
                    tomning.assignment.separatorId,
                    tomning.assignment.fiveYearsInspection.id
                  )}
                  className="text-sm text-cyan-700 hover:text-cyan-800 font-medium select-none"
                >
                  <AssignmentTypeButton
                    type={AssignmentType.FiveYearInspection}
                  />
                </Link>
              )}

              {!!tomning.assignment.sixMonthsInspection && (
                <Link
                  to={getLinkToSixMonthsInspectionPage(
                    tomning.assignment.customerId,
                    tomning.assignment.separatorId,
                    tomning.assignment.sixMonthsInspection.id
                  )}
                  className="text-sm text-cyan-700 hover:text-cyan-800 font-medium select-none"
                >
                  <AssignmentTypeButton
                    type={AssignmentType.SixMonthsInspection}
                  />
                </Link>
              )}

              <ActionMenu
                items={[
                  {
                    label: "Skicka preliminärt",
                    onClick: sendPreliminary,
                    icon: (
                      <EnvelopeIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ),
                    isVisible: isReadyForDelivery(tomning) && !isSigned,
                  },
                  // {
                  //   label: "Skicka",
                  //   onClick: openSendFinalModal,
                  //   icon: (
                  //     <EnvelopeIcon
                  //       className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  //       aria-hidden="true"
                  //     />
                  //   ),
                  //   isVisible: isSigned,
                  // },
                  {
                    label: "Uppdrag",
                    onClick: openAssignmentInfoModal,
                    icon: (
                      <DocumentMagnifyingGlassIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ),
                    isVisible: true,
                  },
                  {
                    label: "Byt chaufför",
                    onClick: openChangeUserModal,
                    icon: (
                      <UsersIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ),
                    isVisible: true && !isSigned,
                  },
                  {
                    label: "Återställ tömning",
                    onClick: openResetModal,
                    icon: (
                      <ArrowUturnLeftIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ),
                    isVisible: !isLocked && !isSigned && !isWeighed,
                  },
                  // {
                  //   label: "Ta bort tömning",
                  //   onClick: () => deleteTomning(),
                  //   icon: (
                  //     <TrashIcon
                  //       className="mr-3 h-5 w-5 text-rose-700 group-hover:text-rose-800"
                  //       aria-hidden="true"
                  //     />
                  //   ),
                  //   isVisible:
                  //     tomning.assignment.assignmentStatus ===
                  //       AssignmentStatus.Created &&
                  //     !tomning.assignment.includeTomning, // Tanken här är att man inte ska kunna ta bort en tömning om admin sagt att den ska vara med
                  //   isDanger: true,
                  // },
                ].filter(({ isVisible }) => isVisible)}
              />
            </div>
          </h3>
        </div>
      </dl>
    </div>
  );
};
