import { Company } from "api/company.api";
import { Customer } from "./customer.type";
import { Email } from "./email.type";
import { TransportDocumentStatus } from "./enums";
import { Tomning } from "./tomning.type";
import { User } from "./user.type";
import { Vehicle, VehicleType } from "./vehicle.type";
import { Waste } from "./waste.type";
import { WasteStation } from "./wasteStation.type";
import { NvRapporteringStatus } from "enums/NvRapporteringStatus.enum";

export enum HasSentStatus {
  Sent = "Sent",
  NotSent = "NotSent",
  Ignored = "Ignored",
  SentWithOtherInspection = "SentWithOtherInspection",
}

export type TransportDocument = {
  id: number;
  customer: Customer;
  customerId: number;
  company: Company;
  user: User;
  transportDate: string;
  wasteStation?: WasteStation;
  vehicle?: Vehicle;
  wasteList: Waste[];
  tomning: Tomning;
  driverSignature: string;
  customerSignature: string;
  wasteStationSignature: string;
  hasSentPreliminaryEmail: boolean;
  status: TransportDocumentStatus;
  emailToCustomerStatus: HasSentStatus;
  emailToWasteStationStatus: HasSentStatus;
  emailToParentCustomerStatus: HasSentStatus;
  timeOfDeparture: string;
  emails: Email[];
  transportRapporteringStatus: NvRapporteringStatus;
  transportPlaneringRapporteringStatus: NvRapporteringStatus;
};

//

export type TransportDocumentGroup = {
  wasteStation: WasteStation;
  vehicles: {
    vehicle: Vehicle;
    transportDocuments: TransportDocument[];
  }[];
};

export const groupTransportDocuments = (
  transportDocuments: TransportDocument[]
): TransportDocumentGroup[] => {
  // Create a map of groups
  const groupsMap: { [key: string]: TransportDocumentGroup } = {};

  // Populate the map
  for (const document of transportDocuments) {
    if (!document.wasteStation || !document.vehicle)
      throw Error("Transport document is missing waste station or vehicle");
    const wasteStationKey = document.wasteStation.id;
    const vehicleKey = document.vehicle.id;

    // If this waste station hasn't been added to the map yet, add it
    if (!groupsMap[wasteStationKey]) {
      groupsMap[wasteStationKey] = {
        wasteStation: document.wasteStation,
        vehicles: [],
      };
    }

    // Find the vehicle group for the current vehicle
    let vehicleGroup = groupsMap[wasteStationKey].vehicles.find(
      (vehicle) => vehicle.vehicle.id === vehicleKey
    );

    // If this vehicle hasn't been added to the group yet, add it
    if (!vehicleGroup) {
      vehicleGroup = {
        vehicle: document.vehicle,
        transportDocuments: [],
      };

      groupsMap[wasteStationKey].vehicles.push(vehicleGroup);
    }

    // Add the document to the vehicle group
    vehicleGroup.transportDocuments.push(document);
  }

  // Transform the map into an array and return it
  return Object.values(groupsMap);
};

// check if a group has a transport document with status === Loaded
export const hasLoadedTransportDocument = (
  group: TransportDocumentGroup
): boolean => {
  for (const vehicle of group.vehicles) {
    for (const document of vehicle.transportDocuments) {
      if (document.status === TransportDocumentStatus.Loaded) return true;
    }
  }
  return false;
};

// check if a group has a transport document with status === Loaded
export const hasWeighedTransportDocument = (
  group: TransportDocumentGroup
): boolean => {
  for (const vehicle of group.vehicles) {
    for (const document of vehicle.transportDocuments) {
      if (document.status === TransportDocumentStatus.Weighed) return true;
    }
  }
  return false;
};

// check if a group has a transport document with status === Loaded
export const getWeighedTransportDocuments = (
  group: TransportDocumentGroup
): TransportDocument[] => {
  const result = [];
  for (const vehicle of group.vehicles) {
    for (const document of vehicle.transportDocuments) {
      if (document.status === TransportDocumentStatus.Weighed)
        result.push(document);
    }
  }
  return result;
};

export type TransportDocumentDTO = {
  customerId?: number | null;
};

export type UpdateTransportDocumentDTO = {
  driverSignature: string;
  customerSignature: string;
  wasteStationSignature: string;
  wasteStationId?: number;
  vehicleId?: number;
  transportRapporteringStatus?: NvRapporteringStatus;
  transportPlaneringRapporteringStatus?: NvRapporteringStatus;
  timeOfDeparture?: string;
  transportDate?: string;
};

export const getEmtptyUpdateTransportDocumentDTO =
  (): UpdateTransportDocumentDTO => ({
    driverSignature: "",
    customerSignature: "",
    wasteStationSignature: "",
    wasteStationId: -1,
  });

export const isLocked = ({
  customerSignature,
  driverSignature,
}: TransportDocument) => !!customerSignature || !!driverSignature;

export const sumPreliminaryWeight = (td: TransportDocument) =>
  td.wasteList.reduce((sum, waste) => sum + +waste.weightPreliminary, 0);

export const sumFinalWeight = (td: TransportDocument) =>
  td.wasteList.reduce((sum, waste) => sum + +waste.weightFinal, 0);

export const sumPreliminaryTotalWeight = (tds: TransportDocument[]) =>
  tds.reduce((sum, td) => sum + sumPreliminaryWeight(td), 0);

export const activeTransportDocuments = (
  truckSelected: boolean,
  trailerSelected: boolean,
  group?: TransportDocumentGroup
) => {
  if (!group) return [];
  if (group.vehicles.length === 0) throw Error("No vehicles in group");
  if (group.vehicles.length === 1)
    return group.vehicles[0].transportDocuments.filter(
      (td) => td.status === TransportDocumentStatus.Loaded
    );

  const result = [];
  if (truckSelected) {
    const truck = group.vehicles.find(
      (v) => v.vehicle.type === VehicleType.Truck
    );
    if (!truck) throw Error("No truck in group");
    result.push(...truck.transportDocuments);
  }
  if (trailerSelected) {
    const trailer = group.vehicles.find(
      (v) => v.vehicle.type === VehicleType.Trailer
    );
    if (!trailer) throw Error("No trailer in group");
    result.push(...trailer.transportDocuments);
  }
  return result.filter((td) => td.status === TransportDocumentStatus.Loaded);
};

export const countTransportDocumentInGroups = (
  groups?: TransportDocumentGroup[]
) => {
  if (!groups) return 0;
  let count = 0;
  for (const group of groups) {
    for (const vehicle of group.vehicles) {
      count += vehicle.transportDocuments.length;
    }
  }
  return count;
};

export const getWasteFromTransportDocuments = (
  transportDocuments: TransportDocument[]
): Waste[] => {
  const result: Waste[] = [];
  for (const document of transportDocuments) {
    const wasteList = document.wasteList.map((waste) => ({
      ...waste,
      transportDocument: document,
    }));
    result.push(...wasteList);
  }
  return result;
};

export const getVehicleFromGroups = (
  id: number,
  groups: TransportDocumentGroup[]
): Vehicle => {
  for (const group of groups) {
    for (const vehicle of group.vehicles) {
      if (vehicle.vehicle.id === id) return vehicle.vehicle;
    }
  }
  throw Error("No vehicle with id " + id);
};
