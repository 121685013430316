import { InspectionResult, JaNejStatus, KontrollStatus } from "types/enums";

export const INSPECTION_RESULT_RADIO_ITEMS = [
  { label: InspectionResult.Approved, value: InspectionResult.Approved },
  {
    label: InspectionResult.NotApproved,
    value: InspectionResult.NotApproved,
    isNegative: true,
  },
];

export const JA_NEJ_RADIO_ITEMS = [
  { label: JaNejStatus.Ja, value: JaNejStatus.Ja },
  {
    label: JaNejStatus.Nej,
    value: JaNejStatus.Nej,
  },
];

export const JA_NEJ_FINNEJ_RADIO_ITEMS = [
  { label: JaNejStatus.Ja, value: JaNejStatus.Ja },
  {
    label: JaNejStatus.Nej,
    value: JaNejStatus.Nej,
  },
];

export const KONTROLL_STATUS_RADIO_ITEMS = [
  { label: KontrollStatus.Ja, value: KontrollStatus.Ja },
  { label: KontrollStatus.Nej, value: KontrollStatus.Nej },
  { label: KontrollStatus.FinnsEj, value: KontrollStatus.FinnsEj },
];
