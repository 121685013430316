import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import { Table, THead, TR, TH, TBody, TD } from "components/table";
import { appRoutes } from "routes.type";
import { PageTransition } from "components/animations/SnapUp";
import { AddCompanyDTO, Company, useCompanyList } from "api/company.api";
import PrimaryButton from "components/buttons/PrimaryButton";
import { useAddCompanyLogic } from "hooks/useAddCompanyLogic";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";

export const CompanyListPage = () => {
  const navigate = useNavigate();

  const { data: companies, isLoading } = useCompanyList();

  const { form, mutation, openModal, closeModal, isModalOpen } =
    useAddCompanyLogic();

  const onSubmit = form.handleSubmit((data: AddCompanyDTO) =>
    mutation.mutate(data)
  );

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) {
      navigate(`${appRoutes.customers.fullPath}/${mutation.data.id}`);
    }
  }, [mutation, navigate]);

  // const getCustomerLink = useCallback(
  //   (customerId: number) => `${appRoutes.customers.fullPath}/${customerId}`,
  //   []
  // );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageTransition>
      <PrimaryButton onClick={openModal}>Nytt företag</PrimaryButton>
      <Table>
        <THead>
          <TR>
            <TH isFirstColumn>Namn</TH>
            <TH>Organisationsnummer</TH>
            <TH>Epost</TH>
          </TR>
        </THead>
        <TBody>
          {(companies ?? []).map(
            ({ uuid, name, organizationNumber, contactEmail }: Company) => (
              <TR key={uuid}>
                <TD isFirstColumn>{name}</TD>
                <TD>{organizationNumber}</TD>
                <TD>{contactEmail}</TD>
              </TR>
            )
          )}
        </TBody>
      </Table>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Lägg till företag"
        actionHandler={onSubmit}
        isLoading={mutation.isLoading}
        loadingText="Sparar..."
      >
        <ModalContentWrapper>
          <TextInput
            name="companyName"
            register={form.register}
            label="Företagsnamn"
          />
          <TextInput
            name="organizationNumber"
            register={form.register}
            label="Organisationsnummer"
          />
          <TextInput
            name="personFullName"
            register={form.register}
            label="Kontaktperson"
          />
          <TextInput name="email" register={form.register} label="E-post" />
        </ModalContentWrapper>
      </Modal>
    </PageTransition>
  );
};
