import { Separator, SeparatorType } from "./separator.type";

export const removeCircularDependencies = (separator: Separator) => {
  const { id, createdAt, customerId, ...rest } = separator;
  return rest;
};

export const getSeparatorTypeLabel = (separator: Separator): string => {
  switch (separator.type) {
    case SeparatorType.Oil:
      return separatorTypeToString(SeparatorType.Oil);
    case SeparatorType.Slam:
      return separatorTypeToString(SeparatorType.Slam);
    case SeparatorType.OilAndSlam:
      return separatorTypeToString(SeparatorType.OilAndSlam);
    case SeparatorType.Tvattranna:
      return separatorTypeToString(SeparatorType.Tvattranna);
    case SeparatorType.Fritext:
      return separator.fritextType;
    default:
      throw Error(
        "getSeparatorTypeLabel is missing type for " + separator.type
      );
  }
};

export const separatorTypeToString = (type: SeparatorType): string => {
  switch (type) {
    case SeparatorType.Oil:
      return "Avskiljare (OA)";
    case SeparatorType.Slam:
      return "Avskiljare (SA)";
    case SeparatorType.OilAndSlam:
      return "Avskiljare (SA + OA)";
    case SeparatorType.Tvattranna:
      return "Tvättränna";
    case SeparatorType.Fritext:
      return "Annan";
    default:
      return "";
  }
};

export const separatorTypeToStringShort = (type: SeparatorType): string => {
  switch (type) {
    case SeparatorType.Oil:
      return "OA";
    case SeparatorType.Slam:
      return "SA";
    case SeparatorType.OilAndSlam:
      return "SA + OA";
    case SeparatorType.Tvattranna:
      return "Ränna";
    case SeparatorType.Fritext:
      return "Annan";
    default:
      return "";
  }
};

export const separatorTypeBySeparatorToStringShort = (
  separator: Separator
): string => {
  switch (separator.type) {
    case SeparatorType.Fritext:
      return separator.fritextType;
    default:
      return separatorTypeToStringShort(separator.type);
  }
};

export const getIdentifierWithType = (separator: Separator) =>
  `${getSeparatorTypeLabel(separator)} - ${separator.identifier}`;

export const getShape = ({
  shapeCylindric,
  shapeLyingDown,
  shapeRectangular,
  shapeStandingUp,
  shapeOther,
  shapeOtherValue,
}: Separator) => {
  const result: string[] = [];
  if (shapeCylindric) {
    result.push("Cylindrisk");
  }
  if (shapeRectangular) {
    result.push("Rektangulär");
  }
  if (shapeStandingUp) {
    result.push("Stående");
  }
  if (shapeLyingDown) {
    result.push("Liggande");
  }
  if (shapeOther && shapeOtherValue) {
    result.push(shapeOtherValue);
  }
  return result.join(", ");
};

export const getSeparatorLabel = (
  name:
    | keyof Separator
    | "shape"
    | "separatorClassificationKlass1"
    | "separatorClassificationKlass2"
    | "separatorClassificationAldreGravimetrisk"
    | "separatorClassificationMissing"
) => {
  switch (name) {
    case "type":
      return "Typ";
    // case "fritextType":
    //   return "Typ";
    case "identifier":
      return "Identifiering";
    case "propertyDesignation":
      return "Fastighetsbeteckning";
    case "slamMaxLevel":
      return "Max slamnivå";
    case "oilMaxLevel":
      return "Max oljeskikt";
    case "manufacturer":
      return "Tillverkare";
    case "yearOfManufacture":
      return "Tillverkningsår";
    case "marking858":
      return "Märkning SS EN 858";
    case "cfarNR":
      return "CFAR-nr";
    case "verification858":
      return "Annan verifikation som stöder SS EN 858";
    case "verification858Text":
      return "Ange verifikation som stöder SS EN 858";
    case "provtagningsbrunnFinns":
      return "Finns provtagningsbrunn";
    case "nivalarmFinns":
      return "Finns oljeskiktslarm";
    case "slamnivalarmFinns":
      return "Finns slamnivålarm";
    case "damningslarmHognivalarmFinns":
      return "Finns dämningslarm";
    case "koalecensfilterFinns":
      return "Finns koalesator";
    case "classification":
      return "Klass";
    case "modelIdentifier":
      return "Modellbeteckning";
    case "shapeCylindric":
      return "Cylindrisk";
    case "shapeRectangular":
      return "Rektangulär";
    case "shapeStandingUp":
      return "Stående";
    case "shapeLyingDown":
      return "Liggande";
    case "shapeOther":
      return "Annan";
    case "shapeOtherValue":
      throw Error(
        "getSeparatorLabel missing implementation for shapeOtherValue"
      );
    case "flottarFinns":
      return "Finns automatisk avstängningsventil";
    case "flottarDensitetAngiven":
      return "Angiven densitet flottör";
    case "totalVatskevolym":
      return "Total vätskevolym";
    case "maxSlamvolym":
      return "Max slamvolym";
    case "maxOljevolym":
      return "Max oljelagringsvolym";
    case "avtalSlamtomning":
      return "Tömning";
    case "tomningReminder":
      return "Tillfällen";
    case "avtalFemarskontroll":
      return "5-årsbesiktning";
    case "avtalHalvarsKontroll":
      return "6-månaderskontroll";
    case "flode":
      return "Ns (nominell flödeskapacitet)";
    case "coordinates":
      return "Exakt position";
    case "files":
      return "Filer";
    case "address":
      return "Adress";
    case "postalCode":
      return "Postnummer";
    case "city":
      return "Stad";
    case "shape":
      return "Form";
    case "separatorClassificationKlass1":
      return "Klass 1";
    case "separatorClassificationKlass2":
      return "Klass 2";
    case "separatorClassificationAldreGravimetrisk":
      return "Äldre gravimetrisk avskiljare";
    case "separatorClassificationMissing":
      return "Uppgift saknas";
    case "notes":
      return "Anteckningar";
    case "kommunKod":
      return "Kommunkod";
    default:
      throw Error(`getSeparatorLabel is missing implementation for ${name}`);
  }
};

export const isOilSeparator = (separator: Separator) =>
  separator.type === SeparatorType.Oil ||
  separator.type === SeparatorType.OilAndSlam;

// export const is858 = (separator: Separator) =>
//   separator.marking858 === JaNejStatus.Ja ||
//   (separator.verification858 === JaNejStatus.Ja &&
//     !!separator.verification858Text);
