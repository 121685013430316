import { useMutation, useQuery } from "react-query";
import { Customer } from "types/customer.type";
import instance from "./axiosInstance";
import { RapporteringsTyp } from "./transportDocument.api";
import { NvRapportering } from "types/nvRapportering.type";

export const ENDPOINT = "/nv";

export enum NaturvardsverketQueryKey {
  GetAll = "get-naturvardsverket-list",
  GetOne = "get-naturvardsverket",
}

export type CustomerDropdownItem = {
  id: number;
  name: string;
};

export type CustomersQueryResult = {
  customers: Customer[];
  count: number;
};

export const useRapporteraTransport = () => {
  return useMutation(
    async (payload: any) =>
      (await instance.post(`${ENDPOINT}/transport`, payload)).data
  );
};

export const useRapporteraTransportPlanering = () =>
  useMutation(
    async (payload: any) =>
      (await instance.post(`${ENDPOINT}/transport-planering`, payload)).data
  );

export const useNvRapporteringList = (
  limit: number,
  offset: number,
  type?: RapporteringsTyp
) =>
  useQuery(
    [NaturvardsverketQueryKey.GetAll, type, limit, offset],
    async (): Promise<{ items: NvRapportering[]; totalCount: number }> =>
      (
        await instance.get(
          `${ENDPOINT}/nv-rapportering?limit=${limit}&offset=${offset}${
            !type ? "" : `&type=${type}`
          }`
        )
      ).data
  );
