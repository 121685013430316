import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import {
  SixMonthsInspectionQueryKey,
  useGetSixMonthsInspection,
  useSetSixMonthsInspectionFiles,
  useUpdateSixMonthsInspection,
} from "api/sixMonthsInspection.api";
import menuStore from "store/menu.store";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { Protocol } from "./Protocol";
import { scrollToTop } from "helpers/scroll";
import { PageLoader } from "components/PageLoader";
import { InspectionWrapper } from "components/inspection/InspectionWrapper";
import { InspectionHeader } from "components/inspection/InspectionHeader";
import { Form } from "./Form";
import { InspectionType } from "types/enums";
import { getSeparatorTypeLabel } from "types/separator/separator.helpers";
import { isEmail } from "helpers/string";
import { useUpdateSeparatorLogic } from "hooks/useUpdateSeparatorLogic";
import Modal from "components/Modal";
import Alert from "components/Alert";
import { EditSeparatorTechnicalInformation } from "../separator/EditSeparatorTechnicalInformation";
import { RouteUrl } from "routes.type";
import { InspectionStatus } from "enums/InspectionStatus.enum";
import { inspectionStatusToAssignmentStatus } from "types/assignment.type";
import { EmailStatus } from "types/email.type";
import { useTomning } from "api/tomning.api";
import { ProxyAssignmentBanner } from "components/ProxyAssignmentBanner";

export default function SixMonthsInspectionPage() {
  const params = useParams();
  const inspectionId: string = params?.inspectionId || "";
  const [searchParams, setSearchParams] = useSearchParams();
  const [enableFieldAnimation, setEnableFieldAnimation] = useState(false);
  const queryClient = useQueryClient();

  // update inspection
  const {
    mutate: updateInspection,
    isSuccess,
    isLoading: isSavingInspection,
  } = useUpdateSixMonthsInspection();

  // Klarmarkera
  const {
    mutate: updateInspectionKlarmarkera,
    isLoading: isUpdatingKlarmarkera,
  } = useUpdateSixMonthsInspection({
    onSuccess: (data: SixMonthsInspection) => {
      queryClient.invalidateQueries([
        SixMonthsInspectionQueryKey.GetOne,
        data.id,
      ]);
    },
  });

  const setKlarmarkera = (klarmarkerad: boolean) => {
    if (!inspection) {
      throw Error(
        "SixMonthsInspectionPage.setKlarmarkera, no inspection found"
      );
    }
    updateInspectionKlarmarkera({
      ...inspection,
      klarmarkerad,
    });
  };

  useEffect(() => {
    setTimeout(() => setEnableFieldAnimation(true), 1000);
  }, []);
  const mode = searchParams.get("mode") || "view";

  useEffect(() => {
    if (isSuccess) {
      setSearchParams({
        mode: "view",
      });
      scrollToTop();
    }
  }, [isSuccess, setSearchParams]);

  // Används för att uppdatera status på emails
  const [shouldRefetchInspection, setShouldRefetchInspection] = useState(false);

  const {
    data: inspection,
    isLoading,
    isError,
  } = useGetSixMonthsInspection(+inspectionId, shouldRefetchInspection);

  useEffect(() => {
    if (inspection) {
      setShouldRefetchInspection(
        (inspection.emails ?? []).some(
          (email) =>
            email.status === EmailStatus.Scheduled ||
            email.status === EmailStatus.Sent
        )
      );
    }
  }, [inspection]);

  const separator = inspection?.separator;

  const { setCurrentCustomerName, setSeparator, setInspection } = menuStore();
  useEffect(() => {
    return () => {
      // rensa bort för att inte få en hackig header
      setInspection("");
    };
  }, [setInspection]);

  useEffect(() => {
    if (separator) {
      setCurrentCustomerName(separator?.customer?.name || "");
      setSeparator(
        `${getSeparatorTypeLabel(separator)}${
          !separator.identifier ? "" : ` - ${separator.identifier}`
        }`
      );
      setInspection(inspectionId);
    }
  }, [
    inspectionId,
    separator,
    setCurrentCustomerName,
    setInspection,
    setSeparator,
  ]);

  // Update images
  // Detta är för att göra en optimistic update på delete, fult som fan
  const [tempDeletedFiles, setTempDeletedFiles] = useState<number[]>([]);

  const { mutate: setFiles, isLoading: isSettingFiles } =
    useSetSixMonthsInspectionFiles();

  const onFileAdd = (url: string) => {
    if (inspection) {
      setFiles({
        inspectionId: inspection.id,
        files: [...inspection.files, { id: undefined, name: url, url }],
      });
    }
  };

  const onFileDelete = (id: number) => {
    setTempDeletedFiles((prev) => [...prev, id]);
    if (inspection) {
      setFiles({
        inspectionId: inspection.id,
        files: (inspection.files || []).filter((file) => file.id !== id),
      });
    }
  };

  const {
    isModalOpen,
    formErrors,
    isUpdating,
    onModalClose,
    openModal,
    onSeparatorSubmit,
    register,
    formData,
    isUpdateSeparatorSuccess,
  } = useUpdateSeparatorLogic(inspection?.separator);

  useEffect(() => {
    if (isUpdateSeparatorSuccess) {
      queryClient.invalidateQueries([
        SixMonthsInspectionQueryKey.GetOne,
        inspectionId,
      ]);
    }
  }, [isUpdateSeparatorSuccess, queryClient, inspectionId]);

  // Update separator, katastrof

  // Tomning om man även har en sån
  const { data: tomning, isLoading: isLoadingTomning } = useTomning(
    inspection?.assignment?.tomning?.id ?? -1
  );

  if (isLoading || isLoadingTomning) {
    return <PageLoader />;
  }
  if (isError || !inspection || !separator) {
    return <div>Error</div>;
  }

  const isEditMode = mode === "edit";

  const documentationEmail = inspection.assignment.isProxyAssignment
    ? inspection.assignment.parentCustomer?.documentationEmail
    : inspection.separator.customer?.documentationEmail;

  return (
    <>
      {inspection.assignment.parentCustomer && (
        <ProxyAssignmentBanner
          parentCustomer={inspection.assignment.parentCustomer}
        />
      )}
      <InspectionWrapper
        existingFiles={inspection.files.filter(
          (file) => !tempDeletedFiles.includes(file.id)
        )}
        deleteFile={onFileDelete}
        isLoadingFiles={isSettingFiles}
        afterUploadedFile={onFileAdd}
        type={InspectionType.SixMonths}
        inspectionStatus={inspection.status}
        fileName={`6månaderskontroll-${inspection.inspectionDate
          .toString()
          .replaceAll("-", "")}.pdf`}
        inspectionId={inspection.id}
        hasSentEmail={inspection.hasSentEmail}
        hasValidDocumentationEmail={isEmail(documentationEmail)}
        inspection={inspection}
        openEditSeparatorModal={openModal}
        isEditMode={isEditMode}
        tomning={tomning}
        documentationEmail={documentationEmail}
      >
        {inspection.status === InspectionStatus.MissingSeparatorData && (
          <Alert
            text="Det saknas information om objektet för att kunna utföra en 6-månaderskontroll."
            buttonText="Lägg till information."
            onButtonClick={openModal}
          />
        )}
        <InspectionHeader
          status={inspection.status}
          isEditMode={isEditMode}
          setEditMode={() => setSearchParams({ mode: "edit" })}
          setKlarmarkera={setKlarmarkera}
          isSettingKlarmarkera={isUpdatingKlarmarkera}
          assignment={{
            ...inspection.assignment,
            separator,
            customer: inspection.separator.customer,
            user: inspection.user,
            sixMonthsInspection: inspection,
            assignmentStatus: inspectionStatusToAssignmentStatus(
              inspection.status
            ),
          }}
          openEditSeparatorModal={openModal}
        />

        {isEditMode ? (
          <Form
            inspection={inspection}
            onCancel={() => {
              scrollToTop();
              setSearchParams({ mode: "view" });
            }}
            enableFieldAnimation={enableFieldAnimation}
            updateInspection={updateInspection}
            separatorType={inspection.separator.type}
            isSavingInspection={isSavingInspection}
          />
        ) : (
          <Protocol inspection={inspection} />
        )}
        <Modal
          isOpen={isModalOpen}
          title={"Ändra objekt - " + inspection?.separator.customer?.name}
          onClose={onModalClose}
          actionHandler={onSeparatorSubmit}
          actionText="Spara"
          isLoading={isUpdating}
          loadingText="Sparar..."
        >
          <EditSeparatorTechnicalInformation
            register={register}
            formErrors={formErrors}
            formData={formData}
          />
        </Modal>
      </InspectionWrapper>
    </>
  );
}

export const getLinkToSixMonthsInspectionPage = (
  customerId: number | string,
  separatorId: number | string,
  sixMonthsInspectionId: number | string
) =>
  `${RouteUrl.App}/${RouteUrl.Customers}/${customerId}/${separatorId}/${RouteUrl.SixMonthsInspection}/${sixMonthsInspectionId}`;
