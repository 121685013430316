import { usePatchCustomer } from "api/customer.api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Customer } from "types/customer.type";

export const useUpdateCustomerDocumentationEmailLogic = (
  onSuccess: () => void,
  customer?: Customer
) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const mutation = usePatchCustomer(customer?.id ?? -1, {
    onSuccess,
  });

  const form = useForm<{ documentationEmail: string }>();

  const onSubmit = form.handleSubmit((data: { documentationEmail: string }) =>
    mutation.mutate(data)
  );

  useEffect(() => {
    if (customer) {
      form.reset({
        documentationEmail: customer.documentationEmail,
      });
    }
  }, [customer, form]);

  useEffect(() => {
    if (mutation.isSuccess) {
      setIsModalOpen(false);
    }
  }, [mutation.isSuccess]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    onSubmit,
    closeModal,
    openModal: () => setIsModalOpen(true),
    isSubmitting: mutation.isLoading,
    register: form.register,
    errors: form.formState.errors,
  };
};
