import { classNames } from "helpers/classNames";
import { FC } from "react";

interface Props {
  text: string;
  isSelected: boolean;
  selectedColors?: string;
}
export const Badge: FC<Props> = ({
  text,
  isSelected,
  selectedColors = "bg-cyan-100 text-cyan-700",
}) => (
  <span
    className={classNames(
      "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
      isSelected ? selectedColors : "bg-gray-100 text-gray-900"
    )}
  >
    {text}
  </span>
);

interface BadgeRoundProps {
  text: string;
}
export const BadgeRound: FC<BadgeRoundProps> = ({ text }) => (
  <span
    className={classNames(
      "inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium bg-pink-400 text-white"
    )}
  >
    {text}
  </span>
);
