import { ProxyCustomerDto, useCustomerDropdown } from "api/customer.api";
import Dropdown from "components/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { Tips } from "components/Tips";
import { useMemo } from "react";
import { UseFormRegister } from "react-hook-form";
import { customerToDropdownItem } from "types/user.type";

type Props = {
  register: UseFormRegister<ProxyCustomerDto>;
  formErrors?: any;
  idsToExclude?: number[];
};

export const NewProxyCustomerForm = ({
  register,
  formErrors,
  idsToExclude = [],
}: Props) => {
  const { data: customers } = useCustomerDropdown();
  const customerDropdownItems = useMemo(
    () =>
      (customers ?? [])
        .filter((c) => !idsToExclude.some((id) => id === c.id))
        .map(customerToDropdownItem),
    [customers, idsToExclude]
  );

  return (
    <ModalContentWrapper>
      <Tips
        id="c03261e3-92e8-4d9f-9807-c8a062170311"
        paragraphs={[
          "Börja att skriva namnet på underkunden, så hittar ni rätt kund snabbare.",
        ]}
      />
      <Dropdown
        items={customerDropdownItems}
        name="proxyCustomerId"
        label="Underkund"
        register={register}
        validation={{
          validate: (value: number) => {
            return value > 0 || "Du måste välja en underkund";
          },
        }}
        errorMessage={formErrors?.proxyCustomerId?.message}
      />
    </ModalContentWrapper>
  );
};
