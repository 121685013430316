import { useMutation, useQuery } from "react-query";
import instance from "./axiosInstance";
import { WasteCode } from "types/wasteCode.type";

export const ENDPOINT = "waste-code";

export enum WasteCodeQueryKeys {
  GetAll = "get-all-waste-codes",
  GetDropDown = "get--waste-code-dropdown",
  CreateMany = "create-transport-document",
}

export const useWasteCodeDropDown = (query = "") => {
  return useQuery(
    [WasteCodeQueryKeys.GetDropDown, query],
    async (): Promise<WasteCode[]> =>
      (await instance.get(`${ENDPOINT}/dropdown?q=${query}`)).data
  );
};

export const useCreateWasteCodes = () =>
  useMutation(
    async (payload: {
      codes: { code: string; description: string }[];
    }): Promise<void> => await instance.post(ENDPOINT, payload)
  );
