import { FC } from "react";
import { ModalLabel } from "./ModalLabel";

export interface DropdownItem {
  value: string | number;
  label: string;
}
interface Props {
  items: DropdownItem[];
  label: string;
  disabled?: boolean;
  descriptionItemText?: string;
  validation?: any;
  errorMessage?: string;
  value?: string | number;
  handleChange: (val: string | number) => void;
  hideLabel?: boolean;
  className?: string;
}

const DropdownStupid: FC<Props> = ({
  items,
  label,
  disabled = false,
  descriptionItemText,
  errorMessage,
  value: _value,
  handleChange,
  hideLabel = false,
  className,
}) => {
  const value = _value ?? 0;
  return (
    <div className={className}>
      {!hideLabel && <ModalLabel>{label}</ModalLabel>}
      <select
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm rounded-md text-gray-900 disabled:text-gray-500"
        disabled={disabled}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
        <option disabled value={0} hidden>
          {!descriptionItemText
            ? `Välj ${label.charAt(0).toUpperCase() + label.slice(1)}`
            : descriptionItemText}
        </option>
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {!!errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default DropdownStupid;
