/* This example requires Tailwind CSS v2.0+ */
import { FC } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Link } from "components/Link";
import PrimaryButton from "components/buttons/PrimaryButton";
interface Props {
  header: string;
  link?: string;
  onClick?: () => void;
  subHeader: string;
  buttonText: string;
}

const EmptyState: FC<Props> = ({
  header,
  subHeader,
  link,
  buttonText,
  onClick,
}) => (
  <div className="text-center bg-white sm:bg-inherit py-12 sm:py-0 max-w-md mx-auto">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">{header}</h3>
    <p className="mt-1 text-sm text-gray-500">{subHeader}</p>
    <div className="mt-6">
      {!!link && (
        <Link
          to={link}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          {buttonText}
        </Link>
      )}
      {!link && !!onClick && (
        <PrimaryButton onClick={onClick}>{buttonText}</PrimaryButton>
      )}
    </div>
  </div>
);

export default EmptyState;
