import { useNotification } from "hooks/useNotification";
import { useMutation } from "react-query";
import instance from "./axiosInstance";

export type FeedbackDTO = {
  text: string;
};

export const useSendFeedback = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: FeedbackDTO): Promise<void> =>
      (await instance.post("/feedback", payload)).data,
    {
      onError: () => notification.error("Något gick fel"),
    }
  );
};
