import { DateInput } from "components/DateInput";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { TaskDTO } from "types/task.type";

interface Props {
  register: UseFormRegister<TaskDTO>;
}

export const EditFiveYearsTask: FC<Props> = ({ register }) => {
  return (
    <ModalContentWrapper>
      <DateInput
        name="date"
        register={register}
        label="Nästa tillfälle senast"
      />
    </ModalContentWrapper>
  );
};
