import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "components/buttons/PrimaryButton";
import EmptyState from "components/EmptyState";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import { Table, THead, TR, TH, TBody, TD } from "components/table";
import { appRoutes } from "routes.type";
import { PageTransition } from "components/animations/SnapUp";
import { Panel } from "components/Panel";
import {
  useCreateWasteStation,
  useWasteStationList,
} from "api/wasteStation.api";
import {
  WasteStation,
  WasteStationDTO,
  getEmptyWasteStationDTO,
} from "types/wasteStation.type";
import { WasteStationForm } from "./WasteStationForm";
import { useIsAdmin } from "store/user.store";

export const WasteStationListPage = () => {
  const navigate = useNavigate();

  const isAdmin = useIsAdmin();

  const [isAddWasteStationModalOpen, setIsAddWasteStationModalOpen] =
    useState<boolean>(false);

  const { data: wasteStations, isLoading } = useWasteStationList();

  const {
    mutate,
    isSuccess,
    data: createdWasteStation,
    isLoading: isCreatingWasteStation,
  } = useCreateWasteStation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WasteStationDTO>({
    defaultValues: {
      knCode: "",
    },
  });

  const onSubmit = handleSubmit((data: WasteStationDTO) => mutate(data));

  const closeModal = () => {
    reset(getEmptyWasteStationDTO());
    setIsAddWasteStationModalOpen(false);
  };

  useEffect(() => {
    if (isSuccess && createdWasteStation) {
      navigate(`${appRoutes.wasteStations.fullPath}/${createdWasteStation.id}`);
    }
  }, [isSuccess, createdWasteStation, navigate]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!wasteStations || wasteStations.length === 0) {
    return (
      <PageTransition>
        <Panel>
          <EmptyState
            header="Inga mottagarstationer"
            subHeader="Mottagarstation behövs för att kunna skapa transportdokument"
            buttonText="Ny mottagarstation"
            onClick={() => setIsAddWasteStationModalOpen(true)}
          />
        </Panel>
        <Modal
          isOpen={isAddWasteStationModalOpen}
          onClose={closeModal}
          title="Lägg till mottagarstation"
          actionHandler={onSubmit}
          isLoading={isCreatingWasteStation}
          loadingText="Sparar..."
        >
          <WasteStationForm register={register} errors={errors} />
        </Modal>
      </PageTransition>
    );
  }

  const onWasteStationClick = (id: number) =>
    navigate(`${appRoutes.wasteStations.fullPath}/${id}`);

  return (
    <PageTransition>
      <div className="px-4 sm:px-0 max-w-7xl">
        <div className="sm:flex sm:items-center mb-4 justify-end">
          {isAdmin && (
            <div className="mt-4 sm:mt-0 sm:flex-none flex items-end justify-end">
              <PrimaryButton
                onClick={() => setIsAddWasteStationModalOpen(true)}
              >
                + Ny mottagarstation
              </PrimaryButton>
            </div>
          )}
        </div>

        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>Namn</TH>
              <TH>Org.nummer</TH>
              <TH>Telefon</TH>
              <TH>Stad</TH>
              <TH hideOnTablet>Kommunkod</TH>
            </TR>
          </THead>
          <TBody>
            {wasteStations.map(
              ({
                id,
                name,
                organizationNumber,
                city,
                knCode,
                phone,
              }: WasteStation) => (
                <TR key={id} onClick={() => onWasteStationClick(id)}>
                  <TD isFirstColumn>{name}</TD>
                  <TD>{organizationNumber}</TD>
                  <TD>{phone}</TD>
                  <TD>{city}</TD>
                  <TD hideOnTablet>{knCode}</TD>
                </TR>
              )
            )}
          </TBody>
        </Table>
      </div>

      <Modal
        isOpen={isAddWasteStationModalOpen}
        onClose={closeModal}
        title="Spara mottagarstation"
        actionHandler={onSubmit}
        isLoading={isCreatingWasteStation}
        loadingText="Sparar..."
      >
        <WasteStationForm register={register} errors={errors} />
      </Modal>
    </PageTransition>
  );
};
