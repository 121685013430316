import { Company } from "api/company.api";
import { useUploadFiles } from "api/image.api";
import { FileUploadButton } from "components/buttons/FileUploadButton";
import { DescriptionList } from "components/desriptionList/DescriptionList";
import { Item } from "components/desriptionList/Item";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import TabContainer from "components/tab/TabContainer";
import { guid } from "helpers/guid";
import { FC, useEffect } from "react";

interface Props {
  company: Company;
  setLogoUrl: (url: string) => void;
}

export const OverviewTab: FC<Props> = ({ company, setLogoUrl }) => {
  const {
    mutate: uploadImages,
    isSuccess: isUploadImagesSuccess,
    data: uploadedImages,
    isLoading: isUploadingImages,
    reset,
  } = useUploadFiles();

  const onFileAdd = async (blob: Blob | null, filename: string) => {
    if (!blob) return;
    const file = new File([blob], filename);
    if (!file) {
      return;
    }

    const renamedFile = new File(
      [file],
      `${filename.split(".")[0]}-${guid().split("-")[0]}${
        guid().split("-")[1]
      }.${file.name.split(".")[1]}`
    );

    uploadImages([renamedFile]);
  };

  useEffect(() => {
    if (isUploadImagesSuccess && uploadedImages) {
      setLogoUrl(uploadedImages[0]);
      reset();
    }
  }, [isUploadImagesSuccess, uploadedImages, reset, setLogoUrl]);

  const uploadedLogo =
    !!uploadedImages && uploadedImages?.length > 0 ? uploadedImages[0] : "";

  return (
    <TabContainer>
      <DescriptionList>
        <Item>
          <Label>Namn på ert företag</Label>
          <Value>{company.name}</Value>
        </Item>
        <Item>
          <Label>Organisationsnummer</Label>
          <Value>{company.organizationNumber}</Value>
        </Item>
        <Item>
          <Label>Telefon</Label>
          <Value>{company.phone}</Value>
        </Item>
        <Item>
          <Label>Adress</Label>
          <Value>
            <div className="space-y-1">
              {!!company.address && <p>{company.address}</p>}
              {!!company.postalCode && <p>{company.postalCode}</p>}
              {!!company.city && <p>{company.city}</p>}
            </div>
          </Value>
        </Item>
        <Item>
          <Label>Logga (visas på rapporter)</Label>
          <Value>
            {(!!company.logoUrl || !!uploadedLogo) && (
              <img
                className="h-12 mb-4"
                src={company.logoUrl ?? uploadedLogo}
                alt="Your logo"
              />
            )}
            <FileUploadButton
              onFileAdd={onFileAdd}
              isLoading={isUploadingImages}
              accept="image/*,image/heic"
              label={!!company.logoUrl ? "Byt logga" : "Ladda upp logga"}
            />
          </Value>
        </Item>
        <Item>
          <Label>Kontaktuppgifter för rapportering till Avfallsregistret</Label>
          <Value>
            <div className="space-y-1">
              {!!company.contactFullName && <p>{company.contactFullName}</p>}
              {!!company.contactEmail && <p>{company.contactEmail}</p>}
              {!!company.contactPhone && <p>{company.contactPhone}</p>}
            </div>
          </Value>
        </Item>
      </DescriptionList>
    </TabContainer>
  );
};
