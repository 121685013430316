import { InspectionResult, JaNejStatus, KontrollStatus } from "types/enums";

export const formatJaNejStatus = (status?: JaNejStatus) =>
  !status || status === JaNejStatus.Ej_Angett ? "" : status;

export const formatInspectionResultStatus = (
  status?: InspectionResult,
  onlyResults = true
) =>
  onlyResults
    ? !!status &&
      [InspectionResult.Approved, InspectionResult.NotApproved].includes(status)
      ? status
      : ""
    : !status
    ? ""
    : status === InspectionResult.Unknown
    ? "Ej utförd"
    : status;

export const formatKontrollStatus = (status?: KontrollStatus) =>
  !status || status === KontrollStatus.EjAngett ? "" : status;
