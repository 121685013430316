import { FC, ReactNode } from "react";
import { SnapRight } from "components/animations/SnapUp";

interface FormProps {
  children: ReactNode;
}

export const FormWrapper: FC<FormProps> = ({ children }) => (
  <SnapRight>
    <form className="space-y-8 divide-y divide-gray-50 px-4 pb-5 sm:px-6">
      {children}
    </form>
  </SnapRight>
);
