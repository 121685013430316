import {
  ArchiveBoxArrowDownIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { isEmail } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

interface Props {
  register: UseFormRegister<{ documentationEmail: string }>;
  errors: any;
  customerName?: string;
  wasteStationName?: string;
}
export const DocumentationEmailForm: FC<Props> = ({
  register,
  errors,
  customerName,
  wasteStationName,
}) => {
  return (
    <ModalContentWrapper>
      {!!customerName && (
        <div
          className="flex items-center"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <FaceSmileIcon className="h-4 w-auto text-gray-500 mr-1" />
          {customerName}
        </div>
      )}

      {!!wasteStationName && (
        <div
          className="flex items-center"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <ArchiveBoxArrowDownIcon className="h-4 w-auto text-gray-500 mr-1" />
          {wasteStationName}
        </div>
      )}

      <TextInput
        label="Epostadress för rapporter"
        name="documentationEmail"
        register={register}
        validation={{
          validate: (value: string) =>
            !value || // Ok att lämna tomt
            isEmail(value) ||
            "Denna emailadress ser ut att vara felaktig",
        }}
        errorMessage={errors?.documentationEmail?.message}
      />
    </ModalContentWrapper>
  );
};
