import { CustomerDropdownItem } from "api/customer.api";
import { DropdownItem } from "components/Dropdown";
import { Role } from "./enums";

export type User = {
  id: number;
  email: string;
  name?: string;
  role: Role;
  company?: { name: string };
  companyId: number;
};

export const userToDropdownItem = (user: User): DropdownItem => ({
  label: user.name ?? "",
  value: user.id,
});

export const customerToDropdownItem = (item: CustomerDropdownItem) => ({
  label: item.name,
  value: item.id,
});
