import { ModalContentWrapper } from "components/ModalContentWrapper";
import { RadioGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { VehicleDTO, VehicleType, vehicleTypeToName } from "types/vehicle.type";

interface Props {
  register: UseFormRegister<VehicleDTO>;
  errors: any;
}
export const VehicleModal: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="Registreringsnummer"
        name="registrationNumber"
        register={register}
        validation={{ required: "Detta fält är obligatoriskt" }}
        errorMessage={errors?.registrationNumber?.message}
        autoFocus
      />
      <RadioGroup
        label="Fordonstyp"
        name="type"
        register={register}
        items={[
          {
            label: vehicleTypeToName(VehicleType.Truck),
            value: VehicleType.Truck,
          },
          {
            label: vehicleTypeToName(VehicleType.Trailer),
            value: VehicleType.Trailer,
          },
        ]}
      />
    </ModalContentWrapper>
  );
};
