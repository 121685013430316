import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Invitation, InvitationDTO } from "types/invitation.type";
import instance from "./axiosInstance";
import { CustomerQueryKey } from "./customer.api";

export const ENDPOINT = "invitation";
export const GET_INVITATIONS_KEY = "get-invitations";

export const useGetInvitations = () =>
  useQuery(
    GET_INVITATIONS_KEY,
    async (): Promise<Invitation[]> => (await instance.get(ENDPOINT)).data
  );

export const useGetInvitation = (code: string) =>
  useQuery(
    GET_INVITATIONS_KEY,
    async (): Promise<Invitation> =>
      (await instance.get(`${ENDPOINT}/${code}`)).data
  );

export const useInviteUser = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: InvitationDTO): Promise<Invitation> =>
      await instance.post(ENDPOINT, { ...payload, role: +payload.role }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INVITATIONS_KEY]);
        notification.info(`Inbjudan skickad`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useDeleteInvitation = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (id: number): Promise<void> =>
      await instance.delete(`${ENDPOINT}/${id}`),
    {
      onSuccess: () => {
        notification.info(`Inbjudan borttagen`);
        queryClient.invalidateQueries([CustomerQueryKey.GetOne]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useSignupByInvitation = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: SignupByInvitation): Promise<void> =>
      await instance.post(`${ENDPOINT}/signup`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CustomerQueryKey.GetOne]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export type SignupByInvitation = {
  password: string;
  name: string;
  code: string;
};
