import { MandatoryIndicator } from "components/MandatoryIndicator";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

interface CheckboxItem {
  label: string;
  name: string;
}
interface CheckboxGroupProps {
  label: string;
  items: CheckboxItem[];
  register: UseFormRegister<any>;
  mandatory?: boolean;
}
export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  items,
  register,
  mandatory = false,
}) => {
  return (
    <fieldset className="sm:py-4 sm:border-t sm:border-gray-50">
      <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
        <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-500">
          {label}
          {mandatory && <MandatoryIndicator />}
        </div>
        <div className="sm:col-span-1">
          <div className="max-w-md">
            <div className="mt-4 space-y-4">
              {items.map((item: CheckboxItem) => (
                <Item key={item.name} item={item} register={register} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

interface ItemProps {
  item: CheckboxItem;
  register: UseFormRegister<any>;
}
const Item = ({ item, register }: ItemProps) => (
  <div className="flex items-start">
    <div className="flex items-center h-5">
      <input
        id={item.name}
        {...register(item.name)}
        type="checkbox"
        className="focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300 rounded"
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={item.name} className="font-base text-gray-900">
        {item.label}
      </label>
    </div>
  </div>
);
