/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageLoader } from "components/PageLoader";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { useSearchParams } from "react-router-dom";
import { SearchField } from "components/SearchField";
import { useCallback, useEffect } from "react";
import { isNumber } from "helpers/number";
import { Pagination } from "components/Pagination";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
import { useSearchAssignments } from "api/assignment.api";
import { Assignment, getReferenceNumber } from "types/assignment.type";
import { AssignmentStatusTag } from "components/AssignmentStatusTag";
import { PageTransition } from "components/animations/SnapUp";
import { getLinkToTomningPage } from "../tomning-page/TomningPage";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import { AssignmentType } from "enums/AssignmentType.enum";
import { ProxyAssignmentIcon } from "components/ProxyAssignmentIcon";

const PAGE_SIZE = 20;

export default function AssignmentListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQuery = (query: string) => setSearchParams({ query });

  const queryParam = searchParams.get("query") ?? "";
  const pageParam = searchParams.get("page") ?? "1";

  const pageNumber = isNumber(pageParam) ? +pageParam : 1;

  const {
    mutate: searchAssignments,
    isLoading: isSearchingAssignments,
    data: assignmentSearchResult,
  } = useSearchAssignments();

  useEffect(() => {
    const payload = {
      query: queryParam,
      offset: (pageNumber - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
    };
    searchAssignments(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam, pageParam]);

  const getInspectionLink = useCallback((assignment: Assignment) => {
    if (assignment.sixMonthsInspection) {
      return getLinkToSixMonthsInspectionPage(
        assignment.customerId,
        assignment.separatorId,
        assignment.sixMonthsInspection.id
      );
    } else if (assignment.fiveYearsInspection) {
      return getLinkToFiveYearsInspectionPage(
        assignment.customerId,
        assignment.separatorId,
        assignment.fiveYearsInspection.id
      );
    } else if (assignment.tomning) {
      return getLinkToTomningPage(
        assignment.customerId,
        assignment.separatorId,
        assignment.tomning.id
      );
    } else {
      console.error("Invalid inspection type");
      return "";
    }
  }, []);

  return (
    <PageTransition>
      <div className="md:mt-7">
        <SearchField
          placeholder="Kundnamn, referens eller rapportnummer"
          onSearch={(query: string) => {
            if (query) {
              setQuery(query);
            } else {
              searchParams.delete("query");
              setSearchParams(searchParams);
            }
          }}
          initialValue={queryParam}
        />
        {isSearchingAssignments ? (
          <PageLoader />
        ) : (
          <div className="md:mt-3 bg-white shadow sm:rounded-lg px-4 sm:px-0 mt-4">
            <Table>
              <THead>
                <TR>
                  <TH className="w-0 px-0">
                    <></>
                  </TH>
                  <TH isFirstColumn>Kund</TH>
                  <TH hideOnTablet>Uppdragsförmedlare</TH>
                  <TH hideOnMobile>Typ</TH>
                  <TH hideOnMobile>Datum</TH>
                  <TH>Status</TH>
                  <TH hideOnTablet>Referens</TH>
                </TR>
              </THead>
              <TBody>
                {assignmentSearchResult?.assignments?.map(
                  (assignment: Assignment) => (
                    <TR
                      key={assignment.id}
                      link={getInspectionLink(assignment)}
                    >
                      <TD isFirstColumn>{assignment.customer?.name}</TD>
                      <TD hideOnTablet>
                        {assignment.isProxyAssignment && (
                          <div className="flex gap-x-2 items-center text-sm">
                            <ProxyAssignmentIcon />
                            {assignment.parentCustomer?.name}
                          </div>
                        )}
                      </TD>
                      <TD hideOnMobile>
                        <div className="flex items-center space-x-2">
                          <AssignmentTypeTag type={assignment.type} />
                          {!!assignment.includeTomning && (
                            <AssignmentTypeTag type={AssignmentType.Tomning} />
                          )}
                        </div>
                      </TD>
                      <TD hideOnMobile>{assignment.inspectionDate}</TD>

                      {/* <TD hideOnMobile>{assignment.user?.name}</TD> */}
                      <TD>
                        <AssignmentStatusTag
                          status={assignment.assignmentStatus}
                        />
                      </TD>

                      <TD hideOnTablet>{getReferenceNumber(assignment)}</TD>
                    </TR>
                  )
                )}
              </TBody>
            </Table>
            <Pagination
              currentPage={pageNumber}
              totalCount={assignmentSearchResult?.totalCount ?? 0}
              pageSize={PAGE_SIZE}
              setPage={(page: number) =>
                setSearchParams({ page: page.toString() })
              }
            />
          </div>
        )}
      </div>
    </PageTransition>
  );
}
