import { TomningQueryKey } from "api/tomning.api";
import {
  TransportDocumentQueryKey,
  useUpdateTransportDocument,
} from "api/transportDocument.api";
import { scrollToTop } from "helpers/scroll";
import { useNotification } from "hooks/useNotification";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { Tomning } from "types/tomning.type";
import { TransportDocument } from "types/transportDocument.type";
// import useSound from "use-sound";
// const honkSound = require("assets/honk.wav");
// const lockSound = require("assets/lock.wav");

export const useUpdateDriverSignature = (
  tomning?: Tomning,
  setViewMode?: () => void
) => {
  const [isDriverSignatureModalVisible, setIsDriverSignatureModalVisible] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const notification = useNotification();
  // const [playHonk] = useSound(honkSound);
  // const [playLock] = useSound(lockSound);

  const { mutate: updateTransportDocument, isLoading } =
    useUpdateTransportDocument(tomning?.transportDocument.id ?? -1, {
      onSuccess: (data: TransportDocument) => {
        if (
          tomning &&
          !!data.driverSignature &&
          !tomning.transportDocument.driverSignature
        ) {
          // om detta är den första signaturen låser vi
          // Om detta är den andra signaturen honkar vi
          if (!tomning.transportDocument.customerSignature) {
            // playLock();
            // notification.info("Dokument låst");
          } else {
            // playHonk();
            notification.info("Lastad");
            !!setViewMode && setViewMode();
            scrollToTop();
          }
        }
        // notification.info("Signatur sparad");
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
        setIsDriverSignatureModalVisible(false);
      },
      onError: () => {
        notification.error("Något gick fel");
      },
    });

  // När man klickar på spara vill man uppdatera servern med det nya värdet. När det är klart vill vi stänga modulen
  const onDriverSignatureSubmit = (driverSignature: string) => {
    if (!tomning?.transportDocument) {
      throw Error("No transport document");
    }
    updateTransportDocument({
      ...tomning.transportDocument,
      driverSignature,
    });
  };

  return {
    isDriverSignatureModalVisible,
    showDriverSignatureModal: () => setIsDriverSignatureModalVisible(true),
    hideDriverSignatureModal: () => setIsDriverSignatureModalVisible(false),
    onDriverSignatureSubmit,
    isSubmittingDriverSignature: isLoading,
  };
};
