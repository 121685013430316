import { useMutation } from "react-query";
import instance from "./axiosInstance";

const ENDPOINT = "/postnummer";

export enum PostnummerQueryKey {
  GuessKommun = "guess-kommun",
}

export const useGuessKommun = () =>
  useMutation(
    async ({ postnummer }: { postnummer: string }): Promise<string> =>
      ((await instance.post(`${ENDPOINT}/${postnummer}`)).data ?? "").toString()
  );
