import { useParams } from "react-router-dom";

const PdfPage = () => {
  const params = useParams();
  const fileName: string = params?.pdf || "";
  return (
    <div className="h-screen">
      <iframe
        title="pdf"
        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=https://oakontroll.fra1.digitaloceanspaces.com/${fileName}`}
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"
      ></iframe>
    </div>
  );
};

export default PdfPage;
