import Dropdown from "components/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { isEmail } from "helpers/string";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { Role } from "types/enums";
import { InvitationDTO } from "types/invitation.type";

interface Props {
  register: UseFormRegister<InvitationDTO>;
  errors: any;
}
export const AddUserModal: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="För- och efternamn"
        name="name"
        register={register}
        validation={{ required: "Obligatoriskt fält" }}
        errorMessage={errors?.name?.message}
      />
      <TextInput
        label="E-post"
        name="email"
        register={register}
        validation={{
          validate: (value: string) =>
            isEmail(value) || "Denna e-postadress ser ut att vara felaktig",
        }}
        errorMessage={errors?.email?.message}
      />
      <Dropdown
        items={[
          { label: "Operatör", value: Role.User },
          { label: "Administratör", value: Role.Admin },
        ]}
        name="role"
        label="Kontotyp"
        register={register}
      />
    </ModalContentWrapper>
  );
};
