import { classNames } from "helpers/classNames";

interface Props {
  children: React.ReactNode;
  lgGridCols?: string;
}

export const TabDisplayList: React.FC<Props> = ({
  children,
  lgGridCols = "3",
}) => (
  <dl
    className={classNames(
      "grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 p-4 sm:p-0",
      lgGridCols === "4" ? "lg:grid-cols-4" : "lg:grid-cols-3"
    )}
  >
    {children}
  </dl>
);
