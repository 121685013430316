import { FC } from "react";

interface Props {
  size?: number;
}
export const Ping: FC<Props> = ({ size = 3 }) => (
  <div className={`relative h-3 w-3 ml-2`}>
    <span className={`flex h-${size} w-${size}`}>
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-200 opacity-75"></span>
      <span
        className={`relative inline-flex rounded-full bg-pink-300 h-${size} w-${size}`}
      ></span>
    </span>
  </div>
);
