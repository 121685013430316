import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  isLink?: boolean;
}

export const TH: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableCellElement>,
      HTMLTableCellElement
    >
> = ({
  children,
  isFirstColumn = false,
  isLastColumn = false,
  hideOnMobile = false,
  hideOnTablet = false,
  isLink = false,
  className,
  ...rest
}) =>
  isLink ? (
    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
      {children}
    </th>
  ) : (
    <th
      {...rest}
      scope="col"
      className={twMerge(
        `${
          hideOnTablet
            ? "hidden lg:table-cell"
            : hideOnMobile
            ? "hidden sm:table-cell"
            : ""
        } ${
          isFirstColumn
            ? "py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
            : isLastColumn
            ? "relative py-3 pl-4 pr-4 text-left text-sm sm:pr-6"
            : "px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        }`,
        className
      )}
    >
      {children}
    </th>
  );
