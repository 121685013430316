import { useRapporteraTransport } from "api/avfallsregistret.api";
import {
  TransportDocumentQueryKey,
  usePatchTransportDocument,
  useTransportDocument,
} from "api/transportDocument.api";
import { Panel } from "components/Panel";
import PrimaryButton from "components/buttons/PrimaryButton";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TransportSätt } from "types/tomning.type";
import { getSweref99 } from "helpers/coordinates/coordinates";
import { FormErrorList } from "components/FormErrorList";
import { useGuessKommun } from "api/postnummer.api";
import { Avsändare } from "./Avsändare";
import { Mottagare } from "./Mottagare";
import { Transportör } from "./Transportör";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  TransportDTO,
  TransportDtoSchema,
  isValidAdressrad,
  isValidPostnummer,
  isValidSwerefEValue,
  isValidSwerefNValue,
} from "types/transport.type";
import { RouteUrl } from "routes.type";
import { Avfall } from "./Avfall";
import { DateTimePicker } from "components/DateTimePicker";
import { Overview } from "./Overview";
import { TextInput } from "components/TextInput";
import { useNotification } from "hooks/useNotification";
import { NvRapporteringStatus } from "enums/NvRapporteringStatus.enum";
import { NvReportedTag } from "components/NvReportedTag";
import { useQueryClient } from "react-query";
import { ActionMenu } from "pages/app/dashboard/ActionMenu";
import { EyeSlashIcon } from "@heroicons/react/24/solid";
import {
  DashboardTabs,
  getLinkToDashboardPage,
} from "pages/app/dashboard/DashboardPage";
import { PageLoader } from "components/PageLoader";
import { Tips } from "components/Tips";
import { removeWhitespace } from "helpers/string";

export const NewTransportPage: React.FC = () => {
  // const navigate = useNavigate();
  // tabs
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const transportDocumentId = searchParams.get("tdid");

  const { data: transportDocument, isLoading: isLoadingTransportDocument } =
    useTransportDocument(!transportDocumentId ? -1 : +transportDocumentId);

  const navigateToPåminnelser = useCallback(
    () => navigate(getLinkToDashboardPage(DashboardTabs.Avfallsregistret)),
    [navigate]
  );

  const mutation = useRapporteraTransport();

  const notification = useNotification();

  useEffect(() => {
    if (mutation.isSuccess) {
      notification.success("Transporten har rapporterats");
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetOne,
        +(transportDocumentId ?? "-1"),
      ]);
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetNotReportedToNv,
      ]);
      navigateToPåminnelser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  // Man ska kunna skapa en helt ny eller utgå från ett existerande transportdokument.
  const form = useForm<TransportDTO>({
    defaultValues: {
      transportsatt: TransportSätt.Vägtransport,
      verksamhetsutovare: "",
      verksamhetensNamn: "",
      startPlatsLocationType: "address",
    },
    resolver: zodResolver(TransportDtoSchema),
    mode: "onSubmit",
  });

  // Gissa postnummer
  const gissaMottagareKommun = useGuessKommun();

  useEffect(() => {
    if (gissaMottagareKommun.data) {
      form.setValue("transportSlutplats.kommunkod", gissaMottagareKommun.data);
    }
  }, [gissaMottagareKommun.data, form]);

  const avfallFieldArray = useFieldArray({
    name: "avfall",
    control: form.control,
  });

  const onSubmit = form.handleSubmit((data: TransportDTO) => {
    // validera startPlats
    let hasError = false;
    if (data.startPlatsLocationType === "address") {
      if (!isValidPostnummer(data.transportStartplats.adress?.postnummer)) {
        form.setError("transportStartplats.adress.postnummer", {
          message: "Ange postnummer i formatet XXXXX",
        });
        hasError = true;
      }

      if (!isValidAdressrad(data.transportStartplats.adress?.adressrad)) {
        form.setError("transportStartplats.adress.adressrad", {
          message: "Adress är obligatoriskt",
        });
        hasError = true;
      }
    } else if (data.startPlatsLocationType === "coordinate") {
      if (!isValidSwerefEValue(data.transportStartplats.koordinat?.eposition)) {
        form.setError("transportStartplats.koordinat.eposition", {
          message: "Ange koordinaten enligt SWEREF99, 6 siffror",
        });
        hasError = true;
      }
      if (!isValidSwerefNValue(data.transportStartplats.koordinat?.nposition)) {
        form.setError("transportStartplats.koordinat.nposition", {
          message: "Ange koordinaten enligt SWEREF99, 7 siffror",
        });
        hasError = true;
      }
    }

    if (data.slutPlatsLocationType === "address") {
      if (!isValidPostnummer(data.transportSlutplats.adress?.postnummer)) {
        form.setError("transportSlutplats.adress.postnummer", {
          message: "Ange postnummer i formatet XXXXX",
        });
        hasError = true;
      }

      if (!isValidAdressrad(data.transportSlutplats.adress?.adressrad)) {
        form.setError("transportSlutplats.adress.adressrad", {
          message: "Adress är obligatoriskt",
        });
        hasError = true;
      }
    } else if (data.slutPlatsLocationType === "coordinate") {
      if (!isValidSwerefEValue(data.transportSlutplats.koordinat?.eposition)) {
        form.setError("transportSlutplats.koordinat.eposition", {
          message: "Ange koordinaten enligt SWEREF99, 6 siffror",
        });
        hasError = true;
      }
      if (!isValidSwerefNValue(data.transportSlutplats.koordinat?.nposition)) {
        form.setError("transportSlutplats.koordinat.nposition", {
          message: "Ange koordinaten enligt SWEREF99, 7 siffror",
        });
        hasError = true;
      }
    }
    if (hasError) {
      return;
    }
    mutation.mutate({
      ...data,
      avfall: data.avfall.map((item) => ({ ...item, mangd: +item.mangd })),
      verksamhetsutovare: data.verksamhetsutovare.replace("-", "").trim(),
      tidigareInnehavare: data.tidigareInnehavare.replace("-", "").trim(),
      nyInnehavare: data.nyInnehavare?.replace("-", "").trim(),
      transportStartplats: {
        kommunkod: data.transportStartplats.kommunkod.trim(),
        adress:
          data.startPlatsLocationType === "address"
            ? {
                ...data.transportStartplats.adress,
                postnummer: removeWhitespace(
                  data.transportStartplats.adress?.postnummer
                ),
                adressRad: data.transportStartplats.adress?.adressrad?.trim(),
              }
            : undefined,
        koordinat:
          data.startPlatsLocationType === "coordinate"
            ? {
                ...data.transportStartplats.koordinat,
                nposition: Number(
                  data.transportStartplats.koordinat?.nposition
                ),
                eposition: Number(
                  data.transportStartplats.koordinat?.eposition
                ),
              }
            : undefined,
      },
      transportSlutplats: {
        kommunkod: data.transportSlutplats.kommunkod.trim(),
        adress:
          data.slutPlatsLocationType === "address"
            ? {
                ...data.transportSlutplats.adress,
                postnummer: removeWhitespace(
                  data.transportSlutplats.adress?.postnummer
                ),
                adressRad: data.transportSlutplats.adress?.adressrad?.trim(),
              }
            : undefined,
        koordinat:
          data.slutPlatsLocationType === "coordinate"
            ? {
                ...data.transportSlutplats.koordinat,
                nposition: Number(data.transportSlutplats.koordinat?.nposition),
                eposition: Number(data.transportSlutplats.koordinat?.eposition),
              }
            : undefined,
      },
      verksamhetensKontaktpersonNamn:
        data.verksamhetensKontaktpersonNamn.trim(),
      verksamhetensKontaktpersonEpost:
        data.verksamhetensKontaktpersonEpost.trim(),
      verksamhetensKontaktpersonTelefonnummer:
        data.verksamhetensKontaktpersonTelefonnummer.trim(),
      verksamhetensNamn: data.verksamhetensNamn.trim(),
    });
  });

  // Reset form
  useEffect(() => {
    if (transportDocument) {
      const startPlatsSweRefKoordinater = getSweref99(
        transportDocument.tomning.separator.coordinates
      );
      let startPlatsKoordinater: Koordinat = {
        nposition: 0,
        eposition: 0,
        beskrivning: "",
      };
      let hasStartPlatsKoordinater = false;
      if (startPlatsSweRefKoordinater) {
        hasStartPlatsKoordinater = true;
        startPlatsKoordinater.nposition = startPlatsSweRefKoordinater[0];
        startPlatsKoordinater.eposition = startPlatsSweRefKoordinater[1];
        startPlatsKoordinater.beskrivning = "";
      }

      const slutPlatsSweRefKoordinater = getSweref99(
        transportDocument.wasteStation?.coordinates
      );
      let slutPlatsKoordinater: Koordinat = {
        nposition: 0,
        eposition: 0,
        beskrivning: "",
      };
      let hasSlutPlatsKoordinater = false;
      if (slutPlatsSweRefKoordinater) {
        hasSlutPlatsKoordinater = true;
        slutPlatsKoordinater.nposition = slutPlatsSweRefKoordinater[0];
        slutPlatsKoordinater.eposition = slutPlatsSweRefKoordinater[1];
        slutPlatsKoordinater.beskrivning = "";
      }

      form.reset({
        referens: transportDocument.tomning.assignment.referenceNumber ?? "",
        transportDocumentId: transportDocument.id,
        verksamhetsutovare:
          transportDocument.company.organizationNumber.replace("-", ""),
        verksamhetensNamn: transportDocument.company.name,
        verksamhetensKontaktpersonNamn:
          transportDocument.company.contactFullName,
        verksamhetensKontaktpersonEpost: transportDocument.company.contactEmail,
        verksamhetensKontaktpersonTelefonnummer:
          transportDocument.company.contactPhone,
        transportStartDatum: transportDocument.timeOfDeparture,
        tidigareInnehavare:
          transportDocument.customer.organizationNumber.replace("-", ""),
        transportStartplats: {
          kommunkod: transportDocument.tomning.separator.kommunKod ?? "",
          adress: {
            adressrad: transportDocument.tomning.separator.address,
            postnummer: transportDocument.tomning.separator.postalCode?.replace(
              " ",
              ""
            ),
          },
          koordinat: hasStartPlatsKoordinater
            ? startPlatsKoordinater
            : undefined,
        },
        startPlatsLocationType:
          !hasAddress(transportDocument.tomning.separator) &&
          hasStartPlatsKoordinater
            ? "coordinate"
            : "address",
        slutPlatsLocationType:
          !hasAddress(transportDocument.wasteStation) && hasSlutPlatsKoordinater
            ? "coordinate"
            : "address",
        nyInnehavare:
          transportDocument.wasteStation?.organizationNumber.replace("-", ""),
        transportSlutplats: {
          kommunkod: transportDocument.wasteStation?.knCode ?? "",
          adress: {
            adressrad: transportDocument.wasteStation?.address,
            postnummer: transportDocument.wasteStation?.postalCode.replace(
              " ",
              ""
            ),
          },
          koordinat: hasSlutPlatsKoordinater ? slutPlatsKoordinater : undefined,
        },
        transportsatt: TransportSätt.Vägtransport,
      });
      transportDocument.wasteList.forEach((waste) => {
        avfallFieldArray.append({
          kod: waste.wasteCode.code,
          mangd: !waste.weightFinal
            ? +waste.weightPreliminary
            : +waste.weightFinal,
          wasteUuid: waste.uuid,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportDocument]);

  const error = mutation.error;
  let errorList: string[] = useMemo(() => {
    if (error) {
      const axiosError = error as any;
      if (axiosError.response?.data?.message) {
        if (Array.isArray(axiosError.response.data.message)) {
          return axiosError.response.data.message;
        } else {
          return [axiosError.response.data.message as string];
        }
      }
    }
    return [];
  }, [error]);

  useEffect(() => {
    if (errorList) {
      if (errorList.includes("Felaktigt värde för TidigareInnehavare")) {
        console.log("sätter error på tidigareInnehavare");
        form.setError("tidigareInnehavare", {
          message: "Felaktigt organisationsnummer",
        });
      }
    }
  }, [errorList, form]);

  const patchTd = usePatchTransportDocument(+(transportDocumentId ?? "-1"));

  useEffect(() => {
    if (patchTd.isSuccess) {
      // Gå tillbaka till fittsidan
      notification.info("Påminnelse borttagen");
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetNotReportedToNv,
      ]);
      navigateToPåminnelser();
    }
  }, [patchTd.isSuccess, navigateToPåminnelser, queryClient, notification]);

  const isCompleted =
    transportDocument?.transportRapporteringStatus ===
    NvRapporteringStatus.Completed;

  if (isLoadingTransportDocument) {
    return <PageLoader />;
  }

  return (
    // <PageTransition>
    <FormProvider {...form}>
      <Panel innerClassname="py-4">
        <div className="max-w-[640px]">
          <Tips
            id=",ajdhfkjashdkjashdkjashdkjashdkjahsdkjhaskd"
            paragraphs={[
              "Informationen ni lägger till eller uppdaterar – inklusive detaljer om kund, objekt och mottagarstation – sparas automatiskt på respektive plats. Detta innebär att ni inte behöver fylla i samma information på nytt vid framtida rapporteringar. Vi gör detta för att förenkla processen och spara tid vid varje rapporteringstillfälle.",
            ]}
          />
        </div>
        <div className="w-full h-0 relative">
          <div className="absolute top-[-16px] left-[-24px]">
            {isCompleted && <NvReportedTag />}
          </div>
          <div className="absolute top-[-4px] right-[-12px] ">
            <ActionMenu
              items={[
                {
                  label: "Ta bort påminnelse",
                  onClick: () =>
                    patchTd.mutate({
                      transportRapporteringStatus: NvRapporteringStatus.Ignored,
                    }),
                  icon: (
                    <EyeSlashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  ),
                },
              ]}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 divide-x mt-4 p-4 rounded-lg border border-gray-200 rounded-b-none">
          <Overview td={transportDocument} />
          <Avfall
            avfallFieldArray={avfallFieldArray}
            register={form.register}
            formState={form.formState}
            disabled={isCompleted}
          />
          <div className="flex flex-col gap-y-4 px-6">
            <h2 className="text-gray-900 text-lg font-semibold">Transport</h2>
            <DateTimePicker
              control={form.control}
              name="transportStartDatum"
              label="Tidpunkt för avfärd"
              disabled={isCompleted}
            />
            <TextInput
              register={form.register}
              label="Referens"
              name="referens"
              placeholder="Valfritt"
              disabled={isCompleted}
            />

            {/* <Dropdown
              items={TransportSättDropdownItems}
              name="transportsatt"
              label={"Transportmedel"}
              register={form.register}
              disabled={isCompleted}
            /> */}
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 divide-x mt-0 p-4 rounded-lg border border-gray-200 rounded-t-none border-t-0">
          {/* Avsändare */}
          <Avsändare
            register={form.register}
            formState={form.formState}
            watch={form.watch}
            errorList={errorList}
            setValue={form.setValue}
            disabled={isCompleted}
          />

          <Mottagare
            register={form.register}
            formState={form.formState}
            watch={form.watch}
            setValue={form.setValue}
            disabled={isCompleted}
            errorList={errorList}
          />
          <Transportör
            register={form.register}
            formState={form.formState}
            disabled={isCompleted}
          />
        </div>

        <FormErrorList errors={errorList} />
        <div className="flex items-end mt-4 justify-between">
          {/* <SecondaryButton size="large">Ignorera påminnelse</SecondaryButton> */}
          <div>
            {!isCompleted && (
              <PrimaryButton
                // className="mt-8"
                size="large"
                onClick={onSubmit}
                isLoading={mutation.isLoading}
              >
                Rapportera till Avfallsregistret
              </PrimaryButton>
            )}
          </div>
        </div>
      </Panel>
    </FormProvider>
    // </PageTransition>
  );
};

export const hasAddress = (data?: { address?: string; postalCode?: string }) =>
  !!data && !!data.address && !!data.postalCode;

type Koordinat = {
  nposition: number;
  eposition: number;
  beskrivning: string;
};

export const getLinkToRapporteraTransportPage = (tdId?: number) =>
  `${RouteUrl.App}/${RouteUrl.Avfallsregistret}/${
    RouteUrl.RapporteraTransport
  }${tdId ? `?tdid=${tdId}` : ""}`;
