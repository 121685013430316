import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Assignment } from "types/assignment.type";
import {
  CreateSeparatorDTO,
  Separator,
  UpdateSeparatorDTO,
} from "types/separator/separator.type";
import instance from "./axiosInstance";
import {
  FileDTO,
  FiveYearsInspectionQueryKey,
} from "./fiveYearsInspection.api";
import { SixMonthsInspectionQueryKey } from "./sixMonthsInspection.api";

export const ENDPOINT = "/separator";
export enum SeparatorQueryKey {
  GetOne = "separator-get-one",
  GetSeparatorAssignments = "separator-get-assignments",
}

export const useGetSeparator = (id: number, config = {}) =>
  useQuery(
    [SeparatorQueryKey.GetOne, id],
    async (): Promise<Separator> =>
      (await instance.get(`${ENDPOINT}/${id}`)).data,
    { ...config }
  );

export const useCreateSeparator = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: CreateSeparatorDTO): Promise<Separator> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      onSuccess: () => {
        notification.info(`Objekt skapat`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateSeparator = (id: number, settings = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: UpdateSeparatorDTO): Promise<Separator> =>
      (await instance.put(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: (data: Separator) => {
        notification.info(`Ändringarna sparades`);
        queryClient.invalidateQueries([SeparatorQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([
          SeparatorQueryKey.GetSeparatorAssignments,
          data.id,
        ]);
        queryClient.invalidateQueries([FiveYearsInspectionQueryKey.GetOne]);
        queryClient.invalidateQueries([SixMonthsInspectionQueryKey.GetOne]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export const useDeleteSeparator = (id: number) => {
  const notification = useNotification();

  return useMutation(async () => await instance.delete(`${ENDPOINT}/${id}`), {
    onSuccess: () => {
      notification.info(`Objekt borttaget`);
    },
    onError: () => {
      notification.error("Något gick fel, kontakta support");
    },
  });
};

export const useSetSeparatorFiles = (options = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: SeparatorFilesDTO): Promise<Separator> =>
      (await instance.put(ENDPOINT + "/files", payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (data: Separator) => {
        notification.info("Ändringarna sparade");
        queryClient.invalidateQueries([SeparatorQueryKey.GetOne, data.id]);
      },
      ...options,
    }
  );
};

export const useSeparatorAssignments = (id: number, config = {}) =>
  useQuery(
    [SeparatorQueryKey.GetSeparatorAssignments, id],
    async (): Promise<Assignment[]> =>
      (await instance.get(`${ENDPOINT}/${id}/assignments`)).data,
    { ...config }
  );

export type SeparatorFilesDTO = {
  separatorId: number;
  files: FileDTO[];
};
