export type WasteStation = {
  id: number;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  organizationNumber: string;
  knCode: string;
  phone: string;
  documentationEmail: string;
  coordinates?: string;
};

export type WasteStationDTO = {
  name: string;
  address: string;
  city: string;
  organizationNumber: string;
  postalCode: string;
  knCode: string;
  phone: string;
  documentationEmail: string;
  coordinates?: string;
};

export const getEmptyWasteStationDTO = (): WasteStationDTO => ({
  name: "",
  address: "",
  city: "",
  organizationNumber: "",
  knCode: "",
  phone: "",
  documentationEmail: "",
  postalCode: "",
});
