import { EmailType } from "enums/EmailType.enum";
import { SixMonthsInspection } from "./sixMonthsInspection/sixMonthsInspection.type";
import { FiveYearsInspection } from "./fiveYearsInspection/fiveYearsInspection.type";
import { TransportDocument } from "./transportDocument.type";
import { Customer } from "./customer.type";
import { getLinkToSixMonthsInspectionPage } from "pages/app/six-months-inspection-page/SixMonthsInspectionPage";
import { getLinkToFiveYearsInspectionPage } from "pages/app/five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToTomningPage } from "pages/app/tomning-page/TomningPage";
import { WasteStation } from "./wasteStation.type";

export type Email = {
  id: number;
  header: string;
  content: string;
  type: EmailType;
  sixMonthsInspection?: SixMonthsInspection;
  fiveYearsInspection?: FiveYearsInspection;
  transportDocument?: TransportDocument;
  attachments: Attachment[];
  to: string;
  createdAt: Date;
  status: EmailStatus;
  sentAt: Date;
  customer: Customer;
  wasteStation: WasteStation;
};

export type Attachment = {
  id: number;
  name: string;
  path: string;
};

export enum EmailStatus {
  Scheduled = 10,
  Sent = 20,
  Delivered = 25,
  FailedToSend = 30,
}

export const getLinkToEmailAssignmentPage = (email: Email) => {
  if (email.sixMonthsInspection) {
    return getLinkToSixMonthsInspectionPage(
      email.customer.id,
      email.sixMonthsInspection.separatorId,
      email.sixMonthsInspection.id
    );
  } else if (email.fiveYearsInspection) {
    return getLinkToFiveYearsInspectionPage(
      email.customer.id,
      email.fiveYearsInspection.separatorId,
      email.fiveYearsInspection.id
    );
  } else if (email.transportDocument) {
    return getLinkToTomningPage(
      email.transportDocument.customer?.id ?? -1,
      email.transportDocument.tomning?.separator.id ?? -1,
      email.transportDocument.tomning?.id ?? -1
    );
  } else {
    throw new Error("Invalid inspection type");
  }
};
