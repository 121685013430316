import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "./axiosInstance";
import { Tomning } from "types/tomning.type";
import { TomningType } from "types/enums";
import { TransportDocumentQueryKey } from "./transportDocument.api";

const ENDPOINT = "/tomning";

export enum TomningQueryKey {
  GetOne = "tomning-get-one",
}

export const useTomning = (id: number, shouldPoll = false) =>
  useQuery(
    [TomningQueryKey.GetOne, id],
    async (): Promise<Tomning> =>
      (await instance.get(`${ENDPOINT}/${id}`)).data,
    {
      enabled: id > 0,
      refetchInterval: shouldPoll ? 3000 : false,
    }
  );

export const useCreateTomning = () => {
  const notification = useNotification();

  return useMutation(
    async (payload: TomningDTO): Promise<Tomning> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateTomning = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: UpdateTomningDTO): Promise<Tomning> =>
      (await instance.put(ENDPOINT, payload)).data,

    {
      onError: () => {
        notification.error("Något gick fel");
      },
      onSuccess: (data: Tomning) => {
        notification.info("Ändringarna sparade");
        queryClient.invalidateQueries([TomningQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
      },
      ...settings,
    }
  );
};

export const useResetTomning = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number): Promise<Tomning> =>
      (await instance.put(`${ENDPOINT}/${id}/reset`)).data,

    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      onSuccess: (data: Tomning) => {
        notification.info("Uppdraget återställt");
        queryClient.invalidateQueries([TomningQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
      },
      ...settings,
    }
  );
};

export const useDeleteTomning = (id: number, settings = {}) => {
  const notification = useNotification();
  return useMutation(
    async () => await instance.delete(`${ENDPOINT}/${id}`),

    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export type TomningDTO = {
  inspectionDate: Date;
  assignmentId?: number;
  separatorId: number;
  referenceNumber?: string;
};

export type UpdateTomningDTO = {
  id: number;
  inspectionDate: Date;
  referenceNumber?: string;
  type: TomningType;
};
