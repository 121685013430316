import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "./axiosInstance";
import { Email } from "types/email.type";

export const ENDPOINT = "/email";

export enum EmailQueryKey {
  GetFailedToSend = "email-failed-to-send",
}

export const useFailedToSendEmails = (settings = {}) =>
  useQuery(
    [EmailQueryKey.GetFailedToSend],
    async (): Promise<Email[]> =>
      (await instance.get(`${ENDPOINT}/failed-to-send`)).data,
    { ...settings }
  );

export const usePatchEmail = (settings = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: {
      id: number;
      payload: Partial<{ isErrorConfirmed: boolean }>;
    }): Promise<Email> =>
      (await instance.patch(`${ENDPOINT}/${data.id}`, data.payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([EmailQueryKey.GetFailedToSend]);
      },
      ...settings,
    }
  );
};
