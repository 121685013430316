import { FC } from "react";
import { Transition } from "./animations/Transition";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { config } from "react-spring";

export const EmptyStatePop: FC<{ text: string }> = ({ text }) => (
  <div className="flex flex-col items-center mx-auto my-24">
    <Transition
      settings={{
        from: {
          transform: "scale(0)",
        },
        enter: {
          transform: "scale(1)",
        },
        delay: 100,
        config: { ...config.wobbly, tension: 210, friction: 9 },
      }}
      isVisible={true}
    >
      <CheckCircleIcon className="text-cyan-400 h-12 w-12" />
    </Transition>
    <span className="text-base text-gray-800 mt-2">{text}</span>
  </div>
);
