import { useEffect, useMemo, useState } from "react";
import { Tomning } from "types/tomning.type";
import { TomningQueryKey } from "api/tomning.api";
import { useNotification } from "hooks/useNotification";
import { useQueryClient } from "react-query";
import {
  TransportDocumentQueryKey,
  useUpdateTransportDocumentUser,
} from "api/transportDocument.api";
import { useUsers } from "api/user.api";
import { userToDropdownItem } from "types/user.type";
import { useForm } from "react-hook-form";

export const useUserLogic = (tomning?: Tomning) => {
  const { data: users } = useUsers();
  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );

  const notification = useNotification();
  const queryClient = useQueryClient();
  // Reset to assignment fields
  const [isChangeUserModalOpen, setIsChangeUserModalOpen] =
    useState<boolean>(false);

  const {
    mutate: changeUser,
    isLoading: isChangingUser,
    isSuccess: isChangeUserSuccess,
  } = useUpdateTransportDocumentUser(tomning?.transportDocument?.id ?? -1, {
    onSuccess: () => {
      notification.info("Användare ändrad");
      queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
      queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
    },
  });

  const { register, handleSubmit, reset } = useForm<{
    userId: number;
  }>();

  useEffect(() => {
    if (tomning) {
      reset({
        userId: tomning.transportDocument.user.id,
      });
    }
  }, [tomning, reset]);

  const onUserSubmit = handleSubmit((data: { userId: number }) => {
    if (!tomning) {
      throw Error("No tomning");
    }
    changeUser({ userId: +data.userId });
  });

  useEffect(() => {
    if (isChangeUserSuccess) {
      handleModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeUserSuccess]);

  const handleModalClose = () => {
    setIsChangeUserModalOpen(false);
    setTimeout(() => {
      reset();
    }, 300);
  };

  return {
    isChangeUserModalOpen,
    openChangeUserModal: () => setIsChangeUserModalOpen(true),
    closeChangeUserModal: () => setIsChangeUserModalOpen(false),
    isChangingUser,
    isChangeUserSuccess,
    userDropdownItems,
    onUserSubmit,
    registerUser: register,
    handleChangeUserModalClose: handleModalClose,
  };
};
