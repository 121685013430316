import { Link } from "components/Link";
import { FC } from "react";

interface AvtalPropertyProps {
  label: string;
  value: string;
  action?: () => void;
  actionText?: string;
  link?: string;
}
export const AvtalProperty: FC<AvtalPropertyProps> = ({
  label,
  value,
  action,
  actionText = "Ändra",
  link,
}) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
      <span className="flex-grow">{value}</span>
      <span className="ml-4 flex-shrink-0">
        {!!link && (
          <Link to={link} target="_blank" rel="noopener noreferrer">
            {actionText}
          </Link>
        )}
        {!link && !!action && (
          <button
            onClick={action}
            type="button"
            className="rounded-md bg-white font-medium text-cyan-600 hover:text-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            {actionText}
          </button>
        )}
      </span>
    </dd>
  </div>
);
