import clsx from "clsx";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  innerClassname?: string;
}
export const Panel = ({ children, innerClassname }: Props) => (
  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
    <div className={twMerge(clsx("px-4 py-6 sm:px-6", innerClassname))}>
      {children}
    </div>
  </div>
);
