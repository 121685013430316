import { zodResolver } from "@hookform/resolvers/zod";
import { TomningQueryKey } from "api/tomning.api";
import { usePatchTransportDocument } from "api/transportDocument.api";
import { nowAsISOString } from "helpers/date";
import { useNotification } from "hooks/useNotification";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Tomning } from "types/tomning.type";
import { z } from "zod";

const transportDateSchema = z.object({
  timeOfDeparture: z.string().optional(),
});

type TransportDateDto = z.infer<typeof transportDateSchema>;

export const useUpdateTransportDate = (tomning?: Tomning) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  const [initialValue, setInitialValue] = useState<string | undefined>();

  const form = useForm<TransportDateDto>({
    defaultValues: {
      timeOfDeparture: nowAsISOString(),
    },
    resolver: zodResolver(transportDateSchema),
    mode: "onChange",
  });

  // Watch for changes in timeOfDeparture field
  const timeOfDeparture = useWatch({
    control: form.control,
    name: "timeOfDeparture", // The name of the field to watch
  });

  const { mutate: patchTd } = usePatchTransportDocument(
    tomning?.transportDocument?.id || -1,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
      },
      onError: () => {
        notification.error("Något gick fel");
      },
    }
  );

  // resetta formen när man har en tömning
  useEffect(() => {
    if (tomning?.transportDocument?.id) {
      setInitialValue(tomning.transportDocument.timeOfDeparture);
      form.reset({
        timeOfDeparture: tomning.transportDocument.timeOfDeparture,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tomning?.transportDocument?.id]);

  useEffect(() => {
    console.log({ timeOfDeparture });
    console.log({ initialValue });
    if (
      timeOfDeparture &&
      initialValue &&
      timeOfDeparture !== initialValue &&
      !!timeOfDeparture
    ) {
      patchTd({ timeOfDeparture });
    }
  }, [timeOfDeparture, initialValue, patchTd]);

  return {
    form,
  };
};
