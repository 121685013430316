import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "./axiosInstance";
import { Vehicle, VehicleDTO } from "types/vehicle.type";

export const ENDPOINT = "/vehicle";
export enum VehicleQueryKey {
  GetAll = "vehicle-get-all",
  GetOne = "vehicle-get-one",
  GetContentByCode = "vehicle-get-content-by-code",
}

export const useVehicles = () =>
  useQuery(
    VehicleQueryKey.GetAll,
    async (): Promise<Vehicle[]> => (await instance.get(ENDPOINT)).data
  );

export const useVehicle = (id: number) =>
  useQuery(
    [VehicleQueryKey.GetOne, id],
    async (): Promise<Vehicle> => (await instance.get(`${ENDPOINT}/${id}`)).data
  );

export const useVehicleByCode = (code: string) =>
  useQuery(
    [VehicleQueryKey.GetContentByCode, code],
    async (): Promise<VehicleByCodeResponse[]> =>
      (await instance.get(`${ENDPOINT}/vehicle-content/${code}`)).data,
    { retry: 1 }
  );

export type VehicleByCodeResponse = {
  registrationNumber: string;
  wasteList: {
    code: string;
    description: string;
    quantity: string;
  }[];
};

export const useCreateVehicle = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: VehicleDTO): Promise<Vehicle> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([VehicleQueryKey.GetAll]);
        notification.info(`Fordon skapat`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateVehicle = (id?: number) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: VehicleDTO): Promise<Vehicle> =>
      (await instance.put(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([VehicleQueryKey.GetAll]);
        notification.info(`Fordon ändrat`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};
