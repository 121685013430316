import { useNvRapporteringList } from "api/avfallsregistret.api";
import { Info } from "components/Info";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import { Pagination } from "components/Pagination";
import { PageTransition } from "components/animations/SnapUp";
import { TabDisplayItemLabel } from "components/tab/TabDisplayItemLabel";
import { TabDisplayItemValue } from "components/tab/TabDisplayItemValue";
import { TabDisplayItemWrapper } from "components/tab/TabDisplayItemWrapper";
import { TBody, TD, TH, THead, TR, Table } from "components/table";
import { formatDate } from "helpers/date";
import { isNumber } from "helpers/number";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  NvRapportering,
  rapporteringsTypLabel,
} from "types/nvRapportering.type";

const PAGE_SIZE = 20;
export const NvRapporteringListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<
    NvRapportering | undefined
  >();

  const pageParam = searchParams.get("page") ?? "1";
  const pageNumber = isNumber(pageParam) ? +pageParam : 1;

  const { data, isLoading } = useNvRapporteringList(
    PAGE_SIZE,
    (pageNumber - 1) * PAGE_SIZE
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageTransition>
      <div className="px-4 sm:px-0 space-y-4">
        <Info text="Här kan du se alla rapporteringar som skickats till Naturvårdsverkets Avfallsregister. Klicka på en rad för att se exakt vilken information som skickats." />
        {/* <div className="sm:flex sm:items-center mb-4">
              <div className="sm:flex-auto space-y-2">
                <SearchField
                  placeholder="Namn, kundnummer, email, telefon"
                  onSearch={(query: string) => {
                    if (query) {
                      setQuery(query);
                    } else {
                      searchParams.delete("query");
                      setSearchParams(searchParams);
                    }
                  }}
                  initialValue={queryParam}
                />
                <CheckboxStupid
                  label="Visa borttagna"
                  name="showDeleted"
                  checked={showDeleted}
                  setChecked={(checked) => setShowDeleted(checked)}
                  small
                />
              </div>
              {isAdmin && (
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex items-end justify-end">
                  <PrimaryButton onClick={addCustomer.openModal}>
                    + Ny kund
                  </PrimaryButton>
                </div>
              )}
            </div> */}

        <Table>
          <THead>
            <TR>
              <TH>Typ</TH>
              <TH>Avfallsproducent</TH>
              <TH>Utförd av</TH>
              <TH>Datum</TH>
            </TR>
          </THead>
          <TBody>
            {(data?.items ?? []).map((item) => (
              <TR
                key={item.uuid}
                onClick={() => {
                  setSelectedItem(item);
                  setIsModalOpen(true);
                }}
              >
                <TD>{rapporteringsTypLabel(item.type)}</TD>
                <TD>{item.customerName}</TD>
                <TD>{item.performedByUserName}</TD>
                <TD>{formatDate(item.createdAt)}</TD>
              </TR>
            ))}
          </TBody>
        </Table>
        <Modal
          isOpen={isModalOpen}
          title={
            selectedItem
              ? rapporteringsTypLabel(selectedItem.type)
              : "Rapportering"
          }
          onClose={() => setIsModalOpen(false)}
          afterClose={() => setSelectedItem(undefined)}
          closeButtonText="Stäng"
          isPrettyWide
        >
          {selectedItem && (
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 pt-6">
              <TabDisplayItemWrapper>
                <TabDisplayItemLabel>
                  Naturvårdsverkets AvfallsID
                </TabDisplayItemLabel>
                <TabDisplayItemValue>
                  {selectedItem.nvAvfallId}
                </TabDisplayItemValue>
              </TabDisplayItemWrapper>
              <TabDisplayItemWrapper>
                <TabDisplayItemLabel>Data skickad</TabDisplayItemLabel>
                <TabDisplayItemValue>
                  <div className="max-w-xs">
                    <pre>{JSON.stringify(selectedItem.data, null, 2)}</pre>
                  </div>
                </TabDisplayItemValue>
              </TabDisplayItemWrapper>
              {/* <p>{selectedItem.customerName}</p>
              <p>{selectedItem.performedByUserName}</p>
              <p>{formatDate(selectedItem.createdAt)}</p> */}
            </dl>
          )}
        </Modal>
      </div>

      <Pagination
        currentPage={pageNumber}
        totalCount={data?.totalCount ?? 0}
        pageSize={PAGE_SIZE}
        setPage={(page: number) => setSearchParams({ page: page.toString() })}
      />
    </PageTransition>
  );
};
