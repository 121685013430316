// import { CalendarIcon } from "@heroicons/react/20/solid";
import { GET_TASKS_QK, useIgnoreTask, useUpdateTask } from "api/task.api";
import { addWeeks } from "date-fns";
import { TaskType } from "enums/TaskType.enum";
import { classNames } from "helpers/classNames";
import {
  dateHasPassed,
  getWeekWithLabel,
  getWeeksAsDropdownItems,
} from "helpers/date";
import { FC, useEffect, useMemo, useState } from "react";
import { Task } from "types/task.type";
import { ActionMenu } from "pages/app/dashboard/ActionMenu";
import { useCreateAssignment } from "api/assignment.api";
import { useForm } from "react-hook-form";
import { AssignmentDTO, getEmptyAssignmentDTO } from "types/assignment.type";
import Modal from "components/Modal";
import { ScheduleAssignment } from "./ScheduleAssignment";
import { taskTypeToAssignmentType } from "enums/AssignmentType.enum";
import { useQueryClient } from "react-query";
import {
  CalendarIcon,
  CircleStackIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { Link } from "components/Link";
import { appRoutes } from "routes.type";
import { animated, config, useTransition } from "react-spring";
import {
  BackwardIcon,
  ClockIcon,
  CalendarDaysIcon,
} from "@heroicons/react/20/solid";
import { EmptyStatePop } from "components/EmptyStatePop";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import { ScreenWidth, useScreenWidth } from "hooks/useScreenWidth";
import { useHasPassedTimeAfterInitialRender } from "hooks/useHasPassedTime";

interface Props {
  tasks?: Task[];
}

export const Tasks: FC<Props> = ({ tasks = [] }) => {
  const screenWidth = useScreenWidth();
  const ITEM_HEIGHT = screenWidth === ScreenWidth.Mobile ? 141.5 : 85.5;

  const { mutate: updateTask } = useUpdateTask();
  const { mutate: ignoreTheTask } = useIgnoreTask();

  const ignoreTask = (task: Task) => {
    ignoreTheTask(task.id);
  };

  const pushForwardTask = (task: Task) => {
    updateTask({ ...task, date: addWeeks(new Date(task.date), 1) });
  };

  // Schedule task
  const [taskIdToSchedule, setTaskIdToSchedule] = useState<number | undefined>(
    undefined
  );
  // Behöver detta dubbelstate för animeringen
  const [isCreateAssignmentModalOpen, setIsCreateAssignmentModalOpen] =
    useState<boolean>(false);

  const onAssignClick = (task: Task) => {
    setTaskIdToSchedule(task.id);
    setIsCreateAssignmentModalOpen(true);
  };

  const {
    mutate: scheduleTask,
    isLoading: isCreatingAssignment,
    isSuccess: isCreateAssignmentSuccess,
  } = useCreateAssignment();
  const {
    register: registerAssignment,
    handleSubmit,
    reset: resetAssignmentForm,
  } = useForm<AssignmentDTO>({ defaultValues: getEmptyAssignmentDTO() });

  const onAssignmentSubmit = handleSubmit((data: AssignmentDTO) => {
    // Här ska vi alltid ha en task
    const task = tasks.find((task) => task.id === taskIdToSchedule);
    if (!task) {
      throw Error("Couldn't find task");
    }
    if (!data.userId) {
      // Ska aldrig komma hit
      throw Error("Couldn't find user");
    }
    scheduleTask({
      ...data,
      taskId: task.id,
      userId: +data.userId,
      type: taskTypeToAssignmentType(task.type),
      separatorId: task.separatorId,
      customerId: task.customer.id,
    });
  });

  const onScheduleTaskModalClose = () => {
    setIsCreateAssignmentModalOpen(false);
    setTimeout(() => {
      setTaskIdToSchedule(undefined);
      resetAssignmentForm(getEmptyAssignmentDTO());
    }, 300);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    if (isCreateAssignmentSuccess) {
      resetAssignmentForm(getEmptyAssignmentDTO());
      setIsCreateAssignmentModalOpen(false);
      setTimeout(() => queryClient.invalidateQueries([GET_TASKS_QK]), 300);
    }
  }, [isCreateAssignmentSuccess, resetAssignmentForm, queryClient]);

  const oneSecondHasPassed = useHasPassedTimeAfterInitialRender(1000);

  const weeksAsDropdownItems = useMemo(() => getWeeksAsDropdownItems(), []);
  let height = 0;
  const transitions = useTransition(
    tasks.map((data) => ({
      ...data,
      y: (height += ITEM_HEIGHT) - ITEM_HEIGHT,
    })),
    {
      key: (item: Task) => item.id,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y }) => ({ y, height: ITEM_HEIGHT, opacity: 1 }),
      update: ({ y }) => ({ y, height: ITEM_HEIGHT }),
      config: config.stiff,
      immediate: !oneSecondHasPassed,
    }
  );

  if (tasks.length === 0) {
    return <EmptyStatePop text="Inget att schemalägga" />;
  }

  return (
    <>
      <div className="bg-white shadow sm:rounded-md">
        {/* <ul className="divide-y divide-gray-200"> */}
        <ul
          className="divide-y divide-gray-200 relative h-full"
          style={{ height }}
        >
          {transitions((style, task, t, index) => (
            <animated.div
              className="absolute w-full"
              style={{
                zIndex: tasks.length - index,
                ...style,
                height: ITEM_HEIGHT,
              }}
            >
              <TaskItem
                key={task.id}
                task={task}
                assign={() => onAssignClick(task)}
                ignore={() => ignoreTask(task)}
                pushForward={() => pushForwardTask(task)}
              />
            </animated.div>
          ))}
          {/* {tasks.map((task) => (
            <TaskItem
              key={task.id}
              task={task}
              assign={() => onAssignClick(task)}
              ignore={() => ignoreTask(task)}
              pushForward={() => pushForwardTask(task)}
            />
          ))} */}
        </ul>
      </div>
      <Modal
        title="Schemalägg"
        isOpen={isCreateAssignmentModalOpen}
        onClose={onScheduleTaskModalClose}
        actionHandler={onAssignmentSubmit}
        actionText="Spara"
        isLoading={isCreatingAssignment}
      >
        <ScheduleAssignment
          register={registerAssignment}
          dateDropdownItems={weeksAsDropdownItems}
          task={tasks.find((task) => task.id === taskIdToSchedule)}
        />
      </Modal>
    </>
  );
};

interface TaskItemProps {
  task: Task;
  ignore: () => void;
  pushForward: () => void;
  assign: () => void;
}
const TaskItem: FC<TaskItemProps> = ({ task, ignore, pushForward, assign }) => (
  <li key={task.id} className="relative w-full border border-gray-100">
    <div className="flex items-center px-4 py-4 sm:px-6">
      <div className="min-w-0 flex-1">
        <AssignmentTypeTag type={taskTypeToAssignmentType(task.type)} />
        <div className="mt-2 flex flex-col sm:flex-row">
          <Link
            to={`${appRoutes.customers.fullPath}/${task?.customer?.id}`}
            className="flex items-center text-sm text-gray-800 mr-8 w-xs truncate w-48"
          >
            <FaceSmileIcon
              className="mr-1 h-4 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {task.customer.name}
          </Link>
          {!!task.separator && (
            <Link
              to={`${appRoutes.customers.fullPath}/${task.customer?.id}/${task.separator.id}`}
              className="flex items-center text-sm text-gray-800 mr-8 mt-2 sm:mt-0 w-xs truncate w-36"
            >
              <CircleStackIcon
                className="mr-1 h-4 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {task.separator?.identifier}
            </Link>
          )}
          {/* </div> */}
          <div className="flex items-center text-sm text-gray-700 flex-1 mt-2 sm:mt-0">
            <CalendarIcon
              className="mr-1 h-4 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <p
              className={classNames(
                dateHasPassed(new Date(task.date)) && "text-red-600"
              )}
            >
              Senast <span>{getWeekWithLabel(new Date(task.date))}</span>
            </p>
          </div>
        </div>
      </div>
      <ActionMenu
        items={[
          {
            label: "Schemalägg",
            onClick: assign,
            icon: (
              <CalendarDaysIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
          },
          {
            label: "Flytta fram en vecka",
            onClick: pushForward,
            icon: (
              <ClockIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            isHidden: task.type === TaskType.Tomning,
          },
          {
            label: "Hoppa över tillfälle",
            onClick: ignore,
            icon: (
              <BackwardIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 rotate-180"
                aria-hidden="true"
              />
            ),
          },
        ]}
      />
    </div>
  </li>
);
