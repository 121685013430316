export const getMobileOperatingSystem = () => {
  var userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iPadOS detection
  if (
    /Macintosh/.test(userAgent) &&
    "ontouchstart" in window &&
    navigator.maxTouchPoints > 1
  ) {
    return "iPadOS";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  return "unknown";
};
export const isMobile = () => getMobileOperatingSystem() !== "unknown";

export const isIOS = () =>
  getMobileOperatingSystem() === "iOS" ||
  getMobileOperatingSystem() === "iPadOS";

export const isAndroid = () => getMobileOperatingSystem() === "Android";

export const getEndpointTimestamp = () => new Date().toLocaleTimeString();
