import { useEffect } from "react";
import { ErrorAlert } from "components/ErrorAlert";
import { useVehicleByCode } from "api/vehicle.api";
import { PageLoader } from "components/PageLoader";

export const VehicleContentPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";

  const { data, isLoading, isError } = useVehicleByCode(code);

  useEffect(() => {
    document.title = "Gods - Driftjournalen";
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }
  if (isError || !data) {
    return (
      <div>
        <ErrorAlert text="Något gick fel" />
        <p className="text-gray-900 text-lg">Ring transportören</p>
      </div>
    );
  }

  return (
    <div className="px-6 sm:px-8 bg-white min-h-screen py-8">
      <h2 className="flex items-center justify-between text-lg font-semibold mb-4">
        Godsförteckning
      </h2>
      <div className="space-y-8">
        {data.map(({ registrationNumber, wasteList }) => (
          <div key={registrationNumber} className="">
            <h3 className="font-medium mb-4">Fordon: {registrationNumber}</h3>
            <div className="space-y-4">
              {wasteList.map(({ code, description, quantity }) => (
                <div
                  key={code}
                  className="max-w-lg space-y-2 border border-gray-200 rounded-lg p-4"
                >
                  <div>
                    <span className="font-medium">Avfallskod:</span> {code}
                  </div>
                  <div>
                    <span className="font-medium">Beskrivning:</span>{" "}
                    {description}
                  </div>
                  <div>
                    <span className="font-medium">Uppskattad vikt:</span>{" "}
                    {quantity}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
