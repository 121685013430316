import { FC, useCallback, useEffect, useState } from "react";
import { InspectionPDFPreview } from "components/FilePreview";
import { HasSentStatus, TransportDocument } from "types/transportDocument.type";
import PrimaryButton from "components/buttons/PrimaryButton";
import {
  FinalEmailDTO,
  TransportDocumentQueryKey,
} from "api/transportDocument.api";
import Alert from "components/Alert";
import { TransportDocumentStatus } from "types/enums";
import { Info } from "components/Info";
import Modal from "components/Modal";
import { DocumentationEmailForm } from "components/DocumentationEmailForm";
import { useQueryClient } from "react-query";
import { TomningQueryKey } from "api/tomning.api";
import { useForm } from "react-hook-form";
import { usePatchWasteStation } from "api/wasteStation.api";
import { classNames } from "helpers/classNames";
import SecondaryButton from "components/buttons/SecondaryButton";
import { EmailStatus } from "types/email.type";
import { ErrorAlert } from "components/ErrorAlert";
import { capitalizeFirstLetter } from "helpers/string";
import { Assignment } from "types/assignment.type";
import { useUpdateCustomerDocumentationEmailLogic } from "./hooks/useUpdateCustomerDocumentationEmailLogic.hook";

type Props = {
  fileName?: string;
  previewPDF: () => void;
  transportDocument: TransportDocument;
  sendPDF: (data: FinalEmailDTO) => void;
  isLoadingPreview: boolean;
  tomningId: number;
  assignment: Assignment;
};

export const Attachments: FC<Props> = ({
  previewPDF,
  fileName = "",
  transportDocument,
  sendPDF,
  isLoadingPreview,
  tomningId,
  assignment,
}) => {
  const queryClient = useQueryClient();
  const invalidateTomning = useCallback(() => {
    queryClient.invalidateQueries([TomningQueryKey.GetOne, tomningId]);
    queryClient.invalidateQueries([
      TransportDocumentQueryKey.GetOne,
      transportDocument.id,
    ]);
  }, [queryClient, tomningId, transportDocument.id]);

  // Update customer documentationEmail
  const customerDocEmailLogic = useUpdateCustomerDocumentationEmailLogic(
    invalidateTomning,
    transportDocument.customer
  );

  // Update parentCustomer documentationEmail
  const parentCustomerDocEmailLogic = useUpdateCustomerDocumentationEmailLogic(
    invalidateTomning,
    assignment.parentCustomer
  );

  // Update wasteStation documentation email
  const [
    isWasteStationDocumentationModalOpen,
    setIsWasteStationDocumentationModalOpen,
  ] = useState<boolean>(false);

  const {
    mutate: patchWasteStation,
    isSuccess: isPatchWasteStationSuccess,
    isLoading: isPatchingWasteStation,
  } = usePatchWasteStation(transportDocument.wasteStation?.id ?? -1, {
    onSuccess: () => {
      // Det här är för att jag inte orkar kolla vilken av dem det är
      queryClient.invalidateQueries([TomningQueryKey.GetOne, tomningId]);
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetOne,
        transportDocument.id,
      ]);
    },
  });

  const {
    register: registerWasteStationDocumentationEmail,
    handleSubmit: handleWasteStationDocumentationEmailSubmit,
    reset: resetWasteStationDocumentationEmail,
    formState: { errors: wasteStationDocumentationEmailErrors },
  } = useForm<{ documentationEmail: string }>();

  const onWasteStationDocumentationEmailSubmit =
    handleWasteStationDocumentationEmailSubmit(
      (data: { documentationEmail: string }) => patchWasteStation(data)
    );

  useEffect(() => {
    if (transportDocument.wasteStation) {
      resetWasteStationDocumentationEmail({
        documentationEmail: transportDocument.wasteStation.documentationEmail,
      });
    }
  }, [transportDocument.wasteStation, resetWasteStationDocumentationEmail]);

  useEffect(() => {
    if (isPatchWasteStationSuccess) {
      setIsWasteStationDocumentationModalOpen(false);
    }
  }, [isPatchWasteStationSuccess]);

  const closeWasteStationDocumentationEmailModal = () => {
    setIsWasteStationDocumentationModalOpen(false);
    setTimeout(() => {
      if (transportDocument.wasteStation) {
        resetWasteStationDocumentationEmail({
          documentationEmail: transportDocument.wasteStation.documentationEmail,
        });
      }
    }, 300); // Wait for modal to close
  };

  // Annat skit

  const isSentWithInspection =
    transportDocument.emailToCustomerStatus ===
    HasSentStatus.SentWithOtherInspection;

  const hasSentToCustomer =
    transportDocument.emailToCustomerStatus === HasSentStatus.Sent ||
    isSentWithInspection;
  const hasSentToWasteStation =
    transportDocument.emailToWasteStationStatus === HasSentStatus.Sent;

  const hasSentToParentCustomer =
    transportDocument.emailToParentCustomerStatus === HasSentStatus.Sent;

  const customerEmails = (transportDocument.emails ?? []).filter(
    (e) => !!e.customer && e.customer?.id === transportDocument.customer?.id
  );

  const parentCustomerEmails = (transportDocument.emails ?? []).filter(
    (e) => !!e.customer && e.customer?.id === assignment.parentCustomer?.id
  );

  const wasteStationEmails = (transportDocument.emails ?? []).filter(
    (e) => !!e.wasteStation
  );
  const hasCustomerEmailError =
    customerEmails.length > 0 &&
    customerEmails[0].status === EmailStatus.FailedToSend;

  const errorCustomerName =
    customerEmails.length > 0 ? customerEmails[0].customer.name : "";

  const hasParentCustomerEmailError =
    parentCustomerEmails.length > 0 &&
    parentCustomerEmails[0].status === EmailStatus.FailedToSend;

  const errorParentCustomerName =
    parentCustomerEmails.length > 0
      ? parentCustomerEmails[0].customer.name
      : "";

  const errorWasteStationName =
    wasteStationEmails.length > 0
      ? wasteStationEmails[0].wasteStation.name
      : "";

  const hasWasteStationEmailError =
    wasteStationEmails.length > 0 &&
    wasteStationEmails[0].status === EmailStatus.FailedToSend;

  const isSigned = transportDocument.status === TransportDocumentStatus.Signed;

  const isLoadedOrMore =
    transportDocument.status !== TransportDocumentStatus.Created;

  const sendToParent = () =>
    sendPDF({
      id: transportDocument.id,
      payload: {
        toCustomer: false,
        toWasteStation: false,
        toParentCustomer: true,
      },
    });

  const sendToCustomer = () =>
    sendPDF({
      id: transportDocument.id,
      payload: {
        toCustomer: true,
        toWasteStation: false,
        toParentCustomer: false,
      },
    });

  const sendToWasteStation = () =>
    sendPDF({
      id: transportDocument.id,
      payload: {
        toCustomer: false,
        toWasteStation: true,
        toParentCustomer: false,
      },
    });

  return (
    <div className="flex flex-col">
      <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg mt-6 sm:mt-0 lg:ml-7">
        <div className="px-4 py-5 sm:px-6 flex pb-5 flex-col">
          <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mt-px flex items-center justify-between">
            Transportdokument
          </h3>
        </div>
        {isLoadedOrMore && (
          <div className="border-t border-gray-100 px-4 sm:px-6 py-6">
            <InspectionPDFPreview
              onClick={previewPDF}
              inspectionName={fileName}
              isLoading={isLoadingPreview}
            />
          </div>
        )}

        {isSigned && (
          <>
            {assignment.isProxyAssignment ? (
              <>
                <ReceiverItem
                  hasSent={hasSentToParentCustomer}
                  sendButtonClick={sendToParent}
                  receiverLabel="uppdragsförmedlaren"
                  documentationEmail={
                    assignment.parentCustomer?.documentationEmail as string
                  }
                  openDocumentationModal={parentCustomerDocEmailLogic.openModal}
                  hasEmailError={hasParentCustomerEmailError}
                  errorName={errorParentCustomerName}
                  receiverName={assignment.parentCustomer?.name as string}
                />
                <ReceiverItem
                  hasSent={hasSentToCustomer}
                  sendButtonClick={sendToCustomer}
                  receiverLabel="lämnaren"
                  documentationEmail={
                    transportDocument.customer?.documentationEmail as string
                  }
                  openDocumentationModal={customerDocEmailLogic.openModal}
                  hasEmailError={hasCustomerEmailError}
                  errorName={errorCustomerName}
                  receiverName={transportDocument.customer?.name as string}
                />
                {/* <ReceiverItem
                  hasSent={hasSentToParentCustomer}
                  sendButtonClick={() =>
                    sendPDF({
                      id: transportDocument.id,
                      payload: {
                        toCustomer: true,
                        toWasteStation: false,
                        toParentCustomer: false,
                      },
                    })
                  }
                  receiverLabel="lämnaren"
                  documentationEmail={
                    transportDocument.customer?.documentationEmail as string
                  }
                  openDocumentationModal={() =>
                    setIsCustomerDocumentationModalOpen(true)
                  }
                  hasEmailError={hasCustomerEmailError}
                  errorName={errorCustomerName}
                  receiverName={transportDocument.customer?.name as string}
                /> */}
              </>
            ) : (
              <ReceiverItem
                hasSent={hasSentToCustomer}
                sendButtonClick={sendToCustomer}
                receiverLabel="kunden"
                documentationEmail={
                  transportDocument.customer?.documentationEmail as string
                }
                openDocumentationModal={customerDocEmailLogic.openModal}
                hasEmailError={hasCustomerEmailError}
                errorName={errorCustomerName}
                receiverName={transportDocument.customer?.name as string}
              />
            )}

            <ReceiverItem
              hasSent={hasSentToWasteStation}
              sendButtonClick={sendToWasteStation}
              receiverLabel="mottagarstationen"
              documentationEmail={
                transportDocument.wasteStation?.documentationEmail as string
              }
              openDocumentationModal={() =>
                setIsWasteStationDocumentationModalOpen(true)
              }
              hasEmailError={hasWasteStationEmailError}
              errorName={errorWasteStationName}
              receiverName={transportDocument.wasteStation?.name as string}
            />
          </>
        )}

        {!isSigned && (
          <Info text="Slutför uppdraget för att kunna skicka rapporten" />
        )}
      </div>
      <Modal
        isOpen={customerDocEmailLogic.isModalOpen}
        onClose={customerDocEmailLogic.closeModal}
        title="Ändra kund"
        actionText="Spara"
        actionHandler={customerDocEmailLogic.onSubmit}
        isLoading={customerDocEmailLogic.isSubmitting}
        loadingText="Sparar..."
      >
        <DocumentationEmailForm
          register={customerDocEmailLogic.register}
          errors={customerDocEmailLogic.errors}
          customerName={transportDocument.customer?.name ?? ""}
        />
      </Modal>
      <Modal
        isOpen={parentCustomerDocEmailLogic.isModalOpen}
        onClose={parentCustomerDocEmailLogic.closeModal}
        title="Ändra uppdragsförmedlare"
        actionText="Spara"
        actionHandler={parentCustomerDocEmailLogic.onSubmit}
        isLoading={parentCustomerDocEmailLogic.isSubmitting}
        loadingText="Sparar..."
      >
        <DocumentationEmailForm
          register={parentCustomerDocEmailLogic.register}
          errors={parentCustomerDocEmailLogic.errors}
          customerName={assignment.parentCustomer?.name as string}
        />
      </Modal>
      <Modal
        isOpen={isWasteStationDocumentationModalOpen}
        onClose={closeWasteStationDocumentationEmailModal}
        title="Ändra mottagarstation"
        actionText="Spara"
        actionHandler={onWasteStationDocumentationEmailSubmit}
        isLoading={isPatchingWasteStation}
        loadingText="Sparar..."
      >
        <DocumentationEmailForm
          register={registerWasteStationDocumentationEmail}
          errors={wasteStationDocumentationEmailErrors}
          wasteStationName={transportDocument.wasteStation?.name ?? ""}
        />
      </Modal>
    </div>
  );
};

type ReceiverItemProps = {
  hasSent: boolean;
  sendButtonClick: () => void;
  receiverLabel: string;
  receiverName: string;
  documentationEmail: string;
  openDocumentationModal: () => void;
  hasEmailError: boolean;
  errorName?: string;
};
const ReceiverItem: FC<ReceiverItemProps> = ({
  hasSent,
  sendButtonClick,
  receiverLabel,
  receiverName,
  documentationEmail,
  openDocumentationModal,
  hasEmailError,
  errorName,
}) =>
  !documentationEmail ? (
    <Alert
      text={`${capitalizeFirstLetter(
        receiverLabel
      )} saknar Epostadress för rapporter.`}
      buttonText="Lägg till"
      onButtonClick={openDocumentationModal}
    />
  ) : (
    <div
      className={classNames(
        "border-t border-gray-75",
        !!documentationEmail ? "px-4 sm:px-6 py-6" : ""
      )}
    >
      <h4 className="text-gray-900 text-sm font-semibold mb-4">
        {receiverName}
      </h4>
      {hasEmailError && (
        <div className="mb-2">
          <ErrorAlert
            text={`Det senaste utskicket gick inte fram till ${errorName}. Kontrollera att epostadressen stämmer.`}
          />
        </div>
      )}
      <div className="mb-2">
        {hasSent ? (
          <SecondaryButton onClick={sendButtonClick} fullWidth>
            {`Skicka igen till ${capitalizeFirstLetter(receiverLabel)}`}
          </SecondaryButton>
        ) : (
          <PrimaryButton onClick={sendButtonClick} fullWidth>
            {`Skicka till ${capitalizeFirstLetter(receiverLabel)}`}
          </PrimaryButton>
        )}
      </div>
      <div className="flex justify-center items-center mb-1  mt-4">
        <span className="text-sm text-gray-800 mr-1">Skickas till</span>
        <span className="text-sm text-gray-900 font-semibold">
          {documentationEmail}
        </span>
      </div>
      <div className="flex justify-center items-center">
        <button
          onClick={openDocumentationModal}
          className="text-sm text-cyan-700 hover:text-cyan-500 font-semibold underline"
        >
          {`Ändra ${receiverLabel}s Epostadress för rapporter`}
        </button>
      </div>
    </div>
  );
