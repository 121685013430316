import { useEffect } from "react";
import { PageTransition } from "components/animations/SnapUp";
import { useMagicLinkLogin } from "api/user.api";
import { PageLoader } from "components/PageLoader";

export const MagicLinkLoginPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";

  useEffect(() => {
    document.title = "Välkommen till Driftjournalen";
  }, []);

  const mutation = useMagicLinkLogin();

  useEffect(() => {
    if (code) {
      mutation.mutate({ code });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <PageTransition>
      <PageLoader />
    </PageTransition>
  );
};
