import { Assignment } from "./assignment.type";
import { TomningType } from "./enums";
import { Separator, isAvskiljare } from "./separator/separator.type";
import { TransportDocument } from "./transportDocument.type";
import { User } from "./user.type";

export type Tomning = {
  id: number;
  inspectionDate: Date;
  klarmarkerad: boolean;
  user: User;
  assignment: Assignment;
  separator: Separator;
  transportDocument: TransportDocument;
  type: TomningType;
  rapportNummer: number;
};

export const isReadyForPreliminaryPdf = ({
  type,
  transportDocument,
  separator,
}: Tomning) => {
  return (
    (type !== TomningType.NotAssigned || !isAvskiljare(separator)) &&
    transportDocument.wasteList.length > 0 &&
    !transportDocument.wasteList.some((waste) => !waste.weightPreliminary) &&
    !!transportDocument.wasteStation &&
    !!transportDocument.vehicle
  );
};

export const isReadyForDelivery = (tomning: Tomning) => {
  return (
    isReadyForPreliminaryPdf(tomning) &&
    !!tomning.transportDocument.customerSignature &&
    !!tomning.transportDocument.driverSignature
  );
};

export enum TransportSätt {
  Vägtransport = "R",
  Järnvägstransport = "T",
  Sjötransport = "S",
  Flygtransport = "A",
  Inlandssjöfart = "W",
  Rörledning = "P",
}

export const TransportSättDropdownItems = [
  {
    label: "Vägtransport",
    value: TransportSätt.Vägtransport,
  },
  {
    label: "Järnvägstransport",
    value: TransportSätt.Järnvägstransport,
  },
  {
    label: "Sjötransport",
    value: TransportSätt.Sjötransport,
  },
  {
    label: "Flygtransport",
    value: TransportSätt.Flygtransport,
  },
  {
    label: "Inlandssjöfart",
    value: TransportSätt.Inlandssjöfart,
  },
  {
    label: "Rörledning",
    value: TransportSätt.Rörledning,
  },
];
