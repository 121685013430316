/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "components/Link";
import Header from "./Header";
import { useServerLogout } from "api/auth.api";
import { useLogout } from "hooks/useLogout";
import userStore, { getUserCompanyId } from "store/user.store";
import { PageLoader } from "./PageLoader";
import { Ping } from "./Ping";
import { useTasks } from "api/task.api";
import { isEmptyArray } from "helpers/array";
import { AppRoute, getAppRoutes, RouteLabels, RouteUrl } from "routes.type";
import { classNames } from "helpers/classNames";
import { Role } from "types/enums";
import LogoColor from "assets/Logo_white.png";
import {
  useAssignmentsNotEmailed,
  useAssignmentsWaitingForTimeReportConfirmation,
} from "api/assignment.api";
import {
  useLoadedTransportDocuments,
  useTransportDocumentNotReportedToNv,
} from "api/transportDocument.api";
import { countTransportDocumentInGroups } from "types/transportDocument.type";
import { getWeekWithLabel } from "helpers/date";
import { useFailedToSendEmails } from "api/email.api";
import Modal from "./Modal";
import { TimeReportForm } from "./time-report/TimeReportForm";
import { useTimeReport } from "./time-report/useTimeReport.hook";
import { CompanyId } from "enums/CompanyId.enum";
import { useCompany } from "api/company.api";
// import { Dot } from "./Dot";

type Props = {
  children?: React.ReactNode;
};

const RefreshRate = 100000; // 100 sek

const AppLayout: FC<Props> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const { mutate: _logout, isSuccess, isError } = useServerLogout();

  const companyId = userStore(getUserCompanyId) ?? -1;

  const { data: company } = useCompany(companyId);

  const logout = () => _logout();

  const afterLogout = useLogout();
  const { user } = userStore();

  const { data: assignmentsNotEmailed } = useAssignmentsNotEmailed({
    refetchInterval: RefreshRate, // 10 sek
    enabled: (user?.role ?? Role.Guest) > Role.User,
  });

  const { data: assignmentsNotTimeReportConfirmed } =
    useAssignmentsWaitingForTimeReportConfirmation({
      refetchInterval: RefreshRate, // 10 sek
      enabled:
        (user?.role ?? Role.Guest) > Role.User && companyId === CompanyId.UTAB,
    });

  const { data: notReportedToAvfallsregistret } =
    useTransportDocumentNotReportedToNv({
      refetchInterval: RefreshRate, // 10 sek
      enabled: (user?.role ?? Role.Guest) > Role.User && company?.isNvActive,
    });

  const { data: tasks } = useTasks({
    refetchInterval: RefreshRate, // 10 sek
    enabled: (user?.role ?? Role.Guest) > Role.User,
  });

  const { data: failedToSendEmails } = useFailedToSendEmails({
    refetchInterval: RefreshRate, // 10 sek
    enabled: (user?.role ?? Role.Guest) > Role.User,
  });

  const {
    isTimeReportModalOpen,
    openTimeReportModal,
    closeTimeReportModal,
    assignmentsWaitingForTimeReport,
    updateTimeReport,
    timeReportItems,
    updateTimeReportItemHour,
    isUpdatingTimeReport,
    assignmentsWithFormErrors,
  } = useTimeReport(user?.id);

  // Öppna transportdokument
  const { data: groupGroups } = useLoadedTransportDocuments(user?.id ?? -1);

  useEffect(() => {
    if (isError) {
      // Man ville logga ut men kunde inte nå servern, logga ut i browsern bara
      afterLogout();
    }
  }, [afterLogout, isError]);

  useEffect(() => {
    if (isSuccess) {
      afterLogout();
    }
  }, [isSuccess, afterLogout]);

  useEffect(() => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!user) {
    return <PageLoader />;
  }
  const showDashboardPing =
    !isEmptyArray(assignmentsNotEmailed) ||
    !isEmptyArray(tasks) ||
    !isEmptyArray(failedToSendEmails) ||
    !isEmptyArray(assignmentsNotTimeReportConfirmed) ||
    !isEmptyArray(notReportedToAvfallsregistret);

  const openTransportDocumentCount = countTransportDocumentInGroups([
    ...(groupGroups?.activeGroups ?? []),
    ...(groupGroups?.inactiveGroups ?? []),
  ]);

  const menuItems = getAppRoutes(user.role, pathname, company).filter(
    (menuItem) =>
      menuItem.label !== RouteLabels.OpenTransportDocuments ||
      openTransportDocumentCount > 0
  );

  return (
    <>
      <div>
        <MobileSidebar
          menuItems={menuItems}
          logout={logout}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          showDashboardPing={showDashboardPing}
          tdCount={openTransportDocumentCount}
        />

        {/* Static sidebar for desktop */}
        <DesktopSidebar
          items={menuItems}
          showDashboardPing={showDashboardPing}
          logout={logout}
          tdCount={openTransportDocumentCount}
        />

        <div className="xl:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 xl:hidden pl-1 pt-1 sm:pl-3 bg-gray-500 flex items-center justify-between">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
            <LogoWithColor />
          </div>
          <main className="flex-1">
            <div className="py-2 sm:py-6">
              <Header
                transportDocumentCount={openTransportDocumentCount}
                waitingForTimeReportCount={
                  (assignmentsWaitingForTimeReport || []).length
                }
                openTimeReportModal={openTimeReportModal}
              />
              <div
                className={classNames(
                  "mr-auto px-0 sm:px-6 lg:px-8 mt-4 sm-mt-0 max-w-[1740px]"
                  // isInspectionRoute(pathname)
                  //   ? "max-w-screen-2xl"
                  //   : "max-w-screen-2xl"
                )}
              >
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
      <Modal
        isOpen={isTimeReportModalOpen}
        title="Tidsrapportering"
        onClose={closeTimeReportModal}
        actionHandler={updateTimeReport}
        actionText="Spara"
        isLoading={isUpdatingTimeReport}
      >
        <TimeReportForm
          assignments={assignmentsWaitingForTimeReport}
          timeReportItems={timeReportItems}
          updateTimeReportItemHour={updateTimeReportItemHour}
          assignmentsWithFormErrors={assignmentsWithFormErrors}
        />
      </Modal>
    </>
  );
};

export default AppLayout;

type DesktopSidebarProps = {
  items: AppRoute[];
  showDashboardPing: boolean;
  logout: () => void;
  tdCount: number;
};
const DesktopSidebar: FC<DesktopSidebarProps> = ({
  items,
  showDashboardPing,
  logout,
  tdCount,
}) => {
  const navigate = useNavigate();
  return (
    <div className="hidden xl:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <LogoWithColor onClick={() => navigate(RouteUrl.Home)} />
          <nav className="mt-5 px-2 space-y-1 mb-6">
            {items
              .filter((item) => !item.bottom)
              .map((item: AppRoute) => (
                <Link
                  key={item.label}
                  to={item.fullPath}
                  className={classNames(
                    item.isCurrent
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex items-center px-4 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  {/* <item.icon
                    className={classNames(
                      "mr-3 flex-shrink-0 h-6 w-6",
                      item.isCurrent ? "text-white" : "text-gray-300"
                    )}
                    aria-hidden="true"
                  /> */}
                  {item.label}
                  {item.label === RouteLabels.Dashboard &&
                    showDashboardPing && <Ping />}
                  {/* {item.label === RouteLabels.OpenTransportDocuments &&
                  tdCount > 0 && <Ping />} */}
                </Link>
              ))}
          </nav>
          <div className="w-full border-t border-gray-700" />
          <nav className="mt-5 px-2 space-y-1">
            {items
              .filter((item) => item.bottom)
              .map((item: AppRoute) => (
                <Link
                  key={item.label}
                  to={item.fullPath}
                  className={classNames(
                    item.isCurrent
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex items-center px-4 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  {/* <item.icon
                    className={classNames(
                      "mr-3 flex-shrink-0 h-6 w-6",
                      item.isCurrent ? "text-white" : "text-gray-300"
                    )}
                    aria-hidden="true"
                  /> */}
                  {item.label}
                  {item.label === RouteLabels.Dashboard &&
                    showDashboardPing && <Ping />}
                  {/* {item.label === RouteLabels.Changelog && <Dot />} */}
                </Link>
              ))}
          </nav>
        </div>
        <div className="mb-2 flex justify-start">
          <span className="text-gray-200 text-sm px-4">
            {getWeekWithLabel(new Date())}
          </span>
        </div>
        <div
          onClick={logout}
          className="flex-shrink-0 flex bg-gray-700 p-4 cursor-pointer"
        >
          <div className="flex-shrink-0 w-full group block">
            <div className="flex items-center">
              <div className="ml-0">
                <span className="text-sm font-medium text-white">Logga ut</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type MobileSidebarProps = {
  menuItems: AppRoute[];
  showDashboardPing: boolean;
  logout: () => void;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tdCount: number;
};
const MobileSidebar: FC<MobileSidebarProps> = ({
  menuItems,
  showDashboardPing,
  logout,
  sidebarOpen,
  setSidebarOpen,
  tdCount,
}) => {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 xl:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <nav className="mt-5 px-2 space-y-1">
                  {menuItems.map((item) => (
                    <Link
                      key={item.label}
                      to={item.fullPath}
                      className={classNames(
                        item.isCurrent
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.isCurrent
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.label}
                      {item.label === RouteLabels.Dashboard &&
                        showDashboardPing && <Ping />}
                      {/* {item.label === RouteLabels.OpenTransportDocuments &&
                        tdCount > 0 && <Ping />} */}
                      {/* {item.label === RouteLabels.Feedback && (
                        <div className="ml-4">
                          <Badge
                            selectedColors="bg-pink-100 text-pink-700"
                            text="Ny"
                            isSelected
                          />
                        </div>
                      )} */}
                    </Link>
                  ))}
                </nav>
              </div>
              <div
                onClick={logout}
                className="flex-shrink-0 flex bg-gray-700 p-4 cursor-pointer"
              >
                <div className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <span className="text-sm font-medium text-white">
                        Logga ut
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const LogoWithColor: FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div
    className={classNames(
      "flex-shrink-0 flex items-center px-4",
      !!onClick && "cursor-pointer"
    )}
    onClick={onClick && onClick}
  >
    {/* <ClipboardDocumentCheckIcon className="h-6 lg:h-8 text-white mr-2" /> */}
    <img className="h-10 w-auto" src={LogoColor} alt="logo" />
  </div>
);

// const LogoWithWhite = () => (
//   <div className="flex-shrink-0 flex items-center px-4">
//     {/* <ClipboardDocumentCheckIcon className="h-6 lg:h-8 text-white mr-2" /> */}
//     <img className="h-10 w-auto" src={LogoColorWhite} alt="logo" />
//   </div>
// );
// const isInspectionRoute = (href: string) =>
//   href.includes(Routes.FiveYearsInspection) ||
//   href.includes(Routes.SixMonthsInspection);
