import { FC } from "react";
import { classNames } from "helpers/classNames";
import { Email, EmailStatus } from "types/email.type";
import { FilePreviewSmall } from "components/FilePreviewSmall";
import { formatDate, formatDateWithTime } from "helpers/date";
import { isBefore, isToday } from "date-fns";
import { EmailStatusTag } from "components/EmailStatusTag";
import { Transition } from "components/animations/Transition";
import { config } from "react-spring";
import { InfoNew } from "components/InfoNew";

type Props = {
  emails?: Email[];
  createdAt: Date;
};

export const SentEmails: FC<Props> = ({ emails = [], createdAt }) => {
  if (emails.length === 0) {
    return (
      <div
        className={classNames(
          "bg-white shadow-lg overflow-hidden sm:rounded-lg mt-7 lg:ml-7 mb-4 lg:mb-0 pb-6"
        )}
      >
        <div className="px-4 py-5 sm:px-6 flex pb-5 flex-col">
          <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mt-px flex items-center justify-between">
            Utskick
          </h3>
        </div>
        {/* Visa information om det behövs om att tidigare mail ej syns */}
        <div className="border-t border-gray-100 px-4 sm:px-6 pt-6 text-gray-900">
          {showMissingEmailInfo(createdAt) && (
            <InfoNew
              id="92135267-bb22-4937-9fdd-d91175af1dba"
              paragraphs={[
                "Den 18 augusti uppdaterade vi hur vi hanterar e-postutskick i systemet. Utskick som genomfördes före detta datum finns i vårt system men visas ej av tekniska orsaker. Vi kommer se till att ni får tillgång till även äldre utskick i sinom tid, men prioriterar just nu annan utveckling. Om detta är en prioriterad fråga för er, vänligen kontakta oss så påskyndar vi processen.",
              ]}
            />
          )}
          <span>Inga utskick än så länge</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "bg-white shadow-lg overflow-hidden sm:rounded-lg mt-7 lg:ml-7 mb-4 lg:mb-0 pb-6"
      )}
    >
      <div className="px-4 py-5 sm:px-6 flex pb-5 flex-col">
        <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mt-px flex items-center justify-between">
          Utskick
        </h3>
      </div>
      <div className="border-t border-gray-100 px-4 sm:px-6 divide-y divide-y-gray-200">
        {showMissingEmailInfo(createdAt) && (
          <InfoNew
            id="92135267-bb22-4937-9fdd-d91175af1dba"
            paragraphs={[
              "Den 18 augusti uppdaterade vi hur vi hanterar e-postutskick i systemet. Utskick som genomfördes före detta datum finns i vårt system men visas ej av tekniska orsaker. Vi kommer se till att ni får tillgång till även äldre utskick i sinom tid, men prioriterar just nu annan utveckling. Om detta är en prioriterad fråga för er, vänligen kontakta oss så påskyndar vi processen.",
            ]}
          />
        )}
        {emails
          .sort((a, b) => (a.id < b.id ? 1 : -1))
          .map((email) => (
            <Transition
              settings={{
                from: { opacity: 0 },
                enter: { opacity: 1 },
                config: config.gentle,
              }}
              key={email.id}
            >
              <div className="flex flex-col py-7 text-sm">
                <div className="flex justify-between items-center mb-1">
                  <EmailStatusTag status={email.status} />
                  <span className="text-gray-700 text-xs">
                    {email.status === EmailStatus.Scheduled
                      ? "Skickas inom kort"
                      : isToday(new Date(email.sentAt ?? email.createdAt))
                      ? formatDateWithTime(email.sentAt ?? email.createdAt)
                      : `${formatDate(email.sentAt ?? email.createdAt)}`}
                  </span>
                </div>
                <span className="flex text-xs text-gray-900">
                  <span className="text-gray-900 mr-1 ml-1">Till:</span>
                  {email.to}
                </span>
                <div className="max-w-md mt-3">
                  <div className="space-y-1">
                    {email.attachments.map((att) => (
                      <FilePreviewSmall
                        key={att.id}
                        url={att.path}
                        name={att.name}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </Transition>
          ))}
      </div>
    </div>
  );
};

const showMissingEmailInfo = (createdAt: Date) =>
  isBefore(new Date(createdAt), new Date("2023-08-14"));
