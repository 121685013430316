import { FC, useMemo } from "react";
import { UseFormRegister } from "react-hook-form";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import Dropdown, { DropdownItem } from "components/Dropdown";
import { AssignmentDTO } from "types/assignment.type";
import { Task } from "types/task.type";
import { Customer } from "types/customer.type";
import { ModalLoader } from "components/ModalLoader";
import { useUsers } from "api/user.api";
import { getTaskTypeName, TaskType } from "enums/TaskType.enum";
import { Link } from "components/Link";
import { appRoutes } from "routes.type";
import { TextArea } from "components/TextArea";
import { userToDropdownItem } from "types/user.type";
import {
  CalendarIcon,
  CircleStackIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { getWeekWithLabel } from "helpers/date";

interface Props {
  register: UseFormRegister<AssignmentDTO>;
  dateDropdownItems: DropdownItem[];
  task?: Task; // Task kommer alltid finnas men tror jag måste göra såhär
  customer?: Customer;
}

export const ScheduleAssignment: FC<Props> = ({
  register,
  dateDropdownItems,
  task,
}) => {
  const { data: users, isLoading: isLoadingUsers } = useUsers();

  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );

  if (isLoadingUsers) {
    return <ModalLoader />;
  }

  if (!task) {
    return null;
  }

  return (
    <ModalContentWrapper>
      {/* Kund, typ av uppgift, identifiering på objekt */}
      {/* <div>
        <Link
          to={`${appRoutes.customers.fullPath}/${task?.customer?.id}`}
          // target="_blank"
          // rel="noopener noreferrer"
        >
          {`${task?.customer?.name}`}
        </Link>
        <div className="flex items-center mt-2">
          <p className="leading-6 text-sm font-base text-gray-700 mr-1">{`${getTaskTypeName(
            task?.type ?? TaskType.Other
          )} av`}</p>
          <Link
            to={`${appRoutes.customers.fullPath}/${task?.customer?.id}/${task?.separator?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {task?.separator?.identifier}
          </Link>
        </div>
      </div> */}

      <div className="min-w-0 flex-auto space-y-2">
        <div className="text-normal font-semibold leading-6 text-gray-900 flex items-center">
          {`${getTaskTypeName(task?.type ?? TaskType.Other)}`}
        </div>

        <Link
          to={`${appRoutes.customers.fullPath}/${task?.customer?.id}`}
          style={{ display: "flex", alignItems: "center" }}
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <FaceSmileIcon className="h-4 w-auto text-gray-500 mr-1" />
          {`${task.customer?.name}`}
        </Link>
        <Link
          to={`${appRoutes.customers.fullPath}/${task?.customer?.id}/${task?.separator?.id}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <CircleStackIcon className="h-4 w-auto text-gray-500 mr-1" />
          {task.separator?.identifier}
        </Link>
        <div className="flex items-center text-sm text-gray-700">
          <CalendarIcon
            className="mr-1 h-4 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <p>
            Senast <span>{getWeekWithLabel(new Date(task.date))}</span>
          </p>
        </div>
        {/* <TransportDocumentStatusTag status={td.status} /> */}
      </div>

      <Dropdown
        items={dateDropdownItems}
        name="date"
        label="Datum"
        register={register}
      />
      <Dropdown
        items={userDropdownItems}
        name="userId"
        label="Operatör"
        register={register}
      />
      <TextArea
        label="Kommentar"
        name="notes"
        placeholder="Valfritt"
        register={register}
      />
    </ModalContentWrapper>
  );
};
