import { TomningQueryKey } from "api/tomning.api";
import {
  TransportDocumentQueryKey,
  useUpdateTransportDocument,
} from "api/transportDocument.api";
import { scrollToTop } from "helpers/scroll";
import { useNotification } from "hooks/useNotification";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { Tomning } from "types/tomning.type";
import { TransportDocument } from "types/transportDocument.type";
// import useSound from "use-sound";
// const honkSound = require("assets/honk.wav");
// const lockSound = require("assets/lock.wav");
export const useUpdateCustomerSignature = (
  tomning?: Tomning,
  setViewMode?: () => void
) => {
  const [isCustomerSignatureModalVisible, setIsCustomerSignatureModalVisible] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const notification = useNotification();

  // const [playHonk] = useSound(honkSound);
  // const [playLock] = useSound(lockSound);

  const { mutate: updateTransportDocument, isLoading } =
    useUpdateTransportDocument(tomning?.transportDocument.id ?? -1, {
      onSuccess: (data: TransportDocument) => {
        if (
          tomning &&
          !!data.customerSignature &&
          !tomning.transportDocument.customerSignature &&
          !!tomning.transportDocument.driverSignature
        ) {
          notification.info("Lastad");
          !!setViewMode && setViewMode();
          scrollToTop();
        }
        queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
        setIsCustomerSignatureModalVisible(false);
      },
      onError: () => {
        notification.error("Något gick fel");
      },
    });

  // När man klickar på spara vill man uppdatera servern med det nya värdet. När det är klart vill vi stänga modulen
  const onCustomerSignatureSubmit = (customerSignature: string) => {
    if (!tomning?.transportDocument) {
      throw Error("No transport document");
    }
    updateTransportDocument({
      ...tomning.transportDocument,
      customerSignature,
    });
  };

  return {
    isCustomerSignatureModalVisible,
    showCustomerSignatureModal: () => setIsCustomerSignatureModalVisible(true),
    hideCustomerSignatureModal: () => setIsCustomerSignatureModalVisible(false),
    onCustomerSignatureSubmit,
    isSubmittingCustomerSignature: isLoading,
  };
};

// interface ReturnType {
//   isCustomerSignatureModalVisible: boolean;
//   showCustomerSignatureModal: () => void;
//   hideCustomerSignatureModal: () => void;
// }
