import {} from "@heroicons/react/24/solid";
import {
  ArchiveBoxArrowDownIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  ClipboardIcon,
  TruckIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import {
  AssignmentStatus,
  assignmentStatusLabel,
} from "enums/AssignmentStatus.enum";

interface Props {
  status: AssignmentStatus;
}

export const AssignmentStatusTag: FC<Props> = ({ status }) => {
  switch (status) {
    case AssignmentStatus.Created:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 pl-1.5 pr-2 py-1 text-xs font-medium text-indigo-900 ring-1 ring-inset ring-indigo-600/10">
          <ClipboardIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-indigo-600/20" />
          {assignmentStatusLabel(status)}
        </span>
      );
    case AssignmentStatus.Scheduled:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 pl-1.5 pr-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-600/10">
          <CalendarDaysIcon className="group relative h-3.5 sm:h-4 w-auto rounded-sm hover:bg-gray-600/20" />
          {assignmentStatusLabel(status)}
        </span>
      );
    case AssignmentStatus.MissingSeparatorData:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-yellow-50 pl-1.5 pr-2 py-1 text-xs font-medium text-yellow-900 ring-1 ring-inset ring-yellow-600/10">
          <ExclamationTriangleIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-yellow-600/20" />
          {assignmentStatusLabel(status)}
        </span>
      );
    case AssignmentStatus.Loaded:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-pink-50 pl-1.5 pr-2 py-1 text-xs font-medium text-pink-900 ring-1 ring-inset ring-pink-600/10">
          <TruckIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-pink-600/20" />
          {assignmentStatusLabel(status)}
        </span>
      );
    case AssignmentStatus.Weighed:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-cyan-50 pl-1.5 pr-2 py-1 text-xs font-medium text-cyan-900 ring-1 ring-inset ring-cyan-600/10">
          <ArchiveBoxArrowDownIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-cyan-600/20" />
          {assignmentStatusLabel(status)}
        </span>
      );
    // case AssignmentStatus.Signed:
    //   return (
    //     <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 pl-1.5 pr-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">
    //       <CheckCircleIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-green-600/20" />
    //       {assignmentStatusLabel(status)}
    //     </span>
    //   );
    case AssignmentStatus.RequirementsFulfilled:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-pink-50 pl-1.5 pr-2 py-1 text-xs font-medium text-pink-800 ring-1 ring-inset ring-pink-600/10">
          <ClipboardDocumentCheckIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-pink-600/20" />
          {assignmentStatusLabel(status)}
        </span>
      );
    case AssignmentStatus.Klarmarkerad:
    case AssignmentStatus.Signed:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 pl-1.5 pr-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-400/10">
          <CheckCircleIcon className="group relative -ml-1 h-3.5 sm:h-4 w-auto rounded-sm hover:bg-green-600/20 text-green-600" />
          {assignmentStatusLabel(status)}
        </span>
      );
    case AssignmentStatus.Unknown:
    default:
      throw Error(
        `AssignmentStatusTag is missing implementation for ${status}`
      );
  }
};

export const AssignmentStatusIcon: FC<Props> = ({ status }) => (
  <>
    {status === AssignmentStatus.Scheduled ? (
      <CalendarDaysIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
    ) : status === AssignmentStatus.Created ? (
      <ClipboardIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-indigo-400"
        aria-hidden="true"
      />
    ) : status === AssignmentStatus.RequirementsFulfilled ? (
      <ClipboardDocumentCheckIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-pink-400"
        aria-hidden="true"
      />
    ) : status === AssignmentStatus.Loaded ? (
      <TruckIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-pink-400"
        aria-hidden="true"
      />
    ) : status === AssignmentStatus.Klarmarkerad ? (
      <CheckCircleIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-green-400"
        aria-hidden="true"
      />
    ) : status === AssignmentStatus.Signed ? (
      <CheckCircleIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-green-400"
        aria-hidden="true"
      />
    ) : status === AssignmentStatus.MissingSeparatorData ? (
      <ExclamationTriangleIcon
        className="flex-shrink-0 mr-1 h-5 w-5 text-yellow-400"
        aria-hidden="true"
      />
    ) : (
      "missing"
    )}
  </>
);
