import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
interface Props {
  label?: string;
  name: string;
  register: UseFormRegister<any>;
  tabIndex?: number;
  validation?: any;
  errorMessage?: string;
  onSubmit: () => void;
}
export const PasswordInput: FC<Props> = ({
  label = "Lösenord",
  name,
  register,
  tabIndex,
  validation,
  errorMessage,
  onSubmit,
}) => {
  const hasError = !!errorMessage;
  const [showPassword, setShowPassword] = useState(false);

  const toggleShow = () => setShowPassword((prev) => !prev);

  return (
    <div>
      <label htmlFor={name} className="flex text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={"mt-1 relative rounded-md shadow-sm"}>
        <input
          type={showPassword ? "text" : "password"}
          className={
            "block w-full rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
          }
          {...register(name, validation && validation)}
          required
          tabIndex={tabIndex ? tabIndex : 0}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mr-7">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        <div
          onClick={toggleShow}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              toggleShow();
            }
          }}
          className="absolute inset-y-0 right-0 flex items-center px-2 outline-cyan-500 focus:rounded-r-md cursor-pointer"
          tabIndex={-1}
        >
          {!showPassword && (
            <EyeIcon className="h-6 w-6 text-gray-400 hover:text-gray-600" />
          )}
          {showPassword && (
            <EyeSlashIcon className="h-6 w-6 text-gray-400 hover:text-gray-600" />
          )}
        </div>
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
