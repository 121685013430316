import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { classNames } from "helpers/classNames";

export const useNotification = () => {
  const info = (message: string = "Ändringarna sparade") => {
    toast.custom((t) => (
      <div
        className={classNames(
          "rounded-md bg-cyan-50 p-4 w-56 shadow-md",
          t.visible ? "animate-enter" : "animate-leave"
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-cyan-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-cyan-900">{message}</p>
          </div>
        </div>
      </div>
    ));
  };

  const changesSaved = (message: string = "Ändringarna sparade") => {
    toast.custom((t) => (
      <div
        className={classNames(
          "rounded-md bg-cyan-50 p-4 w-56 shadow-md",
          t.visible ? "animate-enter" : "animate-leave"
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-cyan-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-cyan-900">{message}</p>
          </div>
        </div>
      </div>
    ));
  };

  const success = (message: string) => {
    toast.custom((t) => (
      <div
        className={classNames(
          "rounded-md bg-green-50 p-4 w-56 shadow-lg",
          t.visible ? "animate-enter" : "animate-leave"
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-green-900">{message}</p>
          </div>
        </div>
      </div>
    ));
  };

  const error = (message: string = "Något gick fel, kontakta support.") => {
    toast.custom((t) => (
      <div
        className={classNames(
          "rounded-md bg-red-50 p-4 w-56 shadow-md",
          t.visible ? "animate-enter" : "animate-leave"
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-red-900">{message}</p>
          </div>
        </div>
      </div>
    ));
  };

  return { changesSaved, info, success, error };
};
