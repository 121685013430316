import { FC } from "react";
import EmptyState from "components/EmptyState";
import { Customer } from "types/customer.type";
import TabContainer from "components/tab/TabContainer";
import { formatDate, formatDateWithTime } from "helpers/date";
import { FilePreviewSmall } from "components/FilePreviewSmall";
import {
  Email,
  EmailStatus,
  getLinkToEmailAssignmentPage,
} from "types/email.type";
import { useNavigate } from "react-router-dom";
import { isToday } from "date-fns";
import { EmailStatusTag } from "components/EmailStatusTag";

interface Props {
  customer: Customer;
}

export const EmailTab: FC<Props> = ({ customer }) => {
  const navigate = useNavigate();

  const navigageToInspection = (email: Email) => {
    navigate(getLinkToEmailAssignmentPage({ ...email, customer }));
  };

  if (customer.emails.length === 0) {
    return (
      <TabContainer>
        <div className="px-4 sm:px-0">
          <EmptyState
            header="Ingen skickad epost"
            subHeader={`Här listas de rapporter ni skickat till ${customer.name}`}
            buttonText=""
          />
        </div>
      </TabContainer>
    );
  }

  return (
    <TabContainer>
      <div className="divide-y divide-gray-200 border-t border-b border-gray-200 max-w-md">
        {customer.emails.map((email) => (
          <div key={email.id} className="flex flex-col py-7 text-sm">
            <div className="flex justify-between items-center mb-1">
              <EmailStatusTag status={email.status} />
              <span className="text-gray-700 text-xs">
                {email.status === EmailStatus.Scheduled
                  ? "Skickas inom kort"
                  : isToday(new Date(email.sentAt ?? email.createdAt))
                  ? formatDateWithTime(email.sentAt ?? email.createdAt)
                  : `${formatDate(email.sentAt ?? email.createdAt)}`}
              </span>
            </div>
            <span className="flex text-xs text-gray-900">
              <span className="text-gray-900 mr-1 ml-1">Till:</span>
              {email.to}
            </span>
            <div className="max-w-md my-3">
              <div className="space-y-1">
                {email.attachments.map((att) => (
                  <FilePreviewSmall
                    key={att.id}
                    url={att.path}
                    name={att.name}
                  />
                ))}
              </div>
            </div>
            <div>
              <button
                className="text-cyan-600 hover:text-cyan-700 text-sm font-base"
                onClick={() => navigageToInspection(email)}
              >
                Visa uppdrag
              </button>
            </div>
          </div>
        ))}
      </div>
    </TabContainer>
  );
};

// offline: 'text-gray-500 bg-gray-100/10',
// online: 'text-green-400 bg-green-400/10',
// error: 'text-rose-400 bg-rose-400/10',
