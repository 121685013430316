import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/TextInput";
import { UpdateWasteDTO } from "types/waste.type";
import { UseFormRegister } from "react-hook-form";
import { isNumbers, removeWhitespace } from "helpers/string";
import { TextArea } from "components/TextArea";
interface Props {
  register: UseFormRegister<UpdateWasteDTO>;
  errors: any;
}
export const EditWasteForm: React.FC<Props> = ({ register, errors }) => (
  <ModalContentWrapper>
    <TextInput
      label="Uppskattad vikt"
      name="weightPreliminary"
      register={register}
      appendix="kg"
      size="third"
      autoFocus
      validation={{
        validate: (value: string) => {
          const trimmed = removeWhitespace(value).replace("-", "");
          return (
            !trimmed || // Ok att lämna tomt
            isNumbers(trimmed) ||
            "Ange vikten i hela kilo"
          );
        },
      }}
      errorMessage={errors?.weightPreliminary?.message}
    />
    <TextArea label="Kommentar" name="comment" register={register} />
  </ModalContentWrapper>
);
