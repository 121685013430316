import {
  AssignmentQueryKey,
  useAssignmentsWaitingForTimeReport,
  useUpdateTimeReport,
} from "api/assignment.api";
import { CompanyId } from "enums/CompanyId.enum";
import { useNotification } from "hooks/useNotification";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import userStore, { getUserCompanyId } from "store/user.store";
import { TimeReportDTO } from "types/assignment.type";

export const useTimeReport = (userId: number = -1) => {
  const companyId = userStore(getUserCompanyId) ?? -1;

  const queryClient = useQueryClient();
  const notification = useNotification();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [assignmentsWithFormErrors, setAssignmentsWithFormError] = useState<
    number[]
  >([]);

  const { data: assignmentsWaitingForTimeReport } =
    useAssignmentsWaitingForTimeReport(userId, {
      refetchInterval: 20000, // 20 sek
      enabled: userId > -1 && companyId === CompanyId.UTAB,
    });

  const { mutate, isLoading: isUpdatingTimeReport } = useUpdateTimeReport({
    onSuccess: () => {
      queryClient.invalidateQueries([AssignmentQueryKey.WaitingForTimeReport]);
      setIsModalOpen(false);
    },
    onError: () => notification.error(),
  });
  const [timeReportItems, setTimeReportItems] = useState<TimeReportDTO[]>([]);

  const updateTimeReport = () => {
    // Börja med att validera
    const withErrors: number[] = [];
    for (let item of timeReportItems) {
      if (!item.reportedHours) {
        withErrors.push(item.assignmentId);
      }
    }
    if (withErrors.length > 0) {
      setAssignmentsWithFormError(withErrors);
    } else {
      mutate(timeReportItems);
    }
  };

  const updateTimeReportItemHour = (
    assignmentId: number,
    reportedHours: string
  ) => {
    if (assignmentsWithFormErrors.includes(assignmentId) && !!reportedHours) {
      setAssignmentsWithFormError((prev) =>
        prev.filter((id) => id !== assignmentId)
      );
    }
    setTimeReportItems((prev) => [
      ...prev.map((it) =>
        it.assignmentId === assignmentId
          ? {
              ...it,
              reportedHours,
            }
          : it
      ),
    ]);
  };

  useEffect(() => {
    setTimeReportItems(
      (assignmentsWaitingForTimeReport ?? []).map((ass) => ({
        assignmentId: ass.id,
        reportedHours: "",
        reportedKilometers: "",
      }))
    );
  }, [assignmentsWaitingForTimeReport]);

  return {
    isTimeReportModalOpen: isModalOpen,
    openTimeReportModal: () => setIsModalOpen(true),
    closeTimeReportModal: () => setIsModalOpen(false),
    assignmentsWaitingForTimeReport: assignmentsWaitingForTimeReport ?? [],
    updateTimeReport,
    timeReportItems,
    updateTimeReportItemHour,
    isUpdatingTimeReport,
    assignmentsWithFormErrors,
  };
};
