import * as React from "react";
import { config } from "react-spring";
import { Transition } from "./Transition";

interface Props {
  children: React.ReactNode;
  isVisible?: boolean;
}
export const AnimateOpacity: React.FC<Props> = ({
  children,
  isVisible = true,
}) => {
  return (
    <Transition
      settings={{
        from: { opacity: 0.2 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
          ...config.default,
          precision: 0.01,
          tension: 190,
          friction: 20,
          clamp: true,
        },
      }}
      isVisible={isVisible}
    >
      <>{children}</>
    </Transition>
  );
};
