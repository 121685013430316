import React from "react";
import { Controller, Control } from "react-hook-form";
import { ModalLabel } from "./ModalLabel";

interface DateTimePickerProps {
  name: string;
  control: Control<any>; // Consider using a more specific type based on your form values
  label?: string;
  disabled?: boolean;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  name,
  control,
  label,
  disabled = false,
}) => {
  const handleDateTimeChange = (dateValue: string, timeValue: string) => {
    if (dateValue && timeValue) {
      const [hours, minutes] = timeValue.split(":");
      const date = new Date(dateValue);
      date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

      // Get the time zone offset in minutes and convert it to milliseconds
      const timezoneOffset = date.getTimezoneOffset() * 60000;
      // Adjust the date by the timezone offset to get the correct local time
      const adjustedDate = new Date(date.getTime() - timezoneOffset);

      return adjustedDate.toISOString(); // This will now reflect the correct local time
    }
    return ""; // Fallback value if date or time are not set
  };

  return (
    <div className="flex flex-col gap-y-1">
      {!!label && <ModalLabel>{label}</ModalLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div className="flex items-start gap-x-2">
            <input
              disabled={disabled}
              type="date"
              value={field.value ? field.value.split("T")[0] : ""}
              onChange={(e) =>
                field.onChange(
                  handleDateTimeChange(
                    e.target.value,
                    field.value?.split("T")[1].substring(0, 5) || "00:00"
                  )
                )
              }
              className="flex-1 rounded-md shadow-sm border-gray-300 focus:border-cyan-500 focus:ring-cyan-500 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
            />
            <input
              disabled={disabled}
              type="time"
              value={
                field.value ? field.value.split("T")[1].substring(0, 5) : ""
              }
              onChange={(e) =>
                field.onChange(
                  handleDateTimeChange(
                    field.value?.split("T")[0] || "",
                    e.target.value
                  )
                )
              }
              className="flex-1 rounded-md shadow-sm border-gray-300 focus:border-cyan-500 focus:ring-cyan-500 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
            />
          </div>
        )}
      />
    </div>
  );
};
