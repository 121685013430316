import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FC } from "react";
import { classNames } from "helpers/classNames";
import { RequiredForInspection } from "./RequiredForInspection";
import { ModalLabel } from "./ModalLabel";
import SecondaryButton from "./buttons/SecondaryButton";
interface Props {
  label?: string;
  appendix?: string;
  size?: "third" | "half" | "full";
  errorMessage?: string;
  autoFocus?: boolean;
  placeholder?: string;
  fiveYearWarning?: boolean;
  link?: string;
  linkText?: string;
  type?: "text" | "password";
  onKeyUp?: () => void;
  isMissing?: boolean;
  action?: () => void;
  actionText?: string;
  defaultValue?: string;
  lightLabel?: boolean;
  onChange: (val: string) => void;
  value: string;
}
export const TextInputStupid: FC<Props> = ({
  label,
  appendix,
  size = "full",
  errorMessage,
  autoFocus = false,
  placeholder,
  fiveYearWarning,
  link,
  linkText,
  type = "text",
  onKeyUp = () => null,
  isMissing = false,
  action,
  actionText,
  defaultValue,
  lightLabel = false,
  onChange,
  value,
}) => {
  const hasError = !!errorMessage;
  const sizeClass =
    size === "third" ? "w-1/2 sm:w-1/3" : size === "half" ? "sm:w-1/2" : "";
  return (
    <div>
      {label && (
        <ModalLabel light={lightLabel}>
          {label}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="underline ml-4 text-cyan-600"
            >
              {linkText}
            </a>
          )}
          {fiveYearWarning && <RequiredForInspection isMissing={isMissing} />}
          {action && (
            <div className="ml-auto">
              <SecondaryButton onClick={action}>{actionText}</SecondaryButton>
            </div>
          )}
        </ModalLabel>
      )}
      <div
        className={classNames("mt-1 relative rounded-md shadow-sm", sizeClass)}
      >
        <input
          type={type}
          onChange={(e) => onChange(e.target.value)}
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm",
            hasError
              ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300"
              : "border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
          )}
          autoComplete="off"
          autoFocus={autoFocus}
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onKeyUp();
            }
          }}
          value={value}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {appendix && !hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm">{appendix}</span>
          </div>
        )}
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
