import { FC } from "react";
import { Customer } from "types/customer.type";
import { Link } from "./Link";
import { appRoutes } from "routes.type";

export const ProxyAssignmentBanner: FC<{ parentCustomer: Customer }> = ({
  parentCustomer,
}) => {
  return (
    <div className="w-full flex rounded-md my-4 mb-6  px-6 py-6 text-sm font-semibold text-white bg-gradient-to-r from-indigo-300 via-rose-300 to-pink-300 max-w-8xl">
      Detta är ett förmedlat uppdrag. Uppdragsförmedlare är
      <Link
        to={`${appRoutes.customers.fullPath}/${parentCustomer.id}`}
        className="flex items-center text-sm text-white-700 sm:w-72 ml-1 underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="truncate">{parentCustomer.name}</span>
      </Link>
      .
    </div>
  );
};
