import { AssignmentQueryKey } from "api/assignment.api";
import {
  TransportDocumentQueryKey,
  useUpdateTransportDocumentWasteStationSignature,
} from "api/transportDocument.api";
import { useNotification } from "hooks/useNotification";
import { useState } from "react";
import { useQueryClient } from "react-query";

export const useWasteStationSignatureLogic = () => {
  const [transportIdsToSign, setTransportIdsToSign] = useState<number[]>([]);
  const queryClient = useQueryClient();
  const notification = useNotification();

  const { mutate, isLoading: isSavingSignature } =
    useUpdateTransportDocumentWasteStationSignature({
      onSuccess: () => {
        notification.info("Signatur sparad");
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
        queryClient.invalidateQueries([
          AssignmentQueryKey.WaitingForTimeReport,
        ]);
        setTransportIdsToSign([]);
      },
      onError: () => {
        notification.error();
      },
    });

  const onWasteStationSignatureSubmit = (
    wasteStationSignature: string,
    sendToWasteStation: boolean
  ) => {
    if (transportIdsToSign.length === 0) {
      throw Error("No transport documents to sign");
    }
    mutate({
      wasteStationSignatureList: transportIdsToSign.map(
        (transportDocumentId) => ({
          transportDocumentId,
          wasteStationSignature,
        })
      ),
      sendToWasteStation,
    });
  };

  return {
    isSignatureModalVisible: transportIdsToSign.length > 0,
    hideSignatureModal: () => setTransportIdsToSign([]),
    onWasteStationSignatureSubmit,
    isSavingSignature,
    setTransportIdsToSign,
  };
};
