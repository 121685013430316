import { FC } from "react";
import { Link as RRLink, LinkProps } from "react-router-dom";

export const Link: FC<LinkProps & React.RefAttributes<HTMLAnchorElement>> = ({
  children,
  ...rest
}) => (
  <RRLink className="text-cyan-700 hover:text-cyan-800 font-medium" {...rest}>
    {children}
  </RRLink>
);
