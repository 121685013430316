// Bakar ihop assignment och inspection

export enum AssignmentStatus {
  Unknown = -1,
  Scheduled = 10,
  Created = 20,
  MissingSeparatorData = 28,
  Loaded = 30,
  Weighed = 32,
  Signed = 36,
  RequirementsFulfilled = 40,
  Klarmarkerad = 50,
}

export const assignmentStatusLabel = (status: AssignmentStatus) => {
  switch (status) {
    case AssignmentStatus.Scheduled:
      return "Schemalagd";
    case AssignmentStatus.Created:
      return "Påbörjad";
    case AssignmentStatus.MissingSeparatorData:
      return "Saknar objektdata";
    case AssignmentStatus.Loaded:
      return "Lastad";
    case AssignmentStatus.Weighed:
      return "Lossad";
    case AssignmentStatus.Signed:
      return "Klar";
    case AssignmentStatus.RequirementsFulfilled:
      return "Fullständig";
    case AssignmentStatus.Klarmarkerad:
      return "Klar";
    case AssignmentStatus.Unknown:
    default: {
      //return "Saknar detta just nu";
      throw Error("assignmentStatusLabel missing implementation for " + status);
    }
  }
};
