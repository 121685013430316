/* This example requires Tailwind CSS v2.0+ */
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
interface Props {
  text: string;
  buttonText?: string;
  onButtonClick?: () => void;
}
/* This example requires Tailwind CSS v2.0+ */

export default function Alert({ text, buttonText, onButtonClick }: Props) {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            {text}{" "}
            {!!onButtonClick && !!buttonText && (
              <button
                onClick={onButtonClick}
                className="font-medium text-yellow-700 underline hover:text-yellow-600"
              >
                {buttonText}
              </button>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
