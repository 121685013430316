import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import instance, { ApiUrl } from "./axiosInstance";
import { WasteStation, WasteStationDTO } from "types/wasteStation.type";

export const GET_SEPARATOR_KEY = "get-waste-station-list";
export const GET_SEPARATOR_ASSIGNMENTS_KEY = "getSeparatorASSIGNMENTS";

enum WasteStationQueryKey {
  GetOne = "get-waste-station",
  GetAll = "get-waste-station-list",
}

export const useWasteStation = (id: number) =>
  useQuery(
    [WasteStationQueryKey.GetOne, id],
    async (): Promise<WasteStation> =>
      (await instance.get(`${ApiUrl.WasteStation}/${id}`)).data
  );

export const useWasteStationList = (settings = {}) =>
  useQuery(
    [WasteStationQueryKey.GetAll],
    async (): Promise<WasteStation[]> =>
      (await instance.get(ApiUrl.WasteStation)).data,
    { ...settings }
  );

export const useCreateWasteStation = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: WasteStationDTO): Promise<WasteStation> =>
      (await instance.post(ApiUrl.WasteStation, payload)).data,
    {
      onSuccess: () => {
        notification.info(`Mottagare skapad`);
        queryClient.invalidateQueries([WasteStationQueryKey.GetAll]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateWasteStation = (id: number) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: WasteStationDTO) =>
      (await instance.put(`${ApiUrl.WasteStation}/${id}`, payload)).data,
    {
      onSuccess: (data: WasteStation) => {
        notification.info(`Mottagare uppdaterad`);
        queryClient.invalidateQueries([WasteStationQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([WasteStationQueryKey.GetAll]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const usePatchWasteStation = (id: number, settings = {}) => {
  const notification = useNotification();

  return useMutation(
    async (payload: Partial<WasteStationDTO>): Promise<WasteStation> =>
      (await instance.patch(`${ApiUrl.WasteStation}/${id}`, payload)).data,
    {
      onSuccess: () => {
        notification.info(`Ändringarna sparade`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};
