import { MandatoryIndicator } from "components/MandatoryIndicator";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

export const DateField: FC<{
  label: string;
  mandatory?: boolean;
  register: UseFormRegister<any>;
  name: string;
}> = ({ label, register, name, mandatory = false }) => {
  return (
    <div className="sm:py-4 sm:border-t sm:border-gray-50">
      <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
          {label}
          {mandatory && <MandatoryIndicator />}
        </label>
        <div>
          <input
            type="date"
            placeholder="Välj datum"
            {...register(name)}
            className="text-sm border rounded-md border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
          />
        </div>
      </div>
    </div>
  );
};
