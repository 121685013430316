import {
  DocumentIcon,
  DocumentPlusIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { getFileName, isExcel, isPdf } from "helpers/file";

interface Props {
  url: string;
  onDelete: () => void;
  isInspectionPd?: boolean;
}
export const FilePreview: FC<Props> = ({ onDelete, url }) => {
  const name = getFileName(url);
  return (
    <div className="flex p-4 border border-gray-100 rounded-lg shadow-sm">
      <div className="flex items-center">
        {isPdf(name) ? (
          <DocumentIcon className="h-8 text-red-400" />
        ) : isExcel(name) ? (
          <DocumentPlusIcon className="h-8 text-green-600" />
        ) : (
          <PhotoIcon className="h-8 text-gray-400" />
        )}
        {isPdf(name) || isExcel(name) ? (
          <a
            href={url}
            download={name}
            className="flex flex-col space-y-1 ml-2 cursor-pointer"
          >
            <span
              className="text-sm font-semibold truncate"
              style={{ maxWidth: "25ch" }}
            >
              {name}
            </span>
            <span className="text-sm text-gray-500">
              {getFileTypeName(name)}
            </span>
          </a>
        ) : (
          <a
            href={`/image/${name}`}
            target="_blank"
            rel="noreferrer"
            className="flex flex-col space-y-1 ml-2 cursor-pointer"
          >
            <span
              className="text-sm font-semibold truncate"
              style={{ maxWidth: "25ch" }}
            >
              {name}
            </span>
            <span className="text-sm text-gray-500">Bild</span>
          </a>
        )}
      </div>
      <div className="flex items-center ml-auto">
        <TrashIcon
          onClick={onDelete}
          className="text-sm text-gray-500 h-4 cursor-pointer hover:text-red-400 hover:transform hover:scale-110"
        />
      </div>
    </div>
  );
};

const getFileTypeName = (name: string) => {
  if (isPdf(name)) {
    return "PDF";
  } else if (isExcel(name)) {
    return "Excel";
  } else {
    return "";
  }
};

interface InspectionPDFPreviewProps {
  onClick: () => void;
  inspectionName: string;
  isLoading?: boolean;
}
export const InspectionPDFPreview: FC<InspectionPDFPreviewProps> = ({
  onClick,
  inspectionName,
  isLoading = false,
}) => {
  return (
    <div
      className="flex p-4 border border-gray-100 rounded-lg shadow-sm relative"
      onClick={onClick}
    >
      <div className="flex items-center ">
        <DocumentIcon className="h-8 text-red-400" />
        <div className="flex flex-col space-y-1 ml-2 cursor-pointer">
          <span
            className="text-sm font-semibold truncate"
            style={{ maxWidth: "25ch" }}
          >
            {inspectionName}
          </span>
          <span className="text-sm text-gray-500">PDF</span>
        </div>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

const Loader = () => (
  <svg
    className="w-7 h-7 text-cyan-500 animate-spin absolute right-2"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
