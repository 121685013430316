import { usePatchCustomer } from "api/customer.api";
import { FiveYearsInspectionQueryKey } from "api/fiveYearsInspection.api";
import { SixMonthsInspectionQueryKey } from "api/sixMonthsInspection.api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Customer } from "types/customer.type";

export const useCustomerDocumentationEmail = (
  customer: Customer,
  inspectionId: number
) => {
  const [isDocumentationModalOpen, setIsDocumentationModalOpen] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    mutate: patchCustomer,
    isSuccess: isPatchCustomerSuccess,
    isLoading: isPatchingCustomer,
  } = usePatchCustomer(customer.id, {
    onSuccess: () => {
      // Det här är för att jag inte orkar kolla vilken av dem det är
      queryClient.invalidateQueries([
        FiveYearsInspectionQueryKey.GetOne,
        inspectionId,
      ]);
      queryClient.invalidateQueries([
        SixMonthsInspectionQueryKey.GetOne,
        inspectionId,
      ]);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ documentationEmail: string }>();

  const onSubmit = handleSubmit(({ documentationEmail }) => {
    patchCustomer({ documentationEmail });
  });

  useEffect(() => {
    if (customer) {
      reset({
        documentationEmail: customer.documentationEmail,
      });
    }
  }, [customer, reset]);

  useEffect(() => {
    if (isPatchCustomerSuccess) {
      setIsDocumentationModalOpen(false);
    }
  }, [isPatchCustomerSuccess]);

  const closeModal = () => {
    setIsDocumentationModalOpen(false);
    setTimeout(() => {
      if (customer) {
        reset({
          documentationEmail: customer.documentationEmail,
        });
      }
    }, 300); // Wait for modal to close
  };

  return {
    isDocumentationModalOpen,
    setIsDocumentationModalOpen,
    closeModal,
    onSubmit,
    register,
    errors,
    isPatchingCustomer,
  };
};
