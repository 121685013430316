import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "./axiosInstance";
import {
  TransportDocument,
  TransportDocumentGroup,
  UpdateTransportDocumentDTO,
} from "types/transportDocument.type";
import { CreateWasteDTO } from "types/waste.type";
// import useSound from "use-sound";
import { AssignmentQueryKey } from "./assignment.api";
import { TomningQueryKey } from "./tomning.api";
import { Vehicle } from "types/vehicle.type";

export const ENDPOINT = "transport-document";
export enum TransportDocumentQueryKey {
  GetOne = "get-one-transport-document",
  GetAll = "get-all-transport-documents",
  GetLoad = "get-loaded-transport-documents",
  GetAllByCustomer = "get-all-transport-documents-by-customer",
  GetNotReportedToNv = "get-td-not-reported-to-nv",
}

export const useTransportDocumentList = () =>
  useQuery(
    TransportDocumentQueryKey.GetAll,
    async (): Promise<TransportDocument[]> =>
      (await instance.get(ENDPOINT)).data
  );

export const useLoadedTransportDocuments = (userId: number) =>
  useQuery(
    [TransportDocumentQueryKey.GetLoad],
    async (): Promise<LoadedTransportDocumentResponse> =>
      (await instance.get(`${ENDPOINT}/loaded/${userId}`)).data,
    {
      enabled: userId > 0,
    }
  );

export type LoadedTransportDocumentResponse = {
  activeGroups: TransportDocumentGroup[];
  inactiveGroups: TransportDocumentGroup[];
  activeVehicles: Vehicle[];
};

export const useTransportDocument = (id: number) =>
  useQuery(
    [TransportDocumentQueryKey.GetOne, id],
    async (): Promise<TransportDocument> =>
      (await instance.get(`${ENDPOINT}/${id}`)).data,
    {
      enabled: id > 0,
    }
  );

export const useCreateTransportDocument = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (): Promise<TransportDocument> =>
      (await instance.post(ENDPOINT)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetAll]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
        notification.info(`Nytt transportdokument skapat`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const useUpdateTransportDocument = (id: number, settings = {}) =>
  useMutation(
    async (payload: UpdateTransportDocumentDTO): Promise<TransportDocument> =>
      (await instance.put(`${ENDPOINT}/${id}`, payload)).data,
    { ...settings }
  );

export const usePatchTransportDocument = (id: number, settings = {}) =>
  useMutation(
    async (
      payload: Partial<UpdateTransportDocumentDTO>
    ): Promise<TransportDocument> =>
      (await instance.patch(`${ENDPOINT}/${id}`, payload)).data,
    { ...settings }
  );

export const useUpdateTransportDocumentUser = (id: number, settings = {}) => {
  const notification = useNotification();

  return useMutation(
    async (payload: { userId: number }): Promise<TransportDocument> =>
      await instance.put(`${ENDPOINT}/${id}/user`, payload),
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export const useUpdateTransportDocumentWasteStationSignature = (
  settings = {}
) => {
  const notification = useNotification();

  return useMutation(
    async (
      payload: WasteStationSignatureListDTO
    ): Promise<TransportDocument[]> =>
      await instance.put(`${ENDPOINT}/waste-station-signature`, payload),
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export type WasteStationSignatureDTO = {
  transportDocumentId: number;
  wasteStationSignature: string;
};

type WasteStationSignatureListDTO = {
  wasteStationSignatureList: WasteStationSignatureDTO[];
  sendToWasteStation: boolean;
};

export const useSendPreliminaryTransportDocument = (settings = {}) => {
  return useMutation(
    async (id: number): Promise<number> =>
      (await instance.post(`${ENDPOINT}/${id}/send-preliminary`)).data,
    {
      ...settings,
    }
  );
};

export const useSendFinalEmail = (settings = {}) => {
  // const sound = require("assets/email.wav");
  // const [play] = useSound(sound);
  const queryClient = useQueryClient();
  const notification = useNotification();
  return useMutation(
    async ({ id, payload }: FinalEmailDTO): Promise<TransportDocument> =>
      (await instance.post(`${ENDPOINT}/${id}/send-final`, payload)).data,
    {
      onSuccess: (data: TransportDocument) => {
        // play();
        notification.info("Utskick schemalagt");

        queryClient.invalidateQueries([
          TransportDocumentQueryKey.GetOne,
          data.id,
        ]);
        if (data.tomning) {
          //Invaliderar tomning;
          queryClient.invalidateQueries([
            TomningQueryKey.GetOne,
            data.tomning.id,
          ]);
        }
        queryClient.invalidateQueries([AssignmentQueryKey.GetNotEmailed]);
      },
      onError: () => notification.error(),
      ...settings,
    }
  );
};
export type FinalEmailDTO = {
  id: number;
  payload: {
    toCustomer: boolean;
    toWasteStation: boolean;
    toParentCustomer: boolean;
  };
};

export const useAddWaste = (transportDocumentId: number, settings = {}) => {
  return useMutation(
    async (payload: CreateWasteDTO): Promise<TransportDocument> =>
      (await instance.post(`${ENDPOINT}/${transportDocumentId}/waste`, payload))
        .data,
    {
      ...settings,
    }
  );
};

export const useDeleteTransportDocument = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (id: number): Promise<void> =>
      await instance.delete(`${ENDPOINT}/${id}`),
    {
      onSuccess: () => {
        notification.info(`Transportdokument borttaget`);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetAll]);
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export enum RapporteringsTyp {
  Transport = "Transport",
  TransportPlanering = "TransportPlanering",
}

export type NotReportedToEnvType = {
  tdId: number;
  transportDate: string;
  customerName: string;
  type: RapporteringsTyp;
  itemId: string;
  wasteStationName: string;
};

export const useTransportDocumentNotReportedToNv = (options = {}) =>
  useQuery(
    TransportDocumentQueryKey.GetNotReportedToNv,
    async (): Promise<NotReportedToEnvType[]> =>
      (await instance.get(`${ENDPOINT}/not-reported-to-nv`)).data,
    { ...options }
  );
