import { TextInput } from "components/TextInput";
import { FC } from "react";
import { FormState, UseFormRegister } from "react-hook-form";
import { TransportDTO } from "types/transport.type";

type Props = {
  formState: FormState<TransportDTO>;
  register: UseFormRegister<TransportDTO>;
  disabled?: boolean;
};

export const Transportör: FC<Props> = ({ register, formState, disabled }) => {
  return (
    <div className="pl-6">
      <h2 className="text-gray-900 text-lg font-semibold mb-2">Transportör</h2>

      <div className="space-y-4">
        <TextInput
          register={register}
          label="Företagets namn"
          name="verksamhetensNamn"
          errorMessage={formState?.errors?.verksamhetensNamn?.message}
          disabled={disabled}
        />
        <TextInput
          register={register}
          label="Organisationsnummer"
          name="verksamhetsutovare"
          errorMessage={formState?.errors?.verksamhetsutovare?.message}
          disabled={disabled}
        />
        <TextInput
          register={register}
          label="Kontaktperson - namn"
          name="verksamhetensKontaktpersonNamn"
          errorMessage={
            formState?.errors?.verksamhetensKontaktpersonNamn?.message
          }
          disabled={disabled}
        />

        <TextInput
          register={register}
          label="Kontaktperson - epost"
          name="verksamhetensKontaktpersonEpost"
          errorMessage={
            formState?.errors?.verksamhetensKontaktpersonEpost?.message
          }
          disabled={disabled}
        />
        <TextInput
          register={register}
          label="Kontaktperson - telefon"
          name="verksamhetensKontaktpersonTelefonnummer"
          errorMessage={
            formState?.errors?.verksamhetensKontaktpersonTelefonnummer?.message
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
};
