import { useLocation, useNavigate } from "react-router-dom";

export const useRemoveMultiParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const removeMultiParam = (name: string, value?: string | number) => {
    // Parse the current query string
    const searchParams = new URLSearchParams(location.search);

    if (value === undefined) {
      // Remove all instances of the parameter
      searchParams.delete(name);
    } else {
      // Remove only specific instances of the parameter
      const values = searchParams.getAll(name);
      searchParams.delete(name);
      values.forEach((val) => {
        if (val !== value.toString()) {
          searchParams.append(name, val);
        }
      });
    }

    // Navigate to the new URL with the updated query parameters
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  return removeMultiParam;
};
