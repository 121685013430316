import { TextInput } from "components/TextInput";
import { FC } from "react";
import {
  FormState,
  UseFieldArrayReturn,
  UseFormRegister,
} from "react-hook-form";
import { TransportDTO } from "types/transport.type";
import { TrashIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "components/buttons/SecondaryButton";

type Props = {
  avfallFieldArray: UseFieldArrayReturn<TransportDTO, "avfall">;
  register: UseFormRegister<TransportDTO>;
  formState: FormState<TransportDTO>;
  disabled: boolean;
};

export const Avfall: FC<Props> = ({
  register,
  formState,
  avfallFieldArray,
  disabled,
}) => {
  return (
    <div className="px-6">
      <h2 className="text-gray-900 text-lg font-semibold mb-2 flex justify-between items-center">
        Avfall{" "}
      </h2>
      <div className="flex flex-col gap-y-4 mb-4">
        {avfallFieldArray.fields.map((field, index) => (
          <div key={field.id} className="flex items-start gap-x-4">
            <TextInput
              register={register}
              label="Avfallskod"
              name={`avfall[${index}].kod`}
              errorMessage={
                formState.errors.avfall &&
                formState.errors.avfall[index]?.kod?.message
              }
              disabled={disabled}
            />
            <TextInput
              register={register}
              label="Mängd"
              name={`avfall[${index}].mangd`}
              errorMessage={
                formState.errors.avfall &&
                formState.errors.avfall[index]?.mangd?.message
              }
              appendix="kg"
              disabled={disabled}
            />
            {!disabled && (
              <TrashIcon
                className="text-sm text-gray-500 h-5 cursor-pointer hover:text-red-400 hover:transform hover:scale-110 mt-8"
                onClick={() => avfallFieldArray.remove(index)}
              />
            )}
          </div>
        ))}
        {!disabled && (
          <div>
            <SecondaryButton
              size="small"
              onClick={() => avfallFieldArray.append({ kod: "", mangd: 0 })}
              disabled={disabled}
            >
              + Lägg till
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  );
};
