import { useForm } from "react-hook-form";
import { useGetInvitation } from "api/invitation.api";
import { PageLoader } from "components/PageLoader";
import { useEffect } from "react";
import { SignupDTO, useSignup } from "api/user.api";
import { PasswordInput } from "components/PasswordInput";
import { useNavigate } from "react-router-dom";
import { RouteUrl } from "routes.type";
import { PageTransition } from "components/animations/SnapUp";
import Logo from "assets/Logo.png";

interface SignupForm {
  password: string;
  name: string;
}

function SignUpPage() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Skapa konto - Driftjournalen";
  }, []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";
  const {
    data: invitation,
    isError,
    isLoading: isLoadingInvitation,
  } = useGetInvitation(code);

  const {
    mutate: signup,
    isLoading: isSigningUp,
    isSuccess: isSignupSuccess,
  } = useSignup();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupForm>();
  const onSubmit = handleSubmit((data: SignupForm) => {
    const signupData: SignupDTO = {
      name: data.name,
      password: data.password,
      code,
    };
    signup(signupData);
  });

  useEffect(() => {
    if (isSignupSuccess) {
      navigate(RouteUrl.Login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignupSuccess]);

  if (isLoadingInvitation) {
    return <PageLoader />;
  }
  if (isError || !invitation) {
    return <h1>Något gick fel</h1>;
  }

  return (
    <PageTransition>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Välj ett lösenord för att slutföra registreringen
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    disabled
                    value={invitation.email}
                    className="appearance-none block w-full px-3 py-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                  />
                </div>
              </div>
              <PasswordInput
                register={register}
                name="password"
                label="Välj lösenord (minst 8 tecken)"
                validation={{
                  validate: (value?: string) =>
                    (value ?? "").length >= 8 ||
                    "Lösenordet måste vara minst 8 tecken långt",
                }}
                errorMessage={errors.password?.message}
                onSubmit={onSubmit}
              />

              <div>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300 sm:col-start-2 sm:text-sm"
                  onClick={onSubmit}
                >
                  {isSigningUp && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {isSigningUp ? "Skapar konto..." : "Skapa konto"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}

export default SignUpPage;
