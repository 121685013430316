import { getDetailedSeparatorFields } from "pages/app/separator/TechnicalInformation";
import { FC } from "react";
import { Separator } from "types/separator/separator.type";
import { ModalContentWrapper } from "./ModalContentWrapper";
import { TabDisplayItemLabel } from "./tab/TabDisplayItemLabel";
import { TabDisplayItemValue } from "./tab/TabDisplayItemValue";
import { TabDisplayItemWrapper } from "./tab/TabDisplayItemWrapper";
import { TabDisplayList } from "./tab/TabDisplayList";

interface Props {
  separator: Separator;
}

export const ViewSeparatorModal: FC<Props> = ({ separator }) => {
  return (
    <ModalContentWrapper>
      <TabDisplayList lgGridCols="4">
        {[...getDetailedSeparatorFields(separator)].map(({ label, value }) => (
          <TabDisplayItemWrapper key={label}>
            <TabDisplayItemLabel>{label}</TabDisplayItemLabel>
            <TabDisplayItemValue>{value}</TabDisplayItemValue>
          </TabDisplayItemWrapper>
        ))}
      </TabDisplayList>
    </ModalContentWrapper>
  );
};
