import { ProxyAssignmentTag } from "components/ProxyAssignmentTag";
import { DescriptionList } from "components/desriptionList/DescriptionList";
import { Item } from "components/desriptionList/Item";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import TabContainer from "components/tab/TabContainer";
import { Customer } from "types/customer.type";

interface Props {
  customer: Customer;
}

const CustomerDetails = ({ customer }: Props) => {
  return (
    <TabContainer>
      <DescriptionList>
        <Item>
          <Label>Företagsnamn</Label>
          <Value>
            <div className="gap-x-4 flex items-center">
              {customer.name}
              {customer.isParentCustomer && (
                <ProxyAssignmentTag text="Uppdragsförmedlare" />
              )}
            </div>
          </Value>
        </Item>
        <Item>
          <Label>Organisationsnummer</Label>
          <Value>{customer.organizationNumber}</Value>
        </Item>
        {customer.parentCustomers.length > 0 && (
          <Item>
            <Label>Underkund till</Label>
            <Value>
              {customer.parentCustomers.map((c) => c.name).join(", ")}
            </Value>
          </Item>
        )}
        <Item>
          <Label>Eget kundnummer</Label>
          <Value>{customer.customerIdentification}</Value>
        </Item>
        <Item>
          <Label>ID</Label>
          <Value>{customer.id}</Value>
        </Item>
        <Item>
          <Label>Adress</Label>
          <Value>
            <div className="space-y-1">
              {!!customer.address && <p>{customer.address}</p>}
              {!!customer.postalCode && <p>{customer.postalCode}</p>}
              {!!customer.city && <p>{customer.city}</p>}
              {!!customer.kommunKod && <p>Kommunkod:{customer.kommunKod}</p>}
            </div>
          </Value>
        </Item>
        <Item>
          <Label>Kontaktperson</Label>
          <Value>
            <div className="space-y-1">
              {!!customer.contactName && <p>{customer.contactName}</p>}
              {!!customer.contactPhone && <p>{customer.contactPhone}</p>}
              {!!customer.contactEmail && <p>{customer.contactEmail}</p>}
            </div>
          </Value>
        </Item>
        <Item>
          <Label>Epostadress för rapporter</Label>
          <Value>{customer.documentationEmail}</Value>
        </Item>
        <Item>
          <Label>
            <div className="w-[200px]">
              Kunden har givit fullmakt för rapportering till Avfallsregistret
            </div>
          </Label>
          <Value>{customer.nvFullmakt ? "Ja" : "Nej"}</Value>
        </Item>
        <Item>
          <Label>Anteckningar</Label>
          <Value>{customer.notes}</Value>
        </Item>
      </DescriptionList>
    </TabContainer>
  );
};

export default CustomerDetails;
