import { useQuery } from "react-query";
import { Changelog } from "types/changelog.type";
import instance from "./axiosInstance";

const ENDPOINT = "/changelog";
export const GET_CHANGELOG_QK = "get-changelog";

export const useChangelog = () =>
  useQuery(
    GET_CHANGELOG_QK,
    async (): Promise<Changelog[]> => (await instance.get(ENDPOINT)).data
  );
