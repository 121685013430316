import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getNextSeparatorTaskDate,
  Separator,
} from "types/separator/separator.type";
import { useCreateTask } from "api/task.api";
import { TaskDTO } from "types/task.type";
import { TaskType } from "enums/TaskType.enum";
import { useQueryClient } from "react-query";
import { useNotification } from "hooks/useNotification";
import { SeparatorQueryKey } from "api/separator.api";

export const useCreateTaskLogic = (
  separator: Separator,
  taskType: TaskType
) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const {
    mutate: createTask,
    isSuccess: isCreateTaskSuccess,
    isLoading: isCreatingTask,
  } = useCreateTask({
    onSuccess: () => {
      queryClient.invalidateQueries([SeparatorQueryKey.GetOne, separator.id]);
      notification.info("Datum ändrat");
    },
  });

  const {
    register,
    handleSubmit: handleTaskSubmit,
    watch: formData,
    reset,
    formState: { errors: formErrors },
  } = useForm<TaskDTO>();

  useEffect(() => {
    if (separator) {
      reset({
        separatorId: separator.id,
        type: taskType,
        date: getNextSeparatorTaskDate(separator, taskType),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [separator]);

  useEffect(() => {
    if (isCreateTaskSuccess) {
      setIsModalOpen(false);
    }
  }, [isCreateTaskSuccess]);

  const onTaskSubmit = async () => {
    handleTaskSubmit((data: TaskDTO) => createTask(data))();
  };

  const onModalClose = () => {
    // resetta fälten
    setIsModalOpen(false);
    setTimeout(() => !!separator && reset({ separatorId: separator.id }), 300); // för att inte man ska hinna se att fälten ev ändras tillbaka till ursprungligt värde
  };

  return {
    isModalOpen,
    formErrors,
    isCreatingTask,
    onModalClose,
    openModal,
    onTaskSubmit,
    register,
    formData,
    isCreateTaskSuccess,
  };
};
