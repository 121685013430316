import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RouteUrl } from "routes.type";
import { TomningQueryKey, useTomning } from "api/tomning.api";
import { RadioGroup } from "components/inspection/form/RadioGroup";
import { Role, TomningType, TransportDocumentStatus } from "types/enums";
import { PageLoader } from "components/PageLoader";
import { isAvskiljare } from "types/separator/separator.type";
import { useEffect, useState } from "react";
import { Waste } from "types/waste.type";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import Modal from "components/Modal";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";
import { WasteForm } from "../transport-document/WasteForm";
import { useQueryClient } from "react-query";
import DangerModal from "components/DangerModal";
import { HasSentStatus } from "types/transportDocument.type";
import instance from "api/axiosInstance";
import { SignatureModal } from "components/SignatureModal";
import { Header } from "./Header";
import { Label } from "./Label";
import { Section } from "./Section";
import { useUpdateCustomerSignature } from "./hooks/useUpdateCustomerSignature";
import { useUpdateDriverSignature } from "./hooks/useUpdateDriverSignature";
import { useUpdateTomningType } from "./hooks/useUpdateTomningType";
import { useUpdateWasteStation } from "./hooks/useWasteStationLogic";
import { useWasteLogic } from "./hooks/useWasteLogic";
import { useResetTomningLogic } from "./hooks/useResetTomningLogic";
import { useSendPreliminaryTransportDocument } from "api/transportDocument.api";
import { AssignmentInfoModal } from "../schedule/AssignmentInfoModal";
import { useTomningPageBreadCrumbs } from "./hooks/useTomningPageBreadCrumbs";
import DropdownStupid from "components/DropdownStupid";
import { useVehicleLogic } from "./hooks/useVehicleLogic";
import { useUserLogic } from "./hooks/useUserLogic";
import Dropdown from "components/Dropdown";
import userStore, { getUserRole } from "store/user.store";
import dismissedMessagesStore from "store/dismissedMessages.store";
import { Transition } from "components/animations/Transition";
import { config } from "react-spring";
import Alert from "components/Alert";
import { useSendFinalLogic } from "./hooks/useSendFinalLogic.hook";
import { Attachments } from "./Attachments";
import { isReadyForPreliminaryPdf } from "types/tomning.type";
import { Info } from "components/Info";
import {
  ProtocolLabel,
  ProtocolLabelValueWrapper,
  ProtocolValue,
} from "components/inspection/protocol/LabelValue";
import { PageTransition } from "components/animations/SnapUp";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import { isMobile } from "helpers/isMobile";
import { EditWasteForm } from "./EditWasteForm";
import { SentTdEmails } from "./SentTdEmails";
import { EmailStatus } from "types/email.type";
import { useNotification } from "hooks/useNotification";
import { ProxyAssignmentBanner } from "components/ProxyAssignmentBanner";
import { useUpdateTransportDate } from "./hooks/useUpdateTransportDate";
import { FormProvider } from "react-hook-form";
import { DateTimePicker } from "components/DateTimePicker";
import { WasteCodePreview } from "./WasteCodePreview";

export default function TomningPage() {
  // Tips
  const { tpDone, setTpDone } = dismissedMessagesStore();
  const notification = useNotification();

  const params = useParams();
  const id: number = +(params?.id || "-1");

  // view/edit mode
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "view";
  const isEditMode = mode === "edit";

  const setViewMode = () => setSearchParams({ mode: "view" });

  const queryClient = useQueryClient();

  const [shouldRefetchTomning, setShouldRefetchTomning] = useState(false);

  const { data: tomning, isLoading: isLoadingTomning } = useTomning(
    id,
    shouldRefetchTomning
  );

  useEffect(() => {
    if (tomning) {
      setShouldRefetchTomning(
        (tomning.transportDocument.emails ?? []).some(
          (email) =>
            email.status === EmailStatus.Scheduled ||
            email.status === EmailStatus.Sent
        )
      );
    }
  }, [tomning]);

  // Breadcrumbs
  useTomningPageBreadCrumbs(tomning);

  // Update tomning.type
  const { registerTomningType } = useUpdateTomningType(tomning);

  // Waste station
  const {
    updateWasteStation,
    selectedWasteStationId,
    wasteStationDropdownItems,
  } = useUpdateWasteStation(tomning);

  // transportdatum
  const updateTransportDate = useUpdateTransportDate(tomning);

  // Vehicle
  const { updateVehicle, selectedVehicleId, vehicleDropdownItems } =
    useVehicleLogic(tomning);

  // Waste
  const {
    registerUpdateWaste,
    isUpdatingWaste,
    onUpdateWasteSubmit,
    updateWasteErrors,
    wasteToEdit,
    wasteIdToEdit,
    setWasteIdToEdit,
    handleCloseUpdateWasteModal,
    registerNewWaste,
    newWasteErrors,
    isAddingNewWaste,
    onNewWasteSubmit,
    handleSetWasteCodeQuery,
    wasteCodeComboBoxItems,
    isWasteModalOpen,
    setIsWasteModalOpen,
    wasteCodeError,
    selectedWasteCode,
    setSelectedWasteCode,
    onDeleteConfirm,
    wasteToDelete,
    wasteIdToDelete,
    setWasteIdToDelete,
  } = useWasteLogic(tomning?.transportDocument, id);

  // PDF preview
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const previewPDF = () => {
    const url = `/transport-document/${tomning?.transportDocument.id}/preview-pdf`;
    setIsLoadingPreview(true);
    if (isMobile()) {
      // Ladda ner pdfen om det är på mobil
      instance
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          setIsLoadingPreview(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
        });
    } else {
      // Annars öppna i ny flik
      instance
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          setIsLoadingPreview(false);
          queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
    }
  };

  // Customer signature
  const {
    isCustomerSignatureModalVisible,
    showCustomerSignatureModal,
    hideCustomerSignatureModal,
    onCustomerSignatureSubmit,
    isSubmittingCustomerSignature,
  } = useUpdateCustomerSignature(tomning, setViewMode);

  // Driver signature
  const {
    isDriverSignatureModalVisible,
    showDriverSignatureModal,
    hideDriverSignatureModal,
    onDriverSignatureSubmit,
    isSubmittingDriverSignature,
  } = useUpdateDriverSignature(tomning, setViewMode);

  //

  // Reset tömning
  const {
    handleResetTomning,
    isResettingTomning,
    isResetModalOpen,
    setIsResetModalOpen,
  } = useResetTomningLogic(tomning);

  // Send preliminary TD
  const { mutate: sendPreliminary } = useSendPreliminaryTransportDocument({
    onSuccess: () => {
      notification.info("Utskick schemalagt");
      queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
    },
  });

  // Assignment info
  const [isAssignmentInfoModalOpen, setIsAssignmentInfoModalOpen] =
    useState<boolean>(false);

  // Change user
  const {
    isChangeUserModalOpen,
    openChangeUserModal,
    isChangingUser,
    isChangeUserSuccess,
    userDropdownItems,
    onUserSubmit,
    registerUser,
    handleChangeUserModalClose,
  } = useUserLogic(tomning);

  const userRole = userStore(getUserRole);
  const navigate = useNavigate();

  useEffect(() => {
    if (isChangeUserSuccess) {
      if (userRole === Role.User) {
        navigate(`/app/${RouteUrl.UserSchedule}`);
      }
    }
  }, [isChangeUserSuccess, navigate, userRole]);

  const { changeTdUser, setChangeTdUser } = dismissedMessagesStore();

  // send final emails
  const { sendFinalEmail } = useSendFinalLogic();

  if (isLoadingTomning) {
    return <PageLoader />;
  }

  if (!tomning) {
    return <h1>error</h1>;
  }

  const locked = !isEditMode;

  const isSigned =
    tomning.transportDocument.status === TransportDocumentStatus.Signed;

  const isWeighed =
    tomning.transportDocument.status === TransportDocumentStatus.Weighed;

  const isLoaded =
    tomning.transportDocument.status === TransportDocumentStatus.Loaded;

  const isAdmin = userRole === Role.Admin;

  // Knapp för att toggla mellan edit och view

  return (
    <PageTransition>
      {tomning.assignment.parentCustomer && (
        <ProxyAssignmentBanner
          parentCustomer={tomning.assignment.parentCustomer}
        />
      )}
      <div className="flex flex-col lg:flex-row  pb-4">
        <div className="bg-white shadow-lg overflow-hidden lg:rounded-lg max-w-xl sm:max-w-3xl flex-1">
          <Header
            openResetModal={() => setIsResetModalOpen(true)}
            sendPreliminary={() =>
              sendPreliminary(tomning.transportDocument.id)
            }
            isLocked={locked}
            tomning={tomning}
            openAssignmentInfoModal={() => setIsAssignmentInfoModalOpen(true)}
            openChangeUserModal={openChangeUserModal}
            isSigned={isSigned}
            isWeighed={isWeighed}
            isEditMode={isEditMode}
            toggleEditMode={() =>
              setSearchParams({ mode: isEditMode ? "view" : "edit" })
            }
            // assignment={tomning.assignment}
          />
          <div className="px-4">
            {!tpDone && isLoaded && (
              <div className="rounded-md bg-cyan-50 p-4">
                <div className="flex mb-3 items-center">
                  <LightBulbIcon
                    className="h-5 w-5 text-cyan-400 mr-2"
                    aria-hidden="true"
                  />
                  <span className="text-cyan-800 text-sm font-semibold">
                    Tips!
                  </span>
                </div>
                <p className="text-sm text-cyan-700 mb-4">
                  Uppdraget har nu status "Lastad". För att ange vägd vikt och
                  få mottagarstationens signatur går du in på "Last" (den rosa
                  knappen längst upp till höger).
                </p>

                {/* <p className="text-sm text-cyan-700 mb-4">
            Till höger om textfälten står den vikt du uppskattade vid
            hämtningen. Klicka på knappen för att återställa fältet till det
            värdet.
          </p> */}

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setTpDone(true)}
                    className="ml-3 rounded-md bg-cyan-50 px-2 py-1.5 text-sm font-medium text-cyan-800 hover:bg-cyan-100 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 focus:ring-offset-cyan-50"
                  >
                    Visa inte igen
                  </button>
                </div>
              </div>
            )}
            {/* Ändra typ */}
            {isAvskiljare(tomning.separator) && (
              <Section>
                {locked ? (
                  <ProtocolLabelValueWrapper>
                    <ProtocolLabel>Typ av tömning</ProtocolLabel>
                    <ProtocolValue>
                      {tomning.type === TomningType.Toppsugning
                        ? "Toppsugning"
                        : tomning.type === TomningType.Heltomning
                        ? "Heltömning"
                        : ""}
                    </ProtocolValue>
                  </ProtocolLabelValueWrapper>
                ) : (
                  <>
                    <RadioGroup
                      label="Typ av tömning"
                      items={[
                        {
                          value: TomningType.Toppsugning,
                          label: "Toppsugning",
                        },
                        {
                          value: TomningType.Heltomning,
                          label: "Heltömning",
                        },
                      ]}
                      register={registerTomningType}
                      name="type"
                      mandatory
                      removeTopBorder
                    />
                  </>
                )}
              </Section>
            )}

            <Section>
              {locked ? (
                <ProtocolLabelValueWrapper>
                  <ProtocolLabel>Fordon</ProtocolLabel>
                  <ProtocolValue>
                    {tomning.transportDocument.vehicle?.registrationNumber}
                  </ProtocolValue>
                </ProtocolLabelValueWrapper>
              ) : (
                <div className="max-w-sm sm:max-w-xs">
                  <Label mandatory>Fordon</Label>
                  <DropdownStupid
                    items={vehicleDropdownItems}
                    label="Fordon"
                    disabled={vehicleDropdownItems.length === 0}
                    descriptionItemText={
                      vehicleDropdownItems.length === 0
                        ? "Ni saknar fordon"
                        : ""
                    }
                    hideLabel
                    handleChange={(val: string | number) =>
                      updateVehicle(!val ? undefined : +val)
                    }
                    value={selectedVehicleId}
                  />
                </div>
              )}
            </Section>
            {/* Avfall */}
            <Section>
              <div className="flex items-center justify-between">
                <Label mandatory>Avfall</Label>
                {!locked && (
                  <SecondaryButton
                    onClick={() => setIsWasteModalOpen(true)}
                    size="small"
                  >
                    <PlusIcon
                      className="-ml-1 mr-1 h-3 w-3"
                      aria-hidden="true"
                    />
                    Lägg till
                  </SecondaryButton>
                )}
              </div>
              <div className="space-y-6">
                {tomning.transportDocument.wasteList.map((waste) => (
                  <WasteCodePreview
                    key={waste.id}
                    waste={waste}
                    openEditModal={() => setWasteIdToEdit(waste.id)}
                    onDeleteClick={() => setWasteIdToDelete(waste.id)}
                    isLocked={locked}
                  />
                ))}
              </div>
            </Section>
            {/* Mottagare */}
            <Section>
              {locked ? (
                <ProtocolLabelValueWrapper>
                  <ProtocolLabel>Mottagare</ProtocolLabel>
                  <ProtocolValue>
                    {tomning.transportDocument.wasteStation?.name}
                  </ProtocolValue>
                </ProtocolLabelValueWrapper>
              ) : (
                <div className="max-w-sm sm:max-w-xs">
                  <Label mandatory>Mottagare</Label>
                  <DropdownStupid
                    items={wasteStationDropdownItems}
                    label="Mottagare"
                    disabled={wasteStationDropdownItems.length === 0 || locked}
                    descriptionItemText={
                      wasteStationDropdownItems.length === 0
                        ? "Ni saknar mottagarstationer"
                        : ""
                    }
                    hideLabel
                    handleChange={(val: string | number) =>
                      updateWasteStation(!val ? undefined : +val)
                    }
                    value={selectedWasteStationId}
                  />
                </div>
              )}
            </Section>
            {/* PDF */}
            {!isAdmin && (
              <Section>
                <Label>Preliminärt transportdokument</Label>
                <div className="flex items-center justify-start">
                  {tomning.transportDocument.hasSentPreliminaryEmail ? (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <CheckCircleIcon className="h-5 w-5 text-cyan-400 ml-2 mr-1" />
                        <span className="text-sm text-gray-800 font-normal">
                          Skickat
                        </span>
                      </div>
                      <SecondaryButton size="small" onClick={previewPDF}>
                        Visa
                      </SecondaryButton>
                    </div>
                  ) : (
                    <SecondaryButton size="small" onClick={previewPDF}>
                      Visa
                    </SecondaryButton>
                  )}
                </div>
              </Section>
            )}
            {/* Transportdatum */}
            <Section>
              <Label>Tidpunkt för avfärd</Label>
              <FormProvider {...updateTransportDate.form}>
                <DateTimePicker
                  control={updateTransportDate.form.control}
                  name="timeOfDeparture"
                  // label="Tidpunkt för avfärd"
                  disabled={!isEditMode}
                />
              </FormProvider>
            </Section>
            {/* Avsändarens signatur */}
            <Section>
              <Label>Avsändarens signatur</Label>
              <div>
                {!isReadyForPreliminaryPdf(tomning) && (
                  <Info text="Tillgänglig när obligatoriska fält är ifyllda" />
                )}
                {isReadyForPreliminaryPdf(tomning) &&
                  !tomning.transportDocument.customerSignature && (
                    <PrimaryButton
                      size="small"
                      onClick={showCustomerSignatureModal}
                    >
                      Ange avsändarens signatur
                    </PrimaryButton>
                  )}
              </div>
              <Modal
                isOpen={isCustomerSignatureModalVisible}
                title="Signatur avsändare"
                onClose={hideCustomerSignatureModal}
                hideButtons
              >
                <SignatureModal
                  header="Signatur avsändare"
                  sendImageToParent={(val: string) => {
                    onCustomerSignatureSubmit(val);
                  }}
                  cancel={hideCustomerSignatureModal}
                  isLoading={isSubmittingCustomerSignature}
                />
              </Modal>
              {!!tomning.transportDocument.customerSignature && (
                <div className="flex items-center justify-between">
                  <img
                    src={tomning.transportDocument.customerSignature}
                    className="h-12 w-auto mt-4"
                    style={{ maxWidth: 240 }}
                    alt="Customer signature"
                  />
                  {!locked && (
                    <SecondaryButton
                      size="small"
                      onClick={showCustomerSignatureModal}
                    >
                      Gör om
                    </SecondaryButton>
                  )}
                </div>
              )}
            </Section>
            {/* Chaufförens signatur */}
            <Section>
              <Label>Chaufförens signatur</Label>
              <div>
                {!isReadyForPreliminaryPdf(tomning) && (
                  <Info text="Tillgänglig när obligatoriska fält är ifyllda" />
                )}
                {isReadyForPreliminaryPdf(tomning) &&
                  !tomning.transportDocument.driverSignature && (
                    <PrimaryButton
                      size="small"
                      onClick={showDriverSignatureModal}
                    >
                      Ange chaufförens signatur
                    </PrimaryButton>
                  )}
              </div>

              <Modal
                isOpen={isDriverSignatureModalVisible}
                title="Signatur chaufför"
                onClose={hideDriverSignatureModal}
                hideButtons
              >
                <SignatureModal
                  header="Signatur chaufför"
                  sendImageToParent={(val: string) => {
                    onDriverSignatureSubmit(val);
                  }}
                  cancel={hideDriverSignatureModal}
                  isLoading={isSubmittingDriverSignature}
                />
              </Modal>
              {!!tomning.transportDocument.driverSignature && (
                <div className="flex items-center justify-between">
                  <img
                    src={tomning.transportDocument.driverSignature}
                    className="h-12 w-auto mt-4"
                    alt="Driver signature"
                  />
                  {!locked && (
                    <SecondaryButton
                      size="small"
                      onClick={showDriverSignatureModal}
                    >
                      Gör om
                    </SecondaryButton>
                  )}
                </div>
              )}
            </Section>
            {/* Mottagare signatur */}
            {!!tomning.transportDocument.wasteStationSignature && (
              <Section>
                <Label mandatory>Mottagarens signatur</Label>
                <img
                  src={tomning.transportDocument.wasteStationSignature}
                  className="h-12 w-auto mt-4"
                  alt="Driver signature"
                />
              </Section>
            )}
          </div>
          {/* Ändra avfall - modal*/}
          <Modal
            isOpen={!!wasteIdToEdit}
            title={`Ändra avfall ${wasteToEdit?.wasteCode.codePretty}`}
            actionText="Spara"
            actionHandler={onUpdateWasteSubmit}
            onClose={handleCloseUpdateWasteModal}
            isLoading={isUpdatingWaste}
          >
            <EditWasteForm
              register={registerUpdateWaste}
              errors={updateWasteErrors}
            />
          </Modal>

          {/* Nytt avfall - modal */}
          <Modal
            isOpen={isWasteModalOpen}
            onClose={() => setIsWasteModalOpen(false)}
            title="Lägg till avfall"
            actionHandler={onNewWasteSubmit}
            isLoading={isAddingNewWaste}
          >
            <WasteForm
              wasteCodes={wasteCodeComboBoxItems}
              selectedWasteCode={selectedWasteCode}
              setSelectedWasteCode={setSelectedWasteCode}
              handleSetWasteCodeQuery={handleSetWasteCodeQuery}
              register={registerNewWaste}
              wasteCodeError={wasteCodeError}
              errors={newWasteErrors}
            />
          </Modal>

          {/* Ta bort avfall */}
          <DangerModal
            isOpen={!!wasteIdToDelete}
            title="Bekräfta borttagning av avfall"
            paragraphs={getDangerModalParagraphs(wasteToDelete)}
            actionHandler={onDeleteConfirm}
            onClose={() => setWasteIdToDelete(undefined)}
            actionText="Ta bort"
          />

          {/* Återställ till assignment */}
          <DangerModal
            isOpen={isResetModalOpen}
            title="Återställ uppdrag"
            paragraphs={[
              "Fälten kommer återställas till hur det var när du påbörjade uppdraget.",
            ]}
            actionHandler={handleResetTomning}
            onClose={() => setIsResetModalOpen(false)}
            actionText="Återställ uppdrag"
            isLoading={isResettingTomning}
          />

          {/* Assignment info */}
          <Modal
            isOpen={isAssignmentInfoModalOpen}
            onClose={() => setIsAssignmentInfoModalOpen(false)}
            title="Uppdrag"
            closeButtonText="Stäng"
          >
            <AssignmentInfoModal
              assignmentId={tomning.assignment.id}
              closeModal={() => setIsAssignmentInfoModalOpen(false)}
              isOpen={isAssignmentInfoModalOpen}
            />
          </Modal>

          {/* Change user */}
          <Modal
            isOpen={isChangeUserModalOpen}
            onClose={handleChangeUserModalClose}
            title="Byt chaufför"
            closeButtonText="Stäng"
            actionHandler={onUserSubmit}
            actionText="Spara"
            isLoading={isChangingUser}
          >
            <ModalContentWrapper>
              <div className="pb-8">
                <Transition
                  settings={{
                    enter: { opacity: 1, height: 140 },
                    leave: { opacity: 0, height: 0 },
                    config: { ...config.slow, precision: 0.2, friction: 30 },
                  }}
                  isVisible={!changeTdUser && userRole === Role.User}
                >
                  <div className="flex-col">
                    <Alert
                      text="Om du byter chaufför kommer du inte längre kunna se uppdraget."
                      onButtonClick={() => setChangeTdUser(true)}
                      buttonText="Visa inte igen"
                    />
                    <div className="h-4"></div>
                  </div>
                </Transition>
                <Dropdown
                  items={userDropdownItems}
                  name="userId"
                  label="Chaufför"
                  register={registerUser}
                  validation={{
                    validate: (value: number) =>
                      value > 0 || "Du måste välja en chaufför",
                  }}
                  // errorMessage={formErrors?.userId?.message}
                />
              </div>
            </ModalContentWrapper>
          </Modal>

          {/* Send final email */}
        </div>
        {isAdmin && (
          <div className="lg:w-1/3 flex flex-col space-y-7 mt-7 lg:mt-0">
            <Attachments
              previewPDF={previewPDF}
              fileName="transportdokument.pdf"
              sendPDF={sendFinalEmail}
              transportDocument={tomning.transportDocument}
              isLoadingPreview={isLoadingPreview}
              tomningId={tomning.id}
              assignment={tomning.assignment}
            />
            <SentTdEmails
              emails={tomning.transportDocument.emails}
              isSentWithOtherInspection={
                tomning.transportDocument.emailToCustomerStatus ===
                HasSentStatus.SentWithOtherInspection
              }
            />
          </div>
        )}
      </div>
    </PageTransition>
  );
}

export const getLinkToTomningPage = (
  customerId: number | string,
  separatorId: number | string,
  tomningId: number | string
) =>
  `${RouteUrl.App}/${RouteUrl.Customers}/${customerId}/${separatorId}/${RouteUrl.Tomning}/${tomningId}`;

const getDangerModalParagraphs = (wasteToDelete?: Waste) => {
  if (!wasteToDelete) {
    return [];
  }
  return [
    `Kod: ${wasteToDelete.wasteCode.codePretty}`,
    `Beskrivning: ${wasteToDelete.wasteCode.description}`,
    // "Är du säker på att du vill ta bort avfallet?",
  ];
};
