import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { LocalRequirementDTO } from "types/fiveYearsInspection/fiveYearsInspection.type";
import { TextInput } from "components/TextInput";
import { ModalContentWrapper } from "components/ModalContentWrapper";
interface Props {
  register: UseFormRegister<LocalRequirementDTO>;
  actionHandler: () => void;
}
const AddLocalrequirement: FC<Props> = ({ register, actionHandler }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="Beskrivning"
        name="name"
        register={register}
        autoFocus
        onKeyUp={actionHandler}
      />
    </ModalContentWrapper>
  );
};

export default AddLocalrequirement;
