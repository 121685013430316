// type WasteData = {
//   items: {
//     id: number;
//     transportDocumentId: number;
//     weight: string;
//     weightOriginal: string;
//     label: string;
//   }[];
// };

export type FormWaste = {
  id: number;
  transportDocumentId: number;
  weight: string;
  weightOriginal: string;
  label: string;
};

export const distributeWeight = (
  inputFields: FormWaste[],
  actualWeight: number
): FormWaste[] => {
  const totalCurrentWeight = inputFields.reduce(
    (total, item) => total + +item.weight,
    0
  );
  const difference = actualWeight - totalCurrentWeight;

  let adjustedWeights = inputFields.map((item) => {
    const proportion = +item.weight / totalCurrentWeight;
    return {
      ...item,
      weight: Math.round(+item.weight + difference * proportion).toString(),
    };
  });

  const adjustedWeightSum = adjustedWeights.reduce(
    (total, item) => total + +item.weight,
    0
  );
  const finalDifference = actualWeight - adjustedWeightSum;
  if (finalDifference !== 0) {
    const maxWeightItem = adjustedWeights.reduce((prev, current) =>
      +prev.weight > +current.weight ? prev : current
    );
    maxWeightItem.weight = (
      +maxWeightItem.weight + +finalDifference
    ).toString();
  }

  return adjustedWeights;
};

export const truncateString = (str: string) => {
  const length = 36;
  if (str.length > length) {
    return str.substring(0, length) + "...";
  } else {
    return str;
  }
};
