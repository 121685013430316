import { FC } from "react";
import DangerButton from "./buttons/DangerButton";

type Props = {
  handleButtonClick: () => void;
  isLoading: boolean;
  header: string;
  text: string;
  buttonText: string;
};

export const DangerAction: FC<Props> = ({
  handleButtonClick,
  isLoading,
  header,
  text,
  buttonText,
}) => {
  return (
    <div className="bg-white max-w-lg">
      <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mb-4">
        {header}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p className="leading-6 font-base text-gray-700">{text}</p>
      </div>
      <div className="mt-5">
        <DangerButton onClick={handleButtonClick} isLoading={isLoading}>
          {buttonText}
        </DangerButton>
      </div>
    </div>
  );
};
